import { connect } from 'react-redux';
import HeaderLayout from './HeaderLayout.component';

import {
  setSearchText,
  clearSearchText,
} from '../../../redux/actions/search.actions';

const mapStateToProps = (state) => ({
  searchText: state.search,
});

const mapDispatchToProps = {
  setSearchText,
  clearSearchText,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
