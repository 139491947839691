import React from 'react';
import { Image } from 'antd';
import {
  CERTI_BOOTCAMP,
  CERTI_MINICLASS,
  CERTI_VLC,
  CERTI_LOGO,
  CERTI_SIGN_CEO,
  CERTI_SIGN_HEAD,
} from '../../assets/svg';
import { formatDateSimple } from '../../utils/format';

const TemplateCertif = ({ ...props }) => {
  const generateInfo = () => {
    const dataUser = props.data;
    const render = {
      certificateID: '--',
      leaner: '--',
      program: '--',
      data: null,
    };
    if (dataUser) {
      if (dataUser.first_name && dataUser.last_name) {
        render.leaner = `${dataUser.first_name} ${dataUser.last_name}`;
      } else {
        render.leaner = `${dataUser.name}`;
      }
      render.certificateID = dataUser.certificateID;
      render.program = dataUser.program;
      render.data = dataUser.report;
    }
    return render;
  };
  const templateBootcamp = () => (
    <div
      className="template-bootcamp"
      style={{
        width: 850,
        height: 600,
        position: 'relative',
      }}
    >
      <img
        src={CERTI_BOOTCAMP}
        alt="certif"
        width={850}
        height={600}
        style={{
          position: 'absolute',
        }}
      />
      <div
        className="student-certificate"
        style={{
          width: 'fit-content',
          height: 20,
          position: 'relative',
          top: 152,
          left: 430,
          fontSize: 12,
          color: 'white',
          textTransform: 'uppercase',
        }}
      >
        {generateInfo().certificateID}
      </div>
      <div
        className="student-name"
        style={{
          width: 400,
          height: 40,
          position: 'relative',
          top: 195,
          margin: 'auto',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          fontSize: 16,
          color: 'white',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {generateInfo().leaner}
      </div>
      <div
        className="program-name"
        style={{
          width: 400,
          height: 70,
          margin: 'auto',
          position: 'relative',
          top: 285,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          fontSize: 22,
          color: 'white',
          textAlign: 'center',
        }}
      >
        {generateInfo().program?.program_name}
      </div>
    </div>
  );

  const templateMiniClass = () => (
    <div
      className="template-miniclass"
      style={{
        width: 850,
        height: 600,
        position: 'relative',
        // backgroundImage: `url(${CERTI_MINICLASS})`,
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
      }}
    >
      <img
        src={CERTI_MINICLASS}
        alt="certif"
        width={850}
        height={600}
        style={{
          position: 'absolute',
        }}
      />
      <div
        className="student-certificate"
        style={{
          width: 'fit-content',
          height: 20,
          position: 'relative',
          top: 152,
          left: 430,
          fontSize: 12,
          color: '#EA1A49',
          textTransform: 'uppercase',
        }}
      >
        {generateInfo().certificateID}
      </div>
      <div
        className="student-name"
        style={{
          width: 400,
          height: 40,
          position: 'relative',
          top: 195,
          margin: 'auto',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          fontSize: 16,
          color: '#EA1A49',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {generateInfo().leaner}
      </div>
      <div
        className="program-name"
        style={{
          width: 400,
          height: 70,
          margin: 'auto',
          position: 'relative',
          top: 285,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          fontSize: 22,
          color: '#EA1A49',
          textAlign: 'center',
        }}
      >
        {generateInfo().program?.program_name}
      </div>
    </div>
  );

  const templateVLC = () => (
    <div
      className="template-vlc"
      style={{
        width: 850,
        height: 600,
        position: 'relative',
        // backgroundImage: `url(${CERTI_VLC})`,
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
      }}
    >
      <img
        src={CERTI_VLC}
        alt="certif"
        width={850}
        height={600}
        style={{
          position: 'absolute',
        }}
      />
      <div
        className="student-certificate"
        style={{
          width: 'fit-content',
          height: 20,
          position: 'relative',
          top: 152,
          left: 430,
          fontSize: 12,
          color: 'white',
          textTransform: 'uppercase',
        }}
      >
        {generateInfo().certificateID}
      </div>
      <div
        className="student-name"
        style={{
          width: 400,
          height: 40,
          position: 'relative',
          top: 195,
          margin: 'auto',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          fontSize: 16,
          color: 'white',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {generateInfo().leaner}
      </div>
      <div
        className="program-name"
        style={{
          width: 400,
          height: 70,
          margin: 'auto',
          position: 'relative',
          top: 285,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          fontSize: 22,
          color: 'white',
          textAlign: 'center',
        }}
      >
        {generateInfo().program?.program_name}
      </div>
    </div>
  );

  const templateReport = () => (
    <div
      className="template-report"
      style={{
        width: 850,
        minHeight: 850,
        position: 'relative',
        backgroundColor: 'white',
      }}
    >
      <div
        className="header"
        style={{
          position: 'relative',
          padding: '20px 10px 10px 10px',
          textAlign: 'center',
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>
          TRANSKRIP NILAI
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            fontSize: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              textAlign: 'left',
            }}
          >
            <div style={{ width: 80 }}>ID Sertifikat</div>
            <div>: {generateInfo().certificateID}</div>
          </div>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              textAlign: 'left',
            }}
          >
            <div style={{ width: 80 }}>Program</div>
            <div style={{ width: 300 }}>
              : <b>{generateInfo().program?.program_name}</b>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            fontSize: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              textAlign: 'left',
            }}
          >
            <div style={{ width: 80 }}>Nama</div>
            <div>: {generateInfo().leaner}</div>
          </div>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              textAlign: 'left',
            }}
          >
            <div style={{ width: 80 }}>Periode</div>
            <div style={{ width: 300 }}>
              : {formatDateSimple(generateInfo().program?.schedule_start)} -{' '}
              {formatDateSimple(generateInfo().program?.schedule_end)}
            </div>
          </div>
        </div>
      </div>
      <div
        className="body"
        style={{
          position: 'relative',
          width: 'inherit',
        }}
      >
        <div
          className="logo"
          style={{
            width: 'inherit',
            height: 300,
            position: 'absolute',
            backgroundImage: `url(${CERTI_LOGO})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 500,
            backgroundPosition: 'center',
            opacity: 0.05,
            zIndex: 9,
            top: '15%',
          }}
        />
        <div className="item" style={{ zIndex: 1 }}>
          <div className="custom-table" style={{ margin: '10px' }}>
            <div
              className="header"
              style={{
                display: 'flex',
                textAlign: 'center',
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              <div
                style={{
                  padding: 5,
                  width: 400,
                  border: 'solid 1px #BD224D',
                  borderRight: 'unset',
                }}
              >
                Module
              </div>
              <div
                style={{
                  padding: 5,
                  width: 150,
                  border: 'solid 1px #BD224D',
                  borderRight: 'unset',
                }}
              >
                Post Quiz
              </div>
              <div
                style={{
                  padding: 5,
                  width: 150,
                  border: 'solid 1px #BD224D',
                  borderRight: 'unset',
                }}
              >
                Tugas / Project
              </div>
              <div
                style={{
                  padding: 5,
                  width: 150,
                  border: 'solid 1px #BD224D',
                }}
              >
                *Nilai Module
              </div>
            </div>
            {/* Body Table */}
            {generateInfo().data?.activity_list?.map((item) => (
              <div
                className="body"
                style={{
                  display: 'flex',
                  textAlign: 'center',
                  fontSize: 10,
                }}
              >
                <div
                  style={{
                    textAlign: 'left',
                    padding: '5px 10px',
                    width: 400,
                    border: 'solid 1px #BD224D',
                    borderTop: 'unset',
                    borderRight: 'unset',
                  }}
                >
                  {item.activity_name}
                </div>
                <div
                  style={{
                    padding: 5,
                    width: 150,
                    border: 'solid 1px #BD224D',
                    borderTop: 'unset',
                    borderRight: 'unset',
                  }}
                >
                  {item.nilai_post_quiz}
                </div>
                <div
                  style={{
                    padding: 5,
                    width: 150,
                    border: 'solid 1px #BD224D',
                    borderTop: 'unset',
                    borderRight: 'unset',
                  }}
                >
                  {item.nilai_weekly
                    ? item.nilai_weekly
                    : 'tidak ada tugas individu'}
                </div>
                <div
                  style={{
                    padding: 5,
                    width: 150,
                    border: 'solid 1px #BD224D',
                    borderTop: 'unset',
                  }}
                >
                  {item.nilai_modul}
                </div>
              </div>
            ))}
            <div
              className="body"
              style={{
                display: 'flex',
                textAlign: 'center',
                fontSize: 10,
              }}
            >
              <div
                style={{
                  textAlign: 'left',
                  padding: '5px 10px',
                  width: 400,
                  border: 'solid 1px #BD224D',
                  borderTop: 'unset',
                  borderRight: 'unset',
                }}
              >
                Group Project
              </div>
              <div
                style={{
                  padding: 5,
                  width: 450,
                  border: 'solid 1px #BD224D',
                  borderTop: 'unset',
                }}
              >
                {generateInfo().data?.score_group}
              </div>
            </div>
            <div
              className="body"
              style={{
                display: 'flex',
                textAlign: 'center',
                fontSize: 10,
              }}
            >
              <div
                style={{
                  padding: '5px 10px',
                  width: 400,
                  border: 'solid 1px #BD224D',
                  borderTop: 'unset',
                  borderRight: 'unset',
                }}
              >
                <b>**Nilai Akhir</b>
              </div>
              <div
                style={{
                  padding: 5,
                  width: 450,
                  border: 'solid 1px #BD224D',
                  borderTop: 'unset',
                }}
              >
                <b>{generateInfo().data?.score_final}</b>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: '10px 30px', fontSize: 8 }}>
          <div>
            *(30% Post Quiz + 70% Tugas Individu) / (100% Post Quiz jika tidak
            ada Tugas Individu)
          </div>
          <div>**(5% dari setiap Nilai Module + 30% Group Project)</div>
        </div>
      </div>
      <div
        className="footer"
        style={{
          textAlign: 'center',
          position: 'absolute',
          width: '100%',
          padding: '20px 10px',
          fontSize: 12,
          bottom: 0,
        }}
      >
        <div>Diterbitkan pada Januari 2024</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <div>
            <div>
              <Image src={CERTI_SIGN_CEO} preview={false} width={120} />
            </div>
            <div>
              <b>M. Rizki Fahrurrozi</b>
            </div>
            <div>CEO Boleh Belajar</div>
          </div>
          <div>
            <div>
              <Image src={CERTI_SIGN_HEAD} preview={false} width={120} />
            </div>
            <div>
              <b>Fadhil Hasan Elgianda</b>
            </div>
            <div>Head of Boleh Belajar</div>
          </div>
        </div>
      </div>
    </div>
  );

  const templateRendering = () => {
    let template = null;
    switch (props?.template) {
      case 'bootcamp':
        template = templateBootcamp();
        break;
      case 'miniclass':
        template = templateMiniClass();
        break;
      case 'vlc':
        template = templateVLC();
        break;
      case 'report':
        template = templateReport();
        break;
      default:
        break;
    }
    return template;
  };
  return templateRendering();
};

export default TemplateCertif;
