import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MODERATOR_ROUTE } from '../../navigations/moderator.route';

import config from './Moderator.component.config';

const Moderator = ({ ...props }) => {
  console.log(props);
  return (
    <div>
      <Routes>
        {MODERATOR_ROUTE.map((items) => (
          <Route key={items.key} {...items} />
        ))}
        <Route path="/" element={<Navigate to="/moderator/dashboard" />} />
        <Route path="*" element={<Navigate to="/moderator/dashboard" />} />
      </Routes>
    </div>
  );
};

Moderator.displayName = config.displayName;
Moderator.defaultProps = config.defaultProps;
Moderator.propTypes = config.propTypes;

export default Moderator;
