import React, { createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CloseCircleFilled,
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import { IMG_EMPTY } from '../../../assets/svg';
import {
  BaseLayout,
  DataTable,
  EmptyContent,
  MotionPage,
} from '../../../components';
import config from './AdminQuiz.component.config';
import './AdminQuiz.component.styles.css';
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const AdminQuiz = ({
  clearSearchText,
  createQuiz,
  listQuizData,
  validationQuizData,
  getListQuiz,
  searchText,
  setSearchText,
  setParrentQuiz,
  validateQuiz,
  deleteQuiz,
  ...props
}) => {
  const [apiCallStatus, setApiCallStatus] = React.useState({
    createQuiz: false,
    getListQuiz: false,
    getValidationQuiz: false,
  });
  const [modalCreateVisibility, setModalCreateVisibility] =
    React.useState(false);
  const navigate = useNavigate();
  const isRefForm = React.useRef();
  const location = useLocation();
  const currentMenu = location.pathname;
  const accesedMenu = localStorage.getItem('CURRENT_MENU');
  const numOfPage = JSON.parse(localStorage.getItem('ASSIGNMENT_CURRENT_PAGE'));
  const [current, setCurrent] = React.useState(numOfPage);

  React.useEffect(() => {
    localStorage.setItem('CURRENT_MENU', currentMenu);
    if (accesedMenu !== currentMenu)
      localStorage.setItem('ASSIGNMENT_CURRENT_PAGE', JSON.stringify(1));
    setCurrent(JSON.parse(localStorage.getItem('ASSIGNMENT_CURRENT_PAGE')));
  }, [currentMenu, accesedMenu]);

  const setCurrentPage = (index) => {
    setCurrent(index);
  };

  const quizValidation = (id) => {
    validateQuiz(id)
      .then((...validationData) => {
        const listProgram = (
          <>
            <p>{validationData[0].status_assignment}</p>
            <ul className="list-program">
              {validationData[0].list_program_attached.map((item) => (
                <li className="program-item">{item}</li>
              ))}
            </ul>
            <p>
              Untuk menghapus assignment ini, kamu harus hapus lebih dulu entity
              terkait di program yang tertera.
            </p>
          </>
        );

        if (validationData[0].status_code === 1) {
          Modal.warning({
            title: 'Quiz tidak bisa dihapus',
            content: listProgram,
            centered: true,
            width: 700,
          });
        } else {
          Modal.confirm({
            title: 'Apakah kamu yakin mau menghapus Quiz ini?',
            icon: <QuestionCircleTwoTone />,
            centered: true,
            onOk() {
              quizDelete(id);
            },
          });
        }
      })
      .catch(() =>
        notification.error({
          message: `System Error`,
          description: `Failed to validate quiz. Please try again later.`,
        }),
      );
  };

  const quizDelete = (quizId) => {
    deleteQuiz(quizId)
      .then(() => {
        setApiCallStatus({ ...apiCallStatus, getListQuiz: false });
      })
      .catch((err) => console.log('deleteQuiz', err));
  };

  // useEffect for getListQuiz
  React.useEffect(() => {
    if (apiCallStatus.getListQuiz === false) {
      getListQuiz()
        .catch(() =>
          notification.error({
            message: `System Error`,
            description: `Failed to get list quiz. Please try again later.`,
          }),
        )
        .finally(() => {
          setApiCallStatus({ ...apiCallStatus, getListQuiz: true });
          if (localStorage.getItem('jumpToCreateQuiz')) {
            setModalCreateVisibility(true);
            localStorage.removeItem('jumpToCreateQuiz');
          }
        });
    }

    return () => {
      clearSearchText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallStatus.getListQuiz]);

  const actionDetail = (idx) => (
    <Popconfirm
      placement="left"
      title="Are you sure want to delete this quiz?"
      onConfirm={(e) => {
        quizValidation(idx);
        e.stopPropagation();
      }}
      onCancel={(e) => {
        // Close the Popconfirm
        e.stopPropagation();
      }}
      okText="Delete"
      cancelText="Cancel"
    >
      <Tooltip title="Remove this Quiz">
        <Button
          type="secondary"
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Delete
        </Button>
      </Tooltip>
    </Popconfirm>
  );
  const actionExtraItem = (record) => {
    // const dataArr =
    //   (Array.isArray(listQuizData?.data) && listQuizData?.data) || [];
    // const foundData = dataArr.find((datum) => datum.id === idx);
    setParrentQuiz(record).then(() =>
      navigate(`${window.location.pathname}/detail?id=${record.id}`),
    );
  };

  const actionCreate = () => {
    const fields = isRefForm.current.getFieldsValue();
    const requestbody = {
      duration: 0,
      min_score: 0,
      retake_limit: 0,
      ...fields,
    };
    createQuiz(requestbody).then(() => {
      setModalCreateVisibility(false);
      setApiCallStatus({ ...apiCallStatus, getListQuiz: false });
    });
  };

  const modalCreate = (
    <Modal
      title={
        <div className="header-custom">
          <Title level={5}>Create Assignment</Title>
          <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
      }
      centered
      open={modalCreateVisibility}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => setModalCreateVisibility(false)}
      footer={null}
      destroyOnClose
    >
      <Form
        ref={isRefForm}
        layout="vertical"
        name="createQuiz"
        onFinish={actionCreate}
      >
        <Form.Item
          label="Name"
          name="quiz_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        {/* <Form.Item
          label="Duration"
          name="duration"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input
            type="number"
            placeholder="input here ..."
            min={0}
            prefix="Minutes: "
          />
        </Form.Item>
        <Form.Item
          label="Minimum Score"
          name="min_score"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input
            type="number"
            placeholder="input here ..."
            min={0}
            prefix="Score: "
          />
        </Form.Item>
        <Form.Item
          label="Take Limit"
          name="retake_limit"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input
            type="number"
            placeholder="input here ..."
            min={0}
            prefix="Limit: "
          />
        </Form.Item> */}
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => setModalCreateVisibility(false)}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );

  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout
        title="Assignment"
        action={[
          <Input
            size="middle"
            placeholder="Search Quiz"
            allowClear
            style={{
              width: 300,
            }}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />,
          <div className="">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setModalCreateVisibility(true)}
            >
              Create Assignment
            </Button>
          </div>,
          // <Select defaultValue="lucy" allowClear>
          //   <Select.Option value="lucy">Filter by Recently</Select.Option>
          // </Select>,
        ]}
        collapsedMenu={props?.common?.collapsedMenu}
      >
        <div id="AdminQuiz">
          <Card>
            {Array.isArray(listQuizData?.data) ? (
              // <DataList
              //   withPagination={5}
              //   disablePagination
              //   data={listQuizData?.data?.filter(
              //     (item) =>
              //       item?.quiz_name
              //         ?.toLowerCase()
              //         .includes(searchText?.toLowerCase()) ||
              //       item?.description
              //         ?.toLowerCase()
              //         ?.includes(searchText?.toLowerCase()),
              //   )}
              //   loading={listQuizData?.isLoading}
              //   haveAction
              //   actionDetail={actionDetail}
              //   actionExtraItem={actionExtraItem}
              // />
              <DataTable
                loading={listQuizData?.isLoading}
                columns={config.defaultProps.headColumn}
                dataSource={listQuizData?.data?.filter((item) =>
                  item?.quiz_name
                    ?.toLowerCase()
                    ?.includes(searchText?.toLowerCase()),
                )}
                haveAction={[actionDetail]}
                clickOnRow={actionExtraItem}
                withPagination={12}
                setCurrentPage={setCurrentPage}
                currentPage={current}
              />
            ) : (
              <EmptyContent
                title="Kamu Belum Membuat Quiz"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet et
                veritatis, iste rerum illum, quod accusamus perferendis reprehenderit
                delectus quia qui saepe neque quaerat, earum ipsum libero. Officiis,
                ad suscipit!"
                onButtonPressed={() => setModalCreateVisibility(true)}
                buttonText="Create Assignment"
              />
            )}
          </Card>
        </div>
        {modalCreate}
      </BaseLayout>
    </MotionPage>
  );
};

AdminQuiz.displayName = config.displayName;
AdminQuiz.defaultProps = config.defaultProps;
AdminQuiz.propTypes = config.propTypes;

export default AdminQuiz;
