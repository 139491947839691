import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { List, Card, Typography, Row, Col, Button } from 'antd';

import { Calendar, DateObject } from 'react-multi-date-picker';
import Gregorian from 'react-date-object/calendars/gregorian';
import GregorianEn from 'react-date-object/locales/gregorian_en';

import { formatDate, formatTime } from '../../../utils/format';
import { StudentLayout, LoadingEvent, MotionPage } from '../../../components';

import {
  ICON_CALENDAR_CONFERENCE,
  ICON_CALENDAR_QUIZ,
  ICON_CALENDAR_TUGAS,
  ICON_CALENDAR_VIDEO,
} from '../../../assets/svg';

import config from './StudentCalendarEvents.component.config';
import './StudentCalendarEvents.component.styles.less';

const { Title, Text } = Typography;
const idLocale = require('moment/locale/id');
moment.locale('id', idLocale);

const StudentCalendarEvents = ({
  upcomingEvents,
  getUpcomingEvents,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  ...props
}) => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    firsload: false,
    showLoading: false,
  });

  const calendarRef = React.useRef();
  const [calendarData, setCalendarData] = React.useState({
    selectedDate: 'notset',
    events:
      upcomingEvents.data !== undefined &&
      upcomingEvents.data.map((events) =>
        events.data.length > 0 ? new Date(events.date) : null,
      ),
  });

  const mountingFirstData = () => {
    getUpcomingEvents();
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.firsload = true;
      return { ...currentState };
    });
  };

  React.useEffect(() => {
    if (!state.firsload) mountingFirstData();
  });

  const checkDateIsDefault = (dateParams) => {
    const defaultData = upcomingEvents.data?.find(
      (events) => events.date === dateParams,
    );
    const checkInState = calendarData.events?.find(
      (events) => moment(new Date(events)).format('YYYY-MM-DD') === dateParams,
    );

    setCalendarData((prevCalendar) => {
      const currentState = { ...prevCalendar };
      currentState.selectedDate = dateParams;
      return { ...currentState };
    });

    if (defaultData !== undefined && checkInState !== undefined) {
      // logic for date in endpoint and in state
      setCalendarData((prevCalendar) => {
        const currentState = { ...prevCalendar };
        currentState.events = [];
        upcomingEvents.data.forEach((events) => {
          if (events.data.length > 0) {
            currentState.events.push(new Date(events.date));
          }
        });
        return { ...currentState };
      });
    } else if (defaultData === undefined && checkInState === undefined) {
      // logic for selected current in other range
      setCalendarData((prevCalendar) => {
        const currentState = { ...prevCalendar };
        currentState.events = [];
        upcomingEvents.data.forEach((events) => {
          if (events.data.length > 0) {
            currentState.events.push(new Date(events.date));
          }
        });
        currentState.events.push(new Date(dateParams));
        return { ...currentState };
      });
    }
  };
  const renderCalendarComponent = () => {
    const rangeDateExisting = {};
    return (
      <Calendar
        ref={calendarRef}
        value={calendarData.events}
        range={false}
        multiple
        calendar={Gregorian}
        locale={GregorianEn}
        format="YYYY-MM-DD"
        numberOfMonths={1}
        minDate={new Date()}
        mapDays={(date) => {
          const convDate = date.date.format('D');
          // const newDate = new DateObject(date).convert(Gregorian, GregorianEn);
          return {
            children: (
              <div
                className="custom-date"
                onClick={() => checkDateIsDefault(date.date.format())}
              >
                {convDate}
              </div>
            ),
          };
        }}
      />
    );
  };

  const actionForwardEvents = (data) => {
    const programID = get(data, 'program_id');
    const activityID = get(data, 'activity_id');
    const entityID = get(data, 'entity_id');

    const params = {
      id: programID,
      program_name: data.program_name,
      url: null,
    };
    if (programID && activityID && entityID) {
      params.url = `course=${programID}&act=${activityID}&ent=${entityID}&events=calendar`;
    } else {
      params.url = `course=${programID}&act=${activityID}$events=calendar`;
    }

    setState((prevState) => {
      // set loading indicator true
      const currentState = { ...prevState };
      currentState.showLoading = !currentState.showLoading;
      return { ...currentState };
    });
    getStudentProgramDetails(params).then((programActivity) => {
      // getting data detail program activity include list activity
      programActivity.forEach((activity) => {
        // looping for generating entity list per activity, nested entity list to current activity
        const activityData = activity;
        getStudentProgramActivityEntity(activityData?.id);
      });
      setTimeout(() => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.showLoading = !currentState.showLoading;
          return { ...currentState };
        });
        navigate(`/student/programs/course?${params.url}`);
      }, 1000);
    });
  };

  const getConditionDatetime = (dateScheduled) => {
    const dateToday = moment(new Date());
    const dateFromData = moment(new Date(dateScheduled));
    const diff = dateToday.diff(dateFromData);
    const diffDuration = moment.duration(diff);

    const diffDays = diffDuration.days();
    const diffHours = diffDuration.hours();
    const diffMinutes = diffDuration.minutes();

    let decisitionDatetime = false;
    if (diffDays >= 0 && diffHours >= 0 && diffMinutes >= 0) {
      decisitionDatetime = true;
    }

    // console.log('Total Duration in millis:', diffDuration.asMilliseconds());
    // console.log('Days:', diffDuration.days());
    // console.log('Hours:', diffDuration.hours());
    // console.log('Minutes:', diffDuration.minutes());
    // console.log('Seconds:', diffDuration.seconds());
    return decisitionDatetime;
  };
  const renderEntityData = (data) => {
    const entityName = data.entity_name;
    const programName = data.program_name;
    const scheduleTime = formatTime(data.schedule_date);
    const checkConditionDateTime = getConditionDatetime(data.schedule_date);

    let icon = null;
    switch (data.activity_type_id) {
      case 1:
        icon = <ICON_CALENDAR_CONFERENCE />;
        break;
      case 2:
        icon = <ICON_CALENDAR_VIDEO />;
        break;
      case 3:
        icon = <ICON_CALENDAR_QUIZ />;
        break;
      case 4:
        icon = <ICON_CALENDAR_QUIZ />;
        break;
      case 5:
        icon = <ICON_CALENDAR_TUGAS />;
        break;
      default:
        icon = <ICON_CALENDAR_TUGAS />;
        break;
    }

    return (
      <Card>
        <div className="content-data">
          <div className="icon">{icon}</div>
          <div className="information">
            <div className="hour">{scheduleTime}</div>
            <div className="title">
              <Title level={4}>{entityName}</Title>
            </div>
            <div className="program">
              <Text>{programName}</Text>
            </div>
            <div className="action-button">
              <Button
                type="primary"
                size="large"
                onClick={() => actionForwardEvents(data)}
                hidden={checkConditionDateTime ? false : 'hidden'}
              >
                Kerjakan Sekarang
              </Button>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  const renderTodayEvents = () => {
    const eventToday = upcomingEvents.data?.find(
      (events) => events.date === today,
    );
    return (
      <div>
        <div className="entity-data">
          {eventToday !== undefined && (
            <List
              itemLayout="horizontal"
              dataSource={eventToday.data}
              renderItem={(entity) => renderEntityData(entity)}
              locale={{
                emptyText: 'Tidak Ada Kegiatan',
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderUpcomingEvents = () => {
    const newDataUpcoming = [];
    upcomingEvents.data?.forEach((events) => {
      if (events.date !== today) {
        newDataUpcoming.push(events);
      }
    });
    return (
      <List
        itemLayout="horizontal"
        dataSource={newDataUpcoming}
        loading={upcomingEvents.loading}
        renderItem={(itemEvents) => {
          const scheduleDate = moment(new Date(itemEvents.date)).format(
            'YYYY-MM-DD',
          );

          let checkHideContent = false;
          if (calendarData.selectedDate !== 'notset') {
            if (
              calendarData.selectedDate !== scheduleDate &&
              calendarData.selectedDate !== today
            ) {
              checkHideContent = 'hidden';
            }
          }

          return (
            <List.Item hidden={checkHideContent}>
              <div className="title-events">{formatDate(itemEvents.date)}</div>
              <div className="entity-data">
                <List
                  itemLayout="horizontal"
                  dataSource={itemEvents.data}
                  renderItem={(entity) => renderEntityData(entity)}
                  locale={{
                    emptyText: 'Tidak Ada Kegiatan',
                  }}
                />
              </div>
            </List.Item>
          );
        }}
      />
    );
  };

  return (
    <MotionPage motionType="fadeIn">
      <StudentLayout>
        <div
          id="StudentCalendarEvents"
          className={`${isMobile && 'mobile-version'}`}
        >
          <Title level={3} hidden={isMobile ? 'hidden' : false}>
            Kalender
          </Title>
          <div className="calendar-content">
            <div className="section-calendar">{renderCalendarComponent()}</div>
            <div className="section-event">
              <div className="section-today">
                <Title level={4}>
                  Hari ini, {moment(new Date()).format('Do MMMM, YYYY')}
                </Title>
                {renderTodayEvents()}
              </div>
              <div className="section-upcoming">
                <Title level={4}>Kegiatan Mendatang</Title>
                {renderUpcomingEvents()}
              </div>
            </div>
          </div>
        </div>
        <LoadingEvent showLoading={state.showLoading} />
      </StudentLayout>
    </MotionPage>
  );
};

StudentCalendarEvents.displayName = config.displayName;
StudentCalendarEvents.defaultProps = config.defaultProps;
StudentCalendarEvents.propTypes = config.propTypes;

export default StudentCalendarEvents;
