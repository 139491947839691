import { connect } from 'react-redux';
import UserAccount from './UserAccount.component';

import { userUpdatePassword } from '../../redux/actions/authUser.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  users: state.users,
});

const mapDispatchToProps = {
  userUpdatePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
