import { connect } from 'react-redux';
import StudentDashboard from './StudentDashboard.component';

import {
  getStudentCourseList,
  getStudentProgramList,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  updateStudentProgramActivity,
  getCourseDetail,
  getStudentLearningProgress,
} from '../../../redux/actions/student.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  programList: state.student.programList,
  programSelection: state.student.programSelection,
  common: state.common.settings,
  users: state.users,
});

const mapDispatchToProps = {
  getStudentCourseList,
  getStudentProgramList,
  getCourseDetail,
  getStudentLearningProgress,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  updateStudentProgramActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);
