import React, { useCallback, useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';

import config from './YoutubePlayerCustom.component.config';
import './YoutubePlayerCustom.component.styles.css';

const YoutubePlayerCustom = ({
  roleUser,
  material,
  selectionContent,
  activitySessionUser,
  onCreateActivitySession,
  onUpdateActivitySession,
}) => {
  const [player, setPlayer] = useState(null);
  const [vidState, setVidState] = useState(-1);
  const [duration, setDuration] = useState(null);
  const [finishedWatching, setFinishedWatching] = useState(false);

  const opts = {
    playerVars: {
      disablekb: 1,
      modestbranding: 1,
    },
  };
  const finishWatching =
    activitySessionUser === undefined ? 1 : activitySessionUser.status === 1;

  const MINIMUM_TIME_REMAINING =
    activitySessionUser === undefined
      ? 0
      : activitySessionUser.vod_duration - 60;

  const trackVidState = (videoEvent) => {
    switch (videoEvent.data) {
      case 0:
        // On End, If in some cases the video is mark not finished after 30 Seconds
        // This funcntion still going to marked the video
        setVidState(videoEvent.data);
        if (!finishedWatching) {
          markedAsFinish();
        }
        break;
      case 1:
        // On Play, Create session
        setVidState(videoEvent.data);
        // First time watching video
        if (activitySessionUser?.id === null) {
          createSessionFirst(videoEvent);
        }
        break;
      case 2:
        // On Pause, Save last timestamp
        setVidState(videoEvent.data);
        updateCurrentTimestamp(videoEvent.target.getCurrentTime());
        break;
      default:
        break;
    }
  };

  const getVideoID = (materialLink) => {
    let videoID;
    if (materialLink.includes('watch?v')) {
      const splittedLink = materialLink.split('v=');
      videoID = splittedLink[splittedLink.length - 1];
    } else if (materialLink.includes('youtu.be')) {
      const splittedLink = materialLink.split('/');
      videoID = splittedLink[splittedLink.length - 1];
    } else {
      videoID = materialLink;
    }

    return videoID;
  };

  const createSessionFirst = (event) => {
    const durationInSeconds = event.target.getDuration();
    setDuration(durationInSeconds);
    if (activitySessionUser?.id === null) {
      // create session when learner watching first time
      const paramsSession = {
        entity_id: selectionContent?.entityID,
        vod_duration: duration,
        vod_current_time: 0,
      };
      if (onCreateActivitySession !== undefined) {
        onCreateActivitySession(paramsSession);
      }
    }
  };

  const markedAsFinish = useCallback(() => {
    if (onUpdateActivitySession !== undefined) {
      onUpdateActivitySession({
        id: activitySessionUser.id,
        vod_current_time: duration,
        status: 1,
      });
    }
  }, [duration, activitySessionUser?.id, onUpdateActivitySession]);
  // }, [duration, activitySessionUser.id, onUpdateActivitySession]);

  const updateCurrentTimestamp = useCallback(
    (currenTimestampInSeconds) => {
      if (onUpdateActivitySession !== undefined) {
        console.log('Saved Timestamp: ', currenTimestampInSeconds);
        onUpdateActivitySession({
          id: activitySessionUser.id,
          vod_current_time: finishWatching
            ? duration
            : currenTimestampInSeconds,
          status: finishWatching ? 1 : 0,
        });
      }
    },
    [duration, finishWatching, onUpdateActivitySession, activitySessionUser],
  );

  const playVideoFrom = (event, isFinish) => {
    let timestamp;
    console.log('Retrieved Timestamp: ', activitySessionUser.vod_current_time);
    isFinish
      ? (timestamp = 0)
      : (timestamp = activitySessionUser.vod_current_time);
    event?.target.seekTo(timestamp);
    event?.target.pauseVideo();
    setTimeout(() => {
      event?.target.playVideo();
    }, 2000);
    clearTimeout();
  };

  const onReady = (event) => {
    setPlayer(event.target);
    const durationInSeconds = event.target.getDuration();
    setDuration(durationInSeconds);
    activitySessionUser.status === 1
      ? playVideoFrom(event, true)
      : playVideoFrom(event);
  };

  useEffect(() => {
    // Update the current time every 30 seconds
    const intervalId = setInterval(() => {
      if (player && vidState === 1) {
        if (MINIMUM_TIME_REMAINING <= player.getCurrentTime()) {
          setFinishedWatching(true);
          markedAsFinish();
        } else {
          updateCurrentTimestamp(player.getCurrentTime());
        }
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [
    player,
    MINIMUM_TIME_REMAINING,
    vidState,
    markedAsFinish,
    updateCurrentTimestamp,
  ]);

  return (
    <div className="video-container">
      <div>
        <YouTube
          videoId={getVideoID(material)}
          onReady={onReady}
          onStateChange={trackVidState}
          opts={opts}
        />
      </div>
    </div>
  );
};

YoutubePlayerCustom.displayName = config.displayName;
YoutubePlayerCustom.defaultProps = config.defaultProps;
YoutubePlayerCustom.propTypes = config.propTypes;

export default YoutubePlayerCustom;
