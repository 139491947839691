import { connect } from 'react-redux';
import Admin from './Admin.component';
import { updateCollapsedMenu } from '../../redux/actions/common.actions';
import { fConsumeToken } from '../../redux/actions/authUser.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  users: state.users,
});

const mapDispatchToProps = {
  fConsumeToken,
  updateCollapsedMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
