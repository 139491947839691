import { SET_CERTIFICATE_LIST } from '../reduxType';

const initialState = {
  list: {
    data: [],
    isLoading: false,
  },
};

export default (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_CERTIFICATE_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
