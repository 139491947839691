const displayName = 'AdminProgamBundling';
const propTypes = {};
const defaultProps = {
  dummData: () => {
    const data = [];
    for (let index = 0; index < 10; ) {
      data.push({
        id: index,
        program_bundling: `Porgram ${index}`,
        program_sku: 'dsadasdasd',
        created_at: '2022-06-01 08:40:00',
        updated_at: '2022-06-01 23:00:00',
      });
      index += 1;
    }
    return data;
  },
  headColumn: [
    {
      title: '#ID',
      key: 'id',
      width: 50,
      fixed: true,
      sortNumber: true,
    },
    {
      title: 'Program Bundling',
      key: 'program_bundling',
      sort: true,
      width: 220,
      fixed: true,
    },
    {
      title: 'Program SKU',
      key: 'program_sku',
      sort: true,
      width: 150,
    },
    {
      title: 'Created Date',
      key: 'created_at',
      formatDateTime: true,
      sortFormatDate: true,
      width: 250,
    },
    {
      title: 'Updated Date',
      key: 'updated_at',
      formatDateTime: true,
      sortFormatDate: true,
      width: 250,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      width: 120,
    },
  ],
};

export default { displayName, propTypes, defaultProps };
