import _, { isNaN } from 'lodash';

export const getParamURL = (param) => {
  const rawUrl = window.location.search;

  const objectURL = _.chain(rawUrl)
    .replace('?', '')
    .split('&')
    .map(_.partial(_.split, _, '=', 2))
    .fromPairs()
    .value();

  const getParam = objectURL[param] || '';

  return getParam;
};
