import React from 'react';
import { format } from 'date-fns';
import { Col, List, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import config from './DataListHighlight.component.config';
import './DataListHighlight.component.styles.css';

const { Title, Text, Paragraph } = Typography;

const DataListHighlight = ({ sourceData, isLoading, ...props }) => {
  const renderSchedule = (data) => {
    const date = data.split('/');
    let parsingDate = null;
    if (date.length > 1) {
      parsingDate = `${format(new Date(date[0]), 'dd MMM')} - ${format(
        new Date(date[1]),
        'dd MMM yyyy',
      )}`;
    } else {
      parsingDate = format(new Date(date), 'EEEE, dd MMM yyy');
    }
    return parsingDate;
  };

  const renderInstructure = (data) => {
    let instructure = null;
    let reInstructure = [];
    if (Array.isArray(data)) {
      instructure = data.length;
      for (let index = 0; index < 2; ) {
        reInstructure.push(
          <div className="icon-instructure">
            <UserOutlined />
          </div>,
        );
        index += 1;
      }
      if (instructure > 2) {
        reInstructure.push(
          <div className="icon-instructure">+{data.length - 2}</div>,
        );
      }
    } else {
      reInstructure = <Text>{data}</Text>;
    }
    return reInstructure;
  };

  const renderLearners = (data) => {
    const student = data.student.length;
    const group = data.group.length;
    const reLearners = [
      <div className="learners">{student} Learners</div>,
      <div className="learners">{group} Group</div>,
    ];
    return reLearners;
  };

  const renderItem = (item) => {
    const data = [];
    Object.keys(item).forEach((idx) => {
      let colData = null;
      const titleIndex = idx.replaceAll('_', ' ', idx);
      const titleConvert =
        titleIndex.charAt(0).toUpperCase() + titleIndex.slice(1);
      if (idx !== 'id') {
        if (config.defaultProps.ignoreTitle.indexOf(idx) < 0)
          colData = (
            <Col md={6}>
              <Title level={5}>{titleConvert}</Title>
              <Paragraph
                ellipsis={{
                  rows: 3,
                  expandable: false,
                }}
              >
                {item[idx]}
              </Paragraph>
            </Col>
          );

        if (config.defaultProps.titleDate.indexOf(idx) >= 0)
          colData = (
            <Col md={6}>
              <Title level={5}>{titleConvert}</Title>
              <Paragraph
                ellipsis={{
                  rows: 3,
                  expandable: false,
                }}
              >
                {renderSchedule(item[idx])}
              </Paragraph>
            </Col>
          );
      }
      // const colData = (
      //   <Col flex="auto" key={idx}>
      //     <Title level={5}>{idx.charAt(0).toUpperCase() + idx.slice(1)}</Title>
      //     <div className="row-data">
      //       {config.defaultProps.ignoreTitle.indexOf(idx) < 0 && (
      //         <Text>{item[idx]}</Text>
      //       )}

      //       {idx === 'schedule' || idx === 'create date'
      //         ? renderSchedule(item[idx])
      //         : null}

      //       {idx === 'instructor' && renderInstructure(item[idx])}
      //       {idx === 'learners & groups' && renderLearners(item[idx])}
      //     </div>
      //   </Col>
      // );
      data.push(colData);
    });
    return (
      <List.Item>
        <Row gutter={24} style={{ width: '100%' }} justify="start">
          {data}
        </Row>
      </List.Item>
    );
  };

  return (
    <div id="DataListHighlight">
      <List
        key="DataListHighlight"
        size="large"
        itemLayout="horizontal"
        dataSource={sourceData}
        renderItem={(item) => renderItem(item)}
        loading={isLoading}
        pagination={{
          defaultPageSize: 5,
          pageSize: 5,
          hideOnSinglePage: true,
          disabled: true,
        }}
      />
    </div>
  );
};

DataListHighlight.displayName = config.displayName;
DataListHighlight.defaultProps = config.defaultProps;
DataListHighlight.propTypes = config.propTypes;

export default DataListHighlight;
