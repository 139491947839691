import React from 'react';
import moment from 'moment';
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Upload,
  Button,
  Switch,
  Popover,
  Row,
  Col,
  DatePicker,
} from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../services/aws.service';
import config from './ModalCreateActivity.component.config';
import './ModalCreateActivity.component.styles.css';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea, Search } = Input;

const ModalCreateActivity = ({
  actionCreate,
  showModal,
  closeModal,
  actionCloseModal,
  sourceLessonData,
  isEdit,
}) => {
  const refForm = React.useRef();
  const [listLesson, setListLesson] = React.useState(sourceLessonData);
  const [visibleLessonList, setVisibleLessonList] = React.useState(false);
  const handleVisibleChange = () => setVisibleLessonList(!visibleLessonList);
  const onKeywordChange = (e) => {
    const keywordText = e.target.value;
    let filteredList = sourceLessonData;
    if (keywordText !== '') {
      filteredList = sourceLessonData.filter((item) =>
        item?.lesson_name.toLowerCase().includes(keywordText.toLowerCase()),
      );
    }
    setListLesson(filteredList);
  };

  const selectExistingLesson = (lesson) => {
    refForm.current.setFieldsValue({
      master_id: lesson?.id,
      activity_name: lesson?.lesson_name,
      activity_tags: lesson?.lesson_tags,
      description: lesson?.description,
    });
  };
  const listExistingLesson = () => (
    <div>
      <Search
        placeholder="search..."
        onChange={onKeywordChange}
        style={{ marginBottom: 20 }}
      />
      <div className="lesson-list">
        <Row gutter={24}>
          {listLesson.map((item) => (
            <Col md={24} className="item-lesson-existing">
              <Text>{item?.lesson_name}</Text>
              <Button onClick={() => selectExistingLesson(item)}>Select</Button>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );

  const [state, setState] = React.useState({
    fileList: [],
    onUploadFile: false,
  });
  const actionUpload = async (upload) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.onUploadFile = !currentState.onUploadFile;
      return { ...currentState };
    });
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/activity',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };

  const createActivity = () => {
    const fields = refForm.current.getFieldsValue();
    const inputData = {
      ...fields,
      master_id: fields?.master_id !== undefined ? fields?.master_id : 0,
      moderator_id:
        fields?.moderator_id !== undefined ? fields?.moderator_id : 0,
      is_active: fields?.is_active === true ? 1 : 0,
      banner_image:
        state?.fileList[0]?.uid !== undefined ? state?.fileList[0]?.uid : '',
      scheduled_date_start:
        fields.schedule_start !== undefined
          ? moment(fields.schedule_start).format('YYYY-MM-DD H:m:s')
          : null,
      scheduled_date_end:
        fields.schedule_end !== undefined
          ? moment(fields.schedule_end).format('YYYY-MM-DD H:m:s')
          : null,
    };
    actionCreate(inputData).then(() => {
      setState({ fileList: [] });
      refForm.current.setFieldsValue({
        master_id: '',
        moderator_id: '',
        activity_name: '',
        description: '',
      });
    });
    closeModal({ action: actionCloseModal });
  };

  return (
    <Modal
      title={
        <div className="header-custom">
          <Title level={3}>Create Activity</Title>
        </div>
      }
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => closeModal({ action: actionCloseModal })}
      footer={null}
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="vertical"
        id="ModalCreateActivity"
        onFinish={() => createActivity()}
      >
        <div
          style={{
            display: 'none',
            width: '100%',
            textAlign: 'right',
            marginBottom: 15,
          }}
        >
          <Popover
            content={listExistingLesson}
            title="Lesson"
            trigger="click"
            placement="bottom"
            open={visibleLessonList}
            onOpenChange={handleVisibleChange}
          >
            <Button type="primary">Select from Existing Lesson</Button>
          </Popover>
        </div>
        <Form.Item name="master_id" hidden>
          <Input value={0} type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Activity Name"
          name="activity_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Activity Tags"
          name="activity_tags"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <Form.Item label="Upload Banner" hidden>
          <Form.Item name="banner_image" noStyle hidden={state.onUploadFile}>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={actionUpload}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <div hidden={!state.onUploadFile} style={{ textAlign: 'center' }}>
            <p>Banner uploading, please wait...</p>
          </div>
        </Form.Item>
        <Form.Item label="Moderator Name" name="moderator_id" hidden={!isEdit}>
          <Input value={0} type="text" placeholder="input here ..." />
        </Form.Item>
        <Row gutter={24}>
          <Col flex="auto">
            <Form.Item label="Schedule start" name="schedule_start">
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm:00"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item label="Schedule end" name="schedule_end">
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm:00"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Is Active" name="is_active" hidden={!isEdit}>
          <Switch />
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => closeModal({ action: actionCloseModal })}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalCreateActivity.displayName = config.displayName;
ModalCreateActivity.defaultProps = config.defaultProps;
ModalCreateActivity.propTypes = config.propTypes;

export default ModalCreateActivity;
