import PropTypes from 'prop-types';

const displayName = 'ListLearnerPrograms';
const propTypes = {};
const defaultProps = {
  dummData: () => {
    const data = [];
    for (let index = 0; index < 10; ) {
      data.push({
        id: index,
        'Learners name': `Learners ${index}`,
        group: `Group ${index}`,
        project: `Project ${index}`,
        action: true,
      });
      index += 1;
    }
    return data;
  },
};

export default { displayName, propTypes, defaultProps };
