import { connect } from 'react-redux';
import StudentPrograms from './StudentPrograms.component';

import {
  setStudentProgram,
  getStudentProgramList,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  updateStudentProgramActivity,
} from '../../../redux/actions/student.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  programList: state.student.programList,
  programSelection: state.student.programSelection,
});

const mapDispatchToProps = {
  setStudentProgram,
  getStudentProgramList,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  updateStudentProgramActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentPrograms);
