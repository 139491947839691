import React, { useState, useEffect } from 'react';
import { isNaN, isObject } from 'lodash';

import { Table, Input, Row, Col, Tag, Progress, Typography } from 'antd';
import { formatNumber, formatDate, formatDateTime } from '../../utils/format';

import config from './DataTable.component.config';
import './DataTable.component.styles.less';

const { Paragraph } = Typography;

const DataTable = ({
  title,
  columns,
  dataSource,
  search,
  withPagination,
  disablePagination,
  haveAction,
  clickOnRow,
  loading,
  setCurrentPage,
  currentPage,
  currentFilter,
  filteredDataByTag,
}) => {
  const currentMenu = localStorage.getItem('CURRENT_MENU');
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const [value, setValue] = useState('');
  const indicatorColour = [
    '#0a7ce5',
    '#1F36C1',
    '#3ca1ff',
    '#54adff',
    '#b6dbff',
  ];

  const filterInput = (
    <Input
      size="small"
      value={value}
      onChange={(e) => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = dataSource.filter((data) => {
          let isInclude = false;
          columns
            .map((item) => item.key)
            .every((key) => {
              if (
                data[key].toLowerCase().includes(currValue.toLocaleLowerCase())
              ) {
                isInclude = true;
              }
              return !isInclude;
            });
          return isInclude;
        });
        setFilteredDataSource(filteredData);
      }}
    />
  );

  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);

  const generateColumns = () => {
    let width = 0;
    let keyDefault = 0;
    const data = columns.map((val) => {
      width += val.width;
      const keyIndexing = val.key ? val.key : keyDefault;
      const arrayColumn = {
        title: val.title,
        dataIndex: keyIndexing,
        width: val.width,
        ...(val.fixed && {
          fixed: 'left',
        }),
        ...(val.sort && {
          sorter: (a, b) => a[keyIndexing].localeCompare(b[keyIndexing]),
        }),
        ...(val.defaultSortOrder && {
          defaultSortOrder: val.defaultSortOrder,
        }),
        ...(val.tags && {
          render: (keyData) => (
            <>
              {keyData.map((a) => (
                <Tag style={{ borderRadius: '15px' }} color="#1e87f0" key={a}>
                  {a}
                </Tag>
              ))}
            </>
          ),
        }),
        ...(val.formatParagraph && {
          render: (keyData) => (
            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: false,
              }}
            >
              {keyData}
            </Paragraph>
          ),
        }),
        ...(val.percentage && {
          render: (keyData) => (
            <>
              <Row>
                <Col flex={1} style={{ marginRight: '5px' }}>
                  {keyData[0]}%
                </Col>
                <Col flex={4} style={{ width: '150px' }}>
                  <Progress
                    percent={keyData[0]}
                    showInfo={false}
                    strokeColor="#ff5821"
                    size="small"
                  />
                </Col>
              </Row>
              <Row>
                <Col flex={1} style={{ marginRight: '5px' }}>
                  {keyData[1]}%
                </Col>
                <Col flex={4} style={{ width: '150px' }}>
                  <Progress
                    percent={keyData[1]}
                    showInfo={false}
                    strokeColor="#fdb400"
                    size="small"
                  />
                </Col>
              </Row>
            </>
          ),
        }),
        ...(val.sortNumber && {
          sorter: (a, b) => {
            const ac = a[keyIndexing];
            const bc = b[keyIndexing];
            const res = ac - bc;
            return res;
          },
        }),
        ...(val.formatNumber && {
          render: (keyData) =>
            formatNumber(keyData, val.numberFixed ? val.numberFixed : 0),
        }),
        ...(val.sortFormatNumber && {
          sorter: (a, b) => a[keyIndexing] - b[keyIndexing],
        }),
        ...(val.formatDate && {
          render: (keyData) => formatDate(keyData),
        }),
        ...(val.formatDateTime && {
          render: (keyData) => formatDateTime(keyData),
        }),
        ...(val.sortFormatDate && {
          sorter: (a, b) =>
            new Date(a[keyIndexing]).getTime() -
            new Date(b[keyIndexing]).getTime(),
        }),
        ...(val.actionRows && {
          render: (keyData) => (
            <div className="action-table">
              {haveAction && haveAction.map((items) => items(keyData))}
            </div>
          ),
        }),
      };
      keyDefault += 1;
      return arrayColumn;
    });

    return {
      width,
      data,
    };
  };
  const paginationConfig = {
    current: currentPage,
    showSizeChanger: false,
    defaultPageSize: 10,
    onChange: (item) => {
      switch (currentMenu) {
        case '/admin/programs':
          localStorage.setItem('PROGRAMS_CURRENT_PAGE', JSON.stringify(item));
          break;
        case '/admin/lessons':
          localStorage.setItem('LESSONS_CURRENT_PAGE', JSON.stringify(item));
          break;
        case '/admin/assignment':
          localStorage.setItem('ASSIGNMENT_CURRENT_PAGE', JSON.stringify(item));
          break;
        default:
          null;
      }
      // localStorage.setItem('PROGRAMS_CURRENT_PAGE', JSON.stringify(item));
      setCurrentPage(item);
    },
    pageSize: withPagination,
    hideOnSinglePage: disablePagination,
  };
  return (
    <div>
      {title && <h1>{title}</h1>}
      <div id="DataTable">
        {search && (
          <Row>
            <Col
              span={20}
              style={{
                textAlign: 'right',
                paddingRight: '10px',
                paddingTop: '4px',
              }}
            >
              Search:
            </Col>
            <Col span={4}>{filterInput}</Col>
          </Row>
        )}
        <Table
          dataSource={currentFilter ? filteredDataByTag : filteredDataSource}
          columns={generateColumns().data}
          scroll={{ x: generateColumns().width }}
          pagination={
            isObject(withPagination) ? { ...withPagination } : paginationConfig
          }
          loading={loading}
          onRow={(record) => {
            const optionRow = clickOnRow && {
              onClick: () => {
                clickOnRow(record);
              },
            };
            return optionRow;
          }}
          className={`${clickOnRow ? 'custom-row-click' : null}`}
        />
      </div>
    </div>
  );
};

DataTable.displayName = config.displayName;
DataTable.defaultProps = config.defaultProps;
DataTable.propTypes = config.propTypes;

export default DataTable;
