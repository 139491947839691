import React from 'react';
import { motion } from 'framer-motion';

import config from './MotionPage.component.config';

const MotionPage = ({ motionType = 'default', children }) => {
  const motionConfig = () => {
    let configMotion = {};
    switch (motionType) {
      case 'slideTop':
        configMotion = {
          animate: {
            opacity: [0, 1],
            y: [-1000, 0],
          },
        };
        break;
      case 'slideBottom':
        configMotion = {
          animate: {
            opacity: [0, 1],
            y: [1000, 0],
          },
        };
        break;
      case 'slideLeft':
        configMotion = {
          animate: {
            opacity: [0, 1],
            x: [1000, 0],
          },
        };
        break;
      case 'slideRight':
        configMotion = {
          animate: {
            opacity: [0, 1],
            x: [-1000, 0],
          },
        };
        break;
      case 'fadeIn':
        configMotion = {
          animate: { opacity: [0, 1] },
          transition: {
            type: 'spring',
            duration: 1,
          },
        };
        break;
      case 'fadeOut':
        configMotion = {
          animate: { opacity: [1, 0] },
          transition: {
            type: 'spring',
            duration: 1,
          },
        };
        break;
      case 'bounce':
        configMotion = {
          initial: { scale: 0 },
          animate: { scale: 1 },
          transition: {
            type: 'spring',
            stiffness: 300,
            damping: 10,
          },
        };
        break;
      default:
        configMotion = {
          initial: { scale: 0 },
          animate: { scale: 1 },
          transition: {
            type: 'spring',
            damping: 20,
          },
        };
        break;
    }
    return configMotion;
  };
  return <motion.div {...motionConfig()}>{children}</motion.div>;
};

MotionPage.displayName = config.displayName;
MotionPage.defaultProps = config.defaultProps;
MotionPage.propTypes = config.propTypes;

export default MotionPage;
