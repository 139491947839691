import React, { useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  List,
  Row,
  Col,
  Space,
  Input,
  Table,
} from 'antd';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { exportArraytoExcel } from '../../utils/general.utils';

import config from './ModalPreviewEntitySession.component.config';
import './ModalPreviewEntitySession.component.styles.css';

const { Title, Text } = Typography;

const ModalPreviewEntitySession = ({
  title,
  entityName,
  data,
  showModal,
  onCancel,
  ...props
}) => {
  const [localSearchText, setLocalSearchText] = React.useState('');

  const formatDate = (date) => {
    let parsingDate;
    try {
      parsingDate = `${format(new Date(date), 'EEEE, dd MMMM yyy')}, ${format(
        new Date(date),
        'HH:mm:ss',
      )}`;
    } catch (err) {
      parsingDate = '-';
    }
    return parsingDate;
  };

  const formattedData =
    (Array.isArray(data) &&
      data.map((datum) => ({
        name: datum?.name || '-',
        quiz_score:
          typeof datum?.quiz_score === 'number' ? datum?.quiz_score : '-',
        session_start: formatDate(datum?.session_start) || '-',
        session_end: formatDate(datum?.session_end) || '-',
      }))) ||
    [];

  const filteredData =
    formattedData.filter(
      (item) =>
        item?.name?.toLowerCase()?.includes(localSearchText.toLowerCase()) ||
        item?.quiz_score?.toString()?.includes(localSearchText.toLowerCase()),
    ) || [];

  const sessionDataColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Text>{name}</Text>,
    },
    {
      title: 'Quiz Score',
      dataIndex: 'quiz_score',
      key: 'quiz_score',
      // eslint-disable-next-line camelcase
      render: (quiz_score) => <Text>{quiz_score}</Text>,
    },
    {
      title: 'Session Start Date',
      dataIndex: 'session_start',
      key: 'session_start',
      // eslint-disable-next-line camelcase
      render: (session_start_date) => <Text>{session_start_date}</Text>,
    },
    {
      title: 'Session End Date',
      dataIndex: 'session_end',
      key: 'session_end',
      // eslint-disable-next-line camelcase
      render: (session_end_date) => <Text>{session_end_date}</Text>,
    },
  ];

  const renderSessionDataTable = () => (
    <Table dataSource={filteredData} columns={sessionDataColumns} />
  );

  const handleExportAsExcel = () => {
    const args = {
      data: formattedData,
      wsName: 'Session Activity Data',
      wbName: entityName,
    };
    // console.log(args);
    exportArraytoExcel(args);
  };

  return (
    <Modal
      title={<Title level={3}>{title}</Title>}
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      footer={<Button onClick={handleExportAsExcel}>Export as XLSX</Button>}
      width={1000}
      destroyOnClose
      onCancel={onCancel}
    >
      <Input
        prefix={<SearchOutlined />}
        size="middle"
        allowClear
        placeholder="search name or score..."
        style={{
          float: 'right',
          borderRadius: 20,
          width: 300,
          marginBottom: 20,
        }}
        onChange={(e) => setLocalSearchText(e.target.value)}
      />
      {renderSessionDataTable()}
    </Modal>
  );
};

ModalPreviewEntitySession.displayName = config.displayName;
ModalPreviewEntitySession.defaultProps = config.defaultProps;
ModalPreviewEntitySession.propTypes = config.propTypes;

export default ModalPreviewEntitySession;
