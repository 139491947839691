import PropTypes from 'prop-types';

const displayName = 'AdminLessons';
const propTypes = {};
const defaultProps = {
  dummData: () => {
    const data = [];
    for (let index = 0; index < 10; ) {
      data.push({
        id: index,
        'lesson name': `lesson ${index}`,
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis perferendis dolore fugit ',
        status: 'not used',
      });
      index += 1;
    }
    return data;
  },
  headColumn: [
    {
      title: 'Lesson Name',
      key: 'lesson_name',
      sort: true,
      width: 200,
      fixed: true,
    },
    {
      title: 'Lesson Tags',
      key: 'lesson_tags',
      sort: true,
      width: 150,
    },
    {
      title: 'Description',
      key: 'description',
      formatParagraph: true,
      width: 350,
    },
    {
      title: 'Created Date',
      key: 'created_at',
      formatDate: true,
      sortFormatDate: true,
      width: 250,
    },
    // {
    //   title: '',
    //   dataIndex: 'id',
    //   key: 'id',
    //   actionRows: true,
    //   width: 50,
    // },
  ],
};

export default { displayName, propTypes, defaultProps };
