import PropTypes from 'prop-types';

const displayName = 'DetailQuiz';
const propTypes = {};
const defaultProps = {
  quizType: [
    {
      id: 1,
      name: 'Single Answer',
    },
    {
      id: 2,
      name: 'Multiple Answer',
    },
    {
      id: 3,
      name: 'Essay',
    },
    // {
    //   id: 4,
    //   name: 'Pairing',
    // },
  ],
  dummQuestionExist: [
    {
      id: 1,
      value:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit at rerum similique commodi, perferendis accusamus facere vero odio modi libero tempora nesciunt sunt pariatur eligendi magnam delectus totam sequi repellat.',
    },
    {
      id: 2,
      value:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit at rerum similique commodi,',
    },
  ],
  alphabet: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ],
};

export default { displayName, propTypes, defaultProps };
