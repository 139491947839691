import { get } from 'lodash';
import {
  SET_LESSON_LIST,
  SET_LESSON_ENTITY_LIST,
  SET_LESSON_FIELD_SUGGESTIONS,
} from '../reduxType';

import api from '../../services/api.service';
import {
  generateNotification,
  generateHeaderRequest,
  retrieveErrorHandling,
} from '../../utils/general.utils';
import { getToken } from '../../services/token.service';

const { apiService, setAuthToken } = api.getInstance();
const isUserToken = getToken();

export const lessonsCreate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'lesson/create',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Lessons has been created',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const lessonsUpdate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'lesson/update',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Lesson has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const lessonsDelete = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.delete(
      `lesson/delete?lessonID=${payload}`,
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Lesson has been delete.',
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const lessonsList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_LESSON_LIST,
    payload: {
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      '/lesson/get_lesson_list',
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_LESSON_LIST,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_LESSON_LIST,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const setParrentLesson = (payload) => async (dispatch) => {
  let result = true;
  try {
    dispatch({
      type: SET_LESSON_ENTITY_LIST,
      payload: {
        parrent: payload,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const lessonsEntityList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_LESSON_ENTITY_LIST,
    payload: {
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/lesson/get_entity_list?lesson_id=${payload}`,
      generateHeaderRequest(),
    );
    const arrayListActivityEntity = get(data, 'data')?.sort(
      (a, b) => a.position - b.position,
    );
    dispatch({
      type: SET_LESSON_ENTITY_LIST,
      payload: {
        entity: arrayListActivityEntity,
        isLoading: false,
      },
    });
    result = Promise.resolve(arrayListActivityEntity);
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_LESSON_ENTITY_LIST,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const lessonsEntityUpdatePosition = (payload) => async (dispatch) => {
  let result;
  try {
    const { status, data } = await apiService.post(
      `/lesson/update_entity_position`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const lessonsEntityCreate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'lesson/create_entity',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Activity has been created.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    const descriptionMsg = `
      ${error?.response?.data?.detail}
      ${error?.response?.data?.detail?.[0]?.loc.join('/')}
      ${error?.response?.data?.detail?.[0]?.msg}
      ${error?.response?.data?.detail?.[0]?.type}
    `;
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: descriptionMsg,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const lessonsEntityDelete = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      `lesson/delete_entity?entity_id=${payload}`,
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Activity has been deleted.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    const descriptionMsg = `
      ${error?.response?.data?.detail}
      ${error?.response?.data?.detail?.[0]?.loc.join('/')}
      ${error?.response?.data?.detail?.[0]?.msg}
      ${error?.response?.data?.detail?.[0]?.type}
    `;
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: descriptionMsg,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const lessonsEntityUpdate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'lesson/update_entity',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Activity has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    const descriptionMsg = `
      ${error?.response?.data?.detail}
      ${error?.response?.data?.detail?.[0]?.loc.join('/')}
      ${error?.response?.data?.detail?.[0]?.msg}
      ${error?.response?.data?.detail?.[0]?.type}
    `;
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: descriptionMsg,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const lessonGetTypeSuggestions = (payload) => async (dispatch) => {
  dispatch({
    type: SET_LESSON_FIELD_SUGGESTIONS,
    payload: {
      isLoading: true,
    },
  });

  let outcome;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `general/entity_type`,
      generateHeaderRequest(),
    );
    // console.log(data);
    dispatch({
      type: SET_LESSON_FIELD_SUGGESTIONS,
      payload: {
        program_types: [...data.data],
        isLoading: false,
      },
    });
    outcome = Promise.resolve();
  } catch (err) {
    dispatch({
      type: SET_LESSON_FIELD_SUGGESTIONS,
      payload: {
        program_types: [],
        isLoading: false,
      },
    });
    outcome = Promise.reject();
  }
  return outcome;
};
