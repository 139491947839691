import { connect } from 'react-redux';
import AdminQuiz from './AdminQuiz.component';
import {
  createQuiz,
  getListQuiz,
  setParrentQuiz,
  validateQuiz,
  deleteQuiz,
} from '../../../redux/actions/quiz.actions';
import {
  setSearchText,
  clearSearchText,
} from '../../../redux/actions/search.actions';

const mapStateToProps = (state) => ({
  common: state.common.settings,
  searchText: state.search,
  listQuizData: state.quiz.list,
});

const mapDispatchToProps = {
  clearSearchText,
  createQuiz,
  getListQuiz,
  setParrentQuiz,
  validateQuiz,
  deleteQuiz,
  setSearchText,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminQuiz);
