import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Row, Typography, Card, Button } from 'antd';

import config from './CardTopPrograms.component.config';
import './CardTopPrograms.component.styles.css';

const { Title, Text, Paragraph } = Typography;

const CardTopPrograms = ({
  sourceData,
  navigateToDetailPrograms,
  ...props
}) => {
  const listData = useMemo(
    () => (sourceData !== undefined ? sourceData : []),
    [sourceData],
  );
  const programCard = useMemo(
    () =>
      listData.map((items, index) => (
        <Col md={6} key={items.id}>
          <Card
            key={`card-${items.id}`}
            className="card-highlight"
            onClick={() => navigateToDetailPrograms(items)}
          >
            <div
              className="image-content"
              style={{
                backgroundImage: `url(${
                  items?.banner_image !== ''
                    ? items?.banner_image
                    : 'https://bolehbelajar.com/wp-content/uploads/2022/03/bootcamp-banner-resize.png'
                })`,
              }}
            />
            <div className="content-body">
              <div>
                <Title level={5}>{items?.program_name}</Title>
              </div>
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
                style={{
                  color: '#7B7777',
                }}
              >
                {items?.description}
              </Paragraph>
              <span className={`program-type type-${items.program_type_id}`}>
                {items.program_type_name}
              </span>
              {/* <div className="btn-action-class">
            <Button
              type="primary"
              onClick={() => navigateToDetailPrograms(items)}
            >
              Lihat Kelas
            </Button>
          </div> */}
            </div>
          </Card>
        </Col>
      )),
    [listData, navigateToDetailPrograms],
  );
  const renderCard = () => {
    let cardData = (
      <div style={{ padding: '0 15px' }}>
        <Text level={5} style={{ color: 'grey' }}>
          Kamu belum terdaftar di program ini...
        </Text>
      </div>
    );

    if (listData?.length > 0) cardData = programCard;
    return cardData;
  };
  return (
    <div id="cardPrograms" className={`${isMobile && 'mobile-version'}`}>
      <Row gutter={24}>{renderCard()}</Row>
    </div>
  );
};

CardTopPrograms.displayName = config.displayName;
CardTopPrograms.defaultProps = config.defaultProps;
CardTopPrograms.propTypes = config.propTypes;

export default CardTopPrograms;
