import PropTypes from 'prop-types';

const displayName = 'AdminDashboard';
const propTypes = {};
const defaultProps = {
  dummClass: () => {
    const data = [];
    for (let index = 1; index <= 5; ) {
      data.push({
        class: `Class ${index}`,
        programs: `Digital Maarketing ${index}`,
        lessons: `Lessons ${index}`,
        schedule: '2022-05-01 / 2022-05-31',
        instructor: ['instructure 1', 'instructure 2', 'instructure 3'],
        'learners & groups': {
          student: ['student 1', 'student 2', 'student 3', 'student 4'],
          group: ['group 1', 'group 2', 'group 3'],
        },
      });
      index += 1;
    }
    return data;
  },
  dummPrograms: () => {
    const data = [];
    for (let index = 1; index <= 5; ) {
      data.push({
        'program name': `Program ${index}`,
        'create date': `2022-05-01`,
        'total lesson': `24 Lesson`,
        status: 'On Progress',
      });
      index += 1;
    }
    return data;
  },
  dummLessons: () => {
    const data = [];
    for (let index = 1; index <= 5; ) {
      data.push({
        'lesson name': `Program ${index}`,
        schedule: `2022-05-01`,
        instructor: `24 Lesson`,
      });
      index += 1;
    }
    return data;
  },
};

export default { displayName, propTypes, defaultProps };
