import PropTypes from 'prop-types';

const displayName = 'BaseLayout';
const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
