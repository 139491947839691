import { MenuOutlined } from '@ant-design/icons';
import { Button, Card, List, Popover, Typography } from 'antd';
import React from 'react';

import config from './CardListDataFlex.component.config';
import './CardListDataFlex.component.styles.css';

const { Title, Paragraph, Text } = Typography;

const CardListDataFlex = ({
  sourceData,
  loading,
  actionDetail,
  extraAction,
  ...props
}) => {
  const renderItem = (data) => (
    <List.Item className="student-item-list">
      {extraAction !== undefined && (
        <div className="button-extra">
          <Popover
            title={false}
            placement="bottom"
            trigger="click"
            content={<div className="pop-action">{extraAction}</div>}
          >
            <Button icon={<MenuOutlined />} />
          </Popover>
        </div>
      )}
      <Card onClick={() => actionDetail(data)}>
        <div
          className="image-content"
          style={{
            backgroundImage: `url('https://bolehbelajar.com/wp-content/uploads/2022/03/bootcamp-banner-resize.png')`,
          }}
        />
        {/* <Image src={IMG_EMPTY} preview={false} /> */}
        <div className="title-content">
          <Title level={4}>{data?.program_name}</Title>
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: false,
            }}
          >
            {data?.description}
          </Paragraph>
        </div>
        {/* <div className="completely-content">
          <Text>{data?.complete} complete</Text>
        </div> */}
      </Card>
    </List.Item>
  );

  const configPagination = {
    showSizeChanger: false,
    defaultPageSize: 8,
    pageSize: 8,
    hideOnSinglePage: true,
  };

  return (
    <div id="CardListDataFlex">
      <List
        key="StudentProgram"
        size="large"
        itemLayout="horizontal"
        dataSource={sourceData}
        renderItem={(item) => renderItem(item)}
        loading={loading}
        pagination={configPagination}
      />
    </div>
  );
};

CardListDataFlex.displayName = config.displayName;
CardListDataFlex.defaultProps = config.defaultProps;
CardListDataFlex.propTypes = config.propTypes;

export default CardListDataFlex;
