import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Tooltip,
  Typography,
  Modal,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  List,
} from 'antd';
import {
  DeleteOutlined,
  AuditOutlined,
  CaretDownFilled,
  ExclamationCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { forEach, parseInt } from 'lodash';

import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';

import { IMG_EMPTY } from '../../../../assets/svg';
import {
  DataList,
  EmptyContent,
  LoadingEvent,
  TemplateCertif,
} from '../../../../components';
import {
  getInitialClassName,
  getPrefix,
  getRandomId,
} from '../../../../utils/format';

import config from './ListLearnerPrograms.component.config';
import './ListLearnerPrograms.component.styles.css';
import { getParamURL } from '../../../../utils/parse';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../../services/aws.service';

const { Title, Text, Link } = Typography;

const testDataStudent = {
  namaStudent: 'Billy Kurniawan',
  program: 'DMB Batch 15',
  preQuizScore: 80,
  take1: 55,
  take2: 75,
  take3: 70,
  take4: 70,
  postQuizScore: null,
  weeklyAssign: 70,
  finalScore: 75,
};

const ExpandIcon = ({ isActive }) => (
  <CaretDownFilled rotate={isActive ? 0 : -90} style={{ fontSize: '25px' }} />
);

ExpandIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

const ListLearnerPrograms = ({
  navigationLocal,
  onRemoveLearner,
  participantsGetStudentData,
  participantsGetSummaryStudentData,
  reportStudent,
  programActivityListData,
  programParrent,
  programDetail,
  filteredParticipantsData,
  localSearchText,
  setLocalSearchText,
  participantsGetStudentQuizData,
  ...props
}) => {
  const navigate = useNavigate();
  const paramProgramID = getParamURL('id');

  const [removeLearnerModal, setRemoveLearnerModal] = useState({
    isVisible: false,
    participantDetail: null,
  });

  const [selectedLearner, setSelectedLearner] = useState({
    loading: false,
    learner: null,
    show: false,
  });

  const [modalPopup, setModalPopup] = React.useState({
    itemSelected: null,
    modalQuiz: false,
    quizDataPopup: null,
    quizScorePopUp: null,
    modalSubmitQuiz: false,
    modalAssignment: false,
    modalSubmitAssignment: false,
  });

  const renderRemoveLearnerModal = ({ isVisible, participantDetail }) => (
    <Modal
      open={isVisible}
      onOk={() => {
        onRemoveLearner(participantDetail);
        setRemoveLearnerModal({ isVisible: false, participantDetail: null });
      }}
      onCancel={() =>
        setRemoveLearnerModal({ isVisible: false, participantDetail: null })
      }
    >
      <p>Are you sure you want to remove this learner?</p>
      <ul>
        <li key="learner-name">
          Name: <em>{participantDetail?.name}</em>
        </li>
        <li key="learner-email">
          Email: <em>{participantDetail?.email}</em>
        </li>
      </ul>
    </Modal>
  );

  const emptyContent = () => (
    <div className="empty-content">
      <div className="img-empty">
        <IMG_EMPTY />
      </div>
      <div className="content-empty">
        <Title level={2}>Kamu Belum Memasukan Learners</Title>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet et
          veritatis, iste rerum illum, quod accusamus perferendis reprehenderit
          delectus quia qui saepe neque quaerat, earum ipsum libero. Officiis,
          ad suscipit!
        </Text>
      </div>
      <Button
        type="primary"
        onClick={() => navigationLocal({ action: 'selectionLearner' })}
      >
        Add Learner
      </Button>
    </div>
  );

  const actionActivity = (idx) => (
    <Tooltip title="Remove this learner">
      <Button
        type="text"
        shape="circle"
        size="large"
        icon={<DeleteOutlined />}
        onClick={() =>
          setRemoveLearnerModal({
            isVisible: true,
            participantDetail:
              Array.isArray(props?.dataSource) &&
              props?.dataSource?.find((el) => el?.id === idx),
          })
        }
      />
    </Tooltip>
  );

  const detailLearner = (idx) => (
    <Tooltip title="Detail Learner">
      <Button
        type="text"
        shape="circle"
        size="large"
        icon={<AuditOutlined />}
        onClick={() => {
          const learner = props?.dataSource?.find((el) => el?.id === idx);
          const programId = programParrent?.id;
          if (programId) {
            setSelectedLearner({ ...selectedLearner, loading: true });
            participantsGetStudentData({
              programId,
              userId: learner.id,
            }).then(() => {
              setSelectedLearner({
                ...selectedLearner,
                learner,
                show: true,
                loading: false,
              });
            });
          } else {
            console.error('Program ID not available');
          }
        }}
      />
    </Tooltip>
  );

  const combinedActions = (idx) => [detailLearner(idx), actionActivity(idx)];

  const openModalQuizHistory = (item) => {
    if (item?.configuration?.rasio_essay !== null) {
      participantsGetStudentQuizData(item?.session?.id).then((data) => {
        setModalPopup({
          ...modalPopup,
          quizDataPopup: data,
          itemSelected: item,
          modalQuiz: true,
        });
      });
    } else {
      setModalPopup({
        ...modalPopup,
        itemSelected: item,
        modalQuiz: true,
      });
    }
  };

  const openModalSubmitQuiz = (item) => {
    participantsGetStudentQuizData(item?.session?.id).then((data) => {
      setModalPopup({
        ...modalPopup,
        itemSelected: item,
        quizDataPopup: data,
        modalSubmitQuiz: true,
      });
    });
  };

  const modalInfoQuizReport = () => (
    <Modal
      className="modal-quiz-info"
      open={modalPopup.modalQuiz}
      onCancel={() =>
        setModalPopup({
          ...modalPopup,
          itemSelected: null,
          modalQuiz: false,
        })
      }
      centered
      maskClosable={false}
      footer={false}
      width={600}
    >
      <Title level={3}>Quiz Submission History</Title>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: 0, width: 110 }}>
          <b>Quiz Name</b>
        </div>
        <div>: {modalPopup.itemSelected?.entity_name}</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: 0, width: 110 }}>
          <b>Student Name</b>
        </div>
        <div>: {selectedLearner.learner?.name}</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: 0, width: 110 }}>
          <b>Take Limit</b>
        </div>
        <div>: {modalPopup.itemSelected?.configuration?.retake_limit}</div>
      </div>
      {/* take limit if exist */}
      {modalPopup.itemSelected?.configuration?.retake_limit > 0 && (
        <div>
          <Row gutter={16}>
            {modalPopup.itemSelected?.session?.history?.map((list, index) => (
              <Col span={6} style={{ textAlign: 'center' }}>
                <div>
                  <b>Take {index + 1}</b>
                </div>
                <div>{list?.quiz_score}</div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '50%',
        }}
      >
        <div style={{ margin: 0, width: 110 }}>
          <b>Highest Score</b>
        </div>
        <div style={{ color: '#C9264B' }}>
          <b>: {modalPopup.itemSelected?.session?.quiz_score}</b>
        </div>
      </div>
      {modalPopup.itemSelected?.configuration?.rasio_essay !== null && (
        <>
          <div style={{ marginTop: 10 }}>
            <b>Essay Question</b>
            <div>
              {modalPopup.quizDataPopup?.length > 0 &&
                modalPopup.quizDataPopup[0].question}
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <b>Essay Answer</b>
            <div>
              {modalPopup.quizDataPopup?.length > 0 &&
                modalPopup.quizDataPopup[0].quizanswer_essay}
            </div>
          </div>
        </>
      )}
    </Modal>
  );

  const modaSubmitQuizReport = () => (
    <Modal
      className="modal-quiz-submit"
      open={modalPopup.modalSubmitQuiz}
      onCancel={() =>
        setModalPopup({
          ...modalPopup,
          quizDataPopup: null,
          modalSubmitQuiz: false,
        })
      }
      centered
      maskClosable={false}
      footer={false}
      width={500}
    >
      <Title level={3}>Submit Essay Score</Title>
      <div style={{ marginTop: 10 }}>
        <b>Essay Question</b>
        <div>
          {modalPopup.quizDataPopup?.length > 0 &&
            modalPopup.quizDataPopup[0].question}
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <b>Essay Answer</b>
        <div>
          {modalPopup.quizDataPopup?.length > 0 &&
            modalPopup.quizDataPopup[0].quizanswer_essay}
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Input Essay Score</b>
        </div>
        <div>
          <Input
            type="number"
            defaultValue={
              modalPopup.quizScorePopUp != null
                ? modalPopup.quizScorePopUp?.score_admin
                : modalPopup.itemSelected?.session?.quiz_submit_adm
            }
            style={{
              width: 70,
              textAlign: 'right',
            }}
            onChange={(e) => {
              const valueQuiz = e.target.value;
              if (valueQuiz > 0) {
                setTimeout(() => {
                  props
                    .participantsGenerateStudentQuiz({
                      quiz_id: parseInt(modalPopup.itemSelected?.material_link),
                      session_id: modalPopup.itemSelected?.session?.id,
                      score: parseFloat(valueQuiz),
                    })
                    .then((resp) => {
                      setModalPopup({
                        ...modalPopup,
                        quizScorePopUp: resp,
                      });
                    });
                }, 1000);
              }
            }}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          marginRight: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Multiple Choice Score</b>
        </div>
        <div>
          <b>
            {modalPopup.quizScorePopUp != null
              ? modalPopup.quizScorePopUp?.score_student
              : modalPopup.itemSelected?.session?.quiz_submit_std}
          </b>
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          marginRight: 25,
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Quiz Final Score</b>
        </div>
        <div>
          <b>
            {modalPopup.quizScorePopUp != null
              ? modalPopup.quizScorePopUp?.final_score
              : modalPopup.itemSelected?.session?.quiz_score}
          </b>
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => {
          props
            .participantSubmitStudentQuiz({
              quiz_id: parseInt(modalPopup.itemSelected?.material_link),
              session_id: modalPopup.itemSelected?.session?.id,
              score: parseFloat(modalPopup.quizScorePopUp?.score_admin),
            })
            .then((resp) => {
              participantsGetStudentData({
                programId: programParrent?.id,
                userId: selectedLearner.learner.id,
              }).then(() => {
                setModalPopup({
                  ...modalPopup,
                  modalSubmitQuiz: false,
                });
              });
            });
        }}
      >
        Submit
      </Button>
    </Modal>
  );

  const openModalAssignment = (item) => {
    setModalPopup({
      ...modalPopup,
      itemSelected: item,
      modalAssignment: true,
    });
  };

  const [valueWeeklySubmission, setValueWeeklySubmission] = React.useState(0);
  const formSubmitAssignmentWeekly = () => (
    <>
      <Title level={3}>Submit Assignment Score</Title>
      <div style={{ marginTop: 10 }}>
        <b>Learner’s assignment submission</b>
        <div>
          <Link
            href={modalPopup.itemSelected?.session?.assignment_submit?.url}
            target="_blank"
          >
            {modalPopup.itemSelected?.session?.assignment_submit?.name}
          </Link>
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Input Assignment Score</b>
        </div>
        <div>
          <Input
            type="number"
            // defaultValue={
            //   modalPopup.quizScorePopUp != null
            //     ? modalPopup.quizScorePopUp?.score_admin
            //     : modalPopup.itemSelected?.session?.quiz_submit_adm
            // }
            style={{
              width: 70,
              textAlign: 'right',
            }}
            onChange={(e) => {
              const valueSubmit = e.target.value;
              setValueWeeklySubmission(valueSubmit);
            }}
          />
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => {
          props
            .participantSubmitWeekAssignment({
              session_id: modalPopup.itemSelected?.session?.id,
              score: parseFloat(valueWeeklySubmission),
              type: 'week',
            })
            .then((resp) => {
              participantsGetStudentData({
                programId: programParrent?.id,
                userId: selectedLearner.learner.id,
              }).then(() => {
                setModalPopup({
                  ...modalPopup,
                  modalAssignment: false,
                });
              });
            });
        }}
      >
        Submit
      </Button>
    </>
  );
  const [valueGroupSubmission, setValueGroupSubmission] = React.useState({
    score_juri: null,
    score_mentor: null,
    score_final: null,
    group: [],
  });
  const formSubmitAssignmentGroup = () => {
    let objectSubmit = null;
    if (modalPopup.itemSelected?.configuration?.assignment_file) {
      objectSubmit = JSON.parse(
        modalPopup.itemSelected?.configuration?.assignment_file,
      );
      // setValueGroupSubmission({
      //   ...valueGroupSubmission,
      //   score_juri: modalPopup.itemSelected?.configuration?.score_juri,
      //   score_mentor: modalPopup.itemSelected?.configuration?.score_mentor,
      //   score_final: modalPopup.itemSelected?.configuration?.score_final,
      //   // group: modalPopup.itemSelected?.configuration?.detail,
      // });
    }
    return (
      <>
        <Title level={3}>Submit Group Assignment Score</Title>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <b>Learner’s group assignment submission</b>
          <div>
            <Link href={objectSubmit?.url} target="_blank">
              {objectSubmit?.name}
            </Link>
          </div>
        </div>
        <Form
          onFinish={() => {
            const dataGroupAssignment = {
              group_id: modalPopup.itemSelected?.configuration?.group_id,
              ...valueGroupSubmission,
            };
            props.finalGroupScorePost(dataGroupAssignment).then((resp) => {
              participantsGetStudentData({
                programId: programParrent?.id,
                userId: selectedLearner.learner.id,
              }).then(() => {
                setModalPopup({
                  ...modalPopup,
                  modalAssignment: false,
                });
              });
            });
          }}
        >
          <Title level={5}>Nilai Kelompok</Title>
          <div
            style={{
              marginBottom: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>Nilai Juri</div>
            <Form.Item
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'nilai juri is required',
                },
              ]}
            >
              <Input
                required
                type="number"
                style={{
                  width: 70,
                  textAlign: 'right',
                  padding: '2px 5px',
                }}
                defaultValue={
                  modalPopup.itemSelected?.configuration?.score_juri
                }
                onChange={(e) => {
                  const valueSubmit = e.target.value;
                  setValueGroupSubmission((prev) => {
                    const current = { ...prev };
                    current.score_juri = parseInt(valueSubmit);
                    if (current.score_juri && current.score_mentor) {
                      current.score_final = parseFloat(
                        (current.score_juri + current.score_mentor) / 2,
                      ).toFixed(2);
                    }
                    return { ...current };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            style={{
              marginBottom: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>Nilai Mentor</div>
            <Form.Item
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'nilai mentor is required',
                },
              ]}
            >
              <Input
                required
                type="number"
                style={{
                  width: 70,
                  textAlign: 'right',
                  padding: '2px 5px',
                }}
                defaultValue={
                  modalPopup.itemSelected?.configuration?.score_mentor
                }
                onChange={(e) => {
                  const valueSubmit = e.target.value;
                  setValueGroupSubmission((prev) => {
                    const current = { ...prev };
                    current.score_mentor = parseInt(valueSubmit);
                    if (current.score_juri && current.score_mentor) {
                      current.score_final = parseFloat(
                        (current.score_juri + current.score_mentor) / 2,
                      ).toFixed(2);
                    }
                    return { ...current };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            style={{
              marginBottom: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>Total Nilai Kelompok</div>
            <div
              style={{
                width: 70,
                textAlign: 'right',
                marginRight: 20,
              }}
            >
              {valueGroupSubmission.score_final
                ? valueGroupSubmission.score_final
                : modalPopup.itemSelected?.configuration?.score_final}
            </div>
          </div>
          <Title level={5}>Nilai Individu</Title>
          {modalPopup.itemSelected?.configuration?.detail?.map((group) => (
            <div
              style={{
                marginBottom: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>{group?.user_name}</div>
              <Form.Item
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'nilai juri is required',
                  },
                ]}
              >
                <Input
                  required
                  type="number"
                  style={{
                    width: 70,
                    textAlign: 'right',
                    padding: '2px 5px',
                  }}
                  defaultValue={group?.score}
                  onChange={(e) => {
                    const valueSubmit = e.target.value;
                    setValueGroupSubmission((prev) => {
                      const current = { ...prev };
                      const detailGroup = current.group;
                      const findUser = detailGroup.find(
                        (user) => user.user_id === group.user_id,
                      );
                      if (findUser) {
                        findUser.score = parseInt(valueSubmit);
                      } else {
                        detailGroup.push({
                          user_id: group.user_id,
                          score: parseInt(valueSubmit),
                        });
                      }
                      return { ...current };
                    });
                  }}
                />
              </Form.Item>
            </div>
          ))}
          <Button htmlType="submit" type="primary" style={{ marginTop: 20 }}>
            Submit
          </Button>
        </Form>
      </>
    );
  };
  const modalSubmitAssignmentReport = () => (
    <Modal
      className="modal-assignment"
      open={modalPopup.modalAssignment}
      onCancel={() =>
        setModalPopup({
          ...modalPopup,
          modalAssignment: false,
        })
      }
      centered
      maskClosable={false}
      footer={false}
      width={500}
    >
      {modalPopup.itemSelected?.activity_type_id === 6 &&
        formSubmitAssignmentWeekly()}
      {modalPopup.itemSelected?.activity_type_id === 8 &&
        formSubmitAssignmentGroup()}
    </Modal>
  );

  const collapseListDataReport = (idx, items) => {
    const isQuizEssay = (item) => {
      let stateItem = null;
      if (item.activity_type_id === 3 || item.activity_type_id === 7) {
        if (item.configuration.rasio_essay === null) {
          stateItem = (
            <Button type="default" className="disabled" disabled>
              Tidak Ada Essay
            </Button>
          );
        } else {
          stateItem = (
            <Button
              type="primary"
              className={item.session?.quiz_score ? 'success' : 'not'}
              onClick={() => {
                openModalSubmitQuiz(item);
              }}
            >
              {item.session?.quiz_score
                ? 'Essay Score Submited'
                : 'Submit Essay Score'}
              {item.session?.quiz_score ? (
                <CheckCircleFilled
                  style={{ marginLeft: '5px', fill: '#389517' }}
                />
              ) : (
                <CloseCircleFilled
                  style={{ marginLeft: '5px', fill: '#389517' }}
                />
              )}
            </Button>
          );
        }
      }
      return stateItem;
    };

    const isAssignment = (item) => {
      let stateItem = null;
      if (item.activity_type_id === 6 || item.activity_type_id === 8) {
        let objectAssignment = item.session?.assignment_submit;
        if (item.activity_type_id === 8) {
          if (item?.configuration?.assignment_file)
            objectAssignment = JSON.parse(item?.configuration?.assignment_file);
        }
        stateItem = (
          <Button
            type="primary"
            className={objectAssignment ? 'success' : 'not'}
            onClick={() => openModalAssignment(item)}
            disabled={objectAssignment === null}
          >
            Assignment Score {objectAssignment ? 'Submitted' : 'Not Submitted'}
            {objectAssignment ? (
              <CheckCircleFilled
                style={{ marginLeft: '5px', fill: '#389517' }}
              />
            ) : (
              <CloseCircleFilled
                style={{ marginLeft: '5px', fill: '#389517' }}
              />
            )}
          </Button>
        );
      }
      return stateItem;
    };

    const renderScore = (item) => {
      let scoring = '--';
      if (item.activity_type_id === 3 || item.activity_type_id === 7) {
        if (item.session?.quiz_score !== null)
          scoring = item.session?.quiz_score;
      } else if (item.activity_type_id === 6) {
        if (item.session?.assignment_score !== null)
          scoring = item.session?.assignment_score;
      } else if (item.activity_type_id === 8) {
        scoring = item.configuration?.score_final;
      }
      return scoring;
    };

    return (
      <>
        {items.map((item) => (
          <div key={item.id} className="list-item">
            <div className="list-item-contents">
              <div>{item.entity_name}</div>
              <div className="action-item-contents">
                <div className="list-item-score">
                  <div>{renderScore(item)}</div>
                  <ExclamationCircleFilled
                    onClick={() => {
                      if (
                        item.activity_type_id === 3 ||
                        item.activity_type_id === 7
                      ) {
                        openModalQuizHistory(item);
                      }
                      // openQuizHistoryModal(item.session, item.configuration);
                      // console.log('configuration :', item.configuration);
                    }}
                  />
                </div>
                <div className="list-item-button">
                  {isQuizEssay(item)}
                  {isAssignment(item)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const reportLearner = () => (
    <div id="DetailLearner" style={{ marginTop: 20 }}>
      <div style={{ display: 'flex' }}>
        <Title level={5} style={{ width: '130px', margin: 0 }}>
          Nama Student
        </Title>
        <Title level={5} style={{ margin: 0 }}>
          : {selectedLearner.learner.name}
        </Title>
      </div>
      <div style={{ display: 'flex' }}>
        <Title level={5} style={{ width: '130px', margin: 0 }}>
          Program
        </Title>
        <Title level={5} style={{ margin: 0 }}>
          : {programParrent.program_name}
        </Title>
      </div>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <b>Rincian Program :</b>
        <Collapse
          accordion="true"
          expandIcon={ExpandIcon}
          expandIconPosition="end"
        >
          {reportStudent.data.activity_list.map((item) => (
            <Collapse.Panel
              header={<Title level={5}>{item.activity_name}</Title>}
            >
              {collapseListDataReport(item.id, item.entity_list)}
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '0px 20px',
          marginTop: '10px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '130px', margin: 0 }}>Nilai Individu</div>
        <div style={{ width: 350 }}>{reportStudent.data?.score_individu}</div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '0px 20px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '130px', margin: 0 }}>Nilai Kelompok</div>
        <div style={{ width: 350 }}>{reportStudent.data?.score_group}</div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '5px 20px',
          justifyContent: 'space-between',
        }}
      >
        <Title level={5} style={{ width: '130px', margin: 0 }}>
          Nilai Akhir
        </Title>
        <div style={{ width: 350 }}>
          <Title level={5} style={{ width: '130px', margin: 0 }}>
            {reportStudent.data?.score_final}
          </Title>
        </div>
      </div>
      <div
        className="button-action-report"
        style={{
          marginTop: 20,
          marginBottom: 20,
          textAlign: 'right',
        }}
      >
        <Button
          type="primary"
          onClick={() => setSelectedLearner({ show: false })}
        >
          Kembali
        </Button>
      </div>
    </div>
  );

  const [tempReportLearner, setTempReportLearner] = useState({
    loading: false,
    message: null,
    countData: 0,
    finished: 0,

    report: null,
    certificate: null,
  });
  const generateCertificateID = (
    selectedProgram,
    yearCertificateClaimed,
    monthCertificateClaimed,
  ) => {
    let IDCertificate;
    let TP;
    const IDProgram = [selectedProgram.id];
    const randomNumbers = getRandomId();
    const prefix = getPrefix(selectedProgram.program_name);

    try {
      switch (selectedProgram.program_type_id) {
        case 1:
          TP = prefix;
          break;
        case 2:
          TP = 'MC';
          break;
        case 3:
          TP = 'VC';
          break;
        default:
          break;
      }
      const NP = getInitialClassName(selectedProgram.program_name, 3);
      IDCertificate = `${TP.concat(IDProgram.toString().padStart(3, '0'))
        .concat(NP)
        .concat(yearCertificateClaimed)
        .concat(monthCertificateClaimed)
        .concat(
          selectedProgram.student_serial_number
            ? selectedProgram.student_serial_number
            : getRandomId(),
        )
        .concat(randomNumbers)}`;
    } catch (error) {
      console.log('Error Generating Certificate: ', error);
    }

    return IDCertificate;
  };

  const generateLinkCertificateStudent = async (dataUsers, file) => {
    await putObjectAwsS3Bucket(
      'testting/certif',
      file,
      `${dataUsers.certificateID}.pdf`,
      (originKey) => {
        const keyCertificate = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyCertificate.replaceAll('+', ' '),
          (objectURL) => {
            let nameofUsr = dataUsers.users.name;
            if (dataUsers.users.first_name && dataUsers.users.last_name) {
              nameofUsr = `${dataUsers.users.first_name} ${dataUsers.users.last_name}`;
            }

            const payload = {
              program_id: dataUsers.program?.id,
              learner_id: dataUsers.users?.id,
              learner_name: nameofUsr,
              cert_id: dataUsers.certificateID,
              cert_link_file: objectURL,
            };
            props.postCertificateProgramAdmin(payload);
          },
          'uploadCertificate',
        );
      },
      'uploadCertificate',
    );
  };
  const generatePDFUsers = async (dataUsers) => {
    const doc = new JsPDF('l', 'mm', '', true);
    const mm = 0.2645833333;

    const certificateUsers = document.getElementById('CertificateUsers');
    const pageCertificate = await html2canvas(certificateUsers, {
      width: 850,
      height: 600,
    }).then(async (canvas) => {
      const componentData = canvas.toDataURL('image/jpeg');
      doc.deletePage(1);
      doc.addPage('', 'l', [canvas.width * mm, canvas.height * mm]);
      const docWidth = doc.internal.pageSize.getWidth();
      const docHeight = doc.internal.pageSize.getHeight();
      doc.addImage(componentData, 'JPEG', 0, 0, docWidth, docHeight);

      return componentData;
    });
    const reportUsers = document.getElementById('ReportUsers');
    const pageReport = await html2canvas(reportUsers, {
      width: 850,
      height: 850,
    }).then(async (canvas) => {
      const componentData2 = canvas.toDataURL('image/jpeg');
      doc.addPage('', 'l', [canvas.width * mm, canvas.height * mm]);
      const docWidth = doc.internal.pageSize.getWidth() - 50;
      const docHeight = doc.internal.pageSize.getHeight();
      doc.addImage(componentData2, 'JPEG', 25, 0, docWidth, docHeight);

      return componentData2;
    });

    setTempReportLearner((previous) => {
      const current = { ...previous };
      current.finished += 1;
      return { ...current };
    });
    // doc.save(`${dataUsers.certificateID}.pdf`);
    const fileDoc = doc.output('blob');
    return fileDoc;
  };

  const [sourceStudent, setSourceStudent] = useState({
    status: 'idle',
    total_student: props.dataSource.length,
    total_done: 0,
    data_report: [],
  });

  useEffect(() => {
    if (sourceStudent.status === 'finish') {
      if (sourceStudent.total_done === sourceStudent.total_student) {
        sourceStudent.data_report.forEach((dataReport) => {
          // console.log(dataReport);
          const dataUsers = {
            ...dataReport,
            program: programParrent,
            certificateID: generateCertificateID(
              programParrent,
              moment().format('YY'),
              moment().format('MM'),
            ),
          };
          // console.log(dataUsers);
          const certificate = TemplateCertif({
            template: 'bootcamp',
            data: dataUsers,
          });
          const report = TemplateCertif({
            template: 'report',
            data: dataUsers,
          });
          setTimeout(async () => {
            setTempReportLearner((previous) => {
              const current = { ...previous };
              current.certificate = certificate;
              current.report = report;
              return { ...current };
            });
            await generatePDFUsers(dataUsers).then(async (file) => {
              await generateLinkCertificateStudent(dataUsers, file);
            });
          }, 500);
        });
        setTempReportLearner((previous) => {
          const current = { ...previous };
          current.message = 'data report student berhasil di buat.';
          return { ...current };
        });
      } else {
        setTempReportLearner((previous) => {
          const current = { ...previous };
          current.message =
            'data report student belum langkap. pastikan semua input data report student terisi semua tanpa terlewat sebelum generate repor';
          return { ...current };
        });
      }
      // setTempReportLearner((previous) => {
      //   const current = { ...previous };
      //   current.loading = false;
      //   return { ...current };
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    programParrent,
    sourceStudent.status,
    sourceStudent.total_done,
    sourceStudent.total_student,
    sourceStudent.data_report,
  ]);

  const generateReportLearner = async () => {
    /* eslint-disable no-plusplus */
    const programId = programParrent?.id;
    setTempReportLearner((previous) => {
      const current = { ...previous };
      current.loading = true;
      current.message = 'Tunggu, sistem sedang melakukan validasi...';
      current.countData = props.dataSource?.length;

      return { ...current };
    });
    // const users = props.dataSource[0];
    // await participantsGetSummaryStudentData({
    //   programId,
    //   userId: users.id,
    // }).then(async (response) => {
    //   const dataUsers = {
    //     ...users,
    //     certificateID: generateCertificateID(
    //       programParrent,
    //       moment().format('YY'),
    //       moment().format('MM'),
    //     ),
    //     program: programParrent,
    //     report: response,
    //   };

    //   const certificate = TemplateCertif({
    //     template: 'bootcamp',
    //     data: dataUsers,
    //   });
    //   const report = TemplateCertif({
    //     template: 'report',
    //     data: dataUsers,
    //   });

    //   setTimeout(async () => {
    //     setTempReportLearner((previous) => {
    //       const current = { ...previous };
    //       current.certificate = certificate;
    //       current.report = report;
    //       return { ...current };
    //     });
    //     await generatePDFUsers(dataUsers).then(async (file) => {
    //       await generateLinkCertificateStudent(dataUsers, file);
    //     });
    //   }, 500);
    // });
    // --------------------
    // validating source data student
    let countData = 0;
    setSourceStudent({
      ...sourceStudent,
      status: 'running',
    });
    await props.dataSource.forEach(async (users) => {
      await participantsGetSummaryStudentData({
        programId,
        userId: users.id,
      }).then(async (response) => {
        if (response?.done) {
          setSourceStudent({
            ...sourceStudent,
            total_done: sourceStudent.total_done + 1,
          });
          // sourceStudent.total_done += 1;
        }
        countData += 1;
        setSourceStudent((prev) => {
          const curr = { ...prev };
          curr.data_report.push({
            users,
            report: response,
          });

          return { ...curr };
        });
      });
      if (countData === sourceStudent.total_student) {
        setSourceStudent({
          ...sourceStudent,
          status: 'finish',
        });
      }
    });
    // --------------------
    // validation source data available to generate certificate?
    // console.log('proses ini');
    // sourceStudent.data_report.map((dataReport) => {
    //   console.log(dataReport);
    //   const dataUsers = {
    //     ...dataReport,
    //     program: programParrent,
    //     certificateID: generateCertificateID(
    //       programParrent,
    //       moment().format('YY'),
    //       moment().format('MM'),
    //     ),
    //   };

    //   console.log(dataUsers);

    //   // const certificate = TemplateCertif({
    //   //   template: 'bootcamp',
    //   //   data: dataUsers,
    //   // });
    //   // const report = TemplateCertif({
    //   //   template: 'report',
    //   //   data: dataUsers,
    //   // });

    //   // setTimeout(async () => {
    //   //   setTempReportLearner((previous) => {
    //   //     const current = { ...previous };
    //   //     current.certificate = certificate;
    //   //     current.report = report;
    //   //     return { ...current };
    //   //   });
    //   //   await generatePDFUsers(dataUsers).then(async (file) => {
    //   //     await generateLinkCertificateStudent(dataUsers, file);
    //   //   });
    //   // }, 500);
    //   return dataReport;
    // });
    // if (sourceStudent.total_student > sourceStudent.total_done) {
    // }
    // --------------------
  };

  useEffect(() => {
    if (tempReportLearner.loading)
      if (tempReportLearner.finished === tempReportLearner.countData) {
        setTempReportLearner((previous) => {
          const current = { ...previous };
          current.loading = false;
          current.countData = 0;
          current.finished = 0;
          current.certificate = null;
          current.report = null;
          return { ...current };
        });
      }
  }, [tempReportLearner]);

  return (
    <div>
      {props.showEmptyLearner && emptyContent()}
      {!selectedLearner.show && props.showListLearner && (
        <div className="content-detail">
          <div className="section-header">
            <div className="title">
              <Title level={4}>Learners ({props?.dataSource?.length})</Title>
              <Input
                prefix={<SearchOutlined />}
                style={{
                  width: 300,
                }}
                allowClear
                placeholder="Find learner..."
                onChange={(e) =>
                  setLocalSearchText({
                    ...localSearchText,
                    learner: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Button
                type="primary"
                size="middle"
                icon={<PlusSquareOutlined />}
                onClick={() => navigationLocal({ action: 'selectionLearner' })}
              >
                Add Learners
              </Button>
              <Button
                type="primary"
                size="middle"
                onClick={() => generateReportLearner()}
                style={{ marginLeft: 10 }}
              >
                Generate Report
              </Button>
            </div>
          </div>
          <DataList
            withPagination={5}
            disablePagination
            data={(Array.isArray(props.dataSource) && props.dataSource) || []}
            haveAction
            actionDetail={combinedActions}
            loading={selectedLearner.loading}
          />
        </div>
      )}
      {renderRemoveLearnerModal(removeLearnerModal)}
      {selectedLearner.show && reportLearner()}
      {modalInfoQuizReport()}
      {modaSubmitQuizReport()}
      {modalSubmitAssignmentReport()}
      <div
        className="template-report"
        style={{
          width: 'fit-content',
          margin: 'auto',
        }}
      >
        {/* <TemplateCertif template="bootcamp" /> */}
        {/* <TemplateCertif template="report" /> */}
        {tempReportLearner.loading && (
          <div style={{ textAlign: 'center', marginBottom: 50 }}>
            <List loading={tempReportLearner.loading} />
            <Text>Please wait, on working generate certificate of learner</Text>
          </div>
        )}
        <div
          id="CertificateUsers"
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          {tempReportLearner.certificate}
        </div>
        <div
          id="ReportUsers"
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          {tempReportLearner.report}
        </div>
      </div>
      <Modal
        open={tempReportLearner.loading}
        centered
        closable={false}
        closeIcon={false}
        maskClosable={false}
        footer={false}
      >
        <div style={{ padding: 10, textAlign: 'center' }}>
          <List loading={tempReportLearner.loading} />
          <div>{tempReportLearner.message}</div>
          <Button
            style={{ marginTop: 30 }}
            onClick={() => {
              setTempReportLearner((previous) => {
                const current = { ...previous };
                current.loading = false;
                return { ...current };
              });
            }}
          >
            Kembali
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ListLearnerPrograms.displayName = config.displayName;
ListLearnerPrograms.defaultProps = config.defaultProps;
ListLearnerPrograms.propTypes = config.propTypes;

export default ListLearnerPrograms;
