import PropTypes from 'prop-types';

const displayName = 'CardDataEvent';
const propTypes = {};
const defaultProps = {
  data: [
    {
      id: 1,
      entity_name: 'zoom meeting',
      entity_type: 'video conference',
      time: '2022-08-08 15:15:00',
      intructor_name: 'Mas Diwang',
      moderator_name: 'Moch. Dicky',
      description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel quam eos quas sapiente, mollitia nisi deleniti est! Debitis, nemo aut. Sit quasi praesentium consequuntur. Iusto deserunt cupiditate autem sequi labore.',
    },
    {
      id: 2,
      entity_name: 'how to combine facebook ads with marketplace',
      entity_type: 'video learning',
      time: '2022-08-10 20:15:00',
      intructor_name: 'Mas Jenzo',
      moderator_name: 'Moch. Dicky',
      description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel quam eos quas sapiente, mollitia nisi deleniti est! Debitis, nemo aut. Sit quasi praesentium consequuntur. Iusto deserunt cupiditate autem sequi labore.',
    },
    {
      id: 1,
      entity_name: 'Pre test - facebook ads marketing',
      entity_type: 'Pre Test',
      time: '2022-08-15 15:15:00',
      intructor_name: 'Mas Jenzo',
      moderator_name: 'Moch. Dicky',
      description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel quam eos quas sapiente, mollitia nisi deleniti est! Debitis, nemo aut. Sit quasi praesentium consequuntur. Iusto deserunt cupiditate autem sequi labore.',
    },
    {
      id: 1,
      entity_name: 'facebook marketing API',
      entity_type: 'Post Test',
      time: '2022-08-31 15:15:00',
      intructor_name: 'Mas Armand',
      moderator_name: 'Moch. Dicky',
      description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel quam eos quas sapiente, mollitia nisi deleniti est! Debitis, nemo aut. Sit quasi praesentium consequuntur. Iusto deserunt cupiditate autem sequi labore.',
    },
  ],
};

export default { displayName, propTypes, defaultProps };
