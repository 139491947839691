import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Typography } from 'antd';

import config from './NumberingDragable.component.config';
import './NumberingDragable.component.styles.css';

const { Title, Text } = Typography;

const NumberingDragable = ({
  locked,
  sourceData,
  needUpdatePosition,
  actionUpdatePosition,
  actionClickItem,
  hoverItem,
  addMoreBtn,
  funcAddMoreBtn,
}) => {
  const [data, setData] = useState(sourceData);
  useEffect(() => {
    setData(sourceData);
  }, [sourceData]);

  const changePositionList = (cloneData) => {
    // change position list
    const isData = cloneData;
    const newObj = [];
    for (let index = 0; index < isData?.length; ) {
      const newPosition = index + 1;
      const currPostition = isData[index].position;

      newObj.push({
        ...isData[index],
        position: index + 1,
      });

      isData[index].position = newPosition;
      index += 1;
    }
    if (needUpdatePosition) {
      actionUpdatePosition(newObj);
    }
    return isData;
  };

  const onHandleDragEnd = (result) => {
    if (!result.destination) return;
    const clonedListItems = JSON.parse(JSON.stringify(data));
    const [reorderedItem] = clonedListItems.splice(result.source.index, 1);
    clonedListItems.splice(result.destination.index, 0, reorderedItem);

    const clonedData = changePositionList(clonedListItems);
    setData(clonedData);
  };

  const renderItem = (item, index) => (
    <Draggable
      draggableId={`numbering-${index}`}
      index={index}
      key={index}
      isDragDisabled={locked}
    >
      {(provided, snapshot) => (
        <div
          key={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...snapshot.isDragging}
          {...provided.draggableProps.style}
        >
          <div
            key={index}
            onClick={() => actionClickItem(index)}
            type={hoverItem === index ? 'primary' : ''}
            className={`object-numbering ${
              hoverItem === index ? 'selected' : ''
            }`}
          >
            {index + 1}
          </div>
        </div>
      )}
    </Draggable>
  );

  return (
    <div id="NumberingDragable">
      <DragDropContext onDragEnd={onHandleDragEnd}>
        <Droppable droppableId="dropableButton" direction="horizontal">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data.map((item, index) => renderItem(item, index))}
              <Button
                hidden={!addMoreBtn}
                type="primary"
                icon={<PlusOutlined />}
                className="button-add-more-section"
                onClick={funcAddMoreBtn}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

NumberingDragable.displayName = config.displayName;
NumberingDragable.defaultProps = config.defaultProps;
NumberingDragable.propTypes = config.propTypes;

export default NumberingDragable;
