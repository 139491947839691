import React, { useEffect } from 'react';
import moment from 'moment';

import { isMobile } from 'react-device-detect';
import {
  CheckOutlined,
  WhatsAppOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Button, Card, List, Tabs, Typography } from 'antd';
import { MotionPage } from '../../../components';

import ContentParrent from './ContentParrent/ContentParrent.component';
import ContentActivity from './ContentActivity/ContentActivity.component';
import ContentEntity from './ContentEntity/ContentEntity.component';

import config from './MainContent.component.config';
import './MainContent.component.styles.css';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const idLocale = require('moment/locale/id');

const MainContent = ({
  programSelection,
  treeData,
  sourceData,
  activitySessionUser,
  activitySessionUserPrev,
  quizSubmissionHistory,
  onCreateActivitySession,
  onUpdateActivitySession,
  onUpdateQuizSubmission,
  onUpdateStateSession,
  onUpdateAssignmentSubmission,
  onUpdateGroupAssignmentSubmission,
  navigateCourse,
  setDefaultCheckTree,
  openModal,
  courseDetail,
  onValidateClaimingCertif,
  ...props
}) => {
  const {
    isDataLoaded,
    selectionContentType,
    selectionContent,
    selectionContentPrev,
    contentVideoPlaying,
  } = sourceData;

  const onNavigateCourse = (refID) => {
    const splitID = refID.split('-');

    const paramNav = {
      parrent: splitID[0],
      activity: splitID[1],
      entity: splitID[2],
    };
    setDefaultCheckTree([`${splitID[0]}-${splitID[1]}`, refID]);
    navigateCourse(paramNav);
  };

  const getConditionDatetime = (dateScheduled) => {
    const dateToday = moment(new Date());
    const dateFromData = moment(new Date(dateScheduled));
    const diff = dateToday.diff(dateFromData);
    const diffDuration = moment.duration(diff);

    const diffDays = diffDuration.days();
    const diffHours = diffDuration.hours();
    const diffMinutes = diffDuration.minutes();

    let decisitionDatetime = false;
    if (diffDays >= 0 && diffHours >= 0 && diffMinutes >= 0) {
      decisitionDatetime = true;
    }
    return decisitionDatetime;
  };

  const declineContent = () => (
    <div
      style={{
        backgroundColor: '#ffffff',
        padding: 24,
        // margin: '30px 24px 0px 24px',
        borderRadius: 12,
      }}
    >
      <Title level={2}>
        {`Selamat Datang di Program `}
        {courseDetail?.program_name}
      </Title>
      <Paragraph
        ellipsis={{
          rows: 3,
          expandable: true,
        }}
      >
        {courseDetail?.description}
      </Paragraph>
      <br />
      <br />
      <Paragraph style={{ fontSize: 16 }}>
        Course yang kamu pilih saat ini tidak tersedia. <br />
        Silahkan cek jadwal atau hubungi customer support Boleh Belajar.
        <br />
        <br />
      </Paragraph>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          icon={<WhatsAppOutlined />}
          onClick={() => window.open('https://wa.me/6281944365758', '_blank')}
        >
          CS Boleh Belajar
        </Button>
      </div>
    </div>
  );

  const defaultContent = () => (
    <div
      style={{
        backgroundColor: '#ffffff',
        padding: 24,
        // margin: '30px 24px 0px 24px',
        borderRadius: 12,
      }}
    >
      <Title level={2}>
        {`Selamat Datang di Program `}
        {courseDetail?.program_name}
      </Title>
      <Paragraph
        ellipsis={{
          rows: 3,
          expandable: true,
        }}
      >
        {courseDetail?.description}
      </Paragraph>
      <br />
      <br />
      <Title level={4}>Materi Program</Title>
      <List
        itemLayout="horizontal"
        dataSource={courseDetail.activity_list}
        renderItem={(items, index) => (
          <List.Item>
            <Text>
              {`${Number(index) + 1}. `}
              {items.activity_name}
            </Text>
          </List.Item>
        )}
      />
    </div>
  );

  const renderContent = () => {
    let contentRender = null;
    if (selectionContentType === 'parrent') {
      contentRender = <ContentParrent selectionContent={selectionContent} />;
    } else if (selectionContentType === 'activity') {
      contentRender = (
        <ContentActivity
          selectionContent={selectionContent}
          onNavigateCourse={onNavigateCourse}
        />
      );
    } else if (selectionContentType === 'entity') {
      contentRender = (
        <ContentEntity
          users={props.users}
          selectionContent={selectionContent}
          selectionContentPrev={selectionContentPrev}
          activitySessionUser={activitySessionUser}
          activitySessionUserPrev={activitySessionUserPrev}
          courseDetail={courseDetail}
          contentVideoPlaying={contentVideoPlaying}
          getConditionDatetime={getConditionDatetime}
          openModal={openModal}
          onNavigateCourse={onNavigateCourse}
          onCreateActivitySession={onCreateActivitySession}
          onUpdateActivitySession={onUpdateActivitySession}
          onUpdateQuizSubmission={onUpdateQuizSubmission}
          onUpdateStateSession={onUpdateStateSession}
          onUpdateAssignmentSubmission={onUpdateAssignmentSubmission}
          onUpdateGroupAssignmentSubmission={onUpdateGroupAssignmentSubmission}
          quizSubmissionHistory={quizSubmissionHistory}
          onValidateClaimingCertif={onValidateClaimingCertif}
        />
      );
    } else if (
      courseDetail.activity_list.length > 0 &&
      courseDetail.activity_list[0].is_active === 0
    ) {
      contentRender = declineContent();
    } else {
      contentRender = defaultContent();
    }
    return contentRender;
  };

  const renderDescription = (description) => {
    try {
      return JSON.parse(description)?.map((item, index) => (
        <div>
          <Text>
            {index + 1}. {item.description}
          </Text>
        </div>
      ));
    } catch (e) {
      return <Paragraph>{description}</Paragraph>;
    }
  };

  const handleNextClick = (condition) => {
    let nextEntity = null;

    const operator = condition === 'next' ? Number(1) : Number(-1);

    const current = courseDetail.activity_list.find((item) => {
      const selected = item.entity_list.find(
        (itemSelected) => itemSelected.id === sourceData.selectionContent.id,
      );
      return selected;
    });

    const nextObject = current.entity_list.find(
      (item) =>
        item.position === sourceData.selectionContent.position + operator,
    );

    if (nextObject === undefined) {
      // find object next activity
      const currentActivityIndex = courseDetail.activity_list.findIndex(
        (item) => item.id === current.id,
      );

      const nextActivity =
        courseDetail.activity_list[currentActivityIndex + operator];

      const nextActivityEntity =
        nextActivity.entity_list[
          condition === 'next' ? 0 : nextActivity.entity_list.length - 1
        ];
      nextEntity = nextActivityEntity;
    } else {
      nextEntity = nextObject;
    }

    onNavigateCourse(nextEntity.id);
  };

  const disablePrevContent = () => {
    const current = courseDetail?.activity_list?.find((item) => {
      const selected = item?.entity_list?.find(
        (itemSelected) => itemSelected.id === sourceData?.selectionContent?.id,
      );
      return selected;
    });

    const prevObject = current?.entity_list?.find(
      (item) => item.position === sourceData.selectionContent.position - 1,
    );

    const currentActivityIndex = courseDetail?.activity_list?.findIndex(
      (item) => item.id === current?.id,
    );

    const prevActivity = courseDetail?.activity_list[currentActivityIndex - 1];

    if (prevObject === undefined && prevActivity === undefined) {
      return true;
    }

    return false;
  };

  const disableLastContent = () => {
    const current = courseDetail?.activity_list.find((item) => {
      const selected = item?.entity_list.find(
        (itemSelected) => itemSelected.id === sourceData?.selectionContent?.id,
      );
      return selected;
    });

    const nextObject = current?.entity_list.find(
      (item) => item.position === sourceData.selectionContent.position + 1,
    );

    const currentActivityIndex = courseDetail?.activity_list.findIndex(
      (item) => item.id === current?.id,
    );

    const lastActivity = courseDetail?.activity_list[currentActivityIndex + 1];

    if (
      (nextObject === undefined && lastActivity === undefined) ||
      activitySessionUser.status === null
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <MotionPage>
        <div id="MainContent">
          <div className={`content ${isMobile && 'mobile'}`}>
            {renderContent()}
          </div>

          <Card
            style={{
              marginTop:
                isMobile &&
                selectionContent?.activity_type_id === 2 &&
                activitySessionUser?.status === 0
                  ? 0
                  : 16,

              marginBottom:
                isMobile &&
                selectionContent?.activity_type_id === 2 &&
                activitySessionUser?.status === 0
                  ? 0
                  : 48,
              // display: courseDetail.activity_list[0].is_active === 0 && 'none',
            }}
          >
            <div className="extra-content">
              <Tabs
                items={[
                  {
                    key: 1,
                    label: 'Deskripsi Content',
                    children: (
                      <div>
                        {renderDescription(selectionContent?.description)}
                      </div>
                    ),
                  },
                  // {
                  //   key: 2,
                  //   label: 'Reviews',
                  //   children: <Paragraph>Reviews Content</Paragraph>,
                  // },
                  // {
                  //   key: 3,
                  //   label: 'Q&A',
                  //   children: <Paragraph>Discussion</Paragraph>,
                  // },
                ]}
              />
            </div>
          </Card>
        </div>
      </MotionPage>
      <div className="button-content-navigation">
        <Button
          icon={<LeftOutlined />}
          type="primary"
          onClick={() => handleNextClick('prev')}
          style={{ marginRight: 10 }}
          disabled={disablePrevContent()}
        >
          Previous
        </Button>
        <Button
          type="primary"
          onClick={() => handleNextClick('next')}
          disabled={disableLastContent()}
        >
          Next
          <RightOutlined />
        </Button>
      </div>
    </div>
  );
};

MainContent.displayName = config.displayName;
MainContent.defaultProps = config.defaultProps;
MainContent.propTypes = config.propTypes;

export default MainContent;
