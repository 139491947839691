import PropTypes from 'prop-types';

const displayName = 'DetailEntity';
const propTypes = {};
const defaultProps = {
  entityDontHaveLink: [3, 4, 5, 6],
  entityRichText: [5, 6],
  entityForQuizID: [3, 4],
  alphabet: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ],
};

export default { displayName, propTypes, defaultProps };
