import { Button, Typography, Empty } from 'antd';
import React from 'react';

import config from './EmptyContent.component.config';

const EmptyContent = ({
  title,
  description,
  onButtonPressed,
  buttonText,
  hasIcon,
  icon = undefined,
}) => (
  <div className="empty-content">
    <div className="img-empty">
      <Empty />
    </div>
    <div className="content-empty">
      <Typography.Title level={2}>{title}</Typography.Title>
      <Typography.Text>{description}</Typography.Text>
    </div>
    <Button type="primary" onClick={onButtonPressed} icon={hasIcon && icon}>
      {buttonText}
    </Button>
  </div>
);

EmptyContent.displayName = config.displayName;
EmptyContent.defaultProps = config.defaultProps;
EmptyContent.propTypes = config.propTypes;

export default EmptyContent;
