import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import headerReducer from './header.reducer';
import commonReducer from './common.reducer';
import userAuthReducer from './userAuth.reducer';
import searchReducer from './search.reducer';
import lessonsReducer from './lessons.reducer';
import programsReducer from './programs.reducer';
import programBundlingReducer from './program_bundling.reducer';
import quizReducer from './quiz.reducer';
import studentReducer from './student.reducer';
import certificateReducer from './certificate.reducer';

const headerPersistConfig = {
  key: 'header',
  storage,
};
const usersPersistConfig = {
  key: 'users',
  storage,
};
const lessonPersistConfig = {
  key: 'lesson',
  storage,
};
const programPersistConfig = {
  key: 'program',
  storage,
};
const programBundlingPersistConfig = {
  key: 'program_bundling',
  storage,
};
const quizPersistConfig = {
  key: 'quiz',
  storage,
};

const studentPersistConfig = {
  key: 'student',
  storage,
};

const certificatePersistConfig = {
  key: 'certificate',
  storage,
};

const moduleState = {
  header: persistReducer(headerPersistConfig, headerReducer),
  common: commonReducer,
  users: persistReducer(usersPersistConfig, userAuthReducer),
  search: searchReducer,
  lesson: persistReducer(lessonPersistConfig, lessonsReducer),
  program: persistReducer(programPersistConfig, programsReducer),
  program_bundling: persistReducer(
    programBundlingPersistConfig,
    programBundlingReducer,
  ),
  quiz: persistReducer(quizPersistConfig, quizReducer),
  student: persistReducer(studentPersistConfig, studentReducer),
  certificate: persistReducer(certificatePersistConfig, certificateReducer),
};

export default combineReducers(moduleState);
