import { Modal, notification, Typography } from 'antd';
import React, { useState } from 'react';

import { BaseLayout, MotionPage } from '../../../components';
import useCertificateConfig from '../../../hook/useCertificateConfig';

import config from './AdminCertificate.component.config';
import CardCertificate from './CardCertificate/CardCertificate.component';
import ModalCreateCertificate from './ModalCreateCertificate/ModalCreateCertificate.component';

const AdminCertificate = ({
  programGetTypeSuggestions,
  fieldSuggestions,
  certificateCreate,
  certificateUpdate,
  certificateList,
  listCertificate,
  ...props
}) => {
  const { Title, Text } = Typography;
  const { confirm } = Modal;
  const dataCertificate = listCertificate.data;
  const dataProgram = fieldSuggestions.program_types;
  const {
    refreshData,
    isEdit,
    openModal,
    setRefreshData,
    setOpenModal,
    updateCertificate,
    createCertificate,
    currentSelectedId,
    editCertificate,
    getIdForCreateCertificate,
  } = useCertificateConfig();

  React.useEffect(() => {
    if (refreshData === false) {
      certificateList()
        .catch(() =>
          notification.error({
            message: `System Error`,
            description: `Failed to get certificate list. Please try again later.`,
          }),
        )
        .finally(() => setRefreshData(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  // useEffect for programGetTypeSuggestions
  React.useEffect(() => {
    programGetTypeSuggestions().catch(() =>
      notification.error({
        message: `System Error`,
        description: `Unable to get program type suggestions`,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout title="Certificate Configuration">
        <div id="admin-certificate">
          <CardCertificate
            data={dataCertificate}
            programType={dataProgram}
            onEdit={editCertificate}
            onCreate={getIdForCreateCertificate}
          />
        </div>
        <ModalCreateCertificate
          showModal={openModal}
          isEdit={isEdit}
          editData={dataCertificate.find(
            (item) => item.program_type === currentSelectedId.program,
          )}
          closeModal={() => setOpenModal(false)}
          fieldSuggestions={dataProgram}
          createCertificate={createCertificate}
          updateCertificate={updateCertificate}
        />
      </BaseLayout>
    </MotionPage>
  );
};

AdminCertificate.displayName = config.displayName;
AdminCertificate.defaultProps = config.defaultProps;
AdminCertificate.propTypes = config.propTypes;

export default AdminCertificate;
