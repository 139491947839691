import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';

// import { STUDENT_ROUTE } from '../../navigations/student.route';

import config from './NavigationBarMobile.component.config';
import './NavigationBarMobile.component.styles.less';

const NavigationBarMobile = ({ routeMenu, ...props }) => {
  const navigate = useNavigate();
  const navigationMenu = (key) => navigate(key);

  const currentPath = () => {
    const locationPath = window.location.pathname;
    const patternIgnore = ['detail', 'course'];
    let lockPattern = null;
    patternIgnore.forEach((text) => {
      if (locationPath.includes(`/${text}`)) {
        lockPattern = text;
      }
    });

    const pattern = locationPath.includes(`/${lockPattern}`)
      ? locationPath.split(`/${lockPattern}`)[0]
      : locationPath;

    return pattern;
  };

  const generateMenu = (contentMenu) =>
    contentMenu.map((item) =>
      item.forMobile ? <Menu.Item key={item.key} icon={item.icon} /> : null,
    );

  return (
    <div id="MobileNavigation">
      <div className="custom-menu">
        {routeMenu.map((items) =>
          items.forMobile ? (
            <div
              id={items.id}
              className={`collection-menu-item ${
                currentPath() === items.key && `item-selected`
              }`}
              onClick={() => navigationMenu(items.key)}
            >
              {items.icon}
              {items.label}
            </div>
          ) : null,
        )}
      </div>
    </div>
  );
};

NavigationBarMobile.displayName = config.displayName;
NavigationBarMobile.defaultProps = config.defaultProps;
NavigationBarMobile.propTypes = config.propTypes;

export default NavigationBarMobile;
