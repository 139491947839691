import { connect } from 'react-redux';
import AdminLessons from './AdminLessons.component';
import { fConsumeToken } from '../../../redux/actions/authUser.actions';
import {
  lessonsCreate,
  lessonsList,
  setParrentLesson,
} from '../../../redux/actions/lessons.actions';
import {
  clearSearchText,
  setSearchText,
} from '../../../redux/actions/search.actions';

const mapStateToProps = (state) => ({
  common: state.common.settings,
  searchText: state.search,
  lessonListData: state.lesson.list,
});

const mapDispatchToProps = {
  clearSearchText,
  fConsumeToken,
  lessonsCreate,
  lessonsList,
  setParrentLesson,
  setSearchText,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLessons);
