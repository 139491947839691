import { connect } from 'react-redux';
import Instructure from './Instructure.component';

const mapStateToProps = (state) => ({
  name: state.header,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Instructure);
