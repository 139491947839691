import {
  CheckCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Image,
  Modal,
  Radio,
  Row,
  Tabs,
  Tooltip,
  Typography,
  Input,
} from 'antd';
import { get, round } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ICON_QUIZ_NILAI, QUIZ_IMG_POPUP } from '../../../../assets/svg';
import { LoadingEvent } from '../../../../components';

import config from './ContentQuiz.component.config';
import './ContentQuiz.component.styles.css';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ContentQuiz = ({
  quizData,
  selectionContent,
  selectedProgramId,
  activitySessionUser,
  onCreateActivitySession,
  onUpdateQuizSubmission,
  onUpdateStateSession,
  quizSubmissionHistory,
  openModal,
  validateClaimingCertificate,
}) => {
  const [state, setState] = useState({
    onProgressQuiz: false,
    onUpdateSubmit: false,
    collectionAnswer: [],
    onReadCurrentSession: false,
    showModalSubmission: false,
  });
  const [quizStep, setQuizStep] = React.useState(0);
  const [modalFinsish, setModalFinish] = React.useState(false);
  const [countDownTimer, setCountDownTimer] = useState();
  const intervalTime = React.useRef(null);
  const [isClaimed, setIsClaimed] = useState(false);

  const isScorePassed = useMemo(
    () => activitySessionUser.quiz_score >= quizData?.min_score,
    [activitySessionUser, quizData],
  );

  const getTooltipTitle = () => {
    let title;
    if (!isScorePassed) {
      title = 'You have to finish certification test first!';
    } else if (isClaimed) {
      title = 'Certificate can only be claimed once';
    }

    return title;
  };

  useEffect(() => {
    if (selectionContent?.activity_type_id === 7) {
      validateClaimingCertificate(selectedProgramId).then((data) => {
        if (data.length > 0) {
          setIsClaimed(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionContent?.activity_type_id, validateClaimingCertificate]);

  useEffect(() => {
    if (isMobile) {
      const handleBack = () => {
        window.history.forward();
      };

      // Attach the function to the popstate event to prevent browser back navigation
      window.addEventListener('popstate', handleBack);

      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener('popstate', handleBack);
      };
    }

    return false;
  }, []);

  const countDownTimeQuizQuiz = (timer, func, isFinish) => {
    let minutes = Math.floor(timer);
    let seconds = 0;
    if (!isFinish) {
      intervalTime.current = setInterval(() => {
        if (seconds <= 0) {
          if (minutes !== 0) {
            seconds = 60;
            minutes -= 1;
          }
        } else {
          seconds -= 1;
        }
        func({ minutes, seconds });
        // func(`${minutes}m ${seconds}s`);
      }, 1000);
    } else {
      clearInterval(intervalTime.current);
      func({ minutes: null, seconds: null });
    }
  };
  const updateCountTime = (isFinish) => {
    const continueDuration = quizData?.duration;
    countDownTimeQuizQuiz(continueDuration, setCountDownTimer, isFinish);
  };

  const onStartQuiz = () => {
    if (parseFloat(quizData?.duration) > 0) updateCountTime(false);
    if (
      activitySessionUser?.status === null ||
      activitySessionUser?.status === 1
    ) {
      onCreateActivitySession({
        entity_id: selectionContent.entityID,
      });
    }
    // update session state current run content
    onUpdateStateSession({
      quiz_running: true,
    });
    // update for localy state quiz content
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.onProgressQuiz = !currentState.onProgressQuiz;
      return { ...currentState };
    });
  };

  const onFinishQuiz = () => {
    // console.log(state.collectionAnswer);
    onUpdateQuizSubmission({ asnwerSubmit: state.collectionAnswer });
    onUpdateStateSession({
      quiz_running: false,
    });
    updateCountTime(true);
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.onUpdateSubmit = !currentState.onUpdateSubmit;
      currentState.collectionAnswer = [];
      return { ...currentState };
    });
    setTimeout(() => {
      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.onProgressQuiz = !currentState.onProgressQuiz;
        currentState.onUpdateSubmit = !currentState.onUpdateSubmit;
        return { ...currentState };
      });
      setQuizStep(0);
    }, 1000);
  };

  useEffect(() => {
    function startTimer() {
      if (countDownTimer?.minutes === 0 && countDownTimer?.seconds === 0) {
        onUpdateQuizSubmission({ asnwerSubmit: state.collectionAnswer });
        onUpdateStateSession({
          quiz_running: false,
        });
        updateCountTime(true);
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.onProgressQuiz = !currentState.onProgressQuiz;
          currentState.onUpdateSubmit = !currentState.onUpdateSubmit;
          return { ...currentState };
        });
        setTimeout(() => {
          setState((prevState) => {
            const currentState = { ...prevState };
            currentState.onUpdateSubmit = !currentState.onUpdateSubmit;
            return { ...currentState };
          });
        }, 1000);
      }
    }
    if (parseFloat(quizData?.duration) > 0 && state.onProgressQuiz)
      startTimer();
  });

  useEffect(() => {
    const onCheckCurrentSession = () => {
      setState((prevState) => {
        const currentState = { ...prevState };
        if (
          activitySessionUser.entity_id === selectionContent.entityID &&
          activitySessionUser.status === 0
        ) {
          currentState.onProgressQuiz = !currentState.onProgressQuiz;
          // update session state current run content
          if (parseFloat(quizData.duration) > 0) updateCountTime(false);
          onUpdateStateSession({
            quiz_running: true,
          });
        }
        currentState.onReadCurrentSession = !currentState.onReadCurrentSession;
        return { ...currentState };
      });
    };

    if (!state.onReadCurrentSession) onCheckCurrentSession();
  });

  const findAnswerSubmission = (quizID, answerID, type) => {
    let findCollection = null;
    if (type === 'question') {
      findCollection = quizSubmissionHistory.data.list_submission.find(
        (items) => items.quizdetail_id === quizID && items.is_correct === 1,
      );
    } else {
      findCollection = quizSubmissionHistory.data.list_submission.find(
        (items) =>
          items.quizdetail_id === quizID && items.quizanswer_id === answerID,
      );
    }
    return findCollection;
  };

  const renderQuizScore = () => {
    let contentScore = null;
    const activitySession = activitySessionUser;
    const selection = selectionContent;
    if (
      activitySession?.status === 1 &&
      selection?.entityID === activitySession?.entity_id
    ) {
      contentScore = (
        <div className="quiz-scoring">
          <div>
            <ICON_QUIZ_NILAI />
          </div>
          <div>
            <Text>
              Congrats, You have successfully <br /> completed this Quiz <br />
              <b>Your score : {round(activitySession?.quiz_score, 2)}</b>
            </Text>
          </div>
        </div>
      );
    }
    return contentScore;
  };
  console.log(state.collectionAnswer);
  const generateAnswer = (itemQuestion, isResult) => {
    let listAnswer = null;
    listAnswer = itemQuestion.list_answer.map((itemAnswer, indexAnswer) => {
      let tempAnswer = null;

      let statusChecked = null;
      let statusCorrect = null;
      if (isResult) {
        const findSubmission = findAnswerSubmission(
          itemQuestion.quiz_detail_id,
          itemAnswer.answer_id,
          'answer',
        );
        statusChecked =
          get(findSubmission, 'quizanswer_id') === itemAnswer.answer_id
            ? 'checked'
            : false;

        if (
          get(findSubmission, 'quizanswer_id') === itemAnswer.answer_id &&
          get(findSubmission, 'is_correct') === 1
        ) {
          statusCorrect = (
            <CheckCircleOutlined
              style={{
                backgroundColor: '#2CBEA4',
                borderRadius: '100%',
                border: 'unset',
                color: '#fff',
                fontSize: 20,
                marginLeft: 20,
              }}
            />
          );
        }
      }

      switch (itemQuestion.quiz_type) {
        case 1:
          tempAnswer = (
            <div
              className="answer-item single-choice"
              key={`${indexAnswer + 1}-${itemAnswer?.answer_id}`}
            >
              <div className="item-answer">
                {isResult ? (
                  <Radio
                    checked={statusChecked}
                    disabled={isResult ? 'disabled' : false}
                    key={`${indexAnswer + 1}-${itemAnswer?.answer_id}`}
                    onClick={() =>
                      config.defaultProps.selectAnswerSingleChoice(
                        setState,
                        itemQuestion?.quiz_detail_id,
                        itemAnswer.answer_id,
                        itemAnswer.answer,
                      )
                    }
                  />
                ) : (
                  <Radio
                    key={`${indexAnswer + 1}-${itemAnswer?.answer_id}`}
                    checked={
                      config.defaultProps.findCollectionAnswerChoosen(
                        state,
                        itemQuestion?.quiz_detail_id,
                      ) === itemAnswer?.answer_id
                    }
                    onClick={() =>
                      config.defaultProps.selectAnswerSingleChoice(
                        setState,
                        itemQuestion?.quiz_detail_id,
                        itemAnswer.answer_id,
                        itemAnswer.answer,
                      )
                    }
                  />
                )}
                <Text>
                  {` ${config.defaultProps.alphabet[indexAnswer]}. `}
                  {`${itemAnswer.answer}`}
                  {statusCorrect}
                </Text>
              </div>
            </div>
          );
          break;
        case 2:
          tempAnswer = (
            <div
              className="answer-item single-choice"
              key={`${indexAnswer + 1}-${itemAnswer?.answer_id}`}
            >
              <div className="item-answer">
                {isResult ? (
                  <Checkbox
                    checked={statusChecked}
                    disabled={isResult ? 'disabled' : false}
                    key={`${indexAnswer + 1}-${itemAnswer?.answer_id}`}
                    onClick={(condition) =>
                      config.defaultProps.selectAnswerMultipleChoice(
                        setState,
                        condition?.target?.checked,
                        itemQuestion?.quiz_detail_id,
                        itemAnswer.answer_id,
                        itemAnswer.answer,
                      )
                    }
                  />
                ) : (
                  <Checkbox
                    key={`${indexAnswer + 1}-${itemAnswer?.answer_id}`}
                    checked={
                      config.defaultProps.findCollectionAnswerMultipleChoosen(
                        state,
                        itemQuestion?.quiz_detail_id,
                        itemAnswer?.answer_id,
                      ) === itemAnswer?.answer_id
                    }
                    onClick={(condition) =>
                      config.defaultProps.selectAnswerMultipleChoice(
                        setState,
                        condition?.target?.checked,
                        itemQuestion?.quiz_detail_id,
                        itemAnswer.answer_id,
                        itemAnswer.answer,
                      )
                    }
                  />
                )}
                <Text>
                  {` ${config.defaultProps.alphabet[indexAnswer]}. `}
                  {`${itemAnswer.answer}`}
                  {statusCorrect}
                </Text>
              </div>
            </div>
          );
          break;
        default:
          break;
      }
      return tempAnswer;
    });

    if (itemQuestion.quiz_type === 3) {
      listAnswer = (
        <div
          className="answer-item essay"
          key={`${itemQuestion?.question_id}-${itemQuestion?.quiz_detail_id}`}
        >
          <div className="item-answer">
            <Text>Answer :</Text>
            <TextArea
              rows={8}
              showCount
              maxLength={itemQuestion?.word_limit}
              onKeyUp={(field) => {
                config.defaultProps.selectAnswerEssay(
                  setState,
                  itemQuestion?.quiz_detail_id,
                  field.target.value,
                );
              }}
              className="textarea-essay"
            />
          </div>
        </div>
      );
    }
    return listAnswer;
  };

  const generateQuestion = (itemQuestion, index, isResult) => {
    let imagesSource = null;
    if (
      itemQuestion.images !== '' &&
      itemQuestion.images !== null &&
      itemQuestion.images !== undefined
    ) {
      imagesSource = itemQuestion.images.split(', ');
    }

    const question = (
      <Card className="ant-card-question">
        <div>
          <div
            className="question-image"
            hidden={imagesSource !== null ? false : 'hidden'}
          >
            <Row gutter={24} justify="start" className="row-image-question">
              {imagesSource !== null &&
                imagesSource.map((img) => (
                  <Col>
                    <Image
                      className="question-image"
                      preview={{
                        src: img,
                      }}
                      style={{
                        backgroundImage: `url(${img})`,
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
          <Text>
            {index + 1}. {itemQuestion.question}
          </Text>
          <br />
          <br />
        </div>
        {generateAnswer(itemQuestion, isResult)}
      </Card>
    );
    return question;
  };

  const renderNumberOfQuestion = (question, isResult) => {
    const number = question.map((items, index) => {
      const active = quizStep === index ? 'active' : '';
      const findQuestionAnswered = state.collectionAnswer.find(
        (answering) => answering.quizdetail_id === items.quiz_detail_id,
      );
      const IsQuestionAnswering =
        findQuestionAnswered !== undefined && 'isanswered';

      let correctStatusQuestion = '';
      if (isResult) {
        const findSubmission2 = findAnswerSubmission(
          items.quiz_detail_id,
          null,
          'question',
        );
        correctStatusQuestion =
          get(findSubmission2, 'is_correct') === 1 ? 'correct' : 'incorrect';
      }

      return (
        <div
          key={items.quiz_detail_id}
          className={`number-question ${active} ${correctStatusQuestion} ${IsQuestionAnswering}`}
          onClick={() => {
            setQuizStep(index);
          }}
        >
          {index + 1}
        </div>
      );
    });
    return number;
  };
  // controller question
  const nextPagination = () => {
    setQuizStep(quizStep + 1);
  };
  const prevPagination = () => {
    setQuizStep(quizStep - 1);
  };

  const renderQuizContent = (isResult) => {
    const itemQuiz = quizData.items;
    const collectContent = [];
    itemQuiz.forEach((items, index) => {
      const contents = {
        number: index + 1,
        content: generateQuestion(items, index, isResult),
      };
      collectContent.push(contents);
    });

    return (
      <div>
        <div className="steps-timer">
          <div style={{ width: '35%' }} hidden={isMobile}>
            <Title level={3}>Questions Number List</Title>
          </div>
          <div
            style={{
              width: '65%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Button
                type="primary"
                className="prev-btn"
                // onClick={() => prevPagination()}
                icon={<LeftOutlined />}
                hidden={!state.showModalSubmission}
                onClick={() => {
                  if (state.showModalSubmission) {
                    setState((prev) => {
                      const current = { ...prev };
                      current.showModalSubmission =
                        !current.showModalSubmission;
                      return { ...current };
                    });
                    setQuizStep(0);
                  } else {
                    prevPagination();
                  }
                }}
              >
                {state.showModalSubmission
                  ? 'Tinggalkan Mode Evaluasi'
                  : 'Tinggalkan Quiz'}
              </Button>
            </div>
            <div>
              {quizData?.duration > 0 ? (
                <div className="timer" hidden={isResult ? 'hidden' : false}>
                  {countDownTimer?.minutes !== undefined && (
                    <Text>
                      Timer:{' '}
                      {countDownTimer.minutes !== undefined &&
                        countDownTimer.minutes !== null &&
                        `${countDownTimer.minutes}m`}{' '}
                      {countDownTimer.seconds !== undefined &&
                        countDownTimer.seconds !== null &&
                        `${countDownTimer.seconds}s`}
                    </Text>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={`question-content ${isMobile && 'mobile'}`}>
          <div className="steps-paginations" style={{ width: '35%' }}>
            <div className="number-list">
              {renderNumberOfQuestion(itemQuiz, isResult)}
            </div>
          </div>
          <div style={{ width: '65%' }} className="contents">
            <div className="steps-content">
              {collectContent[quizStep].content}
            </div>
            <div className="steps-action">
              {quizStep > 0 && (
                <Button
                  className="prev-btn"
                  onClick={() => prevPagination()}
                  icon={<LeftOutlined />}
                >
                  {!isMobile && 'Sebelumnya'}
                </Button>
              )}
              {quizStep < collectContent.length - 1 && (
                <Button
                  className="next-btn"
                  type="primary"
                  onClick={() => nextPagination()}
                  icon={isMobile && <RightOutlined />}
                >
                  {!isMobile && (
                    <span>
                      Selanjutnya <RightOutlined />
                    </span>
                  )}
                </Button>
              )}
              {!isResult && quizStep === collectContent.length - 1 && (
                // <Popconfirm
                //   placement="topLeft"
                //   title={
                //     <div style={{ width: 300 }}>
                //       <Text>
                //         Perhatian! <br />
                //         Anda yakin dengan jawaban yang sudah anda jawab?
                //       </Text>
                //     </div>
                //   }
                //   onConfirm={() => onFinishQuiz()}
                //   okText="Sudah Yakin!"
                //   cancelText="Belum Yakin"
                // >
                // </Popconfirm>
                <Button
                  className="done-btn"
                  type="primary"
                  onClick={() => setModalFinish(true)}
                >
                  Selesai
                </Button>
              )}
            </div>
          </div>
        </div>
        <Modal
          className="ModalFinish"
          open={modalFinsish}
          centered
          footer={false}
          closable={false}
          width={350}
        >
          <div className="body-content">
            <div className="img-content">
              <QUIZ_IMG_POPUP />
            </div>
            <div className="text-content">
              <Title level={4}>Perhatian!</Title>
              <Text>Anda yakin dengan jawaban yang sudah Anda jawab?</Text>
            </div>
            <div className="btn-content">
              <Button
                type="primary"
                onClick={() => {
                  onFinishQuiz();
                  setModalFinish(false);
                }}
              >
                Sudah Yakin
              </Button>
              <Button type="default" onClick={() => setModalFinish(false)}>
                Belum Yakin
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const renderQuizDetail = () => (
    <div className="question-panel">
      <div className="quiz-form">
        <div className="list-question">{renderQuizContent()}</div>
      </div>
    </div>
  );

  const renderRenderQuizInfo = () => (
    <div className="quiz-info">
      <Title level={5}>{quizData?.quiz_name}</Title>
      {!state.showModalSubmission && (
        <>
          <Text>
            {quizData?.description}
            <br />
            <br />
            {parseFloat(quizData?.min_score) > 0 && (
              <div>
                <b>Skor Minimum: </b>
                {quizData?.min_score}
                <br />
              </div>
            )}
            {parseFloat(quizData?.retake_limit) > 0 && (
              <div>
                <b>Take Limit: </b>
                {`${activitySessionUser?.history.length} / `}
                {quizData?.retake_limit}
                <br />
              </div>
            )}
            {parseFloat(quizData?.duration) > 0 && (
              <div>
                <b>Durasi: </b>
                {quizData?.duration}
                {' Menit'}
              </div>
            )}
          </Text>
          {renderQuizScore()}
          <div className="action-button-info">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: 12,
              }}
            >
              <Button
                type="primary"
                size="large"
                disabled={
                  quizData?.retake_limit > 0 &&
                  activitySessionUser?.history.length === quizData?.retake_limit
                    ? 'disabled'
                    : false
                }
                onClick={() => setModalFinish(true)}
              >
                Mulai Kuis
              </Button>
              {selectionContent?.activity_type_id === 7 && (
                <Tooltip placement="right" title={getTooltipTitle()}>
                  <Button
                    type="primary"
                    disabled={!isScorePassed || isClaimed}
                    onClick={() => openModal(true)}
                  >
                    {`${
                      isClaimed ? 'Certificate Claimed' : 'Claim Certificate'
                    }`}
                  </Button>
                </Tooltip>
              )}
            </div>

            <Button
              type="primary"
              size="large"
              style={{ marginLeft: 20 }}
              disabled={activitySessionUser?.status === 1 ? false : 'disabled'}
              onClick={() => {
                setState((prev) => {
                  const current = { ...prev };
                  current.showModalSubmission = !current.showModalSubmission;
                  return { ...current };
                });
                setQuizStep(0);
              }}
            >
              {state.showModalSubmission ? 'Tutup Jawaban' : 'Lihat jawaban'}
            </Button>
          </div>
        </>
      )}
      {/* section review quiz */}
      {state.showModalSubmission && (
        <Card className="card-review-quiz" style={{ marginTop: 20 }}>
          {renderQuizContent(true)}
        </Card>
      )}
      <Modal
        className="ModalFinish"
        open={modalFinsish}
        centered
        footer={false}
        closable={false}
        width={350}
      >
        <div className="body-content">
          <div className="img-content">
            <QUIZ_IMG_POPUP />
          </div>
          <div className="text-content">
            <Title level={4}>INFO PENTING!</Title>
            <Text>
              Quiz ini harus kamu kerjakan hingga tuntas dan klik tombol selesai
              apabila sudah selesai. Jika kamu melakaukan refresh pada browser
              atau keluar dari browser, waktu akan dilanjutkan namun jawaban
              yang sudah kamu pilih akan hilang!
            </Text>
          </div>
          <div className="btn-content">
            <Button
              type="primary"
              onClick={() => {
                onStartQuiz();
                setModalFinish(false);
              }}
            >
              Mulai Kuis
            </Button>
            <Button type="default" onClick={() => setModalFinish(false)}>
              Belum Yakin
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );

  return (
    <div id="ContentQuiz" className={`${isMobile && 'mobile'}`}>
      {!state.onProgressQuiz && renderRenderQuizInfo()}
      {state.onProgressQuiz && renderQuizDetail()}
      {state.onUpdateSubmit && (
        <LoadingEvent
          showLoading={state.onUpdateSubmit}
          textLoading="Please wait, your aswer on progress to submitted"
        />
      )}
    </div>
  );
};

ContentQuiz.displayName = config.displayName;
ContentQuiz.defaultProps = config.defaultProps;
ContentQuiz.propTypes = config.propTypes;

export default ContentQuiz;
