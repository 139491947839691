import { CloseCircleFilled } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Typography,
  Upload,
} from 'antd';
import React, { useMemo, useState } from 'react';

import moment from 'moment';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../../services/aws.service';
import config from './ModalCreate.component.config';
import './ModalCreate.component.styles.css';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const ModalCreate = ({
  showModal,
  closeModal,
  createProgram,
  fieldSuggestions,
  totalPrograms,
  setCurrent,
  ...props
}) => {
  const refForm = React.useRef();
  const [typeSelected, setTypeSelected] = useState(null);
  const [state, setState] = React.useState({
    fileList: [],
  });
  const [inputValue, setInputValue] = useState('asdas');

  const addPrefixToClassName = (
    className,
    programType,
    bootcampType,
    monthSelected,
  ) => {
    let classPrefix;
    let classWithPrefix;
    switch (programType) {
      case 1:
        classPrefix = `${bootcampType.toUpperCase()} - ${monthSelected} - ${className}`;
        break;
      case 2:
        classPrefix = `MC - ${className}`;
        break;
      case 3:
        classPrefix = `VC - ${className}`;
        break;
      default:
        classPrefix = `${className}`;
        break;
    }

    return classPrefix;
  };

  const actionUpload = async (upload) => {
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/programs',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            // console.log(objectURL);
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };

  return (
    <Modal
      title={
        <div className="header-custom">
          <Title level={3}>Create Program</Title>
          <span>Isi field-field di bawah ini untuk membuat Program Baru.</span>
        </div>
      }
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => closeModal({ action: 'closeModal' })}
      footer={null}
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="vertical"
        id="createProgram"
        onFinish={() => {
          const rawClassName = refForm.current.getFieldsValue().program_name;
          const monthSelected = moment(
            refForm.current.getFieldsValue().month_selected,
          ).format('MMMM');
          const bootcampTypeSelected =
            refForm.current.getFieldsValue().bootcamp_type;
          const prefixedClassName = addPrefixToClassName(
            rawClassName,
            typeSelected,
            bootcampTypeSelected,
            monthSelected,
          );
          refForm.current.setFieldsValue({
            program_name: prefixedClassName,
          });
          const requestBody = {
            ...refForm.current.getFieldsValue(),
            assign_product:
              refForm.current.getFieldsValue().assign_product === undefined
                ? 0
                : refForm.current.getFieldsValue().assign_product,
            banner_image:
              state?.fileList[0]?.url !== undefined
                ? state?.fileList[0]?.url
                : '',
            // program_name: prefixedClassName,
          };
          createProgram(requestBody);
          setState({ fileList: [] });
          refForm.current.setFieldsValue({
            assign_product: 0,
            program_name: '',
            program_type: undefined,
            bootcamp_type: undefined,
            description: '',
            banner_image: '',
            status: '',
          });
          const directToPageNumber = Math.ceil(totalPrograms / 12);
          setCurrent(directToPageNumber);
        }}
      >
        <Form.Item
          label="Program Type"
          name="program_type"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          {/* <AutoComplete
            options={formattedFieldSuggestions?.program_types || []}
            filterOption={(val, option) => option?.value?.toLowerCase()?.includes(val?.toLowerCase())}
          /> */}
          <Select defaultValue={null} onSelect={(val) => setTypeSelected(val)}>
            {fieldSuggestions?.program_types?.map(
              (el) =>
                el.id !== 4 && (
                  <Option key={el?.id} value={el?.id}>
                    {el?.value}
                  </Option>
                ),
            )}
          </Select>
        </Form.Item>
        {typeSelected === 1 && (
          <>
            {/* <Form.Item
              label="Bootcamp Type"
              name="bootcamp_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="choose..."
                onSelect={(val) => setBootcampTypeSelected(val)}
              >
                <Option value={1}>PM (Performance Marketing Specialist)</Option>
                <Option value={2}>FS (Fullstack Digital Marketing)</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Bootcamp Type"
              name="bootcamp_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input
                type="text"
                maxLength={2}
                style={{ textTransform: 'uppercase' }}
              />
            </Form.Item>
            <Form.Item
              label="Select Month"
              name="month_selected"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <DatePicker format="MMMM" picker="month" />
            </Form.Item>
          </>
        )}
        {typeSelected === 2 && (
          <>
            {/* <Form.Item
              label="Bootcamp Type"
              name="bootcamp_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="choose..."
                onSelect={(val) => setBootcampTypeSelected(val)}
              >
                <Option value={1}>PM (Performance Marketing Specialist)</Option>
                <Option value={2}>FS (Fullstack Digital Marketing)</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Mini Class"
              name="miniclass_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input
                type="text"
                maxLength={2}
                style={{ textTransform: 'uppercase' }}
              />
            </Form.Item>
            <Form.Item
              label="Select Month"
              name="month_selected"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <DatePicker format="MMMM" picker="month" />
            </Form.Item>
          </>
        )}
        {typeSelected === 3 && (
          <>
            {/* <Form.Item
              label="Bootcamp Type"
              name="bootcamp_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="choose..."
                onSelect={(val) => setBootcampTypeSelected(val)}
              >
                <Option value={1}>PM (Performance Marketing Specialist)</Option>
                <Option value={2}>FS (Fullstack Digital Marketing)</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Video Learning Content"
              name="vlc_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input
                type="text"
                maxLength={2}
                style={{ textTransform: 'uppercase' }}
              />
            </Form.Item>
            <Form.Item
              label="Select Month"
              name="month_selected"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <DatePicker format="MMMM" picker="month" />
            </Form.Item>
          </>
        )}
        {typeSelected === 4 && (
          <>
            {/* <Form.Item
              label="Bootcamp Type"
              name="bootcamp_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="choose..."
                onSelect={(val) => setBootcampTypeSelected(val)}
              >
                <Option value={1}>PM (Performance Marketing Specialist)</Option>
                <Option value={2}>FS (Fullstack Digital Marketing)</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Program Bundling"
              name="programbundling_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input
                type="text"
                maxLength={2}
                style={{ textTransform: 'uppercase' }}
              />
            </Form.Item>
            <Form.Item
              label="Select Month"
              name="month_selected"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <DatePicker format="MMMM" picker="month" />
            </Form.Item>
          </>
        )}
        {typeSelected === 5 && (
          <>
            {/* <Form.Item
              label="Bootcamp Type"
              name="bootcamp_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="choose..."
                onSelect={(val) => setBootcampTypeSelected(val)}
              >
                <Option value={1}>PM (Performance Marketing Specialist)</Option>
                <Option value={2}>FS (Fullstack Digital Marketing)</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Free Trial"
              name="freetrial_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input
                type="text"
                maxLength={2}
                style={{ textTransform: 'uppercase' }}
              />
            </Form.Item>
            <Form.Item
              label="Select Month"
              name="month_selected"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <DatePicker format="MMMM" picker="month" />
            </Form.Item>
          </>
        )}
        {typeSelected === 99 && (
          <>
            {/* <Form.Item
              label="Bootcamp Type"
              name="bootcamp_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="choose..."
                onSelect={(val) => setBootcampTypeSelected(val)}
              >
                <Option value={1}>PM (Performance Marketing Specialist)</Option>
                <Option value={2}>FS (Fullstack Digital Marketing)</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Internal"
              name="internal_type"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input
                type="text"
                maxLength={2}
                style={{ textTransform: 'uppercase' }}
              />
            </Form.Item>
            <Form.Item
              label="Select Month"
              name="month_selected"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <DatePicker format="MMMM" picker="month" />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Program Name"
          name="program_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." maxLength={110} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea
            type="text"
            placeholder="Isikan deskripsi program di sini (opsional)"
            rows={5}
          />
        </Form.Item>
        <Form.Item label="Upload Banner">
          <Form.Item name="banner_image" noStyle>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={actionUpload}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Assign to Product">
          <Form.Item name="assign_product" noStyle>
            <Radio.Group
              options={[
                {
                  label: 'No',
                  value: 0,
                },
                {
                  label: 'Yes',
                  value: 1,
                },
              ]}
              defaultValue={0}
              optionType="button"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => closeModal({ action: 'closeModal' })}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalCreate.displayName = config.displayName;
ModalCreate.defaultProps = config.defaultProps;
ModalCreate.propTypes = config.propTypes;

export default ModalCreate;
