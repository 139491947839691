import { SET_DEFAULT_REDUX_MENU } from '../reduxType';

const initialState = {
  settings: {
    collapsedMenu: false,
  },
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_DEFAULT_REDUX_MENU:
      return {
        ...state,
        settings: {
          ...payload,
        },
      };
    default:
      return state;
  }
};
