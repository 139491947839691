import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Image, List, Select, Typography } from 'antd';

import { IMG_EMPTY } from '../../../assets/svg';
import {
  StudentLayout,
  CardListDataFlex,
  LoadingEvent,
  MotionPage,
} from '../../../components';

import config from './StudentPrograms.component.config';
import './StudentPrograms.component.styles.css';

const { Title, Paragraph, Text } = Typography;

const StudentPrograms = ({
  programList,
  setStudentProgram,
  getStudentProgramList,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  updateStudentProgramActivity,
  ...props
}) => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    needMounting: true,
    showLoading: false,
    list_data: [],
    temp_data: [],
  });
  const programBootcamp = programList.data.bootcamp;
  const programMiniclass = programList.data.miniclass;
  const programVideoLearningContent = programList.data.videolc;

  const mountingFirstData = () => {
    getStudentProgramList().then(() => {
      // if (programListArr?.length > 0) navigationLocal({ action: 'list' });
      setState((prevState) => {
        const currentState = { ...prevState };
        programBootcamp.forEach((items) => {
          currentState.temp_data = [...currentState.temp_data, items];
        });
        programMiniclass.forEach((items) => {
          currentState.temp_data = [...currentState.temp_data, items];
        });
        programVideoLearningContent.forEach((items) => {
          currentState.temp_data = [...currentState.temp_data, items];
        });
        currentState.list_data = currentState.temp_data;
        return { ...currentState };
      });
    });
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };
  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  });

  const actionDetail = (programsObject) => {
    setState((prevState) => {
      // set loading indicator true
      const currentState = { ...prevState };
      currentState.showLoading = !currentState.showLoading;
      return { ...currentState };
    });

    getStudentProgramDetails(programsObject).then((programActivity) => {
      // getting data detail program activity include list activity
      programActivity.forEach((activity) => {
        // looping for generating entity list per activity, nested entity list to current activity
        const activityData = activity;
        getStudentProgramActivityEntity(activityData?.id);
      });
      setTimeout(() => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.showLoading = !currentState.showLoading;
          return { ...currentState };
        });
        navigate(`/student/programs/course?course=${programsObject?.id}`);
      }, 1000);
    });
  };
  const onHandleChangeProgramType = (val) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      let newList = [];
      currentState.temp_data.forEach((items) => {
        if (items.program_type_id === Number(val)) {
          newList.push(items);
        }
      });
      if (Number(val) === 0 || val === undefined)
        newList = currentState.temp_data;
      currentState.list_data = newList;
      return { ...currentState };
    });
  };
  return (
    <MotionPage motionType="fadeIn">
      <StudentLayout
        title="Program saya"
        action={[
          <Select
            defaultValue="0"
            style={{ width: 200 }}
            allowClear
            onChange={onHandleChangeProgramType}
          >
            <Select.Option value="0">Filter by ...</Select.Option>
            <Select.Option value="1">Bootcamp</Select.Option>
            <Select.Option value="2">Mini Class</Select.Option>
            <Select.Option value="3">Video Learning Content</Select.Option>
          </Select>,
        ]}
      >
        <CardListDataFlex
          sourceData={state.list_data}
          loading={false}
          actionDetail={actionDetail}
          // extraAction={[
          //   <Button type="text" block>
          //     Share
          //   </Button>,
          //   <Button type="text" block>
          //     Favorite
          //   </Button>,
          //   <Button type="text" block>
          //     Archived
          //   </Button>,
          // ]}
        />
        <LoadingEvent showLoading={state.showLoading} />
      </StudentLayout>
    </MotionPage>
  );
};

StudentPrograms.displayName = config.displayName;
StudentPrograms.defaultProps = config.defaultProps;
StudentPrograms.propTypes = config.propTypes;

export default StudentPrograms;
