import { connect } from 'react-redux';
import StudentCalendarEvents from './StudentCalendarEvents.component';

import {
  getUpcomingEvents,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
} from '../../../redux/actions/student.actions';

const mapStateToProps = (state) => ({
  upcomingEvents: state.student.upcomingEvents,
});

const mapDispatchToProps = {
  getUpcomingEvents,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentCalendarEvents);
