import React from 'react';
import { List, Typography } from 'antd';
import config from './ContentParrent.component.config';

const { Title, Text } = Typography;

const ContentParrent = ({ selectionContent }) => {
  const activityList = selectionContent?.activity_list;
  return (
    <div className="content-activity">
      <Title level={4}>
        {/* {`${Number(selectionContent.key) + 1}. `} */}
        {selectionContent?.parrent_name}
      </Title>
      <List
        itemLayout="horizontal"
        dataSource={activityList}
        renderItem={(items, index) => (
          <List.Item>
            <Text>
              {/* {`${Number(selectionContent.key) + 1}.`} */}
              {`${Number(index) + 1}. `}
              {items.activity_name}
            </Text>
          </List.Item>
        )}
      />
    </div>
  );
};

ContentParrent.displayName = config.displayName;
ContentParrent.defaultProps = config.defaultProps;
ContentParrent.propTypes = config.propTypes;

export default ContentParrent;
