import { get } from 'lodash';
import api from '../../services/api.service';
import {
  generateNotification,
  generateHeaderRequest,
  retrieveErrorHandling,
} from '../../utils/general.utils';
import { getToken } from '../../services/token.service';
import { SET_CERTIFICATE_LIST } from '../reduxType';

const { apiService, setAuthToken } = api.getInstance();
const isUserToken = getToken();

export const certificateCreate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'certificate/create_configuration',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Certificate has been created',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const certificateUpdate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'certificate/update_configuration',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Certificate has been updated',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const certificateList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_CERTIFICATE_LIST,
    payload: {
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      '/certificate/get_configuration',
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_CERTIFICATE_LIST,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_CERTIFICATE_LIST,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getCertificatePublicAPI = (payload) => async () => {
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `certificate/get_certificate_participant_public?certID=${payload}`,
      generateHeaderRequest(),
    );
    result = Promise.resolve(data.data);
  } catch (error) {
    retrieveErrorHandling(error);
    result = Promise.reject(error);
  }
  return result;
};
