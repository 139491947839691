import PropTypes from 'prop-types';

const displayName = 'DetailPrograms';
const propTypes = {};
const defaultProps = {
  dummEntity: {
    data: [
      {
        key: '1',
        id: '1',
        name: 'Facebook Marketing Zoom Meeting',
        type: 'static',
      },
      {
        key: '2',
        id: '2',
        name: 'Google Ads Video Learning',
        type: 'dependencies',
      },
      {
        key: '3',
        id: '3',
        name: 'Pre Test Quiz',
        type: 'static',
      },
    ],
  },
};

export default { displayName, propTypes, defaultProps };
