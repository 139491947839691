import React from 'react';

import { Card, notification, Button, Input } from 'antd';
import { PlusOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { BaseLayout, DataTable } from '../../../components';

import FormCreate from './Control/form.component';
import config from './AdminProgramBundling.component.config';
import './AdminProgramBundling.component.styles.css';

const AdminProgramBundling = ({
  programList,
  getProgramBundling,
  programListData,
  programBundlingData,
  programBundlingSelected,
  setProgramBundlingSelected,
  createProgramBundling,
  updateProgramBundling,
  searchText,
  setSearchText,
}) => {
  const [stateBundling, setStateBundling] = React.useState({
    loading: false,
    loadingLoadProgramList: true,
    loadingLoadProgramBundlingList: true,
    optionForm: 'create',
  });
  const [modalCreateVisibility, setModalCreateVisibility] =
    React.useState(false);

  React.useEffect(() => {
    if (stateBundling.loadingLoadProgramList) {
      programList({ pagination: false })
        .catch(() =>
          notification.error({
            message: `System Error`,
            description: `Failed to get program list. Please try again later.`,
          }),
        )
        .finally(() =>
          setStateBundling({ ...stateBundling, loadingLoadProgramList: false }),
        );
    }
    if (stateBundling.loadingLoadProgramBundlingList) {
      getProgramBundling()
        .catch(() =>
          notification.error({
            message: `System Error`,
            description: `Failed to get program bundling list. Please try again later.`,
          }),
        )
        .finally(() =>
          setStateBundling({
            ...stateBundling,
            loadingLoadProgramBundlingList: false,
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateBundling.loadingLoadProgramList,
    stateBundling.loadingLoadProgramBundlingList,
  ]);

  const actionDetail = (data) => (
    <Button
      type="secondary"
      icon={<EditOutlined />}
      onClick={() => {
        const findData = programBundlingData.data.find(
          (bundling) => bundling.id === data,
        );
        if (findData) {
          setProgramBundlingSelected(findData);
          setModalCreateVisibility(true);
          setStateBundling({ ...stateBundling, optionForm: 'update' });
        }
      }}
    >
      Edit
    </Button>
  );

  const createBundling = (payload) => {
    createProgramBundling(payload)
      .then(() => {
        setModalCreateVisibility(false);
        setStateBundling({
          ...stateBundling,
          loadingLoadProgramBundlingList: true,
        });
      })
      .catch((err) => console.log('programCreate', err));
  };
  const updateBundling = (payload) => {
    updateProgramBundling(payload)
      .then(() => {
        setModalCreateVisibility(false);
        setStateBundling({
          ...stateBundling,
          loadingLoadProgramBundlingList: true,
        });
      })
      .catch((err) => console.log('updateProgram', err));
  };

  return (
    <BaseLayout
      title="Program Bundling"
      action={[
        <Input
          size="middle"
          placeholder="Search Programs"
          allowClear
          style={{
            width: 300,
          }}
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />,
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalCreateVisibility(true);
            setStateBundling({ ...stateBundling, optionForm: 'create' });
          }}
        >
          Create Programs
        </Button>,
      ]}
    >
      <Card>
        <DataTable
          loading={programBundlingData?.isLoading}
          columns={config.defaultProps.headColumn}
          dataSource={programBundlingData?.data?.filter((item) =>
            item?.program_bundling?.toLowerCase(),
          )}
          haveAction={[actionDetail]}
          // clickOnRow={(data) => {
          //   setProgramBundlingSelected(data);
          // }}
          withPagination={12}
        />
      </Card>
      <FormCreate
        showModal={modalCreateVisibility}
        closeModal={() => {
          setProgramBundlingSelected({});
          setStateBundling({ ...stateBundling, optionForm: 'create' });
          setModalCreateVisibility(false);
        }}
        optionForm={stateBundling.optionForm}
        programList={programListData}
        programSelected={programBundlingSelected}
        createProgram={createBundling}
        updateProgram={updateBundling}
      />
    </BaseLayout>
  );
};

AdminProgramBundling.displayName = config.displayName;
AdminProgramBundling.defaultProps = config.defaultProps;
AdminProgramBundling.propTypes = config.propTypes;

export default AdminProgramBundling;
