import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { INSTRUCTURE_ROUTE } from '../../navigations/instructure.route';

import config from './Instructure.component.config';

const Instructure = ({ ...props }) => (
  <div>
    <Routes>
      {INSTRUCTURE_ROUTE.map((items) => (
        <Route key={items.key} {...items} />
      ))}
      <Route path="/" element={<Navigate to="/instructure/dashboard" />} />
      <Route path="*" element={<Navigate to="/instructure/dashboard" />} />
    </Routes>
  </div>
);

Instructure.displayName = config.displayName;
Instructure.defaultProps = config.defaultProps;
Instructure.propTypes = config.propTypes;

export default Instructure;
