import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, parseInt, random } from 'lodash';
import { format } from 'date-fns';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import {
  Button,
  Select,
  Typography,
  Dropdown,
  Form,
  Switch,
  Row,
  Col,
  notification,
  Alert,
  Tooltip,
  Popconfirm,
} from 'antd';
import {
  EditOutlined,
  ShareAltOutlined,
  UserOutlined,
  DeleteOutlined,
  DashOutlined,
  LeftCircleOutlined,
  PlusSquareOutlined,
  EyeOutlined,
  ScheduleOutlined,
  DeleteFilled,
  EyeFilled,
} from '@ant-design/icons';

import {
  ModalCreateEntity,
  ModalPreviewEntitySession,
  DataTableDragable,
  MotionPage,
} from '../../../../components';
import DetailEntityFromActivity from '../DetailEntity/DetailEntity.component';
import ModalEdit from './ModalEdit/ModalEdit.component';
import { getParamURL } from '../../../../utils/parse';

import config from './DetailActivity.component.config';
import './DetailActivity.component.styles.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const DetailActivity = ({
  isLoading,
  selectedActivity,
  actionUpdateActivity,
  activityEntityList,
  actionCreateActivityEntity,
  actionUpdateActivityEntity,
  actionDeleteActivityEntity,
  actionUpdatePositionEntity,
  actionUpdateEntityDependency,
  actionPreviewEntitySession,
  sessionPreviewData,
  programUpdateActivityEntityStatus,
  listQuiz,
  selectionQuiz,
  getListQuizDetail,
  fieldSuggestions,
  lessonGetTypeSuggestions,
  participantsData,
  ...props
}) => {
  const currentSelected = selectedActivity;
  const activityEntity = activityEntityList;
  const [state, setState] = useState({
    showEmpty: true,
    showListEntity: false,
    showDetailEntity: false,
    showEntitySessionPreview: false,
    editEntity: false,
    entitySelection: null,
    editActivity: false,
    createActivityEntity: false,
    callEntityDetail: true,
  });

  const navigate = useNavigate();
  const paramProgramID = getParamURL('id');
  const paramsActivityID = getParamURL('activity');
  const paramsEntityID = getParamURL('entity');

  const [hoveredButton, setHoveredButton] = useState(null);
  const [clickedButton, setClickedButton] = useState(null);
  const [visiblePopConfirm, setVisiblePopConfirm] = useState(null);

  const handleMouseEnter = (id) => setHoveredButton(id);
  const handleMouseLeave = () => setHoveredButton(null);
  const handleClick = (id) => setClickedButton(id);

  const showPopconfirm = (id) => {
    setVisiblePopConfirm(id);
  };

  const hidePopconfirm = () => {
    setVisiblePopConfirm(null);
  };
  useEffect(() => {
    if (paramsEntityID && state.callEntityDetail) {
      setState({
        ...state,
        callEntityDetail: false,
      });
      if (
        paramProgramID !== '' &&
        paramsActivityID !== '' &&
        paramsEntityID !== ''
      ) {
        const findEntity = activityEntity.find(
          (item) => item.id === parseInt(paramsEntityID),
        );
        navigationLocalActivity({
          action: 'detailEntity',
          entity: findEntity,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.callEntityDetail, paramsEntityID]);

  useEffect(() => {
    if (state.createActivityEntity === true) {
      lessonGetTypeSuggestions().catch(() =>
        notification.error({
          message: `System Error`,
          description: `Unable to get program type suggestions`,
        }),
      );
    }
  }, [lessonGetTypeSuggestions, state.createActivityEntity]);

  const getEntityQuiz = (entity) => {
    if (
      config.defaultProps.entityForQuizID.indexOf(entity?.activity_type_id) >= 0
    ) {
      getListQuizDetail(Number(entity?.material_link));
    }
  };
  const navigationLocalActivity = ({ ...setup }) => {
    setState((prevState) => {
      const currentState = { ...prevState };

      switch (setup.action) {
        case 'back':
          currentState.showEmpty = false;
          currentState.showListEntity = true;
          currentState.showModalCreate = false;
          currentState.showDetailEntity = false;
          currentState.showEntitySessionPreview = false;
          navigate(
            `${window.location.pathname}?id=${paramProgramID}&activity=${paramsActivityID}`,
          );
          break;
        case 'detailEntity':
          getEntityQuiz(get(setup, 'entity'));
          navigate(
            `${
              window.location.pathname
            }?id=${paramProgramID}&activity=${paramsActivityID}&entity=${
              get(setup, 'entity')?.id
            }`,
          );
          currentState.showDetailEntity = true;
          currentState.entitySelection = get(setup, 'entity');
          break;
        case 'previewEntitySession':
          currentState.showEntitySessionPreview = true;
          currentState.entitySelection = get(setup, 'entity');
          getEntityQuiz(get(setup, 'entity'));
          break;
        case 'editActivity':
          currentState.editActivity = !currentState.editActivity;
          break;
        case 'createActivityEntity':
          currentState.createActivityEntity =
            !currentState.createActivityEntity;
          currentState.editEntity = false;
          break;
        default:
          currentState.showEmpty = true;
          currentState.showListEntity = false;
          currentState.showModalCreate = false;
          currentState.showDetailEntity = false;
          currentState.showEntityModalPreview = false;
          break;
      }

      return { ...currentState };
    });
  };

  const createActivity = (fields) => {
    actionCreateActivityEntity(fields);
    navigationLocalActivity({ action: 'createActivityEntity' });
  };
  const updateActivityEntity = (fields) => {
    let schedule = null;
    if (fields.schedule_date !== null) {
      schedule = moment(fields.schedule_date).format('YYYY-MM-DD H:m:s');
    }
    const updateData = {
      ...state.entitySelection,
      ...fields,
      schedule_date: schedule,
    };
    actionUpdateActivityEntity(updateData);
    navigationLocalActivity({ action: 'createActivityEntity' });
  };
  const updateActivityEntity2 = (fields) => {
    let schedule = null;
    if (fields.schedule_date !== null) {
      schedule = moment(fields.schedule_date).format('YYYY-MM-DD H:m:s');
    }
    const updateData = {
      ...state.entitySelection,
      ...fields,
      schedule_date: schedule,
    };
    actionUpdateActivityEntity(updateData);
  };
  const updateEntityDependency = (fields) => {
    actionUpdateEntityDependency(fields);
  };
  const buttonTypeEntity = ({ ...extraData }) => {
    const entityTypeCanChanges = [2, 3, 4];
    const entityData = extraData;
    const dependStatus = extraData?.dependency;

    let disabledEditable = false;
    if (entityTypeCanChanges.indexOf(entityData.activity_type_id) < 0)
      disabledEditable = 'disabled';
    // if (Number(currentSelected.is_active) === 1) disabledEditable = 'disabled';

    return (
      <Select
        defaultValue={dependStatus}
        style={{ width: 160 }}
        onChange={(value) => {
          const fields = {
            ...entityData,
            entity_id: entityData?.id,
            dependency: value,
          };
          updateEntityDependency(fields);
        }}
        // disabled={disabledEditable}
      >
        <Select.Option value={0} key={random(1, 100).toString()}>
          <div className="entity-type-selection entity-type-selection-1">
            Static
          </div>
        </Select.Option>
        <Select.Option value={1}>
          <div className="entity-type-selection entity-type-selection-2">
            dependencies
          </div>
        </Select.Option>
      </Select>
    );
  };

  const buttonSwitchEntity = ({ ...extraData }) => (
    <Switch
      // disabled={Number(currentSelected.is_active) === 1}
      defaultChecked={extraData?.status === 1 ? 'checked' : false}
      onChange={(eventSwitch) => {
        programUpdateActivityEntityStatus({
          entity_id: extraData?.id,
          status: eventSwitch ? 1 : 0,
        });
      }}
    />
  );

  // const extraMenuDropActEnt = (extraData) => {
  //   let menuItems = [
  //     {
  //       icon: <EditOutlined />,
  //       label: 'Edit Entity',
  //       key: 'Edit',
  //       disabled: Number(currentSelected.is_active) === 1,
  //     },
  //     {
  //       icon: <DeleteOutlined />,
  //       label: 'Delete Entity',
  //       key: 'Delete',
  //       disabled: Number(currentSelected.is_active) === 1,
  //     },
  //   ];
  //   if (extraData.activity_type_id === 3 || extraData.activity_type_id === 4) {
  //     menuItems = [
  //       {
  //         icon: <EyeOutlined />,
  //         label: 'Quiz Submission Result',
  //         key: 'QSR',
  //       },
  //       ...menuItems,
  //     ];
  //   }
  //   return menuItems;
  // };

  const extraMenuDropActEnt = (extraData) => {
    let menuItems = [
      {
        icon: <EditOutlined />,
        label: 'Edit Entity',
        key: 'Edit',
        // disabled: Number(currentSelected.is_active) === 1,
      },
      {
        icon: <DeleteOutlined />,
        label: 'Delete Entity',
        key: 'Delete',
        // disabled: Number(currentSelected.is_active) === 1,
      },
    ];
    // console.log('Current Selected:', currentSelected);
    // console.log('Is Active:', Number(currentSelected.is_active) === 1);

    if (extraData.activity_type_id === 3 || extraData.activity_type_id === 4) {
      menuItems = [
        {
          icon: <EyeOutlined />,
          label: 'Quiz Submission Result',
          key: 'QSR',
        },
        ...menuItems,
      ];
    }

    return menuItems;
  };

  const buttonActionsEntity = ({ ...extraData }) => (
    <Dropdown
      type="primary"
      placement="bottomRight"
      trigger={['click']}
      menu={{
        items: extraMenuDropActEnt(extraData),
        onClick: (e) => {
          switch (e.key) {
            // case 'QSR':
            //   actionPreviewEntitySession(extraData?.id);
            //   setState((prevState) => {
            //     const currentState = { ...prevState };
            //     currentState.entitySelection = extraData;
            //     currentState.showEntitySessionPreview = true;
            //     return { ...currentState };
            //   });
            //   break;
            case 'Edit':
              setState((prevState) => {
                const currentState = { ...prevState };
                currentState.entitySelection = extraData;
                currentState.editEntity = true;
                currentState.createActivityEntity =
                  !currentState.createActivityEntity;
                return { ...currentState };
              });
              break;
            // case 'Delete':
            // deleteActionActivity();
            // console.log(actionDeleteActivityEntity(extraData.id));
            // break;
            default:
              break;
          }
        },
      }}
    >
      <Button type="text" shape="circle" icon={<DashOutlined />} />
    </Dropdown>
  );

  const buttonShowQSR = (extraData) => {
    const isHovered = hoveredButton === `quiz-${extraData.id}`;
    const isClicked = clickedButton === `quiz-${extraData.id}`;

    if (extraData.activity_type_id === 3 || extraData.activity_type_id === 4) {
      return (
        <Tooltip title="Quiz Submission Result">
          <Button
            className="button-SQR"
            size="large"
            icon={isHovered || isClicked ? <EyeOutlined /> : <EyeFilled />}
            type="default"
            onClick={() => {
              handleClick(`quiz-${extraData.id}`);
              showQSR(extraData);
            }}
            onMouseEnter={() => handleMouseEnter(`quiz-${extraData.id}`)}
            onMouseLeave={handleMouseLeave}
            style={{ marginLeft: 10, borderRadius: 10 }}
          />
        </Tooltip>
      );
    }

    return (
      <Button
        className="button-SQR"
        size="large"
        shape="circle"
        icon={isHovered || isClicked ? <EyeOutlined /> : <EyeFilled />}
        type="text"
        disabled
        onMouseEnter={() => handleMouseEnter(`quiz-${extraData.id}`)}
        onMouseLeave={handleMouseLeave}
        style={{ marginLeft: 10, visibility: 'hidden' }}
      />
    );
  };

  const deleteActionEntity = (extraData) => {
    const isHovered = hoveredButton === `delete-${extraData.id}`;
    const isClicked = clickedButton === `delete-${extraData.id}`;
    const isPopConfirmVisible = visiblePopConfirm === `delete-${extraData.id}`;

    return (
      <Popconfirm
        title={
          <p>
            Are you sure you want to delete this activity? <br />
            If you delete an activity, <br />
            the related entity will be deleted too.
          </p>
        }
        onConfirm={() => {
          // handleClick(`delete-${extraData.id}`);
          actionDeleteActivityEntity(extraData.id);
          hidePopconfirm();
        }}
        placement="left"
        open={isPopConfirmVisible}
        onOpenChange={(visible) => {
          if (visible) {
            showPopconfirm(`delete-${extraData.id}`);
          } else {
            hidePopconfirm();
          }
        }}
      >
        <Tooltip
          title="Remove this Activity"
          placement="leftBottom"
          className="button-delete"
        >
          <Button
            type="primary"
            size="large"
            icon={isHovered ? <DeleteOutlined /> : <DeleteFilled />}
            onMouseEnter={() => handleMouseEnter(`delete-${extraData.id}`)}
            onMouseLeave={handleMouseLeave}
            style={{ marginLeft: 10, borderRadius: 10 }}
            // onClick={() => showPopconfirm(`delete-${extraData.id}`)}
            // disabled={Number(currentSelected.is_active) === 1}
          />
        </Tooltip>
      </Popconfirm>
    );
  };

  const showQSR = (extraData) => {
    actionPreviewEntitySession(extraData?.id);
    setState((prevState) => ({
      ...prevState,
      entitySelection: extraData,
      showEntitySessionPreview: true,
    }));
  };

  const renderToggleStatusActivity = () => {
    const statusActivity = currentSelected.is_active;
    const textStatus = statusActivity === 1 ? 'Active' : 'Inactive';
    const textButton = statusActivity === 1 ? 'Inactive' : 'Active';
    const textConfirmActive = (
      <div style={{ width: 350, padding: '0 20px' }}>
        <Text>
          <b>WARNING!</b> <br />
          You are about to set the activity to active, make sure all updated
          data is already correct with the program structure and plan. do you
          wish to proceed?
        </Text>
      </div>
    );
    const textConfirmInactive = (
      <div style={{ width: 350, padding: '0 20px' }}>
        <Text>
          <b>WARNING!</b> <br />
          You are about to set the activity to inactive, make sure learners has
          been notified about this action to avoid misunderstanding. do you wish
          to proceed?
        </Text>
      </div>
    );

    return (
      <>
        <Text>
          Activity is{' '}
          <b>
            <i>{textStatus}</i>
          </b>
        </Text>
        <Popconfirm
          okText="Yes Proceed"
          cancelText="Check Again"
          placement="top"
          icon={false}
          title={statusActivity === 1 ? textConfirmInactive : textConfirmActive}
          onConfirm={() => {
            const fields = {
              ...currentSelected,
              is_active: statusActivity === 0 ? 1 : 0,
            };
            actionUpdateActivity(fields);
          }}
        >
          <Button type="primary" size="small" style={{ marginLeft: 10 }}>
            Set to {textButton}
          </Button>
        </Popconfirm>
      </>
    );
  };

  const [dateRange, setDateRange] = React.useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);
  const contentSchedule = (
    <div>
      <DateRange
        editableDateInputs
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        onChange={(item) => setDateRange([item.selection])}
      />
    </div>
  );

  const contentModerator = (
    <div style={{ width: 300 }}>
      <Title level={5}>Select Moderator</Title>
      <Select
        showSearch
        placeholder="Select a moderator"
        optionFilterProp="children"
        onChange={(e) => console.log(e)}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: 300 }}
      >
        <Option value="dicky">Moch. Dicky</Option>
        <Option value="fadhil">Fadhil</Option>
      </Select>
    </div>
  );

  const renderInstructure = (data) => {
    let instructure = null;
    const reInstructure = [];
    instructure = data.length;
    for (let index = 0; index < 2; ) {
      reInstructure.push(
        <div className="icon-instructure">
          <UserOutlined />
        </div>,
      );
      index += 1;
    }
    if (instructure > 2) {
      reInstructure.push(
        <div className="icon-instructure">+{data.length - 2}</div>,
      );
    }
    return reInstructure;
  };

  const renderDraggableInfo = () => (
    <div
      // hidden={Number(currentSelected.is_active) === 0}
      style={{ marginTop: 20, borderRadius: 10 }}
    >
      <Alert
        type="warning"
        message="Warning"
        description={
          <Paragraph style={{ fontSize: '1.2em' }}>
            Entities inside table below are locked positionally and feature
            (edit / delete), because this activity is currently in <b>Active</b>
            . To enable drag and drop or feature (edit / delete), set this
            activity&apos;s <b>Status</b> to <b>Inactive</b>.
          </Paragraph>
        }
        showIcon
      />
    </div>
  );

  setTimeout(() => {
    if (document.getElementsByClassName('header-activity')[0] !== undefined)
      document.getElementsByClassName(
        'header-activity',
      )[0].style.backgroundImage = `url(${currentSelected?.banner_image})`;
  }, 500);
  return (
    <MotionPage motionType="fadeIn">
      <div key={random(1, 100).toString()}>
        {!state.showDetailEntity && (
          <div id="DetailActivity">
            <div
              className="header-activity"
              style={{
                backgroundImage: `url(${currentSelected?.banner_image})`,
              }}
            >
              <div className="action-header">
                <div>
                  <Button
                    type="primary"
                    size="large"
                    icon={<LeftCircleOutlined />}
                    onClick={() =>
                      props.actionBack({ action: props.actionBackName })
                    }
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    icon={<EditOutlined />}
                    onClick={() =>
                      navigationLocalActivity({ action: 'editActivity' })
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    icon={<ShareAltOutlined />}
                    onClick={() =>
                      notification.info({
                        message: 'Hai, Sorry!',
                        description: `This feature doesn't work for now, under development`,
                      })
                    }
                  >
                    Share
                  </Button>
                </div>
              </div>
              <div className="information-header">
                <div className="content-header">
                  <Title level={3}>{currentSelected?.activity_name}</Title>
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: false,
                    }}
                  >
                    {currentSelected?.description}
                  </Paragraph>
                  <div className="action-setup-activity">
                    <Row gutter={24}>
                      <Col flex="auto">
                        <div className="action">
                          <Text>
                            <ScheduleOutlined />{' '}
                            {currentSelected?.scheduled_date_start &&
                              format(
                                new Date(currentSelected?.scheduled_date_start),
                                'dd MMMM',
                              )}
                            {' s/d '}
                            {currentSelected?.scheduled_date_end &&
                              format(
                                new Date(currentSelected?.scheduled_date_end),
                                'dd MMMM yyy',
                              )}
                          </Text>
                        </div>
                      </Col>
                      {/* <Col flex="auto">
                        <div className="action">
                          <Text>
                            Moderator : <b>-</b>
                          </Text>
                        </div>
                      </Col>
                      <Col flex="auto">
                        <div className="action">
                          <span>Instructor: </span>
                          <br />
                          <div style={{ display: 'flex' }}>
                            {renderInstructure([1, 2, 3])}
                          </div>
                        </div>
                      </Col> */}
                      <Col flex="auto">
                        <div className="action">
                          {renderToggleStatusActivity()}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {renderDraggableInfo()}

            <div className="content-detail">
              <div className="action-content">
                <Text>{activityEntity?.length} Total Entity</Text>
                <Button
                  size="large"
                  icon={<PlusSquareOutlined />}
                  onClick={() =>
                    navigationLocalActivity({ action: 'createActivityEntity' })
                  }
                >
                  Add Entity
                </Button>
              </div>
              <DataTableDragable
                sourceData={activityEntity}
                isLoading={isLoading}
                needUpdatePosition
                actionUpdatePosition={actionUpdatePositionEntity}
                gotoDetail={navigationLocalActivity}
                extraAction={[
                  buttonTypeEntity,
                  // buttonSwitchEntity,
                  // buttonActionsEntity,
                  buttonShowQSR,
                  deleteActionEntity,
                ]}
                // locked={Number(currentSelected.is_active) === 1}
              />
            </div>
          </div>
        )}
        {state.showDetailEntity && (
          <DetailEntityFromActivity
            selectedActivity={selectedActivity}
            actionBack={navigationLocalActivity}
            data={state.entitySelection}
            dataDetailQuiz={selectionQuiz}
            contentModerator={contentModerator}
            actionUpdateActivity={updateActivityEntity2}
            listQuiz={listQuiz}
            deleteActionEntity={deleteActionEntity}
            showQSR={showQSR}
            actionDeleteActivityEntity={actionDeleteActivityEntity}
            // sessionPreviewData={sessionPreviewData}
            participantWeeklyAssignment={props.participantWeeklyAssignment}
            participantsData={participantsData}
            finalGroupGet={props.finalGroupGet}
            finalGroupCreate={props.finalGroupCreate}
            finalGroupPut={props.finalGroupPut}
            finalGroupDelete={props.finalGroupDelete}
            fieldSuggestions={fieldSuggestions}
          />
        )}
        <ModalEdit
          activityData={currentSelected}
          showModal={state.editActivity}
          closeModal={navigationLocalActivity}
          actionClose="editActivity"
          actionUpdateActivity={actionUpdateActivity}
        />
        <ModalCreateEntity
          title={
            state.editEntity ? 'Edit Activity Entity' : 'Create Activity Entity'
          }
          showModal={state.createActivityEntity}
          closeModal={navigationLocalActivity}
          actionCloseModal="createActivityEntity"
          actionCreateActivity={createActivity}
          isEditData={state.editEntity}
          actionUpdateActivity={updateActivityEntity}
          entitySelection={state.entitySelection}
          listQuiz={listQuiz}
          fieldSuggestions={fieldSuggestions}
          includeSchedule
        />
        <ModalPreviewEntitySession
          title="Session Preview"
          entityName={state.entitySelection?.entity_name || '-'}
          showModal={state.showEntitySessionPreview}
          onCancel={() =>
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.entitySelection = null;
              currentState.showEntitySessionPreview = false;

              return { ...currentState };
            })
          }
          data={sessionPreviewData}
        />
      </div>
    </MotionPage>
  );
};

DetailActivity.displayName = config.displayName;
DetailActivity.defaultProps = config.defaultProps;
DetailActivity.propTypes = config.propTypes;

export default DetailActivity;
