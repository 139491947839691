import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import config from './VideoPlayerCustom.component.config';
import './VideoPlayerCustom.component.styles.less';

const VideoPlayerCustom = ({
  material,
  roles,
  selectionContent,
  activitySessionUser,
  onCreateActivitySession,
  onUpdateActivitySession,
  onUpdateStateSession,
}) => {
  const refIframe = React.useRef();
  const [state, setState] = React.useState({
    videoPlayer: document.getElementsByTagName('video'),
  });
  return (
    <div id="VideoPlayerCustom" className={`${isMobile && 'mobile'}`}>
      <iframe
        ref={refIframe}
        id="iframe-player"
        className="iframe-player"
        title="VideoPlayer"
        src={material}
        allow="accelerometer; clipboard-write; encrypted-media; nodownload;"
        allowFullScreen
        scrolling="no"
        seamless
      />
    </div>
  );
};

VideoPlayerCustom.displayName = config.displayName;
VideoPlayerCustom.defaultProps = config.defaultProps;
VideoPlayerCustom.propTypes = config.propTypes;

export default VideoPlayerCustom;
