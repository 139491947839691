import { connect } from 'react-redux';
import DetailStudentCertificate from './DetailStudentCertificate.component';
import { getCertificatePublicAPI } from '../../../redux/actions/certificate.actions';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getCertificatePublicAPI,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailStudentCertificate);
