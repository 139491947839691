/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Select,
  Upload,
  Typography,
  Input,
  Form,
  Col,
  Row,
  Card,
  Space,
  notification,
  Radio,
  Popconfirm,
  Switch,
} from 'antd';
import {
  EditOutlined,
  ShareAltOutlined,
  PlusSquareOutlined,
  LeftCircleOutlined,
  SearchOutlined,
  PaperClipOutlined,
  ScheduleOutlined,
  InfoOutlined,
  FilterOutlined,
  FilterFilled,
} from '@ant-design/icons';
import moment from 'moment';

import {
  BaseLayout,
  DataTableDragable,
  ModalCreateActivity,
  MotionPage,
  LoadingEvent,
} from '../../../components';
import DetailActivity from './DetailActivity/DetailActivity.component';
import ListActivityPrograms from './ListActivityPrograms/ListActivityPrograms.component';
import ListLearnerPrograms from './ListLearnerPrograms/ListLearnerPrograms.component';
import SelectionLearner from './SelectionLearner/SelectionLearner.component';

import { getParamURL } from '../../../utils/parse';
import { formatDate } from '../../../utils/format';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../services/aws.service';

import config from './DetailPrograms.component.config';
import './DetailPrograms.component.styles.css';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const DetailPrograms = ({
  searchText,
  clearSearchText,
  lessonListData,
  programObjectDetail,
  participantsData,
  listQuiz,
  selectionQuiz,
  programTypeSuggenstion,
  fieldSuggestions,
  reportStudent,
  ...props
}) => {
  const navigate = useNavigate();
  const [localSearchText, setLocalSearchText] = React.useState({
    activity: '',
    learner: '',
  });

  const paramProgramID = getParamURL('id');
  const paramsActivityID = getParamURL('activity');
  const paramsEntityID = getParamURL('entity');
  const [programParrent, setProgramParrent] = useState(
    programObjectDetail?.parrent,
  );
  const programActivityListData = programObjectDetail?.activity;
  const [programActivitySelected, setProgramActivitySelected] = useState(
    programObjectDetail?.selectedActivity,
  );
  const [learnerData, setLearnerData] = useState(participantsData.data);
  const programActivityEntityData = programObjectDetail?.activityEntity;
  const programLearnerListData = programObjectDetail?.learner;
  const programDetailLoading = programObjectDetail?.isLoading;
  const programSessionPreview = programObjectDetail?.sessionPreview;
  const lessonObjectListData = lessonListData?.data;
  const [menuFilter, setMenuFilter] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');
  const defaultMenuFilter = useMemo(
    () => ({ value: '', label: 'Show All' }),
    [],
  );
  const refEditProgram = React.useRef();
  const [state, setState] = useState({
    firstMounting: true,
    showFormEditProgram: false,
    showDetailProgram: true,
    fileList: [],
    onUploadFile: false,
    showEmptyActivity: true,
    showListActivity: false,
    showCreateActivity: false,
    showDetailActivityEntity: false,
    showEmptyLearner: true,
    showListLearner: false,
    showSelectionLearner: false,
    learnerList: [],
    showloading: true,
  });
  const mountingFisrtData = () => {
    props.programActivityList(paramProgramID).then(() => {
      navigationLocal({ action: 'showListActivity' });
      // -------
      props.programGetParticipants(paramProgramID).then(() => {
        // ------
        props.lessonsList().then(() => {
          // ------
          props.getListQuiz().then(() => {
            // -----
            props.programGetTypeSuggestions().then(() => {
              // -----
              props.getUserList(2).then((respUser) => {
                setState((prevState) => {
                  const currentState = { ...prevState };
                  currentState.learnerList = respUser;
                  currentState.showloading = false;
                  return { ...currentState };
                });
              });

            });

          });

        });

      });

    });

    if (paramsActivityID !== undefined && paramsActivityID !== '' && paramsEntityID) {
      navigationLocal({
        action: 'detailActivityEntityData',
        activityID: programActivitySelected,
        entityID: paramsEntityID,
      });
    } else if (paramsActivityID !== undefined && paramsActivityID !== '') {
      navigationLocal({
        action: 'detailActivityEntity',
        activityID: programActivitySelected,
      });
    }

    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.firstMounting = false;
      currentState.idLessons = paramProgramID;
      return { ...currentState };
    });
  };

  useEffect(() => {
    if (state.firstMounting) mountingFisrtData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.firstMounting]);

  useEffect(() => {
    const sourceBanner = programParrent?.banner_image;
    if (document.getElementsByClassName('header-programs')[0] !== undefined) {
      if (sourceBanner !== '') {
        if (sourceBanner !== null) {
          if (sourceBanner !== undefined) {
            // const keyBanner = sourceBanner.split('amazonaws.com/')[1];
            // getObjectAwsS3Bucket(
            //   keyBanner.replaceAll('+', ' '),
            //   setBackgroundBanner,
            // );
            document.getElementsByClassName(
              'header-programs',
            )[0].style.backgroundImage = `url(${sourceBanner})`;
          }
        }
      }
    }
  }, [programParrent]);

  const navigationLocal = ({ ...setup }) => {
    setState((prevState) => {
      const currentState = { ...prevState };

      switch (setup.action) {
        case 'showFormEditProgram':
          currentState.showFormEditProgram = !currentState.showFormEditProgram;
          break;
        case 'showEmptyActivity':
          currentState.showEmptyActivity = true;
          currentState.showListActivity = false;
          break;
        case 'showListActivity':
          currentState.showEmptyActivity = false;
          currentState.showListActivity = true;
          break;
        case 'createActivity':
          currentState.showCreateActivity = true;
          break;
        case 'closeCreateActivity':
          currentState.showCreateActivity = false;
          break;
        case 'detailActivityEntity':
          props.setActivityProgram(setup?.activityID);
          setProgramActivitySelected(setup?.activityID);
          props.programActivityEntityList(setup?.activityID?.id);
          navigate(
            `${window.location.pathname}?id=${paramProgramID}&activity=${setup?.activityID?.id}`,
          );
          currentState.showDetailProgram = false;
          currentState.showDetailActivityEntity = true;
          break;
        case 'detailActivityEntityData':
          props.setActivityProgram(setup?.activityID);
          setProgramActivitySelected(setup?.activityID);
          props.programActivityEntityList(setup?.activityID?.id);
          navigate(
            `${window.location.pathname}?id=${paramProgramID}&activity=${setup?.activityID?.id}&entity=${paramsEntityID}`,
          );
          currentState.showDetailProgram = false;
          currentState.showDetailActivityEntity = true;
          break;
        case 'backToDetailProgram':
          setLocalSearchText({ ...searchText, activity: '', learner: '' });
          clearSearchText();
          navigate(`${window.location.pathname}?id=${paramProgramID}`);
          currentState.showDetailProgram = true;
          currentState.showDetailActivityEntity = false;
          break;
        case 'showEmptyLearner':
          currentState.showEmptyLearner = true;
          currentState.showListLearner = false;
          break;
        case 'showListLearner':
          currentState.showEmptyLearner = false;
          currentState.showListLearner = true;
          break;
        case 'detailLeaner':
          setProgramActivitySelected(setup?.activityID);
          props.programActivityEntityList(setup?.activityID?.id);
          navigate(
            `${window.location.pathname}?id=${paramProgramID}&learner=${setup?.activityID?.id}`,
          );
          currentState.showEmptyLearner = false;
          currentState.showListLearner = true;
          break;
        case 'selectionLearner':
          currentState.showSelectionLearner = true;
          break;
        case 'closeSelectionLearner':
          currentState.showSelectionLearner = false;
          break;
        default:
          currentState.showDetailProgram = true;
          currentState.showDetailActivityEntity = false;
          break;
      }

      return { ...currentState };
    });
  };
  // Program Controller
  const actionUploadBanner = async (upload) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.onUploadFile = !currentState.onUploadFile;
      return { ...currentState };
    });
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/programs',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };
  const onEditProgram = () => {
    navigationLocal({ action: 'showFormEditProgram' });
    refEditProgram.current.setFieldsValue({
      program_name: programParrent?.program_name,
      program_type: programParrent?.program_type_id,
      assign_product: programParrent?.assign_product,
      description: programParrent?.description,
    });
  };
  const updateProgram = () => {
    const fields = refEditProgram.current.getFieldsValue();
    const bannerFile =
      state?.fileList[0]?.uid !== undefined ? state?.fileList[0]?.uid : '';
    const updateData = {
      ...fields,
      assign_product:
        fields.assign_product === undefined ? 0 : fields.assign_product,
      id: paramProgramID,
      banner_image: bannerFile,
    };
    props.programUpdate(updateData).then(() => {
      props.programDetail(paramProgramID).then((resp) => {
        props.setParrentProgram(resp);
        setProgramParrent((prevParrent) => {
          const currentParrent = { ...prevParrent };
          currentParrent.program_name = fields?.program_name;
          currentParrent.assign_product = fields?.assign_product;
          currentParrent.program_type_id = fields?.program_type;
          currentParrent.description = fields?.description;
          currentParrent.banner_image = bannerFile;
          return { ...currentParrent };
        });
        navigationLocal({ action: 'showFormEditProgram' });
      });
    });
  };

  // Program Activity Controller
  const createActivity = (fields) => {
    const data = {
      program_id: programParrent?.id,
      master_id: fields?.master_id !== undefined ? fields?.master_id : 0,
      moderator_id:
        fields?.moderator_id !== undefined ? fields?.moderator_id : 0,
      ...fields,
    };

    props.programCreateActivity(data).then(() => {
      props.programActivityList(paramProgramID);
      navigationLocal({ action: 'showListActivity' });
      navigationLocal({ action: 'closeCreateActivity' });
    });
  };
  const updateActivity = (fields) => {
    props.programUpdateActivity(fields).then(() => {
      props.programActivityList(paramProgramID).then(() => {
        setProgramActivitySelected(fields);
        props.setActivityProgram(fields);
      });
    });
  };
  const updateActivityPosition = (paylod) => {
    props.programUpdateActivityPosition({
      program_id: paramProgramID,
      data: paylod
    }).then(() => {
      props.programActivityList(paramProgramID);
    });
  };
  const deleteActivity = (fields) => {
    props.programDeleteActivity(fields).then(() => {
      props.programActivityList(paramProgramID);
    });
  };
  const actionCreateActivityEntity = (fields) => {
    const createData = {
      activity_id: programActivitySelected?.id,
      instructor_id: 0,
      ...fields,
    };
    props.programCreateActivityEntity(createData).then(() => {
      props.programActivityEntityList(programActivitySelected?.id);
    });
  };
  const actionUpdateActivityEntity = (fields) => {
    props.programUpdateActivityEntity(fields).then(() => {
      props.programActivityEntityList(0).then(() => {
        props.programActivityEntityList(programActivitySelected?.id);
      });
    });
  };
  const actionDeleteActivityEntity = (entityID) => {
    props.programDeleteActivityEntity(entityID).then(() => {
      props.programActivityEntityList(0).then(() => {
        props.programActivityEntityList(programActivitySelected?.id);
      });
    });
  };
  const actionUpdatePositionEntity = ({ ...update }) => {
    const params = {
      activity_id: programActivitySelected?.id,
      entity_id: update?.entityID,
      currentPostition: update?.currentPostition,
      newPosition: update?.newPosition,
    };
    props.programEntityUpdatePosition(params).then(() => {
      props.programActivityEntityList(programActivitySelected?.id);
    });
  };
  const actionUpdateEntityDependency = (fields) => {
    const params = {
      entity_id: fields?.entity_id,
      dependency: fields?.dependency,
      ...fields,
    };
    props.programUpdateEntityDependency(params).then(() => {
      props.programActivityEntityList(programActivitySelected?.id);
      notification.success({
        message: `Success`,
        description:
          `${fields?.entity_name} is now set as ${Number(fields?.dependency) === 0 ? 'Static' : 'Dependencies'}`,
      });
    });
    // .then(() => console.log('success'))
    // .catch(() => console.log('failed'))
  };
  const actionPreviewEntitySession = (activityEntityId) => {
    const id = activityEntityId;
    props.programGetSessionPreview(id);
  };
  const actionUpdateParticipantToProgram = (params) => {
    const participant = {
      program_id: paramProgramID,
      user_id: params,
    };
    props.programUpdateParticipant(participant).then(() => {
      notification.info({
        message: `Information`,
        description:
          'List leaner not available to show, but learner successfull added to this program',
      });
      navigationLocal({ action: 'closeSelectionLearner' });
      props.programGetParticipants(paramProgramID);
    });
  };
  const removeParticipantFromProgram = (params) => {
    const participant = {
      program_id: paramProgramID,
      user_id: params?.id,
      ...params,
    };
    props.programRemoveParticipant(participant)
      .then(() => {
        notification.success({
          message: `Success`,
          description: `Learner ${params?.name || ''} (${params?.email || ''
            }) removed from this program`,
        });
        props.programGetParticipants(paramProgramID);
      })
      .catch(() => {
        notification.error({
          message: `System Error`,
          description: `Unable to remove learner ${params?.name || ''} (${params?.email || ''
            }). Please try again later.`,
        });
      });
  };

  const getTagsList = useCallback(() => {
    const tagsListData = programActivityListData.map(
      (program) => program.activity_tags,
    );
    const uniqueTagsListData = tagsListData.filter(
      (num, index) => tagsListData.indexOf(num) === index,
    );

    const newObjects = uniqueTagsListData.map((name) => ({
      value: name.toLowerCase(),
      label: name,
    }));
    setMenuFilter([defaultMenuFilter, ...newObjects]);
  }, [programActivityListData, defaultMenuFilter]);

  useEffect(() => {
    getTagsList();
  }, [getTagsList]);

  const filteredProgramActivityListData = programActivityListData.filter(
    (item) =>
      item?.activity_name
        ?.toLowerCase()
        ?.includes(localSearchText.activity?.toLowerCase()) ||
      item?.description
        ?.toLowerCase()
        ?.includes(localSearchText.activity?.toLowerCase()) ||
      item?.activity_tags
        ?.toLowerCase()
        ?.includes(localSearchText.activity?.toLowerCase()) ||
      item?.moderator_name
        ?.toLowerCase()
        ?.includes(localSearchText.activity?.toLowerCase()),
  );

  const filteredProgramActivityListByTags = programActivityListData.filter(
    (item) =>
      item?.activity_tags?.toLowerCase() === currentFilter.toLowerCase(),
  );

  const filteredProgramActivityList = filteredProgramActivityListByTags.filter(
    (item) =>
      item?.activity_name
        ?.toLowerCase()
        ?.includes(localSearchText.activity?.toLowerCase()) ||
      item?.description
        ?.toLowerCase()
        ?.includes(localSearchText.activity?.toLowerCase()),
  );

  const totalDataByTags = useMemo(
    () => filteredProgramActivityList.length,
    [filteredProgramActivityList],
  );

  const filteredParticipantsData =
    (Array.isArray(participantsData?.data) &&
      participantsData?.data?.filter(
        (item) =>
          item?.name
            ?.toLowerCase()
            ?.includes(localSearchText.learner?.toLowerCase()) ||
          item?.email
            ?.toLowerCase()
            ?.includes(localSearchText.learner?.toLocaleLowerCase()),
      )) ||
    [];
  const renderScheduleProgram = () => {
    let programStart = null;
    let programEnd = null;
    let scheduleProgram = 'Not Set';

    if (
      programParrent.schedule_start !== null &&
      programParrent.schedule_start !== undefined
    ) {
      programStart = formatDate(programParrent?.schedule_start);
    }
    if (
      programParrent.schedule_end !== null &&
      programParrent.schedule_end !== undefined
    ) {
      programEnd = formatDate(programParrent?.schedule_end);
    }

    if (programStart !== null && programEnd !== null) {
      scheduleProgram = `${programStart} s/d ${programEnd}`;
    }

    return scheduleProgram;
  };
  const checkStatusProgram = () => {
    let statusSchedule = 'Comming soon';
    if (programParrent?.schedule_start !== null) {
      const scheduleStart = new Date(programParrent?.schedule_start);
      const today = new Date();

      const diffTime = Math.abs(scheduleStart - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 0) {
        statusSchedule = 'On Progress';
      }
    }
    return statusSchedule;
  };

  const formEditProgram = () => (
    <Card title="Edit Program" hidden={!state.showFormEditProgram}>
      <Form
        ref={refEditProgram}
        layout="vertical"
        id="editProgram"
        onFinish={() => {
          updateProgram();
          refEditProgram.current.setFieldsValue({
            program_name: '',
            program_type: '',
            description: '',
            banner_image: '',
            assign_product: 0,
          });
        }}
      >
        <Form.Item
          label="Program Name"
          name="program_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Program Type"
          name="program_type"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Select defaultValue={null}>
            {programTypeSuggenstion?.program_types?.map(
              (el) =>
                el.id !== 4 && (
                  <Option key={el?.id} value={el?.id}>
                    {el?.value}
                  </Option>
                ),
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <Form.Item label="Upload Banner">
          <Form.Item name="banner_image" noStyle hidden={state.onUploadFile}>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={actionUploadBanner}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <div hidden={!state.onUploadFile} style={{ textAlign: 'center' }}>
            <p>Banner uploading, please wait...</p>
          </div>
        </Form.Item>
        <Form.Item label="Assign to Product">
          <Form.Item name="assign_product" noStyle>
            <Radio.Group
              options={[
                {
                  label: 'No',
                  value: 0,
                },
                {
                  label: 'Yes',
                  value: 1,
                },
              ]}
              defaultValue={0}
              optionType="button"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => navigationLocal({ action: 'showFormEditProgram' })}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout title={null} collapsedMenu={props.common.collapsedMenu}>
        {state.showloading && <LoadingEvent onType="admin" />}
        {state.showDetailProgram && (
          <div id="DetailPrograms">
            {formEditProgram()}
            {!state.showFormEditProgram && (
              <div
                className="header-programs"
                style={{
                  backgroundImage: `url(${programParrent?.banner_image})`,
                }}
              >
                <div className="action-header">
                  <Button
                    type="primary"
                    size="large"
                    icon={<LeftCircleOutlined />}
                    onClick={() => {
                      navigate(`/admin/programs`);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    icon={<EditOutlined />}
                    onClick={() => onEditProgram()}
                  >
                    Edit
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    icon={<ShareAltOutlined />}
                    onClick={() =>
                      notification.info({
                        message: 'Hai, Sorry!',
                        description: `This feature doesn't work for now, under development`,
                      })
                    }
                  >
                    Share
                  </Button>
                </div>
                <div className="information-deck">
                  <Title level={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>{programParrent?.program_name}</div>
                    <div>
                      <div style={{ fontSize: 14 }}>ID: {programParrent?.id}</div>
                      <div style={{ fontSize: 14 }}>SKU: {programParrent?.program_sku === null ? '-' : programParrent?.program_sku}</div>
                    </div>
                  </Title>
                  <Title level={5}>
                    <Space direction="horizontal" size="large">
                      <div>
                        <Text>
                          {
                            programParrent?.assign_product === null || programParrent?.assign_product === 0
                              ? <span>This program is <b>not</b> assigned as product</span>
                              : <span>This program has been assigned as product</span>
                          }
                        </Text>
                        <Popconfirm
                          okText={`${programParrent.assign_product === 0 ? 'Assign' : 'Dismiss'}`}
                          cancelText="Cancel"
                          placement="top"
                          icon={false}
                          title="Are you sure to change program assignment?"
                          onConfirm={() => {
                            const updateData = {
                              id: programParrent.id,
                              assign_product: programParrent.assign_product === 0 || programParrent.assign_product === null ? 1 : 0,
                              program_name: programParrent.program_name,
                              program_type: programParrent.program_type_id,
                              description: programParrent.description,
                              banner_image: programParrent.banner_image
                            };
                            props.programUpdate(updateData).then(() => {
                              setProgramParrent((prevParrent) => {
                                const currentParrent = { ...prevParrent };
                                currentParrent.program_name = updateData.program_name;
                                currentParrent.assign_product = updateData.assign_product;
                                currentParrent.program_type_id = updateData.program_type;
                                currentParrent.description = updateData.description;
                                currentParrent.banner_image = updateData.banner_image;
                                return { ...currentParrent };
                              });
                            });
                          }}
                        >
                          <Button type="primary" size="middle" style={{ marginLeft: 10 }}>
                            Change
                          </Button>
                        </Popconfirm>
                      </ div>
                    </Space>
                  </Title>
                  <div className="deck-status">
                    <Row gutter={24}>
                      <Col xs={12} md={12} xl={12}>
                        <div>
                          <div className="circle-title">
                            <ScheduleOutlined />
                          </div>
                          <Text>{renderScheduleProgram()}</Text>
                        </div>
                      </Col>
                      <Col xs={12} md={12} xl={12}>
                        <div>
                          <div className="circle-title">
                            <InfoOutlined />
                          </div>
                          <Text>
                            <b>{checkStatusProgram()}</b>
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                  >
                    {programParrent?.description}
                  </Paragraph>
                </div>
              </div>
            )}

            <Card>
              <div className="content-detail">
                <div className="section-header">
                  <div className="title">
                    <Title level={4}>
                      Activity (
                        {currentFilter
                        ? `${totalDataByTags}`
                        : `${filteredProgramActivityListData.length}`}
                      )
                    </Title>
                    <Space>
                      <Input
                        prefix={<SearchOutlined />}
                        style={{
                          width: 300,
                        }}
                        allowClear
                        placeholder="Search program..."
                        onChange={(e) =>
                          setLocalSearchText({
                            ...localSearchText,
                            activity: e.target.value,
                          })
                        }
                      />

                      <Select
                        defaultValue=""
                        style={{ width: 160 }}
                        suffixIcon={<FilterFilled />}
                        options={menuFilter}
                        onChange={(value) => {
                          setCurrentFilter(value);
                        }}
                      />
                    </Space>
                  </div>
                  <div>
                    <Popconfirm
                      title={
                        <div style={{ width: 350, padding: '0 20px' }}>
                          <Text>
                            <b>WARNING!</b> <br />
                            You are about to set the activity to {programParrent.is_active === 1 ? 'inactive' : 'active'}, make sure all updated
                            data is already correct with the program structure and plan. do you
                            wish to proceed?
                          </Text>
                        </div>
                      }
                      onConfirm={() => {
                        props.programUpdate({
                          ...programParrent,
                          is_active: programParrent.is_active === 0 ? 1 : 0,
                          program_type: programParrent.program_type_id,
                        }).then(() => {
                          props.programDetail(paramProgramID).then((resp) => {
                            props.setParrentProgram(resp);
                            setProgramParrent(resp);
                          });
                        });
                      }}
                      placement="left"
                    >
                      <Switch
                        className="switch-activity"
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        checked={programParrent.is_active === 1}
                      />
                    </Popconfirm>
                    <Button
                      type="primary"
                      size="middle"
                      icon={<PlusSquareOutlined />}
                      onClick={() =>
                        navigationLocal({ action: 'createActivity' })
                      }
                    >
                      Add Activity
                    </Button>
                  </div>
                </div>
                <div className="section-content">
                  {/* list activity programs */}
                  <ListActivityPrograms
                    actionNavigation={navigationLocal}
                    deleteActivity={deleteActivity}
                    createActivity={() =>
                      navigationLocal({ action: 'createActivity' })
                    }
                    updateActivityPosition={updateActivityPosition}
                    programData={programParrent}
                    sourceData={
                      currentFilter
                        ? filteredProgramActivityList
                        : filteredProgramActivityListData
                    }
                    loadingData={programDetailLoading}
                  />
                </div>
              </div>
            </Card>

            <Card>
              <ListLearnerPrograms
                // showEmptyLearner={state.showEmptyLearner}
                // showListLearner={state.showListLearner}
                dataSource={filteredParticipantsData}
                showEmptyLearner={filteredParticipantsData.length === 0}
                showListLearner={filteredParticipantsData.length > 0}
                participantsGetStudentData={props.participantsGetStudentData}
                reportStudent={reportStudent}
                programActivityListData={programActivityListData}
                programParrent={programParrent}
                programDetail={props.programDetail}
                onRemoveLearner={removeParticipantFromProgram}
                localSearchText={localSearchText}
                setLocalSearchText={setLocalSearchText}
                navigationLocal={navigationLocal}
                participantsGetStudentQuizData={props.participantsGetStudentQuizData}
                participantsGetSummaryStudentData={props.participantsGetSummaryStudentData}
                participantsGenerateStudentQuiz={props.participantsGenerateStudentQuiz}
                participantSubmitStudentQuiz={props.participantSubmitStudentQuiz}
                participantSubmitWeekAssignment={props.participantSubmitWeekAssignment}
                finalGroupScorePost={props.finalGroupScorePost}
                postCertificateProgramAdmin={props.postCertificateProgramAdmin}
              />
            </Card>
          </div>
        )}

        {/* detail activity + entity list */}
        {!state.showloading && (
          <div hidden={!state.showDetailActivityEntity}>
            <DetailActivity
              selectedActivity={programActivitySelected}
              actionBack={navigationLocal}
              actionBackName="backToDetailProgram"
              actionUpdateActivity={updateActivity}
              activityEntityList={programActivityEntityData.filter((item) =>
                item?.entity_name
                  ?.toLowerCase()
                  .includes(searchText?.toLowerCase()),
              )}
              actionCreateActivityEntity={actionCreateActivityEntity}
              actionUpdateActivityEntity={actionUpdateActivityEntity}
              actionUpdatePositionEntity={actionUpdatePositionEntity}
              actionDeleteActivityEntity={actionDeleteActivityEntity}
              actionPreviewEntitySession={actionPreviewEntitySession}
              actionUpdateEntityDependency={actionUpdateEntityDependency}
              sessionPreviewData={programSessionPreview}
              programUpdateActivityEntityStatus={
                props.programUpdateActivityEntityStatus
              }
              listQuiz={listQuiz?.data}
              selectionQuiz={selectionQuiz}
              getListQuizDetail={props.getListQuizDetail}
              isLoading={programDetailLoading}
              lessonGetTypeSuggestions={props.lessonGetTypeSuggestions}
              fieldSuggestions={fieldSuggestions}
              participantWeeklyAssignment={props.participantWeeklyAssignment}
              participantsData={participantsData}
              finalGroupGet={props.finalGroupGet}
              finalGroupCreate={props.finalGroupCreate}
              finalGroupPut={props.finalGroupPut}
              finalGroupDelete={props.finalGroupDelete}
            />
          </div>
        )}

        <ModalCreateActivity
          title="Create Activity"
          showModal={state.showCreateActivity}
          closeModal={navigationLocal}
          actionCloseModal="closeCreateActivity"
          sourceLessonData={lessonObjectListData}
          actionCreate={createActivity}
        />
        <SelectionLearner
          showModal={state.showSelectionLearner}
          closeModal={navigationLocal}
          listLearnerData={state.learnerList}
          participantsList={
            (Array.isArray(participantsData?.data) && participantsData?.data) ||
            []
          }
          actionCloseModal="closeSelectionLearner"
          actionUpdateParticipant={actionUpdateParticipantToProgram}
        />
      </BaseLayout>
    </MotionPage>
  );
};

DetailPrograms.displayName = config.displayName;
DetailPrograms.defaultProps = config.defaultProps;
DetailPrograms.propTypes = config.propTypes;

export default DetailPrograms;
