import { SET_SEARCH_TEXT } from '../reduxType';

export const setSearchText = (text) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_TEXT,
    payload: text,
  });
};

export const clearSearchText = () => (dispatch) => {
  dispatch({
    type: SET_SEARCH_TEXT,
    payload: '',
  });
};
