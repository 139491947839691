import { connect } from 'react-redux';
import DetailQuiz from './DetailQuiz.component';
import {
  updateQuiz,
  createQuizDetail,
  getListQuizDetail,
  updatePositionQuiz,
  listSuggestQuestion,
  listSuggestAnswer,
} from '../../../redux/actions/quiz.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  quizParrent: state.quiz.detail,
  quizSuggestion: state.quiz.suggestion,
});

const mapDispatchToProps = {
  updateQuiz,
  createQuizDetail,
  getListQuizDetail,
  updatePositionQuiz,
  listSuggestQuestion,
  listSuggestAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailQuiz);
