import React, { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Menu, Layout, Typography, Image } from 'antd';

import config from './SideMenuLayout.component.config';
import './SideMenuLayout.component.styles.css';

const { Sider } = Layout;
const { Text } = Typography;

const SideMenuLayout = ({ routeBase, ...props }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    props.setStateSideMenu(!collapsed);
  };
  const currentPath = () => {
    const locationPath = props.location.pathname;
    const patternIgnore = ['detail', 'course'];
    let lockPattern = null;
    patternIgnore.forEach((text) => {
      if (locationPath.includes(`/${text}`)) {
        lockPattern = text;
      }
    });

    const pattern = locationPath.includes(`/${lockPattern}`)
      ? locationPath.split(`/${lockPattern}`)[0]
      : locationPath;

    return pattern;
  };

  const navigate = useNavigate();
  const navigationMenu = (e) => navigate(e.key);

  const generateMenu = (contentMenu) =>
    contentMenu.map((item) => (item.ismenu === 'yes' ? item : null));

  const generateMenuUtils = (contentMenuUtils) =>
    contentMenuUtils.map((item) => (item.foruser ? item : null));

  return (
    <Sider
      id="SideMenuStudent"
      collapsed={collapsed}
      width={250}
      style={{ padding: collapsed && 20 }}
    >
      <div
        className="image-logo"
        onClick={toggleCollapsed}
        style={{ marginLeft: collapsed && 0 }}
      >
        {collapsed ? (
          <Image
            src="https://bolehbelajar.com/wp-content/uploads/2021/08/cropped-icon-boleh-belajar-red-300x300.png"
            className="logo"
            preview={false}
            width={30}
          />
        ) : (
          <Image
            src="https://bolehbelajar.com/wp-content/uploads/2021/08/boleh-belajar-red.png"
            className="logo"
            preview={false}
            width={150}
          />
        )}
      </div>

      <div className="section-menu">
        {/* <Menu
          className="main-menu"
          defaultSelectedKeys={currentPath()}
          selectedKeys={currentPath()}
          mode="inline"
          theme="light"
          items={generateMenu(routeBase)}
          onClick={navigationMenu}
        /> */}
        <Menu
          className="setup-menu"
          mode="inline"
          theme="light"
          items={generateMenuUtils(routeBase)}
          style={{ padding: collapsed && 0 }}
          defaultSelectedKeys={currentPath()}
          selectedKeys={currentPath()}
          onClick={navigationMenu}
        />
      </div>
      {!collapsed && (
        <Text
          style={{
            fontSize: 10,
            marginTop: 20,
            textAlign: 'center',
            color: '#b1b1b1',
          }}
        >
          ©{format(new Date(), 'yyyy ')}
          LMS Boleh Belajar All right reserved
        </Text>
      )}
    </Sider>
  );
};

SideMenuLayout.displayName = config.displayName;
SideMenuLayout.defaultProps = config.defaultProps;
SideMenuLayout.propTypes = config.propTypes;

export default SideMenuLayout;
