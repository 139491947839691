import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const middleware = [thunk];
const getStore = () => {
  let newStore = createStore(
    rootReducer,
    compose(applyMiddleware(...middleware)),
  );
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    newStore = createStore(
      rootReducer,
      compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__(),
      ),
    );
  }
  return newStore;
};

export const store = getStore();
export const persistore = persistStore(store);
