import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Button, Card, Form, Image, Input, Layout, Typography } from 'antd';

import { MotionPage, StudentLayout } from '../../components';

import config from './UserAccount.component.config';
import './UserAccount.component.styles.css';
import { ICON_BANTUAN, ICON_LOGOUT, ICON_PROFILE } from '../../assets/svg';
import { destroyToken } from '../../services/token.service';

const { Title } = Typography;

const UserAccount = ({ userUpdatePassword, ...props }) => {
  const userInfo = props?.users;
  const refUser = React.useRef();
  const navigate = useNavigate();
  setTimeout(() => {
    refUser?.current?.setFieldsValue({
      name: '',
      email: userInfo?.email,
      phone: '',
    });
  }, 1000);
  const updateUserInfo = () => {
    const fields = refUser.current.getFieldsValue();
    userUpdatePassword(fields);
  };

  // useEffect(() => {
  //   console.log('User Info: ', props.users);
  // }, [props.users]);
  return (
    <StudentLayout title="">
      <MotionPage motionType="fadeIn">
        <div id="UserAccount">
          <Card style={{ width: isMobile ? '100%' : 600 }}>
            <Form
              id="user-account"
              ref={refUser}
              layout="vertical"
              onFinish={() => updateUserInfo()}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <div>
                  <ICON_PROFILE />
                </div>
                <Title level={4} style={{ margin: '0 0 0 10px' }}>
                  Akun Saya {props.users.person}
                </Title>
              </div>
              {/* <Form.Item label="Full name" name="name">
                <Input placeholder="Full name of user" />
              </Form.Item> */}
              <Form.Item label="Email Saya" name="email">
                <Input
                  readOnly
                  placeholder="Email of user"
                  // defaultValue={userInfo?.email}
                />
              </Form.Item>
              {/* <Form.Item label="Phone number" name="phone">
                <Input placeholder="Phone number of user" prefix="+62" />
              </Form.Item> */}
              <Form.Item label="Password lama" name="current_password">
                <Input.Password type="password" placeholder="input here..." />
              </Form.Item>
              <Form.Item label="Password baru" name="new_password">
                <Input.Password type="password" placeholder="input here..." />
              </Form.Item>
              <Form.Item
                label="Konfirmasi password baru"
                name="confirm_password"
              >
                <Input.Password type="password" placeholder="input here..." />
              </Form.Item>
              {/* <Title level={4}>Links.</Title>
              <Form.Item name="website">
                <Input placeholder="Website address" prefix="https://" />
              </Form.Item> */}
              {/* <Form.Item name="facebook">
                <Input
                  placeholder="Facebook Profile"
                  prefix="https://facebook.com/"
                />
              </Form.Item>
              <Form.Item name="twitter">
                <Input
                  placeholder="Twitter Profile"
                  prefix="https://twitter.com/"
                />
              </Form.Item>
              <Form.Item name="Instagram">
                <Input
                  placeholder="Instagram Profile"
                  prefix="https://instagram.com/"
                />
              </Form.Item>
              <Form.Item name="Linkedin">
                <Input
                  placeholder="Linkedin Profile"
                  prefix="https://linkedin.com/"
                />
              </Form.Item> */}
              <Form.Item>
                <Button type="primary" block htmlType="submit">
                  Perbaharui akun saya
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <Card style={{ marginTop: 20, width: isMobile ? '100%' : 600 }}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                window.open('https://wa.me/6281944365758', '_blank')
              }
            >
              <div>
                <ICON_BANTUAN />
              </div>
              <div>
                <Title level={4} style={{ margin: '0 0 0 10px' }}>
                  Bantuan
                </Title>
              </div>
            </div>
          </Card>
          <Card style={{ marginTop: 20, width: isMobile ? '100%' : 600 }}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                destroyToken();
                navigate('/auth');
              }}
            >
              <div>
                <ICON_LOGOUT />
              </div>
              <div>
                <Title level={4} style={{ margin: '0 0 0 10px' }}>
                  Keluar
                </Title>
              </div>
            </div>
          </Card>
        </div>
      </MotionPage>
    </StudentLayout>
  );
};

UserAccount.displayName = config.displayName;
UserAccount.defaultProps = config.defaultProps;
UserAccount.propTypes = config.propTypes;

export default UserAccount;
