import { connect } from 'react-redux';
import AdminProgramBundling from './AdminProgramBundling.component';

import { programList } from '../../../redux/actions/programs.actions';
import {
  getProgramBundling,
  setProgramBundlingSelected,
  createProgramBundling,
  updateProgramBundling,
} from '../../../redux/actions/program_bundling.actions';
import {
  clearSearchText,
  setSearchText,
} from '../../../redux/actions/search.actions';

const mapStateToProps = (state) => ({
  common: state.common.settings,
  programListData: state.program.list,
  programBundlingSelected: state.program_bundling.selected,
  programBundlingData: state.program_bundling.list,
});

const mapDispatchToProps = {
  programList,
  getProgramBundling,
  setProgramBundlingSelected,
  createProgramBundling,
  updateProgramBundling,
  clearSearchText,
  setSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminProgramBundling);
