import React from 'react';
import { format } from 'date-fns';
import { random } from 'lodash';
import { Card, Col, List, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import config from './DataList.component.config';
import './DataList.component.styles.css';

const { Title, Text, Paragraph } = Typography;

const DataList = ({
  loading,
  data,
  withPagination,
  disablePagination,
  haveAction,
  actionDetail,
  actionExtraItem,
  showId,
  ...props
}) => {
  const renderSchedule = (date) => {
    const parsingDate = `${format(
      new Date(date),
      'EEEE, dd MMMM yyy',
    )}, ${format(new Date(date), 'HH:mm:ss')}`;
    return parsingDate;
  };

  const renderInstructure = (value) => {
    let instructure = null;
    let reInstructure = [];
    if (Array.isArray(value)) {
      instructure = value.length;
      for (let index = 0; index < 2; ) {
        reInstructure.push(
          <div className="icon-instructure">
            <UserOutlined />
          </div>,
        );
        index += 1;
      }
      if (instructure > 2) {
        reInstructure.push(
          <div className="icon-instructure">+{value.length - 2}</div>,
        );
      }
    } else {
      reInstructure = (
        <Paragraph
          ellipsis={{
            rows: 2,
            expandable: false,
          }}
        >
          {value}
        </Paragraph>
      );
    }
    return reInstructure;
  };

  const renderItem = (item, indexData) => {
    const column = [];
    Object.keys(item).forEach((idx) => {
      let colData = null;
      let titleIndex = idx.replaceAll('_', ' ', idx);
      if (idx === 'id') {
        titleIndex = 'ID';
      }
      if (idx === 'retake_limit') {
        titleIndex = 'take limit';
      }

      const titleConvert =
        titleIndex.charAt(0).toUpperCase() + titleIndex.slice(1);

      if (config.defaultProps.ignoreTitle.indexOf(idx) < 0 && idx !== 'action')
        colData = (
          <Col md={5} key={`${JSON.stringify(item[idx])}-${random(1, 100)}`}>
            <Title level={5}>{titleConvert}</Title>
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: false,
              }}
            >
              {item[idx]}
            </Paragraph>
          </Col>
        );

      if (config.defaultProps.titleDate.indexOf(idx) >= 0)
        colData = (
          <Col md={5} key={`${JSON.stringify(item[idx])}-${random(1, 100)}`}>
            <Title level={5}>{titleConvert}</Title>
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: false,
              }}
            >
              {renderSchedule(item[idx])}
            </Paragraph>
          </Col>
        );

      if (showId && idx === 'id')
        colData = (
          <Col md={1} key={`${JSON.stringify(item[idx])}-${random(1, 100)}`}>
            <Title level={5}>{titleConvert}</Title>
            <Paragraph>#{item[idx]}</Paragraph>
          </Col>
        );

      if (idx === 'instructor')
        colData = (
          <Col md={5} key={`${JSON.stringify(item[idx])}-${random(1, 100)}`}>
            <Title level={5}>{titleConvert}</Title>
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: false,
              }}
            >
              {renderInstructure(item[idx])}
            </Paragraph>
          </Col>
        );

      column.push(colData);
    });
    return (
      <List.Item
        key={`${item.id}`}
        actions={haveAction ? [actionDetail(item.id)] : null}
        className={actionExtraItem ? `list-item-clickable` : ''}
      >
        <Row
          key={`${item.id}`}
          gutter={24}
          style={{ width: '100%' }}
          justify="start"
          onClick={() => {
            if (actionExtraItem) actionExtraItem(item.id);
          }}
        >
          {column}
        </Row>
      </List.Item>
    );
  };
  const configPagination = {
    showSizeChanger: false,
    defaultPageSize: 10,
    pageSize: withPagination,
    hideOnSinglePage: disablePagination,
  };
  return (
    <div id="DataList">
      <List
        key="DataListItem"
        size="large"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => renderItem(item)}
        loading={loading}
        pagination={withPagination ? configPagination : null}
      />
    </div>
  );
};

DataList.displayName = config.displayName;
DataList.defaultProps = config.defaultProps;
DataList.propTypes = config.propTypes;

export default DataList;
