/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Card, Typography, List, notification } from 'antd';

import { useSDK } from '@contentful/react-apps-toolkit';

import config from './CustomApp.component.config';
import './CustomApp.styles.css';

const { Title, Text } = Typography;
const { Meta } = Card;

const apiProduct = 'https://stag-transaction.bolehbelajar.com/api/product';

const CustomApp = ({ ...props }) => {
  const [state, setState] = React.useState({
    selectedItems: null,
  });

  const [stateProduct, setStateProduct] = React.useState({
    productLoad: true,
    listProduct: [],
  });

  useEffect(() => {
    const apiGetProduct = () => {
      const api = axios.request({
        url: apiProduct,
        method: 'GET',
      });
      api
        .then((req) => {
          setStateProduct((prevData) => {
            const current = { ...prevData };
            current.listProduct = req.data;
            current.productLoad = !stateProduct.productLoad;
            return { ...current };
          });
        })
        .catch((err) => {
          notification.error({
            message: 'failed fetch data',
          });
        });
    };

    if (stateProduct.productLoad) {
      apiGetProduct();
    }
  }, [stateProduct]);

  const selectedProduct = (product) => {
    setState((prevData) => {
      const current = { ...prevData };
      current.selectedItems = product;
      return { ...current };
    });
  };

  const setProduct = (product) => {
    const sdk = useSDK();
    const CONTENT_FIELD_ID = 'productID';
    const fieldID = sdk.entry.fields[CONTENT_FIELD_ID];
    return fieldID;
  };

  const playGround = () => (
    <Card
      title={
        <div className="title-playground">
          <Title level={2}>List Product</Title>
          <Button
            type="primary"
            size="large"
            onClick={() => setProduct(state.selectedItems)}
          >
            Select Product
          </Button>
        </div>
      }
      loading={stateProduct.productLoad}
    >
      <List
        grid={{ gutter: 16, column: 5 }}
        dataSource={stateProduct.listProduct}
        renderItem={(item) => (
          <List.Item>
            <Card
              title={item.title}
              id="productListItem"
              className={`${
                item.id === state.selectedItems?.id ? 'product-selected' : ''
              }`}
              onClick={() => selectedProduct(item)}
            >
              <div className="imageProduct">---</div>
              <div className="detailProduct">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              </div>
            </Card>
          </List.Item>
        )}
        pagination={{
          pageSize: 10,
          position: 'bottom',
        }}
      />
    </Card>
  );

  return (
    <div id="CustomApp">
      <div></div>
      {playGround()}
    </div>
  );
};

CustomApp.displayName = config.displayName;
CustomApp.defaultProps = config.defaultProps;
CustomApp.propTypes = config.propTypes;

export default CustomApp;
