import React from 'react';
import path from './path';

import Auth from '../containers/Auth/Auth.container';
import AuthForward from '../containers/AuthForward/AuthForward.container';
import Admin from '../containers/Admin/Admin.container';
import Instructure from '../containers/Instructure/Instructure.container';
import Moderator from '../containers/Moderator/Moderator.container';
import Student from '../containers/Student/Student.container';
import DetailStudentCertificate from '../containers/Student/DetailStudentCertificate/DetailStudentCertificate.container';
import CustomApp from '../containers/CustomApp/CustomApp.container';

export const BASE_ROUTE = [
  {
    key: 'auth',
    index: true,
    path: path.auth,
    element: <Auth />,
  },
  {
    key: 'admin',
    path: path.admin,
    element: <Admin />,
  },
  {
    key: 'instructure',
    path: path.instructure,
    element: <Instructure />,
  },
  {
    key: 'moderator',
    path: path.moderator,
    element: <Moderator />,
  },
  {
    key: 'student',
    path: path.student,
    element: <Student />,
  },
  {
    key: 'auth-forward',
    path: path.frwrd,
    element: <AuthForward />,
  },
  {
    key: 'cert',
    path: path.cert,
    element: <DetailStudentCertificate />,
  },
  {
    key: 'custom-app',
    path: path.customapp,
    element: <CustomApp />,
  },
];
