import React, { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Menu, Layout, Typography, Modal, Button } from 'antd';
import { ICON_BOLBEL_FULL, ICON_BOLBEL } from '../../../assets/svg';

import config from './SideMenuLayout.component.config';
import './SideMenuLayout.component.styles.css';

const { Sider } = Layout;
const { Title, Text } = Typography;

const SideMenuLayout = ({ routeBase, ...props }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [stateModal, setStateModal] = useState({
    modalQuiz: false,
  });

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    props.setStateSideMenu(!collapsed);
  };
  const currentPath = () => {
    const locationPath = props.location.pathname;
    const patternIgnore = ['detail', 'course'];
    let lockPattern = null;
    patternIgnore.forEach((text) => {
      if (locationPath.includes(`/${text}`)) {
        lockPattern = text;
      }
    });

    const pattern = locationPath.includes(`/${lockPattern}`)
      ? locationPath.split(`/${lockPattern}`)[0]
      : locationPath;

    return pattern;
  };

  const navigate = useNavigate();
  const navigationMenu = (e) => {
    const QuizReordering_ = document.getElementsByClassName(
      'quiz-active-reordering',
    ).length;
    if (QuizReordering_ === 0) {
      navigate(e.key);
    } else {
      setStateModal((prevState) => {
        const currentState = { ...prevState };
        currentState.modalQuiz = !currentState.modalQuiz;
        return { ...currentState };
      });
    }
  };

  const generateMenu = (contentMenu) =>
    contentMenu.map((item) => (item.ismenu === 'yes' ? item : null));

  const generateMenuUtils = (contentMenuUtils) =>
    contentMenuUtils.map((item) => (item.foruser === 'yes' ? item : null));

  return (
    <Sider
      id="SideMenu"
      collapsed={collapsed}
      width={250}
      style={{ padding: collapsed && 20 }}
    >
      <div
        className="image-logo"
        onClick={toggleCollapsed}
        style={{ marginLeft: collapsed && 0 }}
      >
        {collapsed ? <ICON_BOLBEL /> : <ICON_BOLBEL_FULL />}
      </div>

      <div className="section-menu">
        <Menu
          className="main-menu"
          defaultSelectedKeys={currentPath()}
          selectedKeys={currentPath()}
          mode="inline"
          theme="light"
          items={generateMenu(routeBase)}
          onClick={navigationMenu}
        />
        <Menu
          className="setup-menu"
          mode="inline"
          theme="light"
          items={generateMenuUtils(routeBase)}
          style={{ padding: collapsed && 0 }}
          defaultSelectedKeys={currentPath()}
          selectedKeys={currentPath()}
          onClick={navigationMenu}
        />
      </div>
      {!collapsed && (
        <Text
          style={{
            fontSize: 10,
            marginTop: 20,
            textAlign: 'center',
            color: '#b1b1b1',
          }}
        >
          ©{format(new Date(), 'yyyy ')}
          LMS Boleh Belajar All right reserved
        </Text>
      )}
      <Modal
        title={
          <div className="header-custom">
            <Title level={3}>Attention!</Title>
            <span>
              Please ensure again you has saved the question and answer
              reordering!
            </span>
          </div>
        }
        centered
        open={stateModal.modalQuiz}
        closeIcon={false}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setStateModal((prevState) => {
                const currentState = { ...prevState };
                currentState.modalQuiz = !currentState.modalQuiz;
                return { ...currentState };
              });
            }}
          >
            Yes, sure!
          </Button>,
        ]}
        destroyOnClose
      ></Modal>
    </Sider>
  );
};

SideMenuLayout.displayName = config.displayName;
SideMenuLayout.defaultProps = config.defaultProps;
SideMenuLayout.propTypes = config.propTypes;

export default SideMenuLayout;
