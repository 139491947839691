import React from 'react';
import { format } from 'date-fns';
import { get } from 'lodash';
import { Card, Col, Row, Typography, Popover, Button } from 'antd';
import {
  FieldTimeOutlined,
  UserOutlined,
  HourglassOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import config from './CardDataEvent.component.config';
import './CardDataEvent.component.styles.css';

const { Title, Text, Paragraph } = Typography;

const CardDataEvent = ({
  navigateToDetailProgramsEntity,
  sourceData,
  ...props
}) => {
  const listData = sourceData !== undefined ? sourceData.data : [];
  const forwardContent = (items) => {
    let params = null;
    if (
      get(items, 'program_id') &&
      get(items, 'activity_id') &&
      get(items, 'id')
    ) {
      params = `course=${get(items, 'program_id')}&act=${get(
        items,
        'activity_id',
      )}&ent=${get(items, 'id')}`;
    } else {
      params = `course=${get(items, 'program_id')}&act=${get(items, 'id')}`;
    }
    navigateToDetailProgramsEntity({
      id: get(items, 'program_id'),
      url: params,
    });
  };

  const renderEvent = () => {
    let popData = (
      <div>
        <Title level={5} style={{ color: 'grey' }}>
          The latest event is not yet available...
        </Title>
      </div>
    );
    if (listData?.length > 0)
      popData = listData.map((items) => (
        <div>
          <Card
            key={items.id}
            title={
              <span style={{ fontSize: 12 }}>
                {format(new Date(items.schedule_start), 'dd MMMM')}
                {' - '}
                {format(new Date(items.schedule_end), 'dd MMMM yyyy')}
              </span>
            }
            onClick={() => forwardContent(items)}
          >
            <Title level={5}>{items.activity_name}</Title>
            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: false,
              }}
            >
              {items.description}
            </Paragraph>
          </Card>
          {items.entity?.length > 0 &&
            items.entity.map((entity) => (
              <Card
                className="events-entity"
                key={entity.id}
                title={
                  <span style={{ fontSize: 12 }}>
                    {format(
                      new Date(entity.schedule_date),
                      'HH:mm:ss ,dd MMMM yyyy',
                    )}
                  </span>
                }
                onClick={() => forwardContent(entity)}
              >
                <Title level={5}>{entity.entity_name}</Title>
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                  }}
                >
                  {entity.description}
                </Paragraph>
              </Card>
            ))}
        </div>
        // <Popover
        //   placement="left"
        //   title={items.entity_name}
        //   content={
        //     <div className="pop-event">
        //       <Row gutter={24}>
        //         <Col md={12}>
        //           {/* <UserOutlined /> {items.moderator_name} */}
        //         </Col>
        //         <Col md={12}>
        //           <FieldTimeOutlined />
        //           {/* {format(new Date(items.time), 'E, dd MMM yyyy H:mm:ss')} */}
        //         </Col>
        //         <Col md={12}>
        //           <HourglassOutlined /> 20:00 - 21:00
        //         </Col>
        //         <Col md={12}>
        //           {/* <MessageOutlined /> {items.entity_type} */}
        //         </Col>
        //       </Row>
        //       <Button
        //         block
        //         style={{ marginTop: 15 }}
        //         onClick={() => navigateToDetailProgramsEntity()}
        //       >
        //         Join class
        //       </Button>
        //     </div>
        //   }
        // >
        // </Popover>
      ));
    return popData;
  };

  return <div id="CardDataEvent">{renderEvent()}</div>;
};

CardDataEvent.displayName = config.displayName;
CardDataEvent.defaultProps = config.defaultProps;
CardDataEvent.propTypes = config.propTypes;

export default CardDataEvent;
