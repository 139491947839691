import { connect } from 'react-redux';
import AdminDashboard from './AdminDashboard.component';
import { lessonsList } from '../../../redux/actions/lessons.actions';
import { programList } from '../../../redux/actions/programs.actions';

const mapStateToProps = (state) => ({
  common: state.common.settings,
  lessonListData: state.lesson.list,
  programListData: state.program.list,
});

const mapDispatchToProps = {
  lessonsList,
  programList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
