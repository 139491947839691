import { DownloadOutlined, LinkedinFilled } from '@ant-design/icons';
import { Button, Card, Col, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Document, Page, pdfjs } from 'react-pdf';
import { MotionPage, StudentLayout } from '../../../components';
import useHandleAddLinkedin from '../../../hook/useHandleAddLinkedin';
import { getFormatedValue } from '../../../utils/format';
import config from './StudentCertificate.component.config';

import './StudentCertificate.styles.component.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { Title } = Typography;
const { Meta } = Card;

const StudentCertificate = ({
  getCertifList,
  certificateList,
  users,
  ...props
}) => {
  const dataCertificate = certificateList.data;
  const { handleAddToLinkedinProfile } = useHandleAddLinkedin();
  const isCertificateAvailable = useMemo(
    () => dataCertificate.length !== 0,
    [dataCertificate],
  );
  useEffect(() => {
    getCertifList().catch((err) =>
      console.log('Error fetching Certificate List!', err),
    );
  }, [getCertifList]);

  const certificateCards = useMemo(
    () =>
      dataCertificate.map((item) => (
        <Col span={isMobile ? 24 : 8}>
          <Card
            onClick={(e) => {
              window.open(`${window.location.origin}/cert/${item.cert_id}`);
              e.stopPropagation();
            }}
            className="cert-card"
            style={{ marginBottom: 24 }}
            bodyStyle={{ height: 155 }}
            cover={
              <div style={{ padding: 12 }}>
                <Tooltip
                  title="Click to See Certificate Detail"
                  placement="bottom"
                >
                  <Document
                    file={item.cert_link_file}
                    error={<div>Please Wait...</div>}
                  >
                    <Page
                      className="PDFPage"
                      pageNumber={1}
                      renderTextLayer={false}
                      renderInteractiveForms={false}
                      renderAnnotationLayer={false}
                      error={<div>Please Wait...</div>}
                    />
                  </Document>
                </Tooltip>
              </div>
            }
            actions={[
              <Button
                onClick={(e) => {
                  handleAddToLinkedinProfile(
                    item.program_name,
                    `${window.location.origin}/cert/${item.cert_id}`,
                    item.cert_id,
                    getFormatedValue(item.claim_date, 'year'),
                    getFormatedValue(item.claimDate),
                  );
                  handleAddToLinkedinProfile();
                  e.stopPropagation();
                }}
                type="text"
                icon={<LinkedinFilled />}
              >
                Add to Profile
              </Button>,
              <Button
                type="text"
                href={item.cert_link_file}
                onClick={(e) => e.stopPropagation()}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>,
            ]}
          >
            <Meta
              title={
                <Title level={5}>Congratulations, {users.person}! 🎉</Title>
              }
              description={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                  }}
                >
                  <p>
                    You&lsquo;ve finished the {item.program_name} Course. You
                    can see the certificate detail or add it to your Linkedin
                    profile.
                  </p>
                </div>
              }
            />
          </Card>
        </Col>
      )),
    [dataCertificate, users.person, handleAddToLinkedinProfile],
  );

  return (
    <StudentLayout title="Daftar Sertifikat">
      <MotionPage motionType="fadeIn">
        {isCertificateAvailable ? (
          <div
            style={{
              padding: isMobile ? '6rem 1.5rem' : '0rem 0rem 0rem 15.5rem',
            }}
          >
            <Row gutter={24}>{certificateCards}</Row>
          </div>
        ) : (
          <Title level={4} style={{ marginLeft: 250, width: 800 }}>
            Setelah kamu menyelesaikan kelas, Daftar Sertifikat kamu akan
            disimpan disini.
            <br />
            <div style={{ fontSize: 16, fontWeight: 'normal', marginTop: 10 }}>
              catatan: hanya kelas tertentu, VLC Bundling dan Bootcamp, yang
              memenuhi syarat untuk certification test. hubungi admin untuk
              informasi lebih lanjut.
            </div>
          </Title>
        )}
      </MotionPage>
    </StudentLayout>
  );
};

StudentCertificate.displayName = config.displayName;
StudentCertificate.defaultProps = config.defaultProps;
StudentCertificate.propTypes = config.propTypes;

export default StudentCertificate;
