import React, { useState, useEffect } from 'react';
import { random } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { List, Typography, Tag } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { formatDateTime } from '../../utils/format';

import config from './DataTableDragable.component.config';
import './DataTableDragable.component.styles.css';

const { Title, Text } = Typography;

const DataTableDragable = ({
  locked,
  sourceData,
  extraAction,
  needUpdatePosition,
  actionUpdatePosition,
  isLoading,
  contentType,
  ...props
}) => {
  const [data, setData] = useState(sourceData);
  // const sortingDragable = (listData) =>
  //   listData?.sort((a, b) => a.position - b.position);
  // useEffect(() => {
  //   sortingDragable(sourceData);
  //   if (data?.length !== sourceData?.length) {
  //     setData(sourceData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, sourceData]);
  useEffect(() => {
    setData(sourceData);
  }, [sourceData]);

  const changePositionList = (cloneData) => {
    // change position list
    const isData = cloneData;
    const newObj = [];
    for (let index = 0; index < isData?.length; ) {
      const newPosition = index + 1;
      const currPostition = isData[index].position;

      if (needUpdatePosition) {
        if (contentType === 'activity') {
          newObj.push({
            activity_id: isData[index].id,
            position: index + 1,
          });
        } else {
          actionUpdatePosition({
            entityID: isData[index].id,
            currentPostition: currPostition,
            newPosition: index + 1,
          });
        }
      }

      isData[index].position = newPosition;
      index += 1;
    }
    if (needUpdatePosition) {
      if (contentType === 'activity') {
        actionUpdatePosition(newObj);
      }
    }
    return isData;
  };

  const onHandleDragEnd = (result) => {
    if (!result.destination) return;
    const clonedListItems = JSON.parse(JSON.stringify(data));
    const [reorderedItem] = clonedListItems.splice(result.source.index, 1);
    clonedListItems.splice(result.destination.index, 0, reorderedItem);

    const clonedData = changePositionList(clonedListItems);
    setData(clonedData);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? '#cdcdcd' : 'inherit',
    ...draggableStyle,
  });

  const renderContent = (item) => {
    let content = null;
    if (contentType === 'activity') {
      content = (
        <div className="text-info">
          {props.gotoDetail ? (
            <Title
              level={5}
              className="title-link"
              onClick={() => props.gotoDetail(item)}
            >
              {item?.activity_name}
            </Title>
          ) : (
            <Title level={5}>{item?.activity_name}</Title>
          )}
          <span style={{ fontSize: 12 }}>
            {item?.description} <Tag color="#c9264b">{item?.activity_tags}</Tag>
            {/* {item?.schedule_date != null &&
              `Schedule: ${formatDateTime(item.schedule_date)}`} */}
          </span>
        </div>
      );
    } else {
      content = (
        <div className="text-info">
          {props.gotoDetail ? (
            <Title
              level={5}
              className="title-link"
              onClick={() =>
                props.gotoDetail({
                  action: 'detailEntity',
                  entity: item,
                })
              }
            >
              {item?.entity_name}
            </Title>
          ) : (
            <Title level={5}>{item?.entity_name}</Title>
          )}
          <span style={{ fontSize: 12 }}>
            {item?.schedule_date != null &&
              `Schedule: ${formatDateTime(item.schedule_date)}`}
          </span>
        </div>
      );
    }
    return content;
  };

  const renderItem = (item, index) => {
    const actionButton = [];
    if (extraAction)
      extraAction.forEach((action) => {
        actionButton.push(action(item));
      });
    return (
      <Draggable
        draggableId={`draggable-${index}`}
        index={index}
        key={random(1, 100).toString()}
        isDragDisabled={locked}
      >
        {(provided, snapshot) => (
          <div
            key={index}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}
          >
            <List.Item key={random(1, 100).toString()}>
              <div className="item-data" key={random(1, 100).toString()}>
                <div className="item-control">
                  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
                  <div
                    className="icon-list"
                    hidden={contentType === 'activity' ? 'hidden' : false}
                  >
                    {config.defaultProps.iconType(item?.activity_type)}
                  </div>
                  {renderContent(item)}
                </div>
                <div className="item-action">{actionButton}</div>
              </div>
            </List.Item>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div id="DataTableDragable">
      <DragDropContext onDragEnd={onHandleDragEnd}>
        <Droppable droppableId="dropableList">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <List
                key={random(1, 100).toString()}
                loading={isLoading}
                rowKey="Id"
                size="default"
                itemLayout="horizontal"
                dataSource={data}
                renderItem={renderItem}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

DataTableDragable.displayName = config.displayName;
DataTableDragable.defaultProps = config.defaultProps;
DataTableDragable.propTypes = config.propTypes;

export default DataTableDragable;
