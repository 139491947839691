import PropTypes from 'prop-types';

const displayName = 'DetailActivity';
const propTypes = {};
const defaultProps = {
  entityDontHaveLink: [3, 4, 5],
  entityForQuizID: [3, 4],
  dummEntity: {
    data: [
      {
        key: '1',
        id: '1',
        name: 'Facebook Marketing Zoom Meeting',
        type: 'static',
        entityType: 'video conference',
        material: 'https://stag-lms.devbdd.com',
      },
      {
        key: '2',
        id: '2',
        name: 'Google Ads Video Learning',
        type: 'dependencies',
        entityType: 'video learning',
        material:
          'https://bdd-lms-bucket.s3.ap-southeast-1.amazonaws.com/video_learning/BDD+LMS+-+13+June+2022.mp4',
      },
      {
        key: '3',
        id: '3',
        name: 'Task document',
        type: 'static',
        entityType: 'document',
        material:
          'https://bdd-lms-bucket.s3.ap-southeast-1.amazonaws.com/document/Common+function+-++Documentation+BDDAI.pdf',
      },
      {
        key: '4',
        id: '4',
        name: 'Pre Test Quiz',
        type: 'static',
        entityType: 'quiz',
      },
    ],
  },
};

export default { displayName, propTypes, defaultProps };
