import React from 'react';
import {
  HomeFilled,
  BankFilled,
  ContainerFilled,
  DatabaseFilled,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import path from './path';
import UserAccountContainer from '../containers/UserAccount/UserAccount.container';
import UserHelpContainer from '../containers/UserHelp/UserHelp.container';

export const INSTRUCTURE_ROUTE = [
  {
    key: `/instructure/${path.GENERAL_PATH.dashboard}`,
    label: 'Dashboard',
    icon: <HomeFilled />,
    index: true,
    path: path.GENERAL_PATH.dashboard,
    element: <div>Instructure</div>,
  },
  // utils
  {
    key: `/instructure/${path.GENERAL_PATH.account}`,
    label: 'Account',
    icon: <UserOutlined />,
    path: path.GENERAL_PATH.account,
    element: <UserAccountContainer />,
    ismenu: false,
    foruser: 'yes',
  },
  {
    key: `/instructure/${path.GENERAL_PATH.help}`,
    label: 'Help',
    icon: <InfoCircleOutlined />,
    path: path.GENERAL_PATH.help,
    element: <UserHelpContainer />,
    ismenu: false,
    foruser: 'yes',
  },
];
