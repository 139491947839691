import React from 'react';
import { isMobile } from 'react-device-detect';
import { Layout, Typography } from 'antd';

import config from './BaseLayout.component.config';
import './BaseLayout.component.styles.css';

const { Content } = Layout;
const { Title } = Typography;

const BaseLayout = ({ title, action, ...props }) => (
  <Layout
    id="BaseLayout"
    style={{
      padding: isMobile ? '80px 20px 20px 20px' : '100px 60px 20px 60px',
    }}
  >
    <div className={`header-content ${isMobile && 'header-mobile'}`}>
      <div className="header-title">
        <Title level={4} style={{ margin: 'unset', color: '#b2b2b' }}>
          {title}
        </Title>
      </div>
      <div className="header-action">{action}</div>
    </div>
    <Content id="ContentLayoutStudent">{props.children}</Content>
  </Layout>
);

BaseLayout.displayName = config.displayName;
BaseLayout.defaultProps = config.defaultProps;
BaseLayout.propTypes = config.propTypes;

export default BaseLayout;
