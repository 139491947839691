import { LinkedinFilled, WhatsAppOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Empty, Layout, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Document, Page, pdfjs } from 'react-pdf';
import { PATTERN } from '../../../assets/svg';
import { EmptyContent, MotionPage } from '../../../components';
import useHandleAddLinkedin from '../../../hook/useHandleAddLinkedin';
import { getFormatedValue } from '../../../utils/format';
import config from './DetailStudentCertificate.component.config';
import './DetailStudentCertificate.styles.component.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { Title, Text } = Typography;
const { Meta } = Card;

const DetailStudentCertificate = ({ getCertificatePublicAPI, ...props }) => {
  const rawUrl = window.location.href;
  const certificateID = rawUrl.substring(rawUrl.lastIndexOf('/') + 1);
  const { handleAddToLinkedinProfile } = useHandleAddLinkedin();
  const [state, setState] = React.useState({
    certificateFile: null,
    studentName: '',
    programName: '',
    claimDate: null,
    dataNotFound: false,
  });

  useEffect(() => {
    getCertificatePublicAPI(certificateID)
      .then((data) =>
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.certificateFile = data.cert_link_file;
          currentState.studentName = data.learner_name;
          currentState.programName = data.program_name;
          currentState.claimDate = data.claim_date;
          return { ...currentState };
        }),
      )
      .catch(() =>
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.dataNotFound = true;
          return { ...currentState };
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MotionPage motionType="fadeIn">
        <div
          className="card"
          style={{
            backgroundImage: `url(${PATTERN})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {state.dataNotFound ? (
            <div className="font-quicksand no-certif">
              <EmptyContent
                title="OOPS !"
                description="Hi! ID certificate tidak bisa kami temukan. Hubungi customer support
             Boleh Belajar dengan klik tombol dibawah ini untuk kami tindak
             lanjuti."
                onButtonPressed={() =>
                  window.open('https://wa.me/6281944365758', '_blank')
                }
                icon={<WhatsAppOutlined />}
                hasIcon
                buttonText="CS Boleh Belajar"
              />
            </div>
          ) : (
            <div className="border-outer font-quicksand">
              <div
                onContextMenu={(e) => e.preventDefault()}
                className="border-inner"
                style={{
                  textAlign: 'center',
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                <Document
                  file={state.certificateFile}
                  error={<div>An error occurred!</div>}
                >
                  <Page
                    className="PDF"
                    pageNumber={1}
                    renderTextLayer={false}
                    renderInteractiveForms={false}
                    renderAnnotationLayer={false}
                    width={720}
                    error={<div>An error occurred!</div>}
                  />
                </Document>
              </div>
            </div>
          )}
          <div
            className="font-quicksand"
            style={{
              display: state.dataNotFound ? 'none' : 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <Text className="fw-500" style={{ fontSize: isMobile ? 16 : 24 }}>
              Sertifikat ini Diterbitkan untuk
            </Text>
            <Text className="fw-700" style={{ fontSize: isMobile ? 22 : 32 }}>
              {state.studentName}
            </Text>
            <Text
              className="fw-500"
              style={{ fontSize: isMobile ? 16 : 24, color: '#7B7777' }}
            >
              Siswa Video Learning Content
            </Text>
          </div>
          <Button
            onClick={(e) => {
              handleAddToLinkedinProfile(
                state.programName,
                `${window.location.origin}/cert/${certificateID}`,
                certificateID,
                getFormatedValue(state.claimDate, 'year'),
                getFormatedValue(state.claimDate),
              );
              e.stopPropagation();
            }}
            type="primary"
            icon={<LinkedinFilled />}
            lineHeight={12}
            id="linkedin-cta"
            style={{ display: state.dataNotFound ? 'none' : 'block' }}
          >
            Tambahkan ke Profil Linkedin
          </Button>
        </div>
      </MotionPage>
      <Layout
        style={{
          padding: isMobile ? '40px 10px 20px 18px' : '40px 60px 20px 140px',
          display: state.dataNotFound ? 'none' : 'block',
        }}
      >
        <Content
          className={`${state.dataNotFound && 'hide'} font-quicksand-content`}
        >
          <Title className="fw-700" id="title-grey">
            SERTIFIKASI UNTUK
          </Title>
          <Text className="fw-700" id="text-black">
            {state.programName}
          </Text>
          <Title className="fw-700" id="title-grey">
            TANGGAL DI TERBITKAN
          </Title>
          <Text className="fw-700" id="text-black">
            {moment(state.claimDate).format('D MMMM YYYY')}
          </Text>
          <Title className="fw-700" id="title-grey">
            MASA BERLAKU
          </Title>
          <Text className="fw-700" id="text-black">
            Sertifikat ini tidak ada masa berlakunya
          </Text>
          <Divider />
          <Title className="fw-700" id="title-black">
            TENTANG
          </Title>
          <Text className="fw-500" id="text-grey">
            Sertifikat ini diberikan kepada {state.studentName} sebagai
            pengakuan atas keberhasilan dalam menyelesaikan program{' '}
            {state.programName}. Dengan keterampilan dan pengetahuan yang telah
            Anda peroleh, kami yakin bahwa Anda memiliki kemampuan untuk secara
            efektif memanfaatkan berbagai strategi pemasaran digital.
          </Text>
          <Title className="fw-700" id="title-black">
            KRITERIA LULUS
          </Title>
          <Text className="fw-500" id="text-grey">
            Telah menyelesaikan seluruh proses pembelajaran dan berhasil lulus
            semua ujian.
          </Text>
        </Content>
      </Layout>
    </>
  );
};

DetailStudentCertificate.displayName = config.displayName;
DetailStudentCertificate.defaultProps = config.defaultProps;
DetailStudentCertificate.propTypes = config.propTypes;

export default DetailStudentCertificate;
