import React from 'react';
import PropTypes from 'prop-types';
import {
  PhoneOutlined,
  PlayCircleOutlined,
  FolderOpenOutlined,
  BookOutlined,
} from '@ant-design/icons';

const displayName = 'DataTableDragable';
const propTypes = {};
const defaultProps = {
  iconType: (type) => {
    let isIcon = <BookOutlined />;
    switch (type) {
      case 'Video Confrence':
        isIcon = <PhoneOutlined />;
        break;
      case 'Video Learning':
        isIcon = <PlayCircleOutlined />;
        break;
      case 'Pre test':
        isIcon = <FolderOpenOutlined />;
        break;
      case 'Post test':
        isIcon = <BookOutlined />;
        break;
      default:
        isIcon = <BookOutlined />;
        break;
    }
    return isIcon;
  },
};

export default { displayName, propTypes, defaultProps };
