import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import {
  Button,
  Image,
  Select,
  Typography,
  Dropdown,
  Menu,
  Form,
  Input,
  Upload,
  notification,
  Card,
  Popconfirm,
} from 'antd';
import {
  EditOutlined,
  ShareAltOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
  DashOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons';

import { IMG_EMPTY } from '../../../assets/svg';
import {
  BaseLayout,
  DataTableDragable,
  EmptyContent,
  ModalCreateEntity,
  MotionPage,
} from '../../../components';
import DetailEntity from './DetailEntity/DetailEntity.component';

import { getParamURL } from '../../../utils/parse';
import {
  putObjectAwsS3Bucket,
  getObjectAwsS3Bucket,
} from '../../../services/aws.service';

import config from './DetailLessons.component.config';
import './DetailLessons.component.styles.css';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const DetailLessons = ({
  searchText,
  clearSearchText,
  lessonEntityObject,
  lessonsEntityList,
  lessonsEntityCreate,
  lessonsEntityDelete,
  lessonsEntityUpdatePosition,
  lessonsUpdate,
  lessonsDelete,
  lessonsEntityUpdate,
  lessonGetTypeSuggestions,
  listQuiz,
  getListQuiz,
  selectionQuiz,
  getListQuizDetail,
  fieldSuggestions,
  ...props
}) => {
  const navigate = useNavigate();
  const lessonID = getParamURL('id');
  const [lessonParrent, setLessonParent] = useState(
    lessonEntityObject?.parrent,
  );
  const lessonEntityList = lessonEntityObject?.entity;
  const lessonEntityLoading = lessonEntityObject?.isLoading;
  const lessonFormRef = React.useRef();
  const [state, setState] = useState({
    entityListData: lessonEntityObject,
    needMounting: true,
    idLessons: null,
    editLesson: true,
    showEmpty: true,
    showListEntity: false,
    showDetailEntity: false,
    entitySelection: null,
    editEntity: false,
    fileList: [],
    onUploadFile: false,
  });
  const mountingFirstData = () => {
    if (state.needMounting) {
      getListQuiz();
      lessonsEntityList(lessonID).then(() => {
        if (lessonEntityList?.length > 0) navigationLocal({ action: 'list' });
      });
      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.idLessons = lessonID;
        currentState.needMounting = false;
        return { ...currentState };
      });
    }
  };

  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  });

  useEffect(() => {
    if (state.showModalCreate === true) {
      lessonGetTypeSuggestions().catch(() =>
        notification.error({
          message: `System Error`,
          description: `Unable to get program type suggestions`,
        }),
      );
    }
  }, [lessonGetTypeSuggestions, state.showModalCreate]);

  useEffect(() => {
    const sourceBanner = lessonParrent?.banner_image;
    if (document.getElementsByClassName('header-lesson')[0] !== undefined) {
      if (sourceBanner !== '') {
        if (sourceBanner !== null) {
          if (sourceBanner !== undefined) {
            // const keyBanner = sourceBanner.split('amazonaws.com/')[1];
            // getObjectAwsS3Bucket(
            //   keyBanner.replaceAll('+', ' '),
            //   setBackgroundBanner,
            // );
            document.getElementsByClassName(
              'header-lesson',
            )[0].style.backgroundImage = `url(${sourceBanner})`;
          }
        }
      }
    }
  }, [lessonParrent]);

  const getEntityQuiz = (entity) => {
    if (
      config.defaultProps.entityQuizID.indexOf(entity?.activity_type_id) >= 0
    ) {
      getListQuizDetail(Number(entity?.material_link));
    }
  };
  const navigationLocal = ({ ...setup }) => {
    setState((prevState) => {
      const currentState = { ...prevState };

      switch (setup.action) {
        case 'list':
          currentState.showModalCreate = false;
          currentState.showDetailEntity = false;
          break;
        case 'back':
          clearSearchText();
          currentState.showEmpty = false;
          currentState.showListEntity = true;
          currentState.showModalCreate = false;
          currentState.showDetailEntity = false;
          currentState.entitySelection = null;
          break;
        case 'visibleModal':
          currentState.showModalCreate = true;
          break;
        case 'editEntity':
          currentState.showModalCreate = true;
          currentState.editEntity = !currentState.editEntity;
          currentState.entitySelection = get(setup, 'entity');
          break;
        case 'closeModal':
          currentState.showModalCreate = false;
          currentState.editEntity = false;
          currentState.entitySelection = null;
          break;
        case 'detailEntity':
          currentState.showDetailEntity = true;
          currentState.entitySelection = get(setup, 'entity');
          getEntityQuiz(get(setup, 'entity'));
          break;
        default:
          currentState.showEmpty = true;
          currentState.showListEntity = false;
          currentState.showModalCreate = false;
          currentState.showDetailEntity = false;
          break;
      }

      return { ...currentState };
    });
  };

  const actionUpdatePositionEntity = ({ ...update }) => {
    const params = {
      lesson_id: lessonID,
      entity_id: update?.entityID,
      currentPostition: update?.currentPostition,
      newPosition: update?.newPosition,
    };
    lessonsEntityUpdatePosition(params).then(() => {
      lessonsEntityList(lessonID);
      setTimeout(() => {
        navigationLocal({ action: 'list' });
      }, 500);
    });
  };

  const actionCreateActivity = (fields) => {
    const createData = {
      lesson_id: lessonParrent?.id,
      ...fields,
    };
    lessonsEntityCreate(createData).then(() => {
      lessonsEntityList(lessonID);
      setTimeout(() => {
        navigationLocal({ action: 'list' });
      }, 500);
    });
  };

  const actionUpdateActivity = (fields) => {
    const updateData = {
      id: state.entitySelection?.id,
      ...fields,
    };
    lessonsEntityUpdate(updateData).then(() => {
      lessonsEntityList(lessonID);
      setTimeout(() => {
        navigationLocal({ action: 'closeModal' });
        navigationLocal({ action: 'list' });
      }, 500);
    });
  };
  const actionDeleteActivity = (entityID) => {
    lessonsEntityDelete(entityID).then(() => {
      lessonsEntityList(lessonID);
      setTimeout(() => {
        navigationLocal({ action: 'list' });
      }, 500);
    });
  };

  const buttonTypeEntity = ({ ...extraData }) => {
    const entityData = extraData;
    const dependStatus = extraData?.dependency;
    return (
      <Select
        defaultValue={dependStatus}
        style={{ width: 160 }}
        // onChange={(value) => {
        //   console.log('update dependency', value, entityData);
        // }}
      >
        <Select.Option value={0}>
          <div className="entity-type-selection entity-type-selection-1">
            Static
          </div>
        </Select.Option>
        <Select.Option value={1}>
          <div className="entity-type-selection entity-type-selection-2">
            dependencies
          </div>
        </Select.Option>
      </Select>
    );
  };
  const buttonActionsEntity = ({ ...extraData }) => (
    <Dropdown
      type="primary"
      placement="bottomRight"
      trigger={['click']}
      menu={{
        items: [
          {
            icon: <EditOutlined />,
            label: 'Edit Entity',
            key: 'Edit',
          },
          {
            icon: <DeleteOutlined />,
            label: 'Delete Entity',
            key: 'Delete',
          },
        ],
        onClick: (e) => {
          switch (e.key) {
            case 'Edit':
              navigationLocal({ action: 'editEntity', entity: extraData });
              break;
            case 'Delete':
              actionDeleteActivity(extraData.id);
              break;
            default:
              break;
          }
        },
      }}
    >
      <Button type="text" shape="circle" icon={<DashOutlined />} />
    </Dropdown>
  );
  const onEditLesson = () => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.editLesson = !currentState.editLesson;
      lessonFormRef.current.setFieldsValue({
        lesson_name: lessonParrent?.lesson_name,
        lesson_tags: lessonParrent?.lesson_tags,
        description: lessonParrent?.description,
      });
      return { ...currentState };
    });
  };
  const onDeleteLesson = () => {
    lessonsDelete(lessonID).then(() => {
      navigate('/admin/lessons');
    });
  };
  const actionUploadBanner = async (upload) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.onUploadFile = !currentState.onUploadFile;
      return { ...currentState };
    });
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/programs',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };
  const onUpdateLesson = () => {
    const fields = lessonFormRef.current.getFieldsValue();
    const bannerFile =
      state?.fileList[0]?.uid !== undefined ? state?.fileList[0]?.uid : '';
    const updateData = {
      ...fields,
      id: lessonID,
      banner_image: bannerFile,
    };
    lessonsUpdate(updateData).then(() => {
      setLessonParent((prevParrent) => {
        const currentParrent = { ...prevParrent };
        currentParrent.lesson_name = fields?.lesson_name;
        currentParrent.description = fields?.description;
        currentParrent.banner_image = bannerFile;
        return { ...currentParrent };
      });
      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.editLesson = !currentState.editLesson;
        return { ...currentState };
      });
    });
  };
  const formEditLesson = () => (
    <div className="editLessonForm">
      <Form
        layout="vertical"
        ref={lessonFormRef}
        onFinish={() => onUpdateLesson()}
      >
        <Form.Item
          label="Lesson Name"
          name="lesson_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <Form.Item
          label="Activity Tags"
          name="lesson_tags"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item label="Upload Banner">
          <Form.Item name="banner_image" noStyle hidden={state.onUploadFile}>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={actionUploadBanner}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <div hidden={!state.onUploadFile} style={{ textAlign: 'center' }}>
            <p>Banner uploading, please wait...</p>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button type="default" onClick={() => onEditLesson()}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout collapsedMenu={props.common.collapsedMenu}>
        {!state.showDetailEntity && (
          <div id="DetailLesson">
            <div
              className="header-lesson"
              hidden={!state.editLesson}
              style={{
                backgroundImage: `url(${lessonParrent?.banner_image})`,
              }}
            >
              <div className="action-header">
                <Button
                  type="primary"
                  size="large"
                  icon={<LeftCircleOutlined />}
                  onClick={() => navigate('/admin/lessons')}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  size="large"
                  icon={<EditOutlined />}
                  onClick={() => onEditLesson()}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Are you sure want deleted this lesson? it will be delete related entity too"
                  onConfirm={() => onDeleteLesson()}
                  placement="bottomLeft"
                >
                  <Button type="primary" size="large" icon={<DeleteOutlined />}>
                    Delete
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  size="large"
                  icon={<ShareAltOutlined />}
                  onClick={() =>
                    notification.info({
                      message: 'Hi, Sorry!',
                      description: `This feature doesn't work for now, under development`,
                    })
                  }
                >
                  Share
                </Button>
              </div>
              <div className="information-header">
                <div className="content-header">
                  <div>
                    <div className="status-lesson">Used</div>
                    <Title level={2}>{lessonParrent?.lesson_name}</Title>
                    <Paragraph
                      ellipsis={{
                        rows: 3,
                        expandable: false,
                      }}
                    >
                      {lessonParrent?.description}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div hidden={state.editLesson} className="form-edit">
              <Card style={{ padding: 20 }}>{formEditLesson()}</Card>
            </div>

            <div className="content-detail">
              <div className="action-content">
                <Text>{lessonEntityList.length} Total Entity</Text>
                <Button
                  size="large"
                  icon={<PlusSquareOutlined />}
                  onClick={() => navigationLocal({ action: 'visibleModal' })}
                >
                  Add New
                </Button>
              </div>
              {lessonEntityList.length > 0 ? (
                <DataTableDragable
                  sourceData={lessonEntityList.filter((item) =>
                    item?.entity_name
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase()),
                  )}
                  isLoading={lessonEntityLoading}
                  needUpdatePosition
                  gotoDetail={navigationLocal}
                  actionUpdatePosition={actionUpdatePositionEntity}
                  extraAction={[buttonTypeEntity, buttonActionsEntity]}
                  locked={false}
                />
              ) : (
                <EmptyContent
                  title="Kamu Belum Menambahkan Entity"
                  description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet et
                veritatis, iste rerum illum, quod accusamus perferendis reprehenderit
                delectus quia qui saepe neque quaerat, earum ipsum libero. Officiis,
                ad suscipit!"
                  onButtonPressed={() =>
                    navigationLocal({ action: 'visibleModal' })
                  }
                  buttonText="Add Entity"
                />
              )}
              <ModalCreateEntity
                title={
                  state.editEntity
                    ? 'Edit Lesson Entity'
                    : 'Create Lesson Entity'
                }
                showModal={state.showModalCreate}
                closeModal={navigationLocal}
                actionCloseModal="closeModal"
                actionCreateActivity={actionCreateActivity}
                isEditData={state.editEntity}
                fieldSuggestions={fieldSuggestions}
                actionUpdateActivity={actionUpdateActivity}
                entitySelection={state.entitySelection}
                listQuiz={listQuiz?.data}
              />
            </div>
          </div>
        )}
        {state.showDetailEntity && (
          <DetailEntity
            data={state.entitySelection}
            dataDetailQuiz={selectionQuiz}
            actionBack={navigationLocal}
            actionUpdateActivity={actionUpdateActivity}
            listQuiz={listQuiz?.data}
          />
        )}
      </BaseLayout>
    </MotionPage>
  );
};

DetailLessons.displayName = config.displayName;
DetailLessons.defaultProps = config.defaultProps;
DetailLessons.propTypes = config.propTypes;

export default DetailLessons;
