import { SET_AUTH_USER } from '../reduxType';

const initialState = {
  access_token: null,
  defpassword: null,
  expired_at: null,
  refresh_token: null,
  user_id: null,
  isLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        ...action?.payload,
      };
    default:
      return state;
  }
};
