import React, { useMemo } from 'react';
import { Layout, Typography } from 'antd';

import config from './BaseLayout.component.config';
import './BaseLayout.component.styles.css';

const { Content } = Layout;
const { Title } = Typography;

const BaseLayout = ({ title, action, ...props }) => {
  const isMultiple = useMemo(() => action?.length > 1, [action?.length]);
  const actionClass = isMultiple ? 'header-action-multiple' : 'header-action';
  return (
    <Layout
      id="BaseLayout"
      style={{
        padding: props.collapsedMenu
          ? '110px 40px 50px 120px'
          : '105px 40px 50px 280px',
      }}
      onMouseEnter={props?.onMouseEnter}
    >
      <div className="header-content">
        <div className="header-title">
          <Title level={4} style={{ margin: 'unset', color: '#b2b2b' }}>
            {title}
          </Title>
        </div>
      </div>
      <div className={actionClass}>{action}</div>
      <Content id="ContentLayout">{props.children}</Content>
    </Layout>
  );
};

BaseLayout.displayName = config.displayName;
BaseLayout.defaultProps = config.defaultProps;
BaseLayout.propTypes = config.propTypes;

export default BaseLayout;
