import { EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Image, Row, Space, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { formatDate } from '../../../../utils/format';
import config from './CardCertificate.component.config';
import './CardCertificate.component.styles.css';

const { Text, Title } = Typography;
const { Meta } = Card;

const CardCertificate = ({ data, onEdit, onCreate, programType }) => {
  const certificateList = useMemo(
    () => (data !== undefined ? data : []),
    [data],
  );
  const getCertifTemplate = useCallback(
    (programName) => {
      const foundObject = certificateList.find(
        (item) => item?.program_type === programName,
      );
      if (foundObject?.template) {
        return <Image src={foundObject?.template} style={{ padding: 10 }} />;
      }
      return (
        <div
          style={{
            height: 250,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      );
    },
    [certificateList],
  );

  const getCertifInfo = useCallback(
    (programName, whichData) => {
      const foundObject = certificateList.find(
        (item) => item.program_type === programName,
      );

      if (foundObject) {
        switch (whichData) {
          case 'updatedData':
            return foundObject?.updated_data;
          case 'user':
            return foundObject?.user;
          default:
            return foundObject?.created_date;
        }
      } else {
        switch (whichData) {
          case 'updatedData':
            return null;
          case 'user':
            return '-';
          default:
            return null;
        }
      }
    },
    [certificateList],
  );

  const actionButtons = useCallback(
    (item) => {
      const foundObject = certificateList.find(
        (dataCertif) => dataCertif.program_type === item.value,
      );

      let actionButton = [];

      if (foundObject) {
        actionButton = [
          <Button
            onClick={() =>
              onEdit(item.id, foundObject.id, foundObject.program_type)
            }
            type="text"
          >
            <EditOutlined /> Edit
          </Button>,
        ];
      } else {
        actionButton = [
          <Button onClick={() => onCreate(item.id)} type="primary">
            <FileAddOutlined /> Create
          </Button>,
        ];
      }

      return actionButton;
    },
    [certificateList, onCreate, onEdit],
  );

  const certificateConfig = useMemo(
    () =>
      programType.map((item) => (
        <Col span={8}>
          <Card
            cover={getCertifTemplate(item.value)}
            actions={actionButtons(item)}
          >
            <Meta
              title={
                <Space align="center" size={24} direction="horizontal">
                  <Title level={5}>
                    {item.value} Certificate Configuration
                  </Title>
                </Space>
              }
              description={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                  }}
                >
                  <Space direction="vertical" style={{ padding: 10 }}>
                    <Typography.Text strong>
                      Certificate Information
                    </Typography.Text>
                    <span>
                      Created at: {formatDate(getCertifInfo(item.value))}
                    </span>
                    <span>
                      Updated at:{' '}
                      {formatDate(getCertifInfo(item.value, 'updatedData'))}
                    </span>
                    <span>
                      Uploaded by: {getCertifInfo(item.value, 'user')}
                    </span>
                  </Space>
                </div>
              }
            />
          </Card>
        </Col>
      )),
    [actionButtons, getCertifInfo, getCertifTemplate, programType],
  );

  const renderCard = () => {
    let cardData;
    if (programType?.length > 0) cardData = certificateConfig;
    return cardData;
  };

  return (
    <div>
      <Row gutter={24}>{renderCard()}</Row>
    </div>
  );
};
CardCertificate.displayName = config.displayName;
CardCertificate.defaultProps = config.defaultProps;
CardCertificate.propTypes = config.propTypes;

export default CardCertificate;
