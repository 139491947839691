import { BellOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Badge, Button, Dropdown, Layout, Modal, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ICON_LOGOUT, ICON_PROFILE } from '../../../assets/svg';
import { destroyToken } from '../../../services/token.service';
import config from './HeaderLayout.component.config';
import './HeaderLayout.component.styles.css';

const { Header } = Layout;
const { Title, Text } = Typography;
const HeaderLayout = ({
  users,
  searchText,
  setSearchText,
  hideSearchBar,
  ...props
}) => {
  const [state, setState] = React.useState({
    visibleModalNotification: false,
  });
  const navigate = useNavigate();
  const menuDropdown = {
    items: [
      {
        icon: <ICON_LOGOUT />,
        key: 'logout',
        label: 'Logout',
      },
    ],
    onClick: (e) => {
      if (e.key === 'logout') {
        destroyToken();
        navigate('/auth');
      }
    },
  };

  const modalNotificationTrigger = () => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.visibleModalNotification =
        !currentState.visibleModalNotification;
      return { ...currentState };
    });
  };

  return (
    <Header
      id="Header"
      style={{
        padding: props.collapsed ? '0 40px 0 110px' : '0 40px 0 300px',
      }}
    >
      {/* <Input
        size="middle"
        placeholder="Search anything..."
        allowClear
        style={{
          width: 300,
          visibility: (hideSearchBar && 'hidden') || 'visible',
        }}
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      /> */}
      <div className="action-right">
        <Badge count={1} hidden>
          <Button
            type="default"
            shape="circle"
            size="large"
            icon={<BellOutlined />}
            onClick={() => modalNotificationTrigger()}
          />
        </Badge>
        <Dropdown
          className="drowpdown-button-custom"
          menu={menuDropdown}
          placement="bottomRight"
          arrow
        >
          <div className="user-drawer">
            <Button
              type="default"
              shape="circle"
              size="large"
              icon={<ICON_PROFILE />}
            />
            <div>
              <Title level={5}>{users?.person}</Title>
              <Text>{users?.email}</Text>
            </div>
          </div>
        </Dropdown>
      </div>
      <Modal
        title={
          <div className="header-custom">
            <Title level={3}>Notification</Title>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </span>
          </div>
        }
        open={state.visibleModalNotification}
        closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
        onCancel={() => modalNotificationTrigger()}
        footer={null}
        destroyOnClose
      >
        <p>This feature on TBD</p>
      </Modal>
    </Header>
  );
};

HeaderLayout.displayName = config.displayName;
HeaderLayout.defaultProps = config.defaultProps;
HeaderLayout.propTypes = config.propTypes;

export default HeaderLayout;
