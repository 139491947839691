import { connect } from 'react-redux';
import AuthForward from './AuthForward.component';

import { fAuthForward } from '../../redux/actions/authUser.actions';
import {
  getStudentProgramList,
  getStudentCourseList,
  getCourseDetail,
  getStudentLearningProgress,
} from '../../redux/actions/student.actions';

const mapStateToProps = (state) => ({
  programList: state.student.programList,
});

const mapDispatchToProps = {
  fAuthForward,
  getStudentProgramList,
  getStudentCourseList,
  getCourseDetail,
  getStudentLearningProgress,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthForward);
