import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { ICON_BOLBEL_FULL, IMG_EMPTY } from '../../assets/svg';

import config from './ComingsoonPage.component.config';
import './ComingsoonPage.component.styles.less';

const ComingsoonPage = ({ ...props }) => (
  <div id="CommingsoonPage">
    <div className="content">
      <div className="logo-app">
        <IMG_EMPTY />
      </div>
      <div className="content-text">
        Hi learners, this feature is under maintenance and not available for
        now, we will release this feature coming soon.
      </div>
    </div>
  </div>
);

ComingsoonPage.displayName = config.displayName;
ComingsoonPage.defaultProps = config.defaultProps;
ComingsoonPage.propTypes = config.propTypes;

export default ComingsoonPage;
