import {
  DatabaseOutlined,
  FileProtectOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Divider,
  Layout,
  Menu,
  Typography,
  Popconfirm,
} from 'antd';
import { get } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Sider from 'antd/lib/layout/Sider';
import { HeaderLayout } from '../../components/StudentLayout';
import { STUDENT_ROUTE } from '../../navigations/student.route';
import { destroyToken, getToken } from '../../services/token.service';

import { ICON_BOLBEL_FULL } from '../../assets/svg';
import {
  IntroTour,
  IntroTourMobile,
  NavigationBarMobile,
} from '../../components';
import config from './Student.component.config';
import { NotificationExpiredToken } from '../../utils/general.utils';

const { Title, Text } = Typography;

const Student = ({ fConsumeToken, ...props }) => {
  const location = useLocation();
  const homeRef = useRef(null);
  const isCourse = location.pathname.includes('course');
  const isDashboard = location.pathname.includes('dashboard');
  const isUserToken = getToken();
  const navigate = useNavigate();
  const navigationMenu = (e) => navigate(e);
  const updateCollapsedMenu = (collapsed) => {
    props.updateCollapsedMenu(collapsed);
  };

  const { pathname } = window.location;

  const handleMenuClick = (e) => {
    navigate(e.key);
  };

  const handleLogout = () => {
    destroyToken();
    navigate('/auth');
  };

  const menuItemsTop = [
    {
      icon: <DatabaseOutlined />,
      key: '/student/dashboard',
      label: 'Kelas Saya',
      class: 'ant-menu-item ant-menu-item-selected kelas-saya-tour',
    },
    {
      icon: <FileProtectOutlined />,
      key: '/student/my-certificate',
      label: 'Sertifikat Saya',
      class: 'ant-menu-item sertifikat-saya-tour',
    },
  ];

  useEffect(() => {
    if (isUserToken !== null) {
      fConsumeToken();
    } else {
      destroyToken();
      NotificationExpiredToken();
    }
    if (get(isUserToken, 'uslo') !== undefined && isUserToken.uslo !== 2) {
      NotificationExpiredToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Layout>
        {!isMobile && !isCourse && (
          <Sider
            width={250}
            id="side-menu"
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <div
                className="menu-profile-tour"
                style={{
                  padding: '6px 24px',
                  textAlign: 'center',
                  height: 'auto',
                  marginTop: 24,
                }}
              >
                <div
                  onClick={() => navigate('/student/dashboard')}
                  style={{
                    cursor: 'pointer',
                    marginBottom: '3rem',
                  }}
                >
                  <ICON_BOLBEL_FULL />
                </div>
                <Avatar
                  style={{
                    backgroundColor: '#C9264B',
                    verticalAlign: 'middle',
                  }}
                  size="large"
                >
                  {props?.users?.person && props.users.person[0]}
                </Avatar>
                <div
                  style={{
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <Title style={{ margin: 'unset' }} level={4}>
                    {props.users?.person}
                  </Title>
                  <Text style={{ fontSize: 12 }}>{props.users?.email}</Text>
                </div>
                <Button
                  type="ghost"
                  icon={<UserOutlined />}
                  onClick={() => navigate('/student/account')}
                >
                  Profile
                </Button>
                <Divider />
              </div>
              <div>
                <p
                  style={{
                    paddingLeft: 24,
                    fontWeight: 700,
                    fontSize: 12,
                    color: '#b1b1b1',
                    height: 'auto',
                    letterSpacing: 2,
                  }}
                >
                  MENU DASHBOARD
                </p>
                <Menu
                  mode="inline"
                  theme="light"
                  defaultSelectedKeys={['/student/dashboard']}
                  selectedKeys={[`${pathname}`]}
                  items={menuItemsTop}
                  onClick={handleMenuClick}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                borderTop: '1px solid rgba(0, 0, 0, 0.06)',
                marginLeft: 16,
                marginRight: 16,
              }}
            >
              <div
                className="help-button-tour"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                  paddingTop: 24,
                }}
              >
                <Button
                  icon={<QuestionCircleOutlined />}
                  onClick={() =>
                    window.open('https://wa.me/6281944365758', '_blank')
                  }
                >
                  Bantuan
                </Button>
                <Popconfirm
                  placement="rightBottom"
                  title={
                    <div>
                      Apakah anda yakin ingin keluar <br />
                      dari LMS - Boleh Belajar?
                    </div>
                  }
                  okText="Ya"
                  cancelText="Tidak"
                  onConfirm={handleLogout}
                >
                  <Button icon={<LogoutOutlined />}>Keluar</Button>
                </Popconfirm>
              </div>
            </div>
          </Sider>
        )}
        <Layout>
          {!isCourse && isUserToken !== null && (
            <HeaderLayout
              users={props.users}
              collapsed={props.common.collapsedMenu}
              routeBase={STUDENT_ROUTE}
            />
          )}
        </Layout>
      </Layout>

      <Routes>
        {STUDENT_ROUTE.map((items) => (
          <Route key={items.key} {...items} />
        ))}
        <Route path="/" element={<Navigate to="/student/dashboard" />} />
        <Route path="*" element={<Navigate to="/student/dashboard" />} />
      </Routes>
      {isMobile && <NavigationBarMobile routeMenu={STUDENT_ROUTE} />}
      {isMobile ? <IntroTourMobile /> : <IntroTour />}
    </div>
  );
};

Student.displayName = config.displayName;
Student.defaultProps = config.defaultProps;
Student.propTypes = config.propTypes;

export default Student;
