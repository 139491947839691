import React from 'react';
import { List, Typography } from 'antd';
import config from './ContentActivity.component.config';

const { Title, Text } = Typography;

const ContentActivity = ({ selectionContent, onNavigateCourse }) => {
  const childList = selectionContent?.entity_list;
  return (
    <div className="content-activity">
      <Title level={4}>
        {selectionContent?.keys}. {selectionContent?.activity_name}
      </Title>
      <List
        itemLayout="horizontal"
        dataSource={childList}
        renderItem={(items, index) => (
          <List.Item
            className="link-items"
            onClick={() => onNavigateCourse(items.id)}
          >
            <Text>
              {items.keys}. {items.entity_name}
            </Text>
          </List.Item>
        )}
      />
    </div>
  );
};

ContentActivity.displayName = config.displayName;
ContentActivity.defaultProps = config.defaultProps;
ContentActivity.propTypes = config.propTypes;

export default ContentActivity;
