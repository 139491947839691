import React from 'react';
import { Button, Image, Typography, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, ReadOutlined } from '@ant-design/icons';

import { IMG_EMPTY } from '../../../../assets/svg';
import {
  DataList,
  EmptyContent,
  DataTableDragable,
} from '../../../../components';

import config from './ListActivityPrograms.component.config';
import './ListActivityPrograms.component.styles.css';

const { Title, Text } = Typography;

const ListActivityPrograms = ({
  actionNavigation,
  deleteActivity,
  createActivity,
  updateActivityPosition,
  programData,
  sourceData,
  loadingData,
  filteredDataByTag,
  ...props
}) => {
  const actionExtraItem = (obj) => {
    actionNavigation({
      action: 'detailActivityEntity',
      activityID: sourceData?.find((items) => items.id === obj.id),
    });
  };
  const actionActivity = (obj) => (
    <div>
      <Tooltip title="See Detail Activity">
        <Button
          type="text"
          size="large"
          shape="circle"
          icon={<ReadOutlined />}
          onClick={() => {
            actionNavigation({
              action: 'detailActivityEntity',
              activityID: sourceData?.find((items) => items.id === obj.id),
            });
          }}
        />
      </Tooltip>
      <Popconfirm
        title={
          <p>
            Are you sure deleted this activity? <br />
            if you delete an activity, <br />
            the related entity will be deleted too
          </p>
        }
        onConfirm={() => {
          deleteActivity(obj.id);
        }}
        placement="left"
      >
        <Tooltip title="Remove this Activity">
          <Button
            size="large"
            shape="circle"
            icon={<DeleteOutlined />}
            type="text"
            style={{ marginLeft: 10 }}
          />
        </Tooltip>
      </Popconfirm>
    </div>
  );

  return (
    <div key="ListActivityPrograms">
      {sourceData ? (
        <DataTableDragable
          contentType="activity"
          sourceData={sourceData}
          isLoading={loadingData}
          needUpdatePosition
          actionUpdatePosition={updateActivityPosition}
          gotoDetail={actionExtraItem}
          extraAction={[actionActivity]}
          locked={Number(programData.is_active) === 1}
        />
      ) : (
        <EmptyContent
          title="Kamu Belum Membuat Activity"
          description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet et
              veritatis, iste rerum illum, quod accusamus perferendis reprehenderit
              delectus quia qui saepe neque quaerat, earum ipsum libero. Officiis,
              ad suscipit!"
          onButtonPressed={createActivity}
          buttonText="Add Activity"
        />
      )}
    </div>
  );
};

ListActivityPrograms.displayName = config.displayName;
ListActivityPrograms.defaultProps = config.defaultProps;
ListActivityPrograms.propTypes = config.propTypes;

export default ListActivityPrograms;
