import PropTypes from 'prop-types';

const displayName = 'DataTable';
const propTypes = {
  title: PropTypes.string,
};
const defaultProps = {
  withPagination: false,
};

export default { displayName, propTypes, defaultProps };
