import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import withClearCache from './cache';

import App from './containers';
import './index.css';

const pckJson = require('../package.json');

Sentry.init({
  dsn: 'https://4bd15f0ba43d2182b5a163215623fd1a@o4505698409644032.ingest.sentry.io/4506578606948352',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://staging-lms.bolehbelajar.com',
        'https://preprod-lms.bolehbelajar.com',
        'https://lms.bolehbelajar.com',
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.ENV,
  release: `lms-bolbel@${pckJson.version}`,
});

const ClearCacheComponent = withClearCache(App);
ReactDOM.render(
  <React.StrictMode>
    <ClearCacheComponent />
  </React.StrictMode>,
  document.getElementById('root'),
);
