/* eslint-disable no-useless-escape */
import PropTypes from 'prop-types';

const displayName = 'MainCourse';
const propTypes = {};
const defaultProps = {
  quizType: [3, 7],
  handleScrollContentOnReloadContent: () => {
    setTimeout(() => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, 100);
  },
  // generateContentList: (programSelection) => {
  //   const dataSourceProgram = programSelection?.activity;
  //   const dataSourceActivityEntity = programSelection?.activityEntity;
  //   const parrentTags = [];
  //   // generate parrent content
  //   for (let index = 0; index < dataSourceProgram.length; ) {
  //     const program = dataSourceProgram[index];

  //     const activityParrent = program?.activity_tags?.trim();
  //     const findTags = parrentTags.find(
  //       (parrent) => parrent?.parrent_name === activityParrent,
  //     );
  //     // console.log(activityParrent);
  //     // console.log(findTags);

  //     if (findTags === undefined)
  //       parrentTags.push({
  //         id: `${index}`,
  //         keys: null,
  //         // activityID: program?.id,
  //         parrent_name: activityParrent, // activity tags
  //         activity_name: program?.activity_name,
  //         description: program?.description,
  //         activity_list: [],
  //       });
  //     index += 1;
  //   }

  //   // generate activity entity list
  //   for (let indexActivity = 0; indexActivity < dataSourceProgram.length; ) {
  //     const findActivityTags = parrentTags.find(
  //       (parrent) =>
  //         parrent?.parrent_name ===
  //         dataSourceProgram[indexActivity]?.activity_tags?.trim(),
  //     );
  //     if (findActivityTags !== undefined) {
  //       const ownActivityID = dataSourceProgram[indexActivity]?.id;
  //       const ownActivityName = dataSourceProgram[indexActivity]?.activity_name;
  //       const ownActivityDescription =
  //         dataSourceProgram[indexActivity]?.description;
  //       const ownActivityTags = dataSourceProgram[indexActivity]?.activity_tags;
  //       const ownActivityCreated = dataSourceProgram[indexActivity]?.created_at;
  //       const ownActivityActive = dataSourceProgram[indexActivity]?.is_active;

  //       // find entity list by activity id
  //       // const childActivity = dataSourceProgram[indexActivity]?.entity_list;
  //       const tempEntityContent = [];
  //       const activityEntityData = dataSourceActivityEntity.find(
  //         (items) => items?.activityID === ownActivityID,
  //       );
  //       if (activityEntityData?.list?.length > 0) {
  //         const arrEntity = activityEntityData.list;
  //         for (let index = 0; index < arrEntity.length; ) {
  //           const entityData = arrEntity[index];
  //           const idChild = `${findActivityTags?.id}-${ownActivityID}-${entityData.id}`;
  //           tempEntityContent.push({
  //             ...entityData,
  //             entityID: entityData.id,
  //             id: idChild,
  //             keys: null,
  //           });
  //           index += 1;
  //         }
  //       }

  //       const entityContent = tempEntityContent.sort((a, b) =>
  //         a.position > b.position ? 1 : -1,
  //       );

  //       findActivityTags?.activity_list.push({
  //         id: `${findActivityTags?.id}-${ownActivityID}`,
  //         keys: null,
  //         activityID: ownActivityID,
  //         activity_name: ownActivityName,
  //         description: ownActivityDescription,
  //         tags: ownActivityTags,
  //         created_at: ownActivityCreated,
  //         is_active: ownActivityActive,
  //         entity_list: entityContent,
  //       });
  //     }
  //     indexActivity += 1;
  //   }
  //   // console.log(parrentTags);
  //   return Promise.resolve(parrentTags);
  // },
};

export default { displayName, propTypes, defaultProps };
