import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Input, notification, Typography } from 'antd';

import {
  BaseLayout,
  DataTable,
  EmptyContent,
  MotionPage,
} from '../../../components';
import ModalCreate from './ModalCreate/ModalCreate.component';

import config from './AdminLessons.component.config';
import './AdminLessons.component.styles.css';

const { Title, Text } = Typography;

const AdminLessons = ({
  clearSearchText,
  lessonsCreate,
  lessonsList,
  lessonListData,
  setSearchText,
  searchText,
  setParrentLesson,
  ...props
}) => {
  const [apiCallStatus, setApiCallStatus] = React.useState({
    lessonsList: false,
  });
  const [modalCreateVisibility, setModalCreateVisibility] =
    React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const numOfPage = JSON.parse(localStorage.getItem('LESSONS_CURRENT_PAGE'));
  const [current, setCurrent] = React.useState(numOfPage);
  const currentMenu = location.pathname;
  const accesedMenu = localStorage.getItem('CURRENT_MENU');

  React.useEffect(() => {
    localStorage.setItem('CURRENT_MENU', currentMenu);
    if (accesedMenu !== currentMenu)
      localStorage.setItem('LESSONS_CURRENT_PAGE', JSON.stringify(1));
    setCurrent(JSON.parse(localStorage.getItem('LESSONS_CURRENT_PAGE')));
  }, [currentMenu, accesedMenu]);

  const setCurrentPage = (index) => {
    setCurrent(index);
  };

  // useEffect for lessonsList
  React.useEffect(() => {
    if (apiCallStatus.lessonsList === false) {
      lessonsList()
        .catch(() =>
          notification.error({
            message: `System Error`,
            description: `Failed to get lessons list. Please try again later.`,
          }),
        )
        .finally(() =>
          setApiCallStatus({ ...apiCallStatus, lessonsList: true }),
        );
    }

    return () => {
      clearSearchText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallStatus.lessonsList]);

  // const actionDetail = (idx) => (
  //   <Tooltip title="View Detail" placement="left">
  //     <Button
  //       type="text"
  //       shape="circle"
  //       onClick={() => {
  //         const dataArr =
  //           (Array.isArray(lessonListData?.data) && lessonListData?.data) || [];
  //         const foundData = dataArr.find((datum) => datum.id === idx);
  //         setParrentLesson(foundData).then(() =>
  //           navigate(`${window.location.pathname}/detail?id=${idx}`),
  //         );
  //       }}
  //       icon={<FileSearchOutlined />}
  //     />
  //   </Tooltip>
  // );
  const actionExtraItem = (record) => {
    // const dataArr =
    //   (Array.isArray(lessonListData?.data) && lessonListData?.data) || [];
    // const foundData = dataArr.find((datum) => datum.id === idx);
    setParrentLesson(record).then(() =>
      navigate(`${window.location.pathname}/detail?id=${record.id}`),
    );
  };

  // CRUD
  const handleCreateCase = (payload) => {
    lessonsCreate(payload)
      .then(() => {
        setModalCreateVisibility(false);
        setApiCallStatus({ ...apiCallStatus, lessonsList: false });
      })
      .catch((resp) => console.log(resp));
  };

  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout
        // onMouseEnter={() => console.log(props)}
        title="Lessons"
        action={[
          <Input
            size="middle"
            placeholder="Search Lessons"
            allowClear
            style={{
              width: 300,
            }}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />,
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalCreateVisibility(true)}
          >
            Create Lesson
          </Button>,
          // <Select defaultValue="lucy" allowClear>
          //   <Select.Option key="lucy" value="lucy">
          //     Filter by Recently
          //   </Select.Option>
          // </Select>,
        ]}
        collapsedMenu={props?.common?.collapsedMenu}
      >
        <div id="AdminLessons">
          <Card>
            {Array.isArray(lessonListData?.data) ? (
              // <DataList
              //   loading={lessonListData?.isLoading}
              //   data={lessonListData?.data?.filter(
              //     (item) =>
              //       item?.lesson_name
              //         ?.toLowerCase()
              //         ?.includes(searchText?.toLowerCase()) ||
              //       item?.description
              //         ?.toLowerCase()
              //         ?.includes(searchText?.toLowerCase()),
              //   )}
              //   withPagination={5}
              //   disablePagination
              //   haveAction
              //   actionDetail={actionDetail}
              //   actionExtraItem={actionExtraItem}
              // />
              <DataTable
                loading={lessonListData?.isLoading}
                columns={config.defaultProps.headColumn}
                dataSource={lessonListData?.data?.filter((item) =>
                  item?.lesson_name
                    ?.toLowerCase()
                    ?.includes(searchText?.toLowerCase()),
                )}
                // haveAction={[actionDetail]}
                clickOnRow={actionExtraItem}
                withPagination={12}
                setCurrentPage={setCurrentPage}
                currentPage={current}
              />
            ) : (
              <EmptyContent
                title="Kamu Belum Membuat Lesson"
                description="Klik tombol dibawah untuk membuat lesson!"
                onButtonPressed={() => setModalCreateVisibility(true)}
                buttonText="Create Lesson"
              />
            )}
          </Card>
          <ModalCreate
            actionCreate={handleCreateCase}
            showModal={modalCreateVisibility}
            closeModal={() => setModalCreateVisibility(false)}
          />
        </div>
      </BaseLayout>
    </MotionPage>
  );
};

AdminLessons.displayName = config.displayName;
AdminLessons.defaultProps = config.defaultProps;
AdminLessons.propTypes = config.propTypes;

export default AdminLessons;
