import {
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LeftCircleOutlined,
  MenuOutlined,
  PlusOutlined,
  SaveFilled,
  ShareAltOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  Upload,
} from 'antd';

import { get } from 'lodash';
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IMG_EMPTY } from '../../../assets/svg';
import { BaseLayout, MotionPage, NumberingDragable } from '../../../components';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../services/aws.service';
import { generateNotification } from '../../../utils/general.utils';
import { getParamURL } from '../../../utils/parse';

import config from './DetailQuiz.component.config';
import './DetailQuiz.component.styles.css';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const DetailQuiz = ({
  quizParrent,
  quizSuggestion,
  updateQuiz,
  createQuizDetail,
  getListQuizDetail,
  updatePositionQuiz,
  listSuggestQuestion,
  listSuggestAnswer,
  ...props
}) => {
  const navigate = useNavigate();
  const actionBack = () =>
    navigate(`${window.location.pathname.replace('/detail', '')}`);
  const findRef = (index) => stateForm.items_question[index];

  const [quizTM, setQuizTM] = React.useState(quizParrent.parrent);
  const quizID = getParamURL('id');
  const isRefEditQuizFirst = React.useRef();
  const isRefEditQuiz = React.useRef();
  const [state, setState] = React.useState({
    firstLoad: true,
    modalEdit: false,
    modalBack: false,
    modalEditQuestion: false,
    loadingUploadImage: false,
    previewVisible: false,
    previewImage: null,
    assignScoreAutomatically: false,
    reordering: false,
  });
  const [stateForm, setStateForm] = React.useState({
    onProgressItems: false,
    onPreviousItems: [],

    onProgressItemAnswer: false,
    onPreviousItemAnswer: [],

    items_question: [],
    number_list: [],
  });

  const [selectedQuestionNumber, setSelectedQuestionNumber] = React.useState({
    current: null,
    temp: null,
  });

  const getQuizDetail = async () => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      currentState.items_question = [];
      currentState.onProgressItemAnswer = false;
      return { ...currentState };
    });
    await getListQuizDetail(quizID).then(async (response) => {
      setTimeout(() => {
        const whereData = response;

        if (whereData.length > 0) {
          const tempListQuestion = [];
          for (let index = 0; index < whereData.length; ) {
            const imgQuestion = [];
            if (
              whereData[index].images !== '' &&
              whereData[index].images !== null &&
              whereData[index].images !== undefined
            ) {
              const imgSource = whereData[index].images.split(', ');
              imgSource.forEach((dt) => {
                imgQuestion.push({
                  uid: dt,
                  url: dt,
                  percent: 100,
                  status: 'done',
                });
              });
            }
            tempListQuestion.push({
              ref: React.createRef(),
              image_question: imgQuestion,
              ...whereData[index],
            });
            index += 1;
          }
          setStateForm((prevState) => {
            const currentState = { ...prevState };
            currentState.items_question = tempListQuestion;
            currentState.onProgressItemAnswer =
              !currentState.onProgressItemAnswer;
            currentState.onProgressItems = true;
            return { ...currentState };
          });
        } else {
          setStateForm((prevState) => {
            const currentState = { ...prevState };
            currentState.onProgressItemAnswer =
              !currentState.onProgressItemAnswer;
            currentState.onProgressItems = true;
            currentState.items_question = [
              {
                ref: React.createRef(),
                question_id: 0,
                question: null,
                quiz_type: 1,
                score_weight: 0,
                word_limit: null,
                word_filter: null,
                image_question: [],
                list_answer: [],
                position: currentState.items_question.length + 1,
              },
            ];
            return { ...currentState };
          });
        }

        setSelectedQuestionNumber((prevSelectedNumber) => {
          const question = { ...prevSelectedNumber };
          if (selectedQuestionNumber.temp === null) {
            question.current = 0;
          } else {
            question.current = question.temp;
          }
          return { ...question };
        });
      });
    }, 0);
  };
  const mountingFirstData = async () => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.firstLoad = !currentState.firstLoad;
      return { ...currentState };
    });
    getQuizDetail().then(() => {
      isRefEditQuizFirst.current.setFieldsValue({
        quiz_name: quizTM.quiz_name,
        description: quizTM.description,
      });
      isRefEditQuiz.current.setFieldsValue({
        duration: quizTM.duration,
        min_score: quizTM.min_score,
        retake_limit: quizTM.retake_limit,
        rasio_mc: quizTM.rasio_mc === null ? 50 : quizTM.rasio_mc * 100,
        rasio_essay:
          quizTM.rasio_essay === null ? 50 : quizTM.rasio_essay * 100,
      });
    });
  };

  useEffect(() => {
    if (state.firstLoad) mountingFirstData();
  });

  // useCallback(() => {
  //   if (state.firstLoad) mountingFirstData();
  // }, [state.firstLoad, mountingFirstData]);

  // Tes create question
  const checkForInvalidQuestion = (arrOfQuestions) => {
    const errors = [];
    for (let i = 0; i < arrOfQuestions.length; i += 1) {
      const questionObj = arrOfQuestions[i];
      // if (state.assignScoreAutomatically === false && questionObj?.score_weight === 0) {
      //   errors.push(`- Question number ${i+1} has 0 score/weight`);
      // };
      if (questionObj.quiz_type === 3) {
        if (!questionObj?.question === true) {
          errors.push(`- Question number ${i + 1} is still empty`);
        }
      } else {
        if (!questionObj?.question === true) {
          errors.push(`- Question number ${i + 1} is still empty`);
        }
        if (questionObj?.list_answer?.length === 0) {
          errors.push(`- Question number ${i + 1} has no options`);
        }
        if (questionObj?.list_answer?.length === 1) {
          errors.push(`- Question number ${i + 1} only has one answer`);
          if (
            !questionObj?.list_answer
              ?.map((el) => el?.answer)
              ?.reduce((tot, el) => tot && el) === true
          ) {
            errors.push(
              `- Question number ${i + 1} has answer(s) with empty field`,
            );
          }
        }
        if (questionObj?.list_answer?.length > 1) {
          if (questionObj.quiz_type === 2) {
            // aditional for type multiple
            let countAnswer = 0;
            questionObj?.list_answer?.forEach((el) => {
              if (el?.is_correct === 1 || el?.is_correct === true)
                countAnswer += 1;
            });
            if (countAnswer === 1) {
              errors.push(`
                - Question number ${i + 1} need more than one correct answer
              `);
            }
          }
          if (
            !questionObj?.list_answer
              ?.map((el) => el?.is_correct)
              ?.reduce((tot, el) => tot || el) === true
          ) {
            errors.push(`- Question number ${i + 1} has no correct answer`);
          }
          if (
            !questionObj?.list_answer
              ?.map((el) => el?.answer)
              ?.reduce((tot, el) => tot && el) === true
          ) {
            errors.push(
              `- Question number ${i + 1} has answer(s) with empty field`,
            );
          }
        }
      }
    }
    return errors;
  };

  const createQuestion = () => {
    stateForm.items_question.forEach((questions, indexQuestion) => {
      let tempImages = [];
      if (questions?.image_question?.length > 0) {
        questions.image_question.forEach((images) => {
          tempImages.push(images.url);
        });
        tempImages = tempImages.join(', ');
      } else {
        tempImages = '';
      }
      stateForm.items_question[indexQuestion].images = tempImages;
    });

    const dataQuestion = {
      quiz_id: quizID,
      items: stateForm.items_question,
    };
    const isInValid = checkForInvalidQuestion(stateForm.items_question);

    if (isInValid.length === 0) {
      createQuizDetail(dataQuestion)
        .then(() => {
          getQuizDetail();
          setTimeout(() => {
            window.scrollTo(0, 5000 * 10);
          }, 500);
        })
        .catch(() =>
          notification.error({
            message: `System error`,
            description: `Unable to create new quiz. Please try again later.`,
          }),
        );
    } else {
      notification.error({
        message: `Invalid Quiz Submission`,
        description: isInValid.map((val) => <p style={{ margin: 0 }}>{val}</p>),
      });
    }
  };
  console.log(selectedQuestionNumber);
  const updatePositionQuizDetail = (objQuiz) => {
    const questionSelected =
      quizParrent.parrent.items[selectedQuestionNumber.current].question_id;
    const currentQuestion = objQuiz.findIndex(
      (itm) => itm.question_id === questionSelected,
    );
    selectedQuestionNumber.temp = currentQuestion;
    setSelectedQuestionNumber((prevSelectedNumber) => {
      const question = { ...prevSelectedNumber };
      question.current = null;
      question.temp = currentQuestion;
      return { ...question };
    });

    updatePositionQuiz({
      quiz_id: quizParrent.parrent.quiz_id,
      data: objQuiz,
    })
      .then(() => {
        getQuizDetail();
      })
      .catch(() =>
        notification.error({
          message: `System error`,
          description: `Unable to create new quiz. Please try again later.`,
        }),
      );
  };

  const toggleModalEdit = () => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.modalEdit = !currentState.modalEdit;
      return { ...currentState };
    });
  };

  const actionUpdateQuiz = () => {
    const fields = isRefEditQuizFirst.current.getFieldsValue();
    const fields2 = isRefEditQuiz.current.getFieldsValue();
    const rasiomc = fields2.rasio_mc !== '' ? parseFloat(fields2.rasio_mc) : 0;
    const rasioes =
      fields2.rasio_essay !== '' ? parseFloat(fields2.rasio_essay) : 0;
    const totalrasio = rasiomc + rasioes;
    const updateData = {
      id: quizID,
      ...fields,
      ...fields2,
      rasio_mc: rasiomc / 100,
      rasio_essay: rasioes / 100,
    };

    if (totalrasio === 100) {
      updateQuiz(updateData).then(() => {
        setQuizTM((prevQuiz) => {
          const currQuiz = { ...prevQuiz };
          return { ...currQuiz, ...updateData };
        });
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.modalEdit = !currentState.modalEdit;
          return { ...currentState };
        });
      });
    } else {
      notification.error({
        message: `Update Failure!`,
        description: `Total score ratio value need to be 100, your score configuration is ${totalrasio}`,
      });
    }
  };
  const modalBackQuiz = () => (
    <Modal
      title={
        <div className="header-custom">
          <Title level={3}>Attention!</Title>
          {state.reordering ? (
            <span>
              Please ensure again you has saved the question and answer
              reordering!
            </span>
          ) : (
            <span>
              Please ensure again you has been save the question and answer, are
              you still want back to list quiz?
            </span>
          )}
        </div>
      }
      centered
      open={state.modalBack}
      closeIcon={false}
      footer={[
        <Button
          type="primary"
          onClick={() => actionBack()}
          hidden={state.reordering}
        >
          Yes, sure!
        </Button>,
        <Button
          type={state.reordering ? 'primary' : 'default'}
          onClick={() => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.modalBack = !currentState.modalBack;
              return { ...currentState };
            });
          }}
        >
          {state.reordering ? 'Yes, Sure!' : 'No, make sure one again!'}
        </Button>,
      ]}
      destroyOnClose
    ></Modal>
  );
  // action function for aswer needed
  const addAnswerQuestion = (indexQuiz) => {
    const listQuestion = stateForm.items_question;
    const listAnswer = listQuestion[indexQuiz].list_answer;
    // const nextAnswerId = listAnswer.length + 1;
    listAnswer.push({
      answer_id: 0,
      answer: null,
      is_correct: false,
    });
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const data = {
        items_question: listQuestion,
      };
      return {
        ...data,
        ...currentState,
      };
    });
  };

  const addMoreAnswer = (indexQuiz) => (
    <Form
      key={indexQuiz}
      ref={findRef(indexQuiz).ref}
      onFinish={() => addAnswerQuestion(indexQuiz)}
    >
      <Button size="middle" type="primary" htmlType="submit">
        Add Answer
      </Button>
    </Form>
  );
  const deleteAnswerQuestion = (indexAnswer, indexQuiz) => {
    const items = stateForm?.items_question;
    const question = items[indexQuiz].list_answer;
    const replacing = question.filter(
      (answer, index) => index !== indexAnswer && answer,
    );
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      currentState.onProgressItemAnswer = !currentState.onProgressItemAnswer;
      currentState.items_question[indexQuiz].previous_answer =
        currentState.items_question[indexQuiz].list_answer;
      return { ...currentState };
    });
    setTimeout(() => {
      setStateForm((prevState) => {
        const currentState = { ...prevState };
        currentState.onProgressItemAnswer = !currentState.onProgressItemAnswer;
        currentState.items_question[indexQuiz].list_answer = replacing;
        return { ...currentState };
      });
    }, 100);
  };
  // type of answer type
  // single choice
  const changeValueSingleChoice = (indexAnswer, indexQuiz) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const itemQuestion = currentState.items_question;
      const question = itemQuestion[indexQuiz];
      for (let idx = 0; idx < question?.list_answer?.length; ) {
        question.list_answer[idx].is_correct = false;
        idx += 1;
      }
      const answer = question?.list_answer[indexAnswer];
      answer.is_correct = true;
      return { ...currentState };
    });
  };

  const answerSingleChoice = (itemAnswer, indexAnswer, indexQuiz) => (
    <div
      className="answer-item single-choice"
      key={`${indexQuiz}-${indexAnswer}`}
    >
      <div className="item-answer">
        <Text>{`${config.defaultProps.alphabet[indexAnswer]}. `}</Text>
        {/* <AutoComplete
          style={{ width: '90%' }}
          options={quizSuggestion?.answer}
          onSelect={(val) => {
            const dummy = quizSuggestion?.answer;
            const findData = dummy.find((items) => items.value === val);
            setAnswerQuestion(
              indexAnswer,
              indexQuiz,
              findData.value,
              findData.id,
            );
          }}
          onChange={(val) => {
            listSuggestAnswer(val);
          }}
          defaultValue={get(itemAnswer, 'answer')}
        > */}
        <Input
          size="large"
          style={{
            textAlign: 'left',
          }}
          placeholder="input answer..."
          defaultValue={get(itemAnswer, 'answer')}
          onKeyUp={(e) =>
            setAnswerQuestion(indexAnswer, indexQuiz, e.target.value)
          }
        />
        {/* </AutoComplete> */}
      </div>
      <Radio
        value={indexAnswer}
        checked={get(itemAnswer, 'is_correct')}
        onClick={() => changeValueSingleChoice(indexAnswer, indexQuiz)}
      >
        Correct
      </Radio>
      <Button
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        onClick={() => deleteAnswerQuestion(indexAnswer, indexQuiz)}
      />
    </div>
  );
  // multiple choice
  const changeValueMultipleChoice = (indexAnswer, indexQuiz) => {
    const listQuestion = { ...stateForm.items_question };
    const question = listQuestion[indexQuiz];
    const listAnswer = question?.list_answer;
    const answer = listAnswer[indexAnswer];
    answer.is_correct = !answer?.is_correct;
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const data = {
        items_question: listQuestion,
      };
      return {
        ...data,
        ...currentState,
      };
    });
  };
  const answerMultipleChoice = (itemAnswer, indexAnswer, indexQuiz) => (
    <div
      className="answer-item single-choice"
      key={`${indexQuiz}-${indexAnswer}`}
    >
      <div className="item-answer">
        <Text>{`${config.defaultProps.alphabet[indexAnswer]}. `}</Text>
        {/* <AutoComplete
          style={{ width: '90%' }}
          options={quizSuggestion?.answer}
          onSelect={(val) => {
            const dummy = quizSuggestion?.answer;
            const findData = dummy.find((items) => items.value === val);
            setAnswerQuestion(
              indexAnswer,
              indexQuiz,
              findData.value,
              findData.id,
            );
          }}
          onChange={(val) => {
            listSuggestAnswer(val);
          }}
          defaultValue={get(itemAnswer, 'answer')}
        > */}
        <Input
          size="large"
          style={{
            textAlign: 'left',
          }}
          placeholder="input answer..."
          defaultValue={get(itemAnswer, 'answer')}
          onKeyUp={(e) =>
            setAnswerQuestion(indexAnswer, indexQuiz, e.target.value)
          }
        />
        {/* </AutoComplete> */}
      </div>
      <Checkbox
        value={indexAnswer}
        checked={get(itemAnswer, 'is_correct')}
        onClick={() => changeValueMultipleChoice(indexAnswer, indexQuiz)}
      >
        Correct
      </Checkbox>
      <Button
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        onClick={() => deleteAnswerQuestion(indexAnswer, indexQuiz)}
      />
    </div>
  );

  // Pairing answer
  const addAnswerQuestionPairing = (indexQuiz) => {
    const refCurrent = findRef(indexQuiz).ref;
    const fields = refCurrent.current.getFieldsValue();
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const quiz = currentState.items_question[indexQuiz];
      quiz.answer = [
        ...quiz.answer,
        {
          answering: {
            statement: get(fields, `statement-quiz${indexQuiz}`),
            pairing: get(fields, `pairing-quiz${indexQuiz}`),
          },
          isCorrect: false,
          typeAnswer: 'pairing',
        },
      ];
      const inputName = `statement-quiz${indexQuiz}`;
      const inputName2 = `pairing-quiz${indexQuiz}`;
      refCurrent.current.setFieldsValue({
        [inputName]: '',
        [inputName2]: '',
      });
      return { ...currentState };
    });
  };

  // Essay answer
  const addAnswerQuestionEssay = (indexQuiz, type, value) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const quiz = currentState.items_question[indexQuiz];
      switch (type) {
        case 'word_limit':
          quiz.word_limit = Number(value);
          break;
        case 'word_filter':
          quiz.word_filter = value;
          break;
        default:
          break;
      }
      return { ...currentState };
    });
  };

  const formPairingAnswer = (indexQuiz) => (
    <Form
      key={indexQuiz}
      ref={findRef(indexQuiz).ref}
      onFinish={() => addAnswerQuestionPairing(indexQuiz)}
      layout="inline"
    >
      <Row gutter={24} style={{ width: '100%' }}>
        <div className="pairing-answer">
          <Col flex="auto">
            <Form.Item
              label="Set Word Limit"
              name={`statement-quiz${indexQuiz}`}
            >
              <TextArea
                rows={1}
                size="large"
                style={{
                  textAlign: 'left',
                  borderRadius: '15px',
                  width: '50%',
                }}
                placeholder="input answer..."
              />
            </Form.Item>
            <Form.Item
              label="Limit these words"
              name={`pairing-quiz${indexQuiz}`}
            >
              <span>
                exclude these words to be input by learners, separate keywords
                using comma (,), and leave it blank if there is no limitation
              </span>
              <TextArea
                rows={2}
                size="large"
                style={{
                  textAlign: 'left',
                  borderRadius: '15px',
                  height: '50px',
                }}
                placeholder="input answer..."
              />
            </Form.Item>
          </Col>
          {/* <Col flex="auto">
          </Col> */}
        </div>
        {/* <Col md={2}>
          <Button size="large" type="primary" htmlType="submit">
            Submit
          </Button>
        </Col> */}
      </Row>
    </Form>
  );

  const formEssayAnswer = (indexQuiz) => (
    <Form key={indexQuiz} ref={findRef(indexQuiz).ref} layout="horizontal">
      <Form.Item
        label={
          <div style={{ width: 400, height: 60, textAlign: 'left' }}>
            <Title level={5} style={{ margin: 'unset' }}>
              Set Word Limit
            </Title>
            <Text style={{ fontSize: 12 }}>
              set 0 for unlimited word limit.
            </Text>
          </div>
        }
        name={`statement-quiz${indexQuiz}`}
      >
        <Input
          rows={1}
          type="number"
          className="question-input"
          placeholder="input here..."
          style={{ borderRadius: 10 }}
          onKeyUp={(e) =>
            addAnswerQuestionEssay(indexQuiz, 'word_limit', e.target.value)
          }
          defaultValue={get(stateForm.items_question[indexQuiz], 'word_limit')}
        />
      </Form.Item>
      <Form.Item
        label={
          <div style={{ width: 400, height: 100, textAlign: 'left' }}>
            <Title level={5} style={{ margin: 'unset' }}>
              Limit these words
            </Title>
            <Text style={{ fontSize: 12, whiteSpace: 'initial' }}>
              exclude these words to be input by learners. separate keywords
              using comma “,”, and leave it blank if there is no limitation.
            </Text>
          </div>
        }
        name={`pairing-quiz${indexQuiz}`}
      >
        <TextArea
          rows={2}
          className="question-input"
          placeholder="insert here..."
          style={{ borderRadius: 10 }}
          onKeyUp={(e) =>
            addAnswerQuestionEssay(indexQuiz, 'word_filter', e.target.value)
          }
          defaultValue={get(stateForm.items_question[indexQuiz], 'word_filter')}
        />
      </Form.Item>
    </Form>
  );
  const answerPairing = (itemAnswer, indexAnswer, indexQuiz) => (
    <div
      className="answer-item single-choice"
      key={`${indexQuiz}-${indexAnswer}`}
    >
      <Text>{get(itemAnswer, 'answering.statement')}</Text>
      <Text>{get(itemAnswer, 'answering.pairing')}</Text>
    </div>
  );

  const actionUploadImagesQuestions = async (indexQuestion, upload) => {
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/quiz',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setStateForm((prevState) => {
              const currentState = { ...prevState };
              const itemQuestion = currentState.items_question[indexQuestion];
              const imgUpload = itemQuestion.image_question.find(
                (items) => items.uid === tempData.uid,
              );
              imgUpload.percent = 100;
              imgUpload.status = 'done';
              imgUpload.url = objectURL;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
    // await generateS3UploadUrl('assets/quiz', upload?.file).then(async (url) => {
    //   await executeS3UploadUrl(url, upload?.file).then((imageUrl) => {
    //     setStateForm((prevState) => {
    //       const currentState = { ...prevState };
    //       const itemQuestion = currentState.items_question[indexQuestion];
    //       const imgUpload = itemQuestion.image_question.find(
    //         (items) => items.uid === tempData.uid,
    //       );
    //       imgUpload.percent = 100;
    //       imgUpload.status = 'done';
    //       imgUpload.url = imageUrl;
    //       return { ...currentState };
    //     });
    //   });
    // });
  };
  const handleDeleteUploadFile = (indexQuestion, { ...data }) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const itemQuestion = currentState.items_question[indexQuestion];
      itemQuestion.image_question = data.fileList;
      return { ...currentState };
    });
  };
  const handlePreviewsUploaded = (indexQuestion, { ...data }) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.previewImage = data?.url;
      currentState.previewVisible = !currentState.previewVisible;
      return { ...currentState };
    });
  };
  const handleClosePreviews = () => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.previewImage = null;
      currentState.previewVisible = !currentState.previewVisible;
      return { ...currentState };
    });
  };
  // action func for question needed
  const setQuestion = (indexQuiz, value, idValue) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const quiz = currentState.items_question[indexQuiz];
      quiz.question = value;
      quiz.question_id = 0;
      if (idValue) quiz.question_id = idValue;
      return { ...currentState };
    });
  };
  const setScoreWeight = (indexQuiz, value) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const quiz = currentState.items_question[indexQuiz];
      quiz.score_weight = Number(value);
      return { ...currentState };
    });
  };
  const setAnswerQuestion = (indexAnswer, indexQuiz, value, idValue) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const listAnswer =
        currentState.items_question[indexQuiz].list_answer[indexAnswer];
      listAnswer.answer = value;
      listAnswer.answer_id = 0;
      if (idValue) listAnswer.answer_id = idValue;
      return { ...currentState };
    });
  };
  const setAnswerType = (indexQuiz, type) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      const quiz = currentState.items_question[indexQuiz];
      quiz.quiz_type = type;
      if (type === 3) {
        quiz.list_answer = [];
      }
      return { ...currentState };
    });
  };
  const renderAnswerType = (index, quizType, answering) => {
    let answerType = null;
    switch (quizType) {
      case 1: // single answer
        answerType = (
          <div>
            <Text>Answer :</Text>
            <div className="populate-answer">
              {answering?.map((itemAnswer, indexAnswer) =>
                answerSingleChoice(itemAnswer, indexAnswer, index),
              )}
            </div>
            <div className="control-answer">{addMoreAnswer(index)}</div>
          </div>
        );
        break;
      case 2: // multiple answer
        answerType = (
          <div>
            <Text>Answer :</Text>
            <div className="populate-answer">
              {answering?.map((itemAnswer, indexAnswer) =>
                answerMultipleChoice(itemAnswer, indexAnswer, index),
              )}
            </div>
            <div className="control-answer">{addMoreAnswer(index)}</div>
          </div>
        );
        break;
      case 3: // Essay
        answerType = (
          <div className="control-answer">{formEssayAnswer(index)}</div>
        );
        break;
      case 4: // pairing answer
        answerType = (
          <div>
            <div className="populate-answer">
              {answering?.map((itemAnswer, indexAnswer) =>
                answerPairing(itemAnswer, indexAnswer, index),
              )}
            </div>
            <div className="control-answer">{formPairingAnswer(index)}</div>
          </div>
        );
        break;
      default:
        break;
    }
    return answerType;
  };

  // const addFormQuestion = () => {
  //   setStateForm((prevState) => {
  //     const currentState = { ...prevState };
  //     currentState.items_question = [
  //       ...currentState.items_question,
  //       {
  //         ref: React.createRef(),
  //         question_id: 0,
  //         question: null,
  //         quiz_type: 1,
  //         score_weight: 0,
  //         image_question: [],
  //         list_answer: [],
  //       },
  //     ];
  //     return { ...currentState };
  //   });
  //   setTimeout(() => {
  //     window.scrollTo(0, document.body.scrollHeight);
  //   }, 100);
  // };
  const addFormQuestion = () => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      currentState.items_question = [
        ...currentState.items_question,
        {
          ref: React.createRef(),
          question_id: 0,
          question: null,
          quiz_type: 1,
          score_weight: 0,
          word_limit: null,
          word_filter: null,
          image_question: [],
          list_answer: [],
          position: currentState.items_question.length + 1,
        },
      ];
      return { ...currentState };
    });
  };

  const addButtonQuestionNumberList = () => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      currentState.items_question = [
        ...currentState.items_question,
        {
          ref: React.createRef(),
          question_id: 0,
        },
      ];
      return { ...currentState };
    });
  };
  // const deleteFormQuestion = (indexQuestion) => {
  //   setStateForm((prevState) => {
  //     const currentState = { ...prevState };
  //     currentState.onPreviousItems = currentState.items_question;
  //     currentState.onProgressItems = !currentState.onProgressItems;
  //     return { ...currentState };
  //   });
  //   setTimeout(() => {
  //     setStateForm((prevState) => {
  //       const currentState = { ...prevState };
  //       const newData = currentState.items_question.filter(
  //         (item, index) => index !== indexQuestion,
  //       );
  //       currentState.items_question = newData;
  //       currentState.onProgressItems = !currentState.onProgressItems;
  //       return { ...currentState };
  //     });
  //     // window.scrollTo(0, document.body.scrollHeight);
  //   }, 100);
  // };

  const deleteFormQuestion = (indexQuestion) => {
    setStateForm((prevState) => {
      const currentState = { ...prevState };
      currentState.onPreviousItems = currentState.items_question;

      currentState.items_question = currentState.items_question.filter(
        (item, index) => index !== indexQuestion,
      );

      if (selectedQuestionNumber.current === indexQuestion) {
        setSelectedQuestionNumber((prevSelectedNumber) => {
          const question = { ...prevSelectedNumber };
          question.current = indexQuestion - 1 >= 0 ? indexQuestion - 1 : null;
          return { ...question };
        });
      }
      return { ...currentState };
    });
  };

  const renderFormUploadImage = (imageQuestion, indexQuestion) => (
    <div className="upload-image">
      <Text>Upload images (optional) :</Text>
      <Upload
        listType="picture-card"
        fileList={imageQuestion}
        onPreview={(file) => handlePreviewsUploaded(indexQuestion, file)}
        customRequest={(file) =>
          actionUploadImagesQuestions(indexQuestion, file)
        }
        onChange={({ ...data }) => handleDeleteUploadFile(indexQuestion, data)}
      >
        {imageQuestion.length >= 5 ? null : (
          <div>
            {state.loadingUploadImage ? (
              <div>
                <Text>upload processing...</Text>
              </div>
            ) : (
              <>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </>
            )}
          </div>
        )}
      </Upload>
      <Modal
        open={state.previewVisible}
        footer={null}
        onCancel={handleClosePreviews}
        destroyOnClose
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={state.previewImage}
        />
      </Modal>
    </div>
  );
  const renderFormQuestion = (item, index) => {
    const question = get(item, 'question');
    const quizType = get(item, 'quiz_type');
    const scoring = get(item, 'score_weight');
    const answering = get(item, 'list_answer');
    const prevAnswering = get(item, 'previous_answer');
    const imageQuestion = get(item, 'image_question')
      ? get(item, 'image_question')
      : [];
    return (
      <MotionPage motionType="fadeIn">
        <Card className="form-question" key={index}>
          <div className="numbering-question">{index + 1}</div>
          <div className="main-header">
            <div className="control-main">
              <div>
                <Input.Group compact>
                  <Form.Item>
                    <Select
                      onChange={(type) => setAnswerType(index, type)}
                      style={{ width: 150 }}
                      placeholder="Choose quiz type..."
                      defaultValue={quizType}
                    >
                      {config.defaultProps.quizType.map((items) => (
                        <Select.Option value={items.id}>
                          {items.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item>
                    <Input
                      hidden={state.assignScoreAutomatically === true}
                      min={0}
                      type="number"
                      style={{
                        textAlign: 'left',
                      }}
                      prefix="Score: "
                      placeholder="score weight..."
                      onKeyUp={(e) => setScoreWeight(index, e.target.value)}
                      defaultValue={scoring}
                    />
                  </Form.Item> */}
                </Input.Group>
              </div>
              <div>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteFormQuestion(index)}
                />
              </div>
            </div>
            <div className="section-input-question">
              <Text>Question :</Text>
              {/* <AutoComplete
                style={{ width: '100%' }}
                options={quizSuggestion?.question}
                onSelect={(val) => {
                  const dummy = quizSuggestion?.question;
                  const findData = dummy.find((items) => items.value === val);
                  setQuestion(index, findData?.value, findData?.id);
                }}
                onChange={(val) => {
                  listSuggestQuestion(val);
                }}
                defaultValue={question}
              > */}
              <TextArea
                className="question-input"
                size="middle"
                placeholder="input question..."
                defaultValue={question}
                onKeyUp={(e) => setQuestion(index, e.target.value)}
                // style={{ ...(!question && { borderColor: 'red', borderWidth: 3 }) }}
              />
              {/* !question && <Text style={{ color: 'red', fontSize: 12 }}>This field is required</Text> */}
              {/* </AutoComplete> */}
            </div>
            <div className="upload-image" style={{ marginTop: 10 }}>
              {renderFormUploadImage(imageQuestion, index)}
            </div>
          </div>
          <div className="main-asnwer">
            {stateForm.onProgressItemAnswer && // rendering answering primary
              renderAnswerType(index, quizType, answering)}
            {!stateForm.onProgressItemAnswer && // rendering previous answer before delete
              renderAnswerType(index, quizType, prevAnswering)}
          </div>
        </Card>
      </MotionPage>
    );
  };

  const renderFormQuestionBySelectedNumber = () => (
    <div>
      {renderFormQuestion(
        stateForm.items_question[selectedQuestionNumber.current],
        selectedQuestionNumber.current,
      )}
    </div>
  );

  const handleAddQuestionAndSwitchTab = () => {
    addFormQuestion();
    setSelectedQuestionNumber((prevSelectedNumber) => {
      const question = { ...prevSelectedNumber };
      question.current = stateForm.items_question.length;
      return { ...question };
    });
  };

  // const addQuestionNumberList = () => {
  //   // Dapatkan panjang list pertanyaan saat ini
  //   const currentQuestionLength = stateForm.items_question.length;

  //   // Dapatkan nomor pertanyaan berikutnya dari defaultProps
  //   const nextQuestionNumber =
  //     defaultProps?.questionNumberList[currentQuestionLength];

  //   // Tambahkan pertanyaan baru dengan nomor yang telah ditentukan
  //   stateForm.items_question.push({
  //     question_id: nextQuestionNumber,
  //     question: null,
  //     list_answer: [], // Tentukan struktur jawaban sesuai kebutuhan
  //   });
  //   setStateForm((prevState) => {
  //     return {
  //       ...prevState,
  //       items_question: stateForm.items_question,
  //     };
  //   });
  // };
  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout>
        <div id="DetailQuiz">
          <div className="header-quiz">
            <div className="action-header">
              <Button
                size="large"
                icon={<LeftCircleOutlined />}
                onClick={() => {
                  setState((prevState) => {
                    const currentState = { ...prevState };
                    currentState.modalBack = !currentState.modalBack;
                    return { ...currentState };
                  });
                }}
                style={{ paddingLeft: 0 }}
              >
                Back
              </Button>
              <div>
                <Button
                  size="large"
                  icon={<EditOutlined />}
                  onClick={() => toggleModalEdit()}
                >
                  Edit
                </Button>
                <Button
                  size="large"
                  icon={<ShareAltOutlined />}
                  onClick={() => {
                    generateNotification({
                      type: 'info',
                      messages: 'This feature still under development',
                    });
                  }}
                >
                  Share
                </Button>
              </div>
            </div>
            <div className="information-header">
              <div className="content-header">
                {/* <div className="status-quiz">Used</div> */}
                <div hidden={state.modalEdit}>
                  <Title level={2} style={{ margin: 'unset' }}>
                    {quizTM.quiz_name}
                  </Title>
                  <Text>{quizTM.description}</Text>
                </div>
                <div hidden={!state.modalEdit}>
                  <Form
                    ref={isRefEditQuizFirst}
                    layout="vertical"
                    name="updateQuiz"
                    onFinish={actionUpdateQuiz}
                  >
                    <Form.Item
                      label="Name"
                      name="quiz_name"
                      rules={[
                        {
                          required: true,
                          message: 'Dont make me empty :(',
                        },
                      ]}
                    >
                      <Input type="text" placeholder="input here ..." />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: 'Dont make me empty :(',
                        },
                      ]}
                    >
                      <TextArea
                        type="text"
                        placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
                        rows={5}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div className="form-action-button">
                        <Button
                          type="default"
                          onClick={() => toggleModalEdit()}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" type="primary">
                          Update
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="question-panel">
            <div className="panel-control" hidden>
              <Title level={4}>Quiz Question</Title>
              <div style={{ float: 'right' }}>
                <Space direction="horizontal" size="large" hidden>
                  <span>
                    <Tooltip title="Assigning score automatically will let the system assign equal score/weight for each question">
                      <InfoCircleOutlined />
                    </Tooltip>
                    &nbsp; Assign Score Automatically
                  </span>
                  <Switch
                    size="middle"
                    type="primary"
                    onChange={() =>
                      setState({
                        ...state,
                        assignScoreManually: !state.assignScoreManually,
                      })
                    }
                  />
                </Space>
              </div>
            </div>
            <div className="content-detail">
              <div className="question-panel-number">
                <div
                  className={`playground-numbering ${
                    state.reordering && 'quiz-active-reordering'
                  }`}
                  style={{ backgroundColor: state.reordering && '#c9264b3b' }}
                >
                  <div style={{ marginBottom: 10 }}>
                    <Title level={5} style={{ margin: 'unset' }}>
                      Questions Number List
                    </Title>
                    <Text style={{ fontSize: 12 }} hidden={!state.reordering}>
                      Drag and drop the number to preferred position. when
                      finished, click save!
                    </Text>
                  </div>
                  <div className="question-number-list">
                    {stateForm.onProgressItems && (
                      <div className="number-list">
                        <NumberingDragable
                          sourceData={stateForm.items_question}
                          needUpdatePosition
                          actionUpdatePosition={updatePositionQuizDetail}
                          actionClickItem={(idx) =>
                            setSelectedQuestionNumber((prevSelectedNumber) => {
                              const question = { ...prevSelectedNumber };
                              question.current = idx;
                              return { ...question };
                            })
                          }
                          hoverItem={selectedQuestionNumber.current}
                          addMoreBtn={!state.reordering}
                          funcAddMoreBtn={handleAddQuestionAndSwitchTab}
                          locked={!state.reordering}
                        />
                        {/* {stateForm.items_question.map(renderQuestionNumberList)} */}
                        {/* <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          className="button-add-more-section"
                          onClick={handleAddQuestionAndSwitchTab}
                        /> */}
                      </div>
                    )}
                  </div>
                  <Button
                    type="primary"
                    className="button-add-more-section"
                    hidden={!state.reordering}
                    onClick={() =>
                      setState((prev) => {
                        const current = { ...prev };
                        current.reordering = !current.reordering;
                        return { ...current };
                      })
                    }
                  >
                    Save
                  </Button>
                </div>
                <div className="action-button-question">
                  <Button
                    type=""
                    icon={<PlusOutlined />}
                    className="button-add-more-section"
                    onClick={() => handleAddQuestionAndSwitchTab()}
                    disabled={state.reordering}
                  >
                    Add Question
                  </Button>
                  <Button
                    type=""
                    className="button-add-more-section"
                    onClick={() =>
                      setState((prev) => {
                        const current = { ...prev };
                        current.reordering = !current.reordering;
                        return { ...current };
                      })
                    }
                    disabled={state.reordering}
                  >
                    Reorder Question
                  </Button>
                  <Button
                    type="primary"
                    icon={<SaveFilled />}
                    className="button-add-more-section"
                    onClick={() => createQuestion()}
                    disabled={state.reordering}
                  >
                    Set Question List
                  </Button>
                </div>
              </div>
              <div className="panel-question">
                {selectedQuestionNumber.current !== null &&
                  renderFormQuestionBySelectedNumber()}
              </div>
              {/* <div className="question-panel-content">
                {stateForm.onProgressItems && ( // rendering question primary
                  <div>{stateForm.items_question.map(renderFormQuestion)}</div>
                )}
                {!stateForm.onProgressItems && ( // rendering question before delete
                  <div>{stateForm.onPreviousItems.map(renderFormQuestion)}</div>
                )}
              </div> */}
            </div>
            <div className="header-quiz" id="additional-config">
              <div>
                <Title level={5}>Set Additional Configuration</Title>
                <Form
                  ref={isRefEditQuiz}
                  layout="vertical"
                  name="updateQuiz"
                  onFinish={actionUpdateQuiz}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Form.Item
                        label="Duration"
                        name="duration"
                        rules={[
                          {
                            required: true,
                            message: 'Dont make me empty :(',
                          },
                        ]}
                        style={{ margin: 'unset' }}
                      >
                        <Input
                          type="number"
                          placeholder="input here ..."
                          min={0}
                          value={0}
                          // defaultValue={quizTM.duration}
                          prefix="Minutes: "
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Minimum Score"
                        name="min_score"
                        rules={[
                          {
                            required: true,
                            message: 'Dont make me empty :(',
                          },
                        ]}
                        style={{ margin: 'unset' }}
                      >
                        <Input
                          type="number"
                          placeholder="input here ..."
                          min={0}
                          value={0}
                          // defaultValue={quizTM.min_score}
                          prefix="Score: "
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Take Limit"
                        name="retake_limit"
                        rules={[
                          {
                            required: true,
                            message: 'Dont make me empty :(',
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="input here ..."
                          min={0}
                          value={0}
                          defaultValue={50}
                          prefix="Limit: "
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div
                    hidden={
                      !stateForm.items_question.find(
                        (item) => item.quiz_type === 3,
                      )
                    }
                  >
                    <Title level={5}>Score Ratio Configuration</Title>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Multiple Choice Ratio"
                          name="rasio_mc"
                        >
                          <Input
                            type="number"
                            placeholder="input here ..."
                            min={0}
                            max={100}
                            value={0}
                            defaultValue={50}
                            prefix="Percentage: "
                            onKeyUp={(e) => {
                              let val = e.target.value;
                              if (val > 99) {
                                val = 99;
                              }
                              const diff = 100 - val;
                              isRefEditQuiz.current.setFieldsValue({
                                rasio_mc: val,
                              });
                            }}
                            rules={[
                              {
                                required: true,
                                message: 'Dont make me empty :(',
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Long Answer Ratio" name="rasio_essay">
                          <Input
                            type="number"
                            placeholder="input here ..."
                            min={0}
                            value={0}
                            max={100}
                            defaultValue={50}
                            prefix="Percentage: "
                            onKeyUp={(e) => {
                              let val = e.target.value;
                              if (val > 99) {
                                val = 99;
                              }
                              const diff = 100 - val;
                              isRefEditQuiz.current.setFieldsValue({
                                rasio_essay: val,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <Form.Item>
                    <div className="form-action-button">
                      <Button htmlType="submit" type="primary">
                        Update
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {/* {modalEditQuiz()} */}
        {modalBackQuiz()}
      </BaseLayout>
    </MotionPage>
  );
};

DetailQuiz.displayName = config.displayName;
DetailQuiz.defaultProps = config.defaultProps;
DetailQuiz.propTypes = config.propTypes;

export default DetailQuiz;
