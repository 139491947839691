import { connect } from 'react-redux';
import DetailPrograms from './DetailPrograms.component';
import {
  lessonsList,
  lessonGetTypeSuggestions,
} from '../../../redux/actions/lessons.actions';
import {
  programCreateActivity,
  programUpdate,
  programDetail,
  programActivityList,
  setActivityProgram,
  programUpdateActivity,
  programUpdateActivityPosition,
  programDeleteActivity,
  programActivityEntityList,
  programEntityUpdatePosition,
  programCreateActivityEntity,
  programUpdateActivityEntity,
  programDeleteActivityEntity,
  programUpdateActivityEntityStatus,
  programUpdateParticipant,
  programRemoveParticipant,
  programGetParticipants,
  programGetSessionPreview,
  programUpdateEntityDependency,
  programGetTypeSuggestions,
  setParrentProgram,
  participantsGetStudentData,
  participantsGetSummaryStudentData,
  participantsGetStudentQuizData,
  participantsGenerateStudentQuiz,
  participantSubmitStudentQuiz,
  participantWeeklyAssignment,
  participantSubmitWeekAssignment,
  finalGroupGet,
  finalGroupCreate,
  finalGroupPut,
  finalGroupDelete,
  finalGroupScorePost,
  postCertificateProgramAdmin,
} from '../../../redux/actions/programs.actions';
import {
  getListQuiz,
  getListQuizDetail,
} from '../../../redux/actions/quiz.actions';
import { getUserList } from '../../../redux/actions/common.actions';
import { clearSearchText } from '../../../redux/actions/search.actions';

const mapStateToProps = (state) => ({
  common: state.common.settings,
  searchText: state.search,
  lessonListData: state.lesson.list,
  programObjectDetail: state.program.detail,
  participantsData: state.program.participants,
  listQuiz: state.quiz.list,
  selectionQuiz: state.quiz.detail,
  programTypeSuggenstion: state.program.fieldSuggestions,
  fieldSuggestions: state.lesson.fieldSuggestions,
  reportStudent: state.program.reportStudent,
});

const mapDispatchToProps = {
  clearSearchText,
  lessonsList,
  programUpdate,
  programDetail,
  programCreateActivity,
  programActivityList,
  setParrentProgram,
  setActivityProgram,
  programUpdateActivity,
  programUpdateActivityPosition,
  programDeleteActivity,
  programActivityEntityList,
  programEntityUpdatePosition,
  programCreateActivityEntity,
  programUpdateActivityEntity,
  programDeleteActivityEntity,
  programUpdateActivityEntityStatus,
  getListQuiz,
  getListQuizDetail,
  getUserList,
  programUpdateParticipant,
  programRemoveParticipant,
  programGetParticipants,
  programGetSessionPreview,
  programUpdateEntityDependency,
  programGetTypeSuggestions,
  lessonGetTypeSuggestions,
  participantsGetStudentData,
  participantsGetSummaryStudentData,
  participantsGetStudentQuizData,
  participantsGenerateStudentQuiz,
  participantSubmitStudentQuiz,
  participantWeeklyAssignment,
  participantSubmitWeekAssignment,
  finalGroupGet,
  finalGroupCreate,
  finalGroupPut,
  finalGroupDelete,
  finalGroupScorePost,
  postCertificateProgramAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailPrograms);
