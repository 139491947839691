import React from 'react';
import { Card, Typography } from 'antd';

import DataListHighlight from '../DataListHighlight/DataListHighlight.component';

import config from './CardListData.component.config';
import './CardListData.component.styles.css';

const { Title } = Typography;

const CardListData = ({
  title,
  actionHeader,
  sourceData,
  isLoading,
  ...props
}) => (
  <div id="CardDataList">
    <Card
      key={title}
      title={
        <div className="header-card">
          <div className="header-title">
            <Title level={3}>{title}</Title>
          </div>
          <div className="header-action">{actionHeader}</div>
        </div>
      }
    >
      <DataListHighlight sourceData={sourceData} isLoading={isLoading} />
    </Card>
  </div>
);

CardListData.displayName = config.displayName;
CardListData.defaultProps = config.defaultProps;
CardListData.propTypes = config.propTypes;

export default CardListData;
