import PropTypes from 'prop-types';

const displayName = 'ContentQuiz';
const propTypes = {};
const defaultProps = {
  alphabet: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ],
  selectAnswerSingleChoice: (
    setState,
    indexQuestion,
    indexAnswer,
    answerTitle,
  ) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      const collection = currentState.collectionAnswer;
      const findCollection = collection.find(
        (items) => items.quizdetail_id === indexQuestion,
      );
      if (findCollection === undefined) {
        collection.push({
          quizdetail_id: indexQuestion,
          quizanswer_id: indexAnswer,
          quizanswer_text: answerTitle,
        });
      } else {
        findCollection.quizdetail_id = indexQuestion;
        findCollection.quizanswer_id = indexAnswer;
        findCollection.quizanswer_text = answerTitle;
      }
      return { ...currentState };
    });
  },
  findCollectionAnswerChoosen: (stateCollection, indexQuestion) => {
    const findCollection = stateCollection.collectionAnswer.find(
      (items) => items.quizdetail_id === indexQuestion,
    );
    return findCollection?.quizanswer_id;
  },
  selectAnswerMultipleChoice: (
    setState,
    condition,
    indexQuestion,
    indexAnswer,
    answerTitle,
  ) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      if (condition) {
        currentState.collectionAnswer = [
          {
            quizdetail_id: indexQuestion,
            quizanswer_id: indexAnswer,
            quizanswer_text: answerTitle,
          },
          ...currentState.collectionAnswer,
        ];
      } else {
        const dataClear = currentState.collectionAnswer.filter(
          (items) => items.quizanswer_id !== indexAnswer,
        );
        currentState.collectionAnswer = dataClear;
      }
      return { ...currentState };
    });
  },
  selectAnswerEssay: (setState, indexQuestion, answerQuestion) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      const findAnswer = currentState.collectionAnswer.find(
        (item) => item.quizdetail_id === indexQuestion,
      );
      if (findAnswer) {
        findAnswer.quizanswer_essay = answerQuestion;
      } else {
        currentState.collectionAnswer = [
          {
            quizdetail_id: indexQuestion,
            quizanswer_id: 0,
            quizanswer_essay: answerQuestion,
          },
          ...currentState.collectionAnswer,
        ];
      }
      return { ...currentState };
    });
  },
  findCollectionAnswerMultipleChoosen: (
    stateCollection,
    indexQuestion,
    indexAnswer,
  ) => {
    const choosenData = stateCollection.collectionAnswer.find(
      (items) =>
        items.quizdetail_id === indexQuestion &&
        items.quizanswer_id === indexAnswer,
    );
    return choosenData?.quizanswer_id;
  },
};

export default { displayName, propTypes, defaultProps };
