import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Col, notification, Row, Select } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { BaseLayout, CardListData, MotionPage } from '../../../components';

import config from './AdminDashboard.component.config';

const AdminDashboard = ({
  lessonsList,
  lessonListData,
  programList,
  programListData,
  ...props
}) => {
  const [apiCallStatus, setApiCallStatus] = React.useState({
    lessonsList: false,
    programList: false,
  });
  const navigate = useNavigate();

  // useEffect for programList
  React.useEffect(() => {
    if (apiCallStatus.programList === false) {
      programList({ page: 1 })
        .catch(() =>
          notification.error({
            message: `System Error`,
            description: `Failed to get program list. Please try again later.`,
          }),
        )
        .finally(() =>
          setApiCallStatus({ ...apiCallStatus, programList: true }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallStatus.programList]);

  // useEffect for lessonsList
  React.useEffect(() => {
    if (apiCallStatus.lessonsList === false) {
      lessonsList()
        .catch(() =>
          notification({
            message: `System Error`,
            description: `Failed to get lessons list. Please try again later.`,
          }),
        )
        .finally(() =>
          setApiCallStatus({ ...apiCallStatus, lessonsList: true }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallStatus.lessonsList]);

  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout
        title="Dashboard"
        action={
          [
            // <Select defaultValue="lucy" allowClear>
            //   <Select.Option value="lucy">Filter by Recently</Select.Option>
            // </Select>,
          ]
        }
        collapsedMenu={props?.common?.collapsedMenu}
        // onMouseEnter={() => console.log(props)}
      >
        <Row gutter={24}>
          <Col xs={24} md={24} xl={24}>
            <CardListData
              title="Programs"
              actionHeader={[
                <Button onClick={() => navigate('/admin/programs')}>
                  See More <RightOutlined />
                </Button>,
              ]}
              sourceData={programListData?.data}
              isLoading={programListData?.isLoading}
            />
          </Col>
          <Col xs={24} md={24} xl={24}>
            <CardListData
              title="Lessons"
              actionHeader={[
                <Button onClick={() => navigate('/admin/lessons')}>
                  See More <RightOutlined />
                </Button>,
              ]}
              sourceData={lessonListData?.data}
              isLoading={lessonListData?.isLoading}
            />
          </Col>
        </Row>
      </BaseLayout>
    </MotionPage>
  );
};

AdminDashboard.displayName = config.displayName;
AdminDashboard.defaultProps = config.defaultProps;
AdminDashboard.propTypes = config.propTypes;

export default AdminDashboard;
