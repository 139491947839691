import PropTypes from 'prop-types';

const displayName = 'ComingsoonPage';
const propTypes = {};
const defaultProps = {};

export default {
  displayName,
  propTypes,
  defaultProps,
};
