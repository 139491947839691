import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { BASE_ROUTE } from './base.route';

const NavigationProvider = () => (
  <Router>
    <Routes>
      {BASE_ROUTE.map((item) => (
        <Route key={item.key} {...item} />
      ))}
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  </Router>
);

export default NavigationProvider;
