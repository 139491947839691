import {
  SET_PROGRAMS_LIST,
  SET_PROGRAMS_PARRENT,
  SET_PROGRAMS_PARTICIPANTS,
  SET_PROGRAMS_FIELD_SUGGESTIONS,
} from '../reduxType';

const initialState = {
  list: {
    data: [],
    isLoading: false,
  },
  fieldSuggestions: {
    program_types: [],
    isLoading: false,
  },
  detail: {
    parrent: {},
    activity: [],
    selectedActivity: {},
    activityEntity: [],
    sessionPreview: {},
    learner: [],
    isLoading: false,
  },
  participants: {
    data: [],
    isLoading: false,
  },
  reportStudent: {
    data: {},
    isLoading: false,
  },
};

export default (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_PROGRAMS_FIELD_SUGGESTIONS:
      return {
        ...state,
        fieldSuggestions: {
          ...state.fieldSuggestions,
          ...action.payload,
        },
      };
    case SET_PROGRAMS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action?.payload,
        },
      };
    case SET_PROGRAMS_PARRENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action?.payload,
        },
      };
    case SET_PROGRAMS_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          ...action.payload,
        },
      };
    case 'GET_STUDENT_DATA':
      return {
        ...state,
        reportStudent: {
          ...state.reportStudent,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
