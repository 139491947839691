import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, List } from 'antd';
import { getParamURL } from '../../utils/parse';
import { generateNotification } from '../../utils/general.utils';
import { ICON_BOLBEL_FULL } from '../../assets/svg';
import config from './AuthForward.component.config';
import './AuthForward.component.styles.css';

const AuthForward = ({
  programList,
  fAuthForward,
  getStudentProgramList,
  getStudentCourseList,
  getCourseDetail,
  getStudentLearningProgress,
  ...props
}) => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    readSession: true,
  });

  const sessionForwardParse = JSON.parse(
    JSON.stringify(
      getParamURL('ssn').replaceAll('%20', '').replaceAll('%22', '"'),
    ),
  );

  const validationJson = () => {
    try {
      JSON.parse(sessionForwardParse);
    } catch (error) {
      return false;
    }
    return true;
  };
  const sessionForward = validationJson()
    ? JSON.parse(sessionForwardParse, '{}')
    : false;
  const sessionProgramId = getParamURL('crs');

  useEffect(() => {
    const findProgramList = (respListProgram, programID) => {
      const programListData = respListProgram;
      let programObject = null;

      const findData = programListData.find(
        (items) => items.id === Number(programID),
      );

      if (findData !== undefined) programObject = findData;

      return programObject;
    };

    if (state.readSession) {
      // #old method validation token forward
      // if (sessionForward) {
      //   fAuthForward(sessionForward).then(() => {
      // ------------------------------------

      // #new method validation token forward from websiite
      if (getParamURL('ssn') !== undefined && getParamURL('ssn') !== '') {
        fAuthForward(getParamURL('ssn')).then(() => {
          let navigatePage = `/student/*`;
          if (sessionProgramId !== '') {
            getStudentCourseList().then((respListProgram) => {
              const programObject = findProgramList(
                respListProgram,
                sessionProgramId,
              );
              if (programObject !== null) {
                getCourseDetail(programObject?.id).then(() => {
                  getStudentLearningProgress(programObject?.id);
                  setTimeout(() => {
                    setState((prevState) => {
                      const currentState = { ...prevState };
                      currentState.showLoading = !currentState.showLoading;
                      return { ...currentState };
                    });
                    // navigate(`/student/programs/newcourse?newcourse=${programId}`);
                    navigatePage = `/student/programs/course?course=${sessionProgramId}`;
                  }, 1000);
                });
                // navigatePage = `/student/programs/course?course=${sessionProgramId}`;
                // getStudentProgramDetails(programObject).then(
                //   (programActivity) => {
                //     // getting data detail program activity include list activity
                //     programActivity.forEach((activity) => {
                //       // looping for generating entity list per activity, nested entity list to current activity
                //       getStudentProgramActivityEntity(activity?.id);
                //     });
                //   },
                // );
              }
            });
            setTimeout(() => {
              navigate(navigatePage);
            }, 5000);
          } else {
            setTimeout(() => {
              navigate(navigatePage);
            }, 1000);
          }
        });
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.readSession = false;
          return { ...currentState };
        });
      } else {
        generateNotification({
          type: 'error',
          response: 404,
          messages: `you don't have a session`,
        });
        setTimeout(() => {
          navigate('/');
        }, 1000);
      }
    }
  }, [
    programList,
    fAuthForward,
    getStudentCourseList,
    navigate,
    sessionForward,
    sessionProgramId,
    state,
    state.readSession,
    getCourseDetail,
    getStudentLearningProgress,
  ]);

  return (
    <div id="fwrd-content">
      <div>
        {/* <Image preview={false} width={200} src={<ICON_BOLBEL_FULL />} /> */}
        <div
          style={{
            width: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ICON_BOLBEL_FULL />
          <List loading />
        </div>
        <p>Tunggu sebentar, sistem sedang melakukan validasi...</p>
      </div>
    </div>
  );
};

AuthForward.displayName = config.displayName;
AuthForward.defaultProps = config.defaultProps;
AuthForward.propTypes = config.propTypes;

export default AuthForward;
