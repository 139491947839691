import PropTypes from 'prop-types';

const displayName = 'DataListHighlight';
const propTypes = {};
const defaultProps = {
  ignoreTitle: [
    'id',
    'create date',
    'created_at',
    'schedule_start',
    'schedule_end',
    'scheduled_date_start',
    'scheduled_date_end',
    'is_active',
    'moderator_id',
    'duration',
    'banner_image',
    'modified_at',
    'program_type_id',
    'moderator_name',
  ],
  titleDate: [''],
};

export default { displayName, propTypes, defaultProps };
