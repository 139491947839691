import { get } from 'lodash';
const AUTH_TOKEN = 'AUTH_TOKEN';

export const storeToken = (token) => {
  if (token) {
    const item = token;
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(item));
  } else {
    localStorage.removeItem(AUTH_TOKEN);
  }
};

export const getToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const item = JSON.parse(token, '{}');
  const now = Math.floor(new Date().getTime() / 1000);
  let respToken = null;

  if (now > get(item, 'expired_at') || item === null) {
    localStorage.removeItem(AUTH_TOKEN);
    respToken = null;
  } else {
    respToken = item;
  }
  return respToken;
};

export const destroyToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem('CURRENT_MENU');
  localStorage.removeItem('PROGRAMS_CURRENT_PAGE');
  localStorage.removeItem('persist:program');
  localStorage.removeItem('persist:lesson');
  localStorage.removeItem('persist:header');
  localStorage.removeItem('persist:certificate');
  localStorage.removeItem('persist:users');
  localStorage.removeItem('persist:quiz');
  localStorage.removeItem('persist:student');
};
