import React, { useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import parse from 'html-react-parser';

import {
  CheckOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Button, Upload, Typography } from 'antd';
import { isMobile } from 'react-device-detect';

import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../../services/aws.service';
import { VideoPlayerCustom, YoutubePlayerCustom } from '../../../../components';
import VideoPlayer from '../VideoPlayer/VideoPlayer.component';
import ContentQuiz from '../ContentQuiz/ContentQuiz.component';
import config from './ContentEntity.component.config';

const { Title, Text, Paragraph } = Typography;
const idLocale = require('moment/locale/id');

const ContentEntity = ({
  selectionContent,
  selectionContentPrev,
  activitySessionUser,
  activitySessionUserPrev,
  courseDetail,
  contentVideoPlaying,
  getConditionDatetime,
  openModal,
  onNavigateCourse,
  onCreateActivitySession,
  onUpdateActivitySession,
  onUpdateQuizSubmission,
  onUpdateStateSession,
  onUpdateAssignmentSubmission,
  onUpdateGroupAssignmentSubmission,
  quizSubmissionHistory,
  onValidateClaimingCertif,
  ...props
}) => {
  const statusDependencyEntity = selectionContent?.dependency;
  const statusPreviousSession = activitySessionUserPrev?.status;
  const typePreviousSession = activitySessionUserPrev?.activity_type_id;

  // set status active entity from static or dependency
  let statusActiveEntity =
    statusDependencyEntity === 0 ? 1 : selectionContent?.statusActive;
  const entityQuizID = [3, 4];
  const prevQuizData = selectionContentPrev;
  const prevIsQuiz = entityQuizID.indexOf(prevQuizData.activity_type_id) >= 0;
  const prevQuizMinScore = prevQuizData.quizData?.min_score; // minimum score quiz
  const prevSessionQuizScore = activitySessionUserPrev.quiz_score; // last score from session
  const prevQuizTakeLimit = prevQuizData.quizData?.retake_limit; // take limit quiz
  const prevCountLimit = activitySessionUserPrev.history.length; // take limit from session

  const checkConditionDatetime = getConditionDatetime(
    selectionContent.schedule_date,
  );
  const scheduleDatetimeFormat = moment(
    new Date(selectionContent.schedule_date),
  )
    .locale('id', idLocale)
    .format('LLLL');

  const [state, setState] = React.useState({
    fileList: [],
  });

  useEffect(() => {
    if (state.fileList.length === 0) {
      let objectParsing = [];
      if (
        selectionContent?.activity_type_id === 6 &&
        activitySessionUser?.assignment_submit !== undefined &&
        activitySessionUser?.assignment_submit !== null
      ) {
        objectParsing = JSON.parse(activitySessionUser?.assignment_submit);
        setState({ fileList: [objectParsing] });
      }

      if (
        selectionContent?.activity_type_id === 8 &&
        selectionContent?.finalGroupData?.assignment_file !== undefined &&
        selectionContent?.finalGroupData?.assignment_file !== null
      ) {
        objectParsing = JSON.parse(
          selectionContent?.finalGroupData?.assignment_file,
        );
        setState({ fileList: [objectParsing] });
      }
    }
  }, [state.fileList, activitySessionUser, selectionContent]);

  const actionUploadFile = async (upload) => {
    const filename = upload?.file?.name?.replaceAll(' ', '_');
    const tempData = {
      uid: upload?.file?.uid,
      name: filename,
    };
    await putObjectAwsS3Bucket(
      'student/assignment',
      upload?.file,
      filename,
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });

            if (selectionContent?.finalGroupData) {
              onUpdateGroupAssignmentSubmission({
                assignment_file: JSON.stringify({
                  ...tempData,
                  uid: originKey,
                  url: objectURL,
                  status: 'done',
                }),
              });
            } else {
              onUpdateAssignmentSubmission({
                assignmentSubmit: JSON.stringify({
                  ...tempData,
                  uid: originKey,
                  url: objectURL,
                  status: 'done',
                }),
              });
            }
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };

  const generateContentVideo = (contentMaterial) => {
    const materialSource = get(contentMaterial, 'material_source');
    let content = null;
    if (
      materialSource === null ||
      materialSource === 0 ||
      materialSource === 1
    ) {
      content = (
        <VideoPlayer
          roles="learner"
          material={contentMaterial?.material_link}
          selectionContent={selectionContent}
          activitySessionUser={activitySessionUser}
          onCreateActivitySession={onCreateActivitySession}
          onUpdateActivitySession={onUpdateActivitySession}
          onUpdateStateSession={onUpdateStateSession}
          contentVideoPlaying={contentVideoPlaying}
        />
      );
    } else if (materialSource === 2) {
      content = (
        <VideoPlayerCustom
          roles="learner"
          material={contentMaterial?.material_link}
          selectionContent={selectionContent}
          activitySessionUser={activitySessionUser}
          onCreateActivitySession={onCreateActivitySession}
          onUpdateActivitySession={onUpdateActivitySession}
          onUpdateStateSession={onUpdateStateSession}
        />
      );
    } else if (materialSource === 3) {
      content = (
        <YoutubePlayerCustom
          material={contentMaterial?.material_link}
          selectionContent={selectionContent}
          activitySessionUser={activitySessionUser}
          onCreateActivitySession={onCreateActivitySession}
          onUpdateActivitySession={onUpdateActivitySession}
          onUpdateStateSession={onUpdateStateSession}
        />
      );
    }
    return content;
  };

  const isContentEntity = (contentType, contentMaterial) => {
    let content = null;
    const findRoleGroupAssignment =
      contentMaterial?.finalGroupData?.group?.find(
        (user) => user.user_id === props.users.user_id,
      );
    const findPIC = contentMaterial?.finalGroupData?.group?.find(
      (user) => user.role_id === 1,
    );
    console.log(contentMaterial?.finalGroupData);
    switch (Number(contentType)) {
      case 1: // content video conference call
        content = (
          <div
            className="content-1"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : '30px 24px 24px 24px',
              height: isMobile ? 'auto' : 567,
              maxHeight: 'auto',
              width: '100%',
              margin: !isMobile && 'auto',
            }}
          >
            <Text>
              Please join at :{' '}
              <a href={contentMaterial?.material_link} target="_blank">
                {contentMaterial?.material_link}
              </a>
            </Text>
          </div>
        );
        break;
      case 2: // content video learning
        content = (
          <div className="content-2">
            {generateContentVideo(contentMaterial)}
          </div>
        );
        break;
      case 3: // content quiz
        content = (
          <div
            className="content-3"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : '30px 24px 24px 24px',
              minHeight: isMobile ? 'auto' : 567,
              maxHeight: 'auto',
              width: isMobile ? '100%' : '100%',
              margin: !isMobile && 'auto',
            }}
          >
            <ContentQuiz
              quizData={contentMaterial?.quizData}
              selectionContent={selectionContent}
              activitySessionUser={activitySessionUser}
              onCreateActivitySession={onCreateActivitySession}
              onUpdateQuizSubmission={onUpdateQuizSubmission}
              onUpdateStateSession={onUpdateStateSession}
              quizSubmissionHistory={quizSubmissionHistory}
            />
          </div>
        );
        break;

      case 5: // content
        content = (
          <div
            className="content-5"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : 30,
              height: isMobile ? 'auto' : 567,
              margin: !isMobile && 'auto',
              overflowY: 'scroll',
            }}
          >
            {parse(contentMaterial?.material_link)}
          </div>
        );
        break;
      case 6:
        content = (
          <div
            className="content-6"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : '30px 24px 24px 24px',
              height: 'auto',
              width: isMobile ? '100%' : '100%',
              margin: !isMobile && 'auto',
            }}
          >
            <div>
              <Title level={4}>{contentMaterial?.entity_name}</Title>
              {contentMaterial?.material_additional !== null &&
                parse(contentMaterial?.material_additional)}
            </div>
            <div className="material-link-assignment">
              {contentMaterial?.material_link !== null &&
                parse(contentMaterial?.material_link)}
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                margin: '30px auto',
              }}
            >
              <div style={{ marginBottom: 10 }}>
                Nama file: NAMA TUGAS [Spasi] Nama Anda, tidak disarankan
                menggunakan simbol khusus.
              </div>
              <Upload
                customRequest={actionUploadFile}
                maxCount={1}
                fileList={state.fileList}
                showUploadList={{
                  showRemoveIcon: false,
                  showDownloadIcon: false,
                }}
                accept=".pdf, .doc, .docs, .xlsx, .pptx"
              >
                <Button type="primary">
                  Submit Tugas
                  <CloudUploadOutlined />
                </Button>
              </Upload>
              {state.fileList.length === 0 && (
                <div>Belum ada tugas yang disubmit</div>
              )}
            </div>
          </div>
        );
        break;
      case 7: // content quiz certification
        content = (
          <div
            className="content-7"
            style={{
              width: isMobile ? '100%' : '100%',
              margin: !isMobile && 'auto',
              minHeight: isMobile ? 'auto' : 567,
              maxHeight: 'auto',
            }}
          >
            <ContentQuiz
              openModal={openModal}
              selectedProgramId={courseDetail.id}
              quizData={contentMaterial?.quizData}
              selectionContent={selectionContent}
              activitySessionUser={activitySessionUser}
              onCreateActivitySession={onCreateActivitySession}
              onUpdateQuizSubmission={onUpdateQuizSubmission}
              onUpdateStateSession={onUpdateStateSession}
              quizSubmissionHistory={quizSubmissionHistory}
              validateClaimingCertificate={onValidateClaimingCertif}
            />
          </div>
        );
        break;
      case 8:
        content = (
          <div
            className="content-6"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : '30px 24px 24px 24px',
              height: 'auto',
              width: isMobile ? '100%' : '100%',
              margin: !isMobile && 'auto',
            }}
          >
            <div>
              <Title level={4}>{contentMaterial?.entity_name}</Title>
              {contentMaterial?.material_additional !== null &&
                parse(contentMaterial?.material_additional)}
            </div>
            <div className="material-link-assignment">
              {contentMaterial?.material_link !== null &&
                parse(contentMaterial?.material_link)}
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                margin: '30px auto',
              }}
            >
              <div style={{ marginBottom: 10 }}>
                Nama file:{' '}
                <b>
                  JUDUL TUGAS [spasi] Nama Kelompok [spasi] Nama PIC Kelompok
                </b>
                , Tidak disarankan menggunakan simbol khusus dalam nama file
                yang disubmit
              </div>

              <Upload
                customRequest={actionUploadFile}
                maxCount={1}
                fileList={state.fileList}
                showUploadList={{
                  showRemoveIcon: false,
                  showDownloadIcon: false,
                }}
                accept=".pdf, .doc, .docs, .xlsx, .pptx"
              >
                <Button
                  type="primary"
                  disabled={findRoleGroupAssignment?.role_id === 2}
                  style={{
                    backgroundColor:
                      contentMaterial?.finalGroupData?.assignment_status === 1
                        ? '#389517'
                        : '#c9264b',
                    borderColor:
                      contentMaterial?.finalGroupData?.assignment_status === 1
                        ? '#389517'
                        : '#c9264b',
                  }}
                >
                  {contentMaterial?.finalGroupData?.assignment_status === 1 ? (
                    <div>
                      kamu sudah submit tugas kamu <CheckCircleOutlined />
                    </div>
                  ) : (
                    <div>
                      Submit Tugas <CloudUploadOutlined />
                    </div>
                  )}
                </Button>
              </Upload>
              {state.fileList.length === 0 && (
                <div>Belum ada tugas yang disubmit</div>
              )}
              {findRoleGroupAssignment?.role_id === 2 && (
                <div style={{ marginTop: 10 }}>
                  Hanya PIC yang bisa menggunggah, hubungi PIC kamu untuk
                  progress group assignment kamu <br />
                  <b>PIC : {findPIC?.user_name}</b>
                </div>
              )}
              <div style={{ textAlign: 'left', marginTop: 20 }}>
                <b>Kelompok : </b>
                {contentMaterial?.finalGroupData?.group_name}
                <br />
                <b>PIC : </b>
                {findPIC?.user_name}
                <br />
                <b>Anggota Kelompok :</b>
                <br />
                {contentMaterial?.finalGroupData?.group?.map(
                  (member) =>
                    member?.role_id !== 1 && <div>* {member?.user_name}</div>,
                )}
              </div>
            </div>
          </div>
        );
        break;
      default:
        content = null;
        break;
    }
    return content;
  };

  const renderRules = () => {
    let isRenderContent = null;
    if (prevIsQuiz) {
      if (prevQuizMinScore === 0 && prevQuizTakeLimit === 0) {
        statusActiveEntity = 0;
        if (
          prevSessionQuizScore !== null &&
          prevSessionQuizScore >= prevQuizMinScore
        ) {
          statusActiveEntity = 1;
        }
        if (
          prevQuizTakeLimit !== null &&
          prevQuizTakeLimit === prevCountLimit
        ) {
          statusActiveEntity = 1;
        }
      } else if (prevQuizMinScore === 0 && prevQuizTakeLimit > 0) {
        statusActiveEntity = 0;
        if (
          prevSessionQuizScore !== null &&
          prevSessionQuizScore >= prevQuizMinScore
        ) {
          statusActiveEntity = 1;
        }
        if (
          prevQuizTakeLimit !== null &&
          prevQuizTakeLimit === prevCountLimit
        ) {
          statusActiveEntity = 1;
        }
      } else if (prevQuizMinScore > 0 && prevQuizTakeLimit === 0) {
        statusActiveEntity = 0;
        if (
          prevSessionQuizScore !== null &&
          prevSessionQuizScore >= prevQuizMinScore
        ) {
          statusActiveEntity = 1;
        }
      } else if (statusPreviousSession === 1) {
        statusActiveEntity = 1;
      }
    } else if (statusPreviousSession === 1) {
      statusActiveEntity = 1;
    } else if (statusDependencyEntity === 0) {
      statusActiveEntity = 1;
    } else {
      statusActiveEntity = 0;
    }

    if (
      selectionContent.schedule_date !== undefined ||
      selectionContent.schedule_date !== null
    ) {
      if (!checkConditionDatetime) {
        statusActiveEntity = 2;
      }
    }

    // console.log('statusActiveEntity 2', statusActiveEntity);
    if (statusActiveEntity === 1) {
      isRenderContent = (
        <div className="content-entity">
          <div style={{}}>
            {isContentEntity(
              selectionContent?.activity_type_id,
              selectionContent,
            )}
          </div>
          {isMobile && (
            <div style={{ textAlign: 'end' }}>
              {/* <Title level={4}>{selectionContent?.entity_name}</Title> */}
              <Button
                size="small"
                hidden={
                  selectionContent?.activity_type_id === 2 &&
                  activitySessionUser?.status === 1
                    ? false
                    : 'hidden'
                }
                style={{
                  marginRight: !isMobile && 24,
                  marginBottom: 10,
                  borderRadius: 100,
                  cursor: 'auto',
                  padding: !isMobile && '0 10px',
                  marginTop: !isMobile ? 16 : 0,
                }}
                type="primary"
                shape="circle"
                icon={<CheckOutlined />}
              />
              {/* {!isMobile &&
                  'Yeay! kamu sudah selesai menonton video pembelajaran ini.'}
              </Button> */}
            </div>
          )}
        </div>
      );
    } else if (statusActiveEntity === 2) {
      isRenderContent = (
        <div className="content-entity">
          <Title level={3}>{`Halo Learner's!`}</Title>
          <Paragraph style={{ fontSize: 16 }}>
            Saat ini kelas belum dibuka, Kelas akan dibuka pada{' '}
            <b>{scheduleDatetimeFormat}</b>.
            <br />
            <br />
            Terimakasih!
          </Paragraph>
        </div>
      );
    } else {
      isRenderContent = (
        <div
          className="content-entity"
          style={{ padding: 24, borderRadius: 12 }}
        >
          <Title level={3}>{`Halo Learner's!`}</Title>
          {statusDependencyEntity === 1 ? (
            <Paragraph style={{ fontSize: 16 }}>
              Kamu perlu menyelesaikan aktivitas
              <Button
                type="link"
                className="button-link-jump-prev"
                onClick={() => onNavigateCourse(selectionContentPrev.id)}
              >
                <b>{selectionContentPrev?.entity_name}</b>
              </Button>
              dahulu <br /> agar aktivitas ini bisa kamu akses.
              <br />
              <br />
              Terimakasih!
            </Paragraph>
          ) : (
            <Paragraph style={{ fontSize: 16 }}>
              Course yang kamu pilih saat ini tidak tersedia. <br />
              Silahkan cek jadwal atau selesaikan aktivitas sebelumnya sebelum
              lanjut ke sesi selanjutnya.
              <br />
              <br />
              Terimakasih!
            </Paragraph>
          )}
        </div>
      );
    }

    return isRenderContent;
  };

  return renderRules();
};

ContentEntity.displayName = config.displayName;
ContentEntity.defaultProps = config.defaultProps;
ContentEntity.propTypes = config.propTypes;

export default ContentEntity;
