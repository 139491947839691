import {
  SET_LESSON_LIST,
  SET_LESSON_ENTITY_LIST,
  SET_LESSON_FIELD_SUGGESTIONS,
} from '../reduxType';

const initialState = {
  list: {
    data: [],
    isLoading: false,
  },
  fieldSuggestions: {
    program_types: [],
    isLoading: false,
  },
  detail: {
    parrent: {},
    entity: [],
    isLoading: false,
  },
};

export default (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_LESSON_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action?.payload,
        },
      };
    case SET_LESSON_ENTITY_LIST:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action?.payload,
        },
      };
    case SET_LESSON_FIELD_SUGGESTIONS:
      return {
        ...state,
        fieldSuggestions: {
          ...state.fieldSuggestions,
          ...action?.payload,
        },
      };
    default:
      return state;
  }
};
