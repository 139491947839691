import PropTypes from 'prop-types';

const displayName = 'AdminQuiz';
const propTypes = {};
const defaultProps = {
  dummData: () => {
    const data = [];
    for (let index = 0; index < 10; ) {
      data.push({
        id: index,
        'Quiz name': `Quiz ${index}`,
        'create date': '2022-06-01',
        quiz: '20 quiz',
        status: 'not used',
      });
      index += 1;
    }
    return data;
  },
  headColumn: [
    {
      title: 'Assignment',
      key: 'quiz_name',
      sort: true,
      width: 200,
      fixed: true,
    },
    {
      title: 'Minimum Score',
      key: 'min_score',
      width: 100,
    },
    {
      title: 'Duration (Minutes)',
      key: 'duration',
      width: 100,
    },
    {
      title: 'Take Limit',
      key: 'retake_limit',
      width: 100,
    },
    {
      title: 'Description',
      key: 'description',
      formatParagraph: true,
      width: 350,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      width: 120,
    },
  ],
};

export default { displayName, propTypes, defaultProps };
