import PropTypes from 'prop-types';

const displayName = 'DataList';
const propTypes = {};
const defaultProps = {
  ignoreTitle: [
    'id',
    'create date',
    'created_at',
    'scheduled_date_start',
    'scheduled_date_end',
    'is_active',
    'moderator_id',
    'duration',
    'banner_image',
    'modified_at',
    'program_type_id',
    'moderator_name',
    'position',
    // 'min_score',
    // 'retake_limit',
  ],
  titleDate: ['created_at', 'create date'],
};

export default { displayName, propTypes, defaultProps };
