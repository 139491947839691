/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { isNaN, isNumber } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  AutoComplete,
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Radio,
  DatePicker,
} from 'antd';
import {
  CloseCircleFilled,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import RichTextEditor from '../RichTextEditor/RichTextEditor.component';

import config from './ModalCreateEntity.component.config';
import './ModalCreateEntity.component.styles.css';

const { Title, Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ModalCreateEntity = ({
  title,
  showModal,
  closeModal,
  actionCloseModal,
  actionCreateActivity,
  isEditData,
  actionUpdateActivity,
  entitySelection,
  listQuiz,
  includeSchedule,
  fieldSuggestions,
  ...props
}) => {
  const refForm = React.useRef();
  const [stateEditForm, setStateEditForm] = React.useState({
    loadEditData: true,
  });
  const [descriptionList, setDescriptionList] = React.useState([
    {
      key: 'itemdesc-1',
      description: '',
      duration: '',
    },
  ]);
  const [entType, setEntType] = useState({ type: 1, condition: 1, material: true});
  const getValueFromRichText = (richValue) => {
    refForm?.current?.setFieldsValue({
      material_link: richValue,
    });
  };
  const getValueFromRichTextExtend = (richValue) => {
    refForm?.current?.setFieldsValue({
      material_additional: richValue,
    });
  };
  const findQuizObject = (quizRef) => {
    let findObject = null;
    findObject = listQuiz.find((items) => items.id === Number(quizRef));
    if (findObject === undefined || isNaN(Number(quizRef))) {
      findObject = listQuiz.find((items) => items.quiz_name === quizRef);
    }
    return findObject;
  };
  const requireForType = (e) => {
    if (e === 1 || e === 2) {
      // setEntType('text');
      setEntType({...entType, type: 1, condition: 1, material: true});
      if (e === 2) {
        setEntType({...entType, material: false});
      }
    } else if (e === 3 || e === 7 ) {
      // setEntType('selection');
      setEntType({...entType, type: 2, condition: 2, material: true});
    } else if (e === 5) {
      // setEntType('rich editor');
      setEntType({...entType, type: 3, condition: 3, material: true});
    } else if (e === 6) {
      // setEntType('rich editor extend');
      setEntType({...entType, type: 4, condition: 4, material: true});
    } else if (e === 8) {
      setEntType({...entType, type: 4, condition: 5, material: true});
    }
    refForm?.current?.setFieldsValue({
      material_source: 0,
    });
    getValueFromRichText(null);

    if (isEditData && stateEditForm.loadEditData === false) {
      setTimeout(() => {
        setDescriptionList([]);
        let materialObject = entitySelection?.material_link;
        if (
          entitySelection?.activity_type_id === 3 ||
          entitySelection?.activity_type_id === 7
        ) {
          materialObject = findQuizObject(
            entitySelection?.material_link,
          ).quiz_name;
        }
        let fields = {
          entity_name: entitySelection?.entity_name,
          material_source:
            entitySelection?.material_source === null
              ? 0
              : entitySelection?.material_source,
          material_link: materialObject,
          material_additional: entitySelection?.material_additional,
        };

        setDescriptionList(entitySelection?.description);

        if (includeSchedule) {
          if (entitySelection?.schedule_date !== null) {
            fields = {
              schedule_date: moment(entitySelection?.schedule_date),
              ...fields,
            };
          }
        }
        refForm?.current?.setFieldsValue(fields);
      }, 1000);
    }
  };

  const renderFieldByEntity = (formType) => {
    // console.log(listQuiz)
    let itemForm = null;
    const listQuizOptions = listQuiz
      .map((quiz) => ({ ...quiz, key: quiz.id, value: quiz.quiz_name }))
      .sort((a, b) => Number(b.id) - Number(a.id));
    // .sort((a, b) => {
    //   let x = a.value;
    //   if (!x === true) x = '';
    //   let y = b.value;
    //   if (!y === true) y = '';
    //   let result = 0;
    //   if (x < y) result = -1;
    //   if (x > y) result = 1;
    //   return result;
    // });
    switch (formType.type) {
      // case 'selection':
      //   itemForm = (
      //     <Select allowClear placeholder="choose bank quiz...">
      //       {listQuiz.map((quiz) => (
      //         <Option value={quiz.id}>{quiz.quiz_name}</Option>
      //       ))}
      //     </Select>
      //   );
      //   break;
      case 2:
        itemForm = (
          <AutoComplete
            options={listQuizOptions}
            filterOption={(val, option) =>
              option.value.toLowerCase().includes(val.toLowerCase())
            }
          />
        );
        break;
      case 3:
        itemForm = (
          <div>
            <RichTextEditor
              defaultValue={isEditData ? entitySelection?.material_link : ''}
              actionGetValue={getValueFromRichText}
            />
            <Input type="text" placeholder="input here ..." hidden />
          </div>
        );
        break;
      case 4:
        itemForm = <TextArea type="text" placeholder="input here ..." rows={5} />;
        break;
      default:
        itemForm = <Input type="text" placeholder="input here ..." />;
        break;
    }
    return itemForm;
  };

  const updateDescriptionlist = (key, value) => {
    const findItem = descriptionList.find((item) => item.key === key);
    findItem.description = value;
    console.log(descriptionList);
  };
  const updateDurationlist = (key, value) => {
    const findItem = descriptionList.find((item) => item.key === key);
    findItem.duration = value;
    console.log(descriptionList);
  };

  const addDescription = () => {
    const getNewKey = Number(descriptionList[descriptionList.length - 1].key.split('-')[1]);
    const newData = [
      ...descriptionList,
      {
        key: `itemdesc-${getNewKey + 1}`,
        description: '',
        duration: '',
      },
    ];
    setDescriptionList(newData);
  };
  const removeDescription = (key) => {
    const newData = descriptionList.filter((item) =>
      item.key !== key
    );
    setDescriptionList(newData);
  };

  useEffect(() => {
    if (isEditData && stateEditForm.loadEditData) {
      setDescriptionList([]);
      setTimeout(() => {
        console.log('lodata');
        requireForType(entitySelection?.activity_type_id);
        let materialObject = entitySelection?.material_link;
        if (
          entitySelection?.activity_type_id === 3 ||
          entitySelection?.activity_type_id === 7
        ) {
          materialObject = findQuizObject(
            entitySelection?.material_link,
          ).quiz_name;
        }
        let fields = {
          activity_type: entitySelection?.activity_type_id,
          entity_name: entitySelection?.entity_name,
          material_source:
            entitySelection?.material_source === null
              ? 0
              : entitySelection?.material_source,
          material_link: materialObject,
          material_additional: entitySelection?.material_additional,
        };

        setDescriptionList(entitySelection?.description);

        if (includeSchedule) {
          if (entitySelection?.schedule_date !== null) {
            fields = {
              schedule_date: moment(entitySelection?.schedule_date),
              ...fields,
            };
          }
        }
        refForm?.current?.setFieldsValue(fields);
      }, 1000);
      setStateEditForm({ ...stateEditForm, loadEditData: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditData, stateEditForm.loadEditData]);

  return (
    <Modal
      title={
        <div className="header-custom">
          <Title level={3}>{title}</Title>
        </div>
      }
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => {
        requireForType();
        closeModal({ action: actionCloseModal });
      }}
      footer={null}
      width={550}
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="vertical"
        name="createEntity"
        onFinish={() => {
          const fields = refForm.current.getFieldsValue();
          const quizObject = findQuizObject(fields.material_link);
          const repopulateDescription = JSON.stringify(descriptionList);
          let rePopulateRequestBody = {
            ...fields,
            description: repopulateDescription
          };

          if (quizObject !== undefined) {
            rePopulateRequestBody = {
              ...fields,
              material_link: quizObject.id,
              description: repopulateDescription
            };
          }
          if (isEditData) {
            actionUpdateActivity(rePopulateRequestBody);
          } else {
            actionCreateActivity(rePopulateRequestBody);
          }
          requireForType(1);
        }}
      >
        <Form.Item
          label="Activity Entity Type"
          name="activity_type"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Select allowClear placeholder="choose..." onChange={requireForType}>
            {/* <Option value="1">Video Confrence</Option>
            <Option value="2">Video Learning</Option>
            <Option value="3">Quiz</Option>
            <Option value="5">Class Material</Option>
            <Option value="6">Assignment Submission</Option> */}
            {fieldSuggestions?.program_types.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Activity Entity Name"
          name="entity_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Material Source"
          name="material_source"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Radio.Group defaultValue={0} disabled={entType.material}>
            <Radio value={0}>Original</Radio>
            <Radio value={1}>AWS S3</Radio>
            <Radio value={2}>Other</Radio>
            <Radio value={3}>Youtube</Radio>
          </Radio.Group>
        </Form.Item>
        {entType.type !== 4 && (
          <Form.Item
            label="Material"
            name="material_link"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            {renderFieldByEntity(entType)}
          </Form.Item>
        )}
        {entType.type === 4 && (
          <div>
            <Form.Item
              label="Assignment Detail"
              name="material_additional"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <div>
                <RichTextEditor
                  defaultValue={isEditData ? entitySelection?.material_additional : ''}
                  actionGetValue={getValueFromRichTextExtend}
                />
                <Input type="text" placeholder="input here ..." hidden />
              </div>
            </Form.Item>
            <Form.Item
              label={`${entType.condition === 4 ? 'Weekly' : 'Final Group'} Assignment Brief Ember Link`}
              name="material_link"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              {renderFieldByEntity(entType)}
            </Form.Item>
          </div>
        )}
        {entType.type === 2 && (
          <Paragraph style={{ fontSize: 12 }}>
            Can&apos;t find your target quiz? Create new quiz{' '}
            <Link
              to="/admin/assignment"
              onClick={() => localStorage.setItem('jumpToCreateQuiz', true)}
            >
              here
            </Link>
          </Paragraph>
        )}
        <Form.Item label="Description" name="description">
          {descriptionList.map((itemDescription) => (
            <div className="item-description" key={itemDescription.key}>
              <Input.TextArea
                rows={3}
                maxLength={512}
                showCount
                type="text"
                className="description"
                placeholder="Notes: Max 512 Character"
                defaultValue={itemDescription.description}
                onChange={(val) =>
                  updateDescriptionlist(itemDescription.key, val.target.value)
                }
              />
              <Input
                type="text"
                className="duration"
                placeholder="input duration here ..."
                defaultValue={itemDescription.duration}
                onChange={(val) =>
                  updateDurationlist(itemDescription.key, val.target.value)
                }
              />
              <div className="action-btn">
                <Button
                  icon={<PlusOutlined />}
                  type="ghost"
                  size="small"
                  onClick={() => addDescription()}
                />
                <Button
                  icon={<MinusOutlined />}
                  type="primary"
                  size="small"
                  onClick={() => removeDescription(itemDescription.key)}
                />
              </div>
            </div>
          ))}
        </Form.Item>
        {includeSchedule && (
          <Form.Item label="Set Schedule" name="schedule_date">
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm:00"
              // onChange={(e) => {
              //   console.log(moment(e).format('YYYY-MM-DD H:m:s'));
              // }}
              style={{ width: '100%' }}
              allowClear
            />
          </Form.Item>
        )}
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => {
                requireForType();
                closeModal({ action: actionCloseModal });
              }}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              {isEditData ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalCreateEntity.displayName = config.displayName;
ModalCreateEntity.defaultProps = config.defaultProps;
ModalCreateEntity.propTypes = config.propTypes;

export default ModalCreateEntity;
