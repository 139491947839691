import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Image, Layout, Menu, Tabs, Typography } from 'antd';
import parse from 'html-react-parser';
import { get } from 'lodash';
import {
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileOutlined,
  LeftOutlined,
  PaperClipOutlined,
  YoutubeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useSelector } from 'react-redux';
import { current } from '@reduxjs/toolkit';
import confirm from 'antd/lib/modal/confirm';
import {
  MotionPage,
  ReactPlayerCustom,
  VideoPlayerCustom,
} from '../../components';
import config from './NewMaincourse.component.config';
import YoutubePlayerCustom from '../../components/YoutubePlayerCustom/YoutubePlayerCustom.component';

const { Header, Sider, Content } = Layout;

const contentStyle = {
  color: '#fff',
  backgroundColor: '#5f5f5f',
  padding: 30,
};

const getIcon = (activityType) => {
  let icon = null;
  switch (activityType) {
    case 2:
      icon = (
        <>
          {/* {val ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )} */}
          <YoutubeOutlined />
        </>
      );
      break;
    case 3:
      icon = (
        <>
          {/* {val ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )} */}
          <FileDoneOutlined />
        </>
      );

      break;
    case 6:
      icon = (
        <>
          {/* {val ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )} */}
          <PaperClipOutlined />
        </>
      );

      break;

    default:
      icon = (
        <>
          {/* {val ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )} */}
          <FileOutlined />
        </>
      );

      break;
  }

  return icon;
};

const NewMaincourse = () => {
  const navigate = useNavigate();
  const [isEntityRunning, setIsEntityRunning] = useState(false);
  const course = useSelector((state) => state.student.courseDetail.data);
  // eslint-disable-next-line
  const [selectedEntity, setSelectedEntity] = useState(course.activity_list[0].entity_list[0]);
  const [currentKey, setCurrentKey] = useState(selectedEntity.id);
  const rootSubmenuKeys = course.activity_list.map((items) => items.id);
  const [openKeys, setOpenKeys] = useState([course.activity_list[0].id]);
  const showConfirm = ({ ...option }) => {
    confirm({
      title: 'PERHATIAN!',
      icon: <ExclamationCircleOutlined />,
      content: 'asd',
      okText: 'Tetap di sini',
      cancelText: 'Tinggalkan dulu',
      // onOk: () => onCancelPopModal(option),
      // onCancel: () => onOKPopModal(option),
    });
  };
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const generateContentVideo = (contentMaterial) => {
    const materialSource = get(contentMaterial, 'material_source');
    let content = null;
    if (
      materialSource === null ||
      materialSource === 0 ||
      materialSource === 1
    ) {
      content = (
        <ReactPlayerCustom
          roles="learner"
          material={contentMaterial?.material_link}
          entityID={selectedEntity.entityID}
          setIsEntityRunning={setIsEntityRunning}
          // activitySessionUser={activitySessionUser}
          // onCreateActivitySession={onCreateActivitySession}
          // onUpdateActivitySession={onUpdateActivitySession}
          // onUpdateStateSession={onUpdateStateSession}
        />
      );
    } else if (materialSource === 2) {
      content = (
        <VideoPlayerCustom
          roles="learner"
          material={contentMaterial?.material_link}
          entityID={selectedEntity.entityID}
          setIsEntityRunning={setIsEntityRunning}
          // activitySessionUser={activitySessionUser}
          // onCreateActivitySession={onCreateActivitySession}
          // onUpdateActivitySession={onUpdateActivitySession}
          // onUpdateStateSession={onUpdateStateSession}
        />
      );
    } else if (materialSource === 3) {
      content = (
        <YoutubePlayerCustom material={contentMaterial?.material_link} />
      );
    }
    return content;
  };

  const handleClick = (e) => {
    if (selectedEntity.activity_type_id === 2 && isEntityRunning) {
      console.log('Video is Running');
    } else {
      console.log('Video Stopped');
    }
    const activityData = course.activity_list.find(
      (data) => data.id === e.keyPath[1], // e.keyPath[1] is Activity ID
    );
    const entityData = activityData.entity_list.find(
      (data) => data.id === e.keyPath[0], // e.keyPath[0] is Entity ID
    );
    setSelectedEntity(entityData);
    setCurrentKey(entityData.id);
  };

  const mappedData = course.activity_list.map((item) => ({
    label: item.activity_name,
    key: item.id,
    disabled: item.is_active === 0,
    children: item.entity_list.map((data) => ({
      label: data.entity_name,
      icon: getIcon(data.activity_type_id),
      key: data.id,
    })),
  }));

  const renderSelectedEntity = (contentType, contentMaterial) => {
    let currentContent = null;
    switch (Number(contentType)) {
      case 1:
        currentContent = (
          <div
            className="content-1"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : '48px 24px 24px 24px',
              height: 567,
              maxHeight: 'auto',
              width: '70%',
              margin: !isMobile && 'auto',
            }}
          >
            <Typography.Text>
              Please join at :{' '}
              <a href={contentMaterial?.material_link} target="_blank">
                {contentMaterial?.material_link}
              </a>
            </Typography.Text>
          </div>
        );
        break;
      case 2:
        // currentContent = <p>Video Content</p>;
        currentContent = (
          <div className="content-2">
            {generateContentVideo(contentMaterial)}
          </div>
        );
        break;
      case 3:
        currentContent = <p>Quiz</p>;
        // content = (
        //   <div
        //     className="content-3"
        //     style={{
        //       backgroundColor: '#ffffff',
        //       padding: isMobile ? 16 : '48px 24px 24px 24px',
        //       minHeight: 567,
        //       maxHeight: 'auto',
        //       width: isMobile ? '100%' : '70%',
        //       margin: !isMobile && 'auto',
        //     }}
        //   >
        //     <ContentQuiz
        //       quizData={contentMaterial?.quizData}
        //       selectionContent={selectionContent}
        //       activitySessionUser={activitySessionUser}
        //       onCreateActivitySession={onCreateActivitySession}
        //       onUpdateQuizSubmission={onUpdateQuizSubmission}
        //       onUpdateStateSession={onUpdateStateSession}
        //       quizSubmissionHistory={quizSubmissionHistory}
        //     />
        //   </div>
        // );
        break;

      case 5:
        currentContent = (
          <div
            className="content-5"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : '5% 20%',
              height: 567,
              margin: !isMobile && 'auto',
              overflowY: 'scroll',
            }}
          >
            {parse(contentMaterial?.material_link)}
          </div>
        );
        break;
      case 6:
        currentContent = (
          <div
            className="content-6"
            style={{
              backgroundColor: '#ffffff',
              padding: isMobile ? 16 : '48px 24px 24px 24px',
              height: 567,
              width: isMobile ? '100%' : '70%',
              margin: !isMobile && 'auto',
            }}
          >
            {parse(contentMaterial?.material_link)}
          </div>
        );
        break;
      case 7:
        currentContent = <p>Certification Quiz</p>;
        // currentContent = (
        //   <div
        //     className="content-7"
        //     style={{
        //       width: isMobile ? '100%' : '70%',
        //       margin: !isMobile && 'auto',
        //       minHeight: 567,
        //       maxHeight: 'auto',
        //     }}
        //   >
        //     <ContentQuiz
        //       openModal={openModal}
        //       selectedProgramId={programSelection.id}
        //       quizData={contentMaterial?.quizData}
        //       selectionContent={selectionContent}
        //       activitySessionUser={activitySessionUser}
        //       onCreateActivitySession={onCreateActivitySession}
        //       onUpdateQuizSubmission={onUpdateQuizSubmission}
        //       onUpdateStateSession={onUpdateStateSession}
        //       quizSubmissionHistory={quizSubmissionHistory}
        //       validateClaimingCertificate={onValidateClaimingCertif}
        //     />
        //   </div>
        // );
        break;
      default:
        currentContent = null;
        break;
    }
    return currentContent;
  };

  // useEffect(() => {
  //   console.log('Data: ', course);
  // }, [course]);

  return (
    <Layout
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0%',
        height: 'auto',
        minHeight: '100vh',
      }}
    >
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <div>
          <Button
            size="large"
            type="text"
            style={{ color: '#c9246b' }}
            icon={<LeftOutlined />}
            onClick={() => navigate('/student/dashboard')}
          />
          {!isMobile && (
            <Image
              src={
                isMobile
                  ? 'https://bolehbelajar.com/wp-content/uploads/2021/08/cropped-icon-boleh-belajar-red-300x300.png'
                  : 'https://bolehbelajar.com/wp-content/uploads/2021/08/boleh-belajar-red.png'
              }
              className="logo"
              preview={false}
              width={isMobile ? 30 : 120}
              onClick={() => navigate('/student/dashboard')}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
        <Typography.Title level={4} style={{ margin: 'unset', color: 'black' }}>
          {course.program_name}
        </Typography.Title>
      </Header>
      <Layout hasSider>
        <Sider width={350} theme="light">
          <Menu
            id="menu-sidebar"
            mode="inline"
            theme="light"
            onClick={handleClick}
            openKeys={openKeys}
            selectedKeys={[currentKey]}
            onOpenChange={onOpenChange}
            items={mappedData}
          />
        </Sider>
        <Content style={contentStyle}>
          <div
            style={{
              backgroundColor: '#fff',
              color: '#333',
              minHeight: 567,
              borderRadius: 16,
              padding: 24,
            }}
          >
            {renderSelectedEntity(
              selectedEntity.activity_type_id,
              selectedEntity,
            )}
          </div>
          <Card
            style={{
              // marginLeft: !isMobile && 24,
              // marginRight: !isMobile && 24,
              marginTop: 16,
              backgroundColor: '#333',
              color: '#fff',
              border: 'none',
            }}
          >
            <div style={{ color: '#333' }}>
              <Tabs
                items={[
                  {
                    key: 1,
                    label: 'Deskripsi',
                    children: <Paragraph>Name Class</Paragraph>,
                  },
                  {
                    key: 2,
                    label: 'Reviews',
                    children: <Paragraph>Reviews Content</Paragraph>,
                  },
                  {
                    key: 3,
                    label: 'T&J',
                    children: <Paragraph>Discussion</Paragraph>,
                  },
                ]}
              />
            </div>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

NewMaincourse.displayName = config.displayName;
NewMaincourse.defaultProps = config.defaultProps;
NewMaincourse.propTypes = config.propTypes;

export default NewMaincourse;
