import {
  CaretRightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PauseOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import { Button, Image, Progress, Slider, Tooltip, Typography } from 'antd';
import { round } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import ReactPlayer from 'react-player';

import { useSelector } from 'react-redux';
import { IMG_REWIND } from '../../../../assets/svg';
import config from './VideoPlayer.component.config';
import './VideoPlayer.component.styles.css';

const { Text } = Typography;

const VideoPlayer = ({
  material,
  roles,
  selectionContent,
  entityID,
  setIsEntityRunning,
  activitySessionUser,
  onCreateActivitySession,
  onUpdateActivitySession,
  onUpdateStateSession,
  contentVideoPlaying,
  ...props
}) => {
  const refVideoPlayer = React.useRef();
  const handleFullScreen = useFullScreenHandle();

  const stylePlayer = () => {
    let styles = null;
    if (handleFullScreen.active)
      styles = {
        width: '100%',
        height: isMobile ? '90%' : '95%',
      };
    return styles;
  };

  const currentTimeFromSession = () => {
    let currentDuration = 0;
    if (activitySessionUser?.status === 1) {
      currentDuration = activitySessionUser.vod_duration;
    } else if (
      activitySessionUser?.vod_current_time !== null &&
      activitySessionUser?.vod_current_time !== undefined
    ) {
      currentDuration = activitySessionUser.vod_current_time / 1000;
    }
    return currentDuration;
  };

  const [stateSession, setStateSession] = React.useState({
    sessionCreated: false,
    sessionUpdate: false,
    sessionFirstLoad: false,
  });

  const [videoState, videoSetState] = React.useState({
    url: material,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.1,
    muted: false,
    played: parseFloat(currentTimeFromSession()),
    lastPlayed: parseFloat(currentTimeFromSession()),
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    seeking: false,
    lastMinutes: 0,
    currentMinutes: 0,
    showVolume: false,
  });

  const formatDuration = (seconds) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = `0${date.getUTCSeconds()}`.slice(-2);
    if (hh) {
      return `${hh}:${`0${mm}`.slice(-2)}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const seekControls = ({ ...action }) => {
    const roleUser = roles;
    const seekValueUpdate = action.seekVal;
    videoSetState((prevState) => {
      const currentState = { ...prevState };
      switch (action.control) {
        case 'mouseDown':
          currentState.seeking = true;
          currentState.played = parseFloat(seekValueUpdate);
          refVideoPlayer.current.player.seekTo(parseFloat(seekValueUpdate));
          break;
        case 'mouseUp':
          currentState.seeking = false;
          // if (roleUser === 'learner' || roleUser === 2) {
          //   if (currentStatusWatching === 1) {
          //     seekValueUpdate = action.seekVal;
          //   } else if (action.seekVal > currentState.lastPlayed) {
          //     seekValueUpdate = currentState.lastPlayed;
          //   }
          // }
          currentState.played = parseFloat(seekValueUpdate);
          refVideoPlayer.current.player.seekTo(parseFloat(seekValueUpdate));
          break;
        case 'seek':
          // if (roleUser === 'learner' || roleUser === 2) {
          //   if (currentStatusWatching === 1) {
          //     seekValueUpdate = action.seekVal;
          //   } else if (action.seekVal > currentState.lastPlayed) {
          //     seekValueUpdate = currentState.lastPlayed;
          //   }
          // }
          currentState.played = parseFloat(seekValueUpdate);
          refVideoPlayer.current.player.seekTo(parseFloat(seekValueUpdate));
          break;
        default:
          break;
      }
      return { ...currentState };
    });
  };

  const onPlayerHandleProgress = (progress) => {
    videoSetState((prevState) => {
      const currentState = { ...prevState };
      if (stateSession.sessionFirstLoad === false) {
        if (activitySessionUser?.status === 0) {
          currentState.played = parseFloat(currentTimeFromSession());
        } else {
          currentState.played = parseFloat(0);
        }
      } else if (!videoState.seeking) {
        currentState.loaded = parseFloat(progress.loaded);
        currentState.played = parseFloat(progress.played);
      }
      return { ...currentState };
    });
  };

  const onPlayerHandleDuration = (duration) => {
    videoSetState((prevState) => {
      const currentState = { ...prevState };
      currentState.duration = duration;
      return { ...currentState };
    });
  };

  const createSessionFirst = () => {
    setStateSession((prevState) => {
      const currentState = { ...prevState };
      currentState.sessionCreated = !currentState.sessionCreated;
      return { ...currentState };
    });
    const paramsSession = {
      entity_id: selectionContent?.entityID,
      vod_duration: videoState.duration,
    };
    if (activitySessionUser?.id === null) {
      // create session when learner watching first time
      if (onCreateActivitySession !== undefined) {
        onCreateActivitySession(paramsSession);
      }
    }
  };

  const updateSessionCurrentVideo = (isFinish) => {
    if (onUpdateActivitySession !== undefined) {
      if (activitySessionUser.status !== 1) {
        onUpdateActivitySession({
          sessionID: activitySessionUser?.id,
          vod_current_time: isFinish
            ? videoState.duration
            : round(videoState.played * 1000) + 1,
          status: isFinish ? 1 : 0,
        });
      }
      if (isFinish) {
        // if user finish whatching the video change state to false
        onUpdateStateSession({
          video_playing: false,
        });
      }
    }
  };

  const forwardSessionCurrentState = ({ ...params }) => {
    if (onUpdateStateSession !== undefined) {
      const paramsUpdateState = {
        video_playing: params?.playing,
      };
      onUpdateStateSession(paramsUpdateState);
    }
  };

  const mainControls = ({ ...action }) => {
    videoSetState((prevState) => {
      const currentState = { ...prevState };
      switch (action.control) {
        case 'stop':
          currentState.playing = false;
          if (currentState.played * 1000 > currentTimeFromSession() * 1000) {
            updateSessionCurrentVideo();
          }
          forwardSessionCurrentState({ ...currentState });
          break;
        case 'play':
          currentState.playing = !currentState.playing;
          if (activitySessionUser?.id === null) {
            createSessionFirst();
          }
          if (stateSession.sessionFirstLoad === false) {
            // when user not yet to finish watching, run this script
            if (
              activitySessionUser?.status === 0 &&
              currentTimeFromSession() > 0
            ) {
              refVideoPlayer.current.player.seekTo(
                parseFloat(currentTimeFromSession()),
              );
              currentState.lastPlayed = currentTimeFromSession();
            }
            setStateSession((prevStateSession) => {
              const currentSession = { ...prevStateSession };
              currentSession.sessionFirstLoad = true;
              return { ...currentSession };
            });
          }
          forwardSessionCurrentState({ ...currentState });
          break;
        case 'rewind':
          refVideoPlayer.current.player.seekTo(
            parseFloat(currentState.played - 10 / 1000),
          );
          break;
        case 'enablepip':
          currentState.pip = !currentState.pip;
          break;
        default:
          break;
      }
      return { ...currentState };
    });
  };

  const interval = React.useRef(null);
  useEffect(() => {
    // useEffect for updating session video running
    // console.log('statePlay: ', state);
    function startTimer() {
      interval.current = setInterval(() => {
        const date = new Date(
          videoState.duration * (1 - videoState.played) * 1000,
        );
        const currentMinutes = date.getUTCMinutes();
        const currentSecconds = `0${date.getUTCSeconds()}`.slice(-2);
        videoSetState((prevState) => {
          const currentState = { ...prevState };
          if (currentState.lastMinutes !== currentMinutes) {
            // logic interval updated for progress watching
            const currentTimePlayed = currentState.played * 1000;
            if (currentTimePlayed > currentTimeFromSession() * 1000) {
              updateSessionCurrentVideo();
              currentState.lastPlayed = currentState.played;
            }
            currentState.lastMinutes = currentMinutes;
          } else if (
            currentMinutes === 0 &&
            parseFloat(currentSecconds) > 1 &&
            parseFloat(currentSecconds) < 10
          ) {
            // logic if learner watching, last 30 seconds, update session to finish
            updateSessionCurrentVideo(true);
          }
          return { ...currentState };
        });
      }, 1000);
    }
    if (videoState.playing) startTimer();
    return () => clearInterval(interval.current); // cleanup
  });

  useEffect(() => {
    if (isMobile) {
      const handleBack = () => {
        window.history.forward();
      };

      // Attach the function to the popstate event to prevent browser back navigation
      window.addEventListener('popstate', handleBack);

      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener('popstate', handleBack);
      };
    }

    return false;
  }, []);

  return (
    <div
      id="VideoPlayer"
      style={{
        borderRadius: 12,
      }}
    >
      <div
        className={`player ${isMobile && 'mobile'}`}
        onContextMenu={(e) => e.preventDefault()}
      >
        <FullScreen handle={handleFullScreen}>
          <div className="playerComponent" style={stylePlayer()}>
            {/* <div className="video-progress">
              Completion: <b>{activitySessionUser?.vod_completion}%</b>
            </div> */}
            <ReactPlayer
              className={`react-player-res ${isMobile && 'mobile'}`}
              ref={refVideoPlayer}
              width="100%"
              height="100%"
              url={material}
              pip={videoState.pip}
              playing={contentVideoPlaying}
              controls={videoState.controls}
              light={videoState.light}
              loop={videoState.loop}
              playbackRate={videoState.playbackRate}
              volume={videoState.volume}
              muted={videoState.muted}
              onProgress={onPlayerHandleProgress}
              onDuration={onPlayerHandleDuration}
            />
            <div className="panel-control">
              <div className="seek-timer">
                <Button
                  size="large"
                  type="primary"
                  onClick={() =>
                    mainControls({
                      control: !videoState.playing ? 'play' : 'stop',
                    })
                  }
                  icon={
                    contentVideoPlaying ? (
                      <PauseOutlined />
                    ) : (
                      <CaretRightOutlined />
                    )
                  }
                  style={{ marginRight: 5 }}
                />
                {!isMobile && (
                  <Button
                    size="large"
                    type="primary"
                    onClick={() =>
                      mainControls({
                        control: 'rewind',
                      })
                    }
                    icon={<Image src={IMG_REWIND} preview={false} width={20} />}
                    style={{ marginRight: 20 }}
                  />
                )}
                <div className="seeker-input">
                  <Text style={{ fontSize: 12, margin: 'auto' }}>
                    {/* {formatDuration(
                      videoState.duration *
                        (!stateSession.sessionFirstLoad
                          ? videoState.lastPlayed
                          : videoState.played),
                    )} */}
                    {formatDuration(videoState.duration * videoState.played)}
                  </Text>
                  <Slider
                    min={0}
                    max={1}
                    step={0.001}
                    tooltip={{
                      open: false,
                    }}
                    // value={
                    //   !stateSession.sessionFirstLoad
                    //     ? videoState.lastPlayed
                    //     : videoState.played
                    // }
                    value={videoState.played}
                    onChange={(value) => {
                      seekControls({
                        control: 'seek',
                        seekVal: value,
                      });
                    }}
                  />
                  <Text style={{ fontSize: 12, margin: 'auto' }}>
                    {formatDuration(
                      videoState.duration * (1 - videoState.played),
                    )}
                  </Text>
                </div>
                <Button
                  className="button-volume"
                  size="large"
                  type="primary"
                  icon={<SoundOutlined />}
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    videoSetState((prevState) => {
                      const currentState = { ...prevState };
                      currentState.showVolume = !currentState.showVolume;
                      return { ...currentState };
                    });
                  }}
                />
                {videoState.showVolume && (
                  <Slider
                    style={{ width: 100 }}
                    min={0}
                    max={1}
                    step={0.01}
                    value={videoState.volume}
                    tooltip={{
                      open: false,
                    }}
                    onChange={(value) => {
                      videoSetState((prevState) => {
                        const currentState = { ...prevState };
                        currentState.volume = value;
                        return { ...currentState };
                      });
                    }}
                  />
                )}
                <Button
                  size="large"
                  type="primary"
                  icon={
                    handleFullScreen.active ? (
                      <FullscreenExitOutlined />
                    ) : (
                      <FullscreenOutlined />
                    )
                  }
                  onClick={() => {
                    if (handleFullScreen.active) {
                      handleFullScreen.exit();
                    } else {
                      handleFullScreen.enter();
                    }
                  }}
                  style={{ marginLeft: 10 }}
                />
                <div className="loaded-video">
                  <Tooltip
                    placement="topLeft"
                    title={`${round(
                      videoState.loaded * 100,
                    )}% video pembelajaran dimuat`}
                    color="#C9264B"
                    key="#C9264B"
                  >
                    <Progress
                      type="circle"
                      percent={round(videoState.loaded * 100)}
                      width={25}
                      strokeColor="#C9264B"
                      strokeWidth={8}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </FullScreen>
      </div>
    </div>
  );
};

VideoPlayer.displayName = config.displayName;
VideoPlayer.defaultProps = config.defaultProps;
VideoPlayer.propTypes = config.propTypes;

export default VideoPlayer;
