import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import moment from 'moment';
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Upload,
  DatePicker,
  Row,
  Col,
} from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../../../services/aws.service';

import config from './ModalEdit.component.config';
import './ModalEdit.component.styles.css';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ModalEdit = ({
  activityData,
  actionUpdateActivity,
  showModal,
  closeModal,
  actionClose,
  ...props
}) => {
  const refForm = React.useRef();
  setTimeout(() => {
    let fields = {
      activity_name: activityData?.activity_name,
      activity_tags: activityData?.activity_tags,
      description: activityData?.description,
      status: `${activityData?.is_active}`,
    };
    if (activityData?.scheduled_date_start !== null) {
      fields = {
        schedule_start: moment(activityData?.scheduled_date_start),
        ...fields,
      };
    }
    if (activityData?.scheduled_date_end !== null) {
      fields = {
        schedule_end: moment(activityData?.scheduled_date_end),
        ...fields,
      };
    }
    if (activityData?.moderator_id !== null) {
      fields = {
        moderator: `${activityData?.moderator_id}`,
        ...fields,
      };
    }
    refForm?.current?.setFieldsValue(fields);
  }, 500);
  const [state, setState] = useState({
    fileList: [],
    onUploadFile: false,
  });
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityData]);
  const actionUploadBanner = async (upload) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.onUploadFile = !currentState.onUploadFile;
      return { ...currentState };
    });
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/programs',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };
  return (
    <Modal
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => closeModal({ action: 'editActivity' })}
      footer={null}
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="vertical"
        name="ModalEdit"
        onFinish={() => {
          const fields = refForm.current.getFieldsValue();
          const updateData = {
            ...fields,
            id: activityData?.id,
            // scheduled_date_start: moment(fields.schedule_start).format(
            //   'YYYY-MM-DD H:m:s',
            // ),
            // scheduled_date_end: moment(fields.schedule_end).format(
            //   'YYYY-MM-DD H:m:s',
            // ),
            // moderator_id: fields?.moderator, // temporary hidden, reason: user as moderator not yet genrate
            moderator_id: 0,
            is_active: fields?.status,
            banner_image:
              state?.fileList[0]?.uid !== undefined
                ? state?.fileList[0]?.uid
                : '',
          };
          actionUpdateActivity(updateData);
          closeModal({ action: actionClose });
        }}
      >
        <Row gutter={24}>
          {/* <Col md={24}>
            <Form.Item
              label="Moderator"
              name="moderator"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Dont make me empty :(',
              //   },
              // ]}
            >
              <Select
                showSearch
                placeholder="Select a moderator"
                optionFilterProp="children"
                onChange={(e) => console.log(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="0">Moch. Dicky</Option>
                <Option value="1">Fadhil</Option>
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col flex="auto">
            <Form.Item label="Schedule start" name="schedule_start">
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm:00"
                onChange={(e) => {
                  console.log(moment(e).format('YYYY-MM-DD H:m:s'));
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item label="Schedule end" name="schedule_end">
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm:00"
                onChange={(e) => {
                  console.log(moment(e).format('YYYY-MM-DD H:m:s'));
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col> */}
          <Col flex="auto">
            <Form.Item
              label="Activity Name"
              name="activity_name"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input type="text" placeholder="input here ..." />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item
              label="Activity Tags"
              name="activity_tags"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
            >
              <Input type="text" placeholder="input here ..." />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Description" name="description">
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <Form.Item label="Upload Banner">
          <Form.Item name="banner_image" noStyle hidden={state.onUploadFile}>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={actionUploadBanner}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <div hidden={!state.onUploadFile} style={{ textAlign: 'center' }}>
            <p>Banner uploading, please wait...</p>
          </div>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select allowClear placeholder="choose...">
            <Option value="0">Inactive</Option>
            <Option value="1">Active</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => closeModal({ action: actionClose })}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalEdit.displayName = config.displayName;
ModalEdit.defaultProps = config.defaultProps;
ModalEdit.propTypes = config.propTypes;

export default ModalEdit;
