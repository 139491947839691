import { connect } from 'react-redux';
import StudentCertificate from './StudentCertificate.component';

import { getCertifList } from '../../../redux/actions/student.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  users: state.users,
  certificateList: state.student.certifList,
});

const mapDispatchToProps = {
  getCertifList,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentCertificate);
