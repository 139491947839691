import { SET_SEARCH_TEXT } from '../reduxType';

const initialState = '';

export default (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_SEARCH_TEXT:
      return action?.payload;
    default:
      return state;
  }
};
