import React from 'react';
import { Button, Card, Form, Input, Layout, Typography } from 'antd';

import { BaseLayout } from '../../components';

import config from './UserHelp.component.config';
import './UserHelp.component.styles.css';

const { Title } = Typography;

const UserHelp = ({ ...props }) => {
  const refUser = React.useRef();
  return (
    <BaseLayout title="Help.">
      <div id="UserHelp">help</div>
    </BaseLayout>
  );
};

UserHelp.displayName = config.displayName;
UserHelp.defaultProps = config.defaultProps;
UserHelp.propTypes = config.propTypes;

export default UserHelp;
