import PropTypes from 'prop-types';

const displayName = 'SelectionLearner';
const propTypes = {};
const defaultProps = {
  dummLearner: () => {
    const data = [];
    for (let index = 0; index < 5; ) {
      data.push({
        id: index,
        'learner name': `learner ${index}`,
      });
      index += 1;
    }
    return data;
  },
  dummExistGroup: () => {
    const data = [];
    for (let index = 0; index < 5; ) {
      data.push({
        id: index,
        group: `Group ${index}`,
        instructor: [1, 2, 3],
      });
      index += 1;
    }
    return data;
  },
};

export default { displayName, propTypes, defaultProps };
