import { get } from 'lodash';
import {
  SET_STUDENT_UPEVENTS,
  SET_STUDENT_PROGRAM,
  SET_STUDENT_PROGRAM_ENTITY,
  SET_STUDENT_PROGRAM_LIST,
  SET_STUDENT_QUIZ_DETAIL,
  SET_STUDENT_QUIZ_SUBMISSION,
  SET_STUDENT_PROGRAM_SESSION_ENTITY,
  SET_STUDENT_PROGRAM_SESSION_ENTITY_PREV,
  SET_STUDENT_CERTIFICATE_LIST,
  SET_STUDENT_COURSE_DETAIL,
  SET_STUDENT_LEARNING_PROGRESS,
} from '../reduxType';

import api from '../../services/api.service';
import {
  generateNotification,
  generateHeaderRequest,
  retrieveErrorHandling,
} from '../../utils/general.utils';
import { getToken } from '../../services/token.service';

const { apiService, setAuthToken } = api.getInstance();
const isUserToken = getToken();

export const getUpcomingEvents = () => async (dispatch) => {
  let result = true;
  dispatch({
    type: SET_STUDENT_UPEVENTS,
    payload: {
      loading: true,
    },
  });

  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      '/student/event/upcoming_event',
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_STUDENT_UPEVENTS,
      payload: {
        data: data.data,
        loading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    dispatch({
      type: SET_STUDENT_UPEVENTS,
      payload: {
        loading: false,
      },
    });
    result = Promise.reject();
  }
  return result;
};

export const setStudentProgram = (payload) => async (dispatch) => {
  let result = true;
  try {
    dispatch({
      type: SET_STUDENT_PROGRAM,
      payload: {
        program: payload,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getStudentProgramList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_STUDENT_PROGRAM_LIST,
    payload: {
      loading: false,
    },
  });

  let result = true;
  try {
    const { status, data } = await apiService.post(
      '/program/get_program_list',
      {},
      generateHeaderRequest(),
    );
    const dataProgram = get(data, 'data');
    dispatch({
      type: SET_STUDENT_PROGRAM_LIST,
      payload: {
        data: dataProgram,
        loading: false,
      },
    });
    result = Promise.resolve(get(data, 'data'));
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getStudentCourseList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_STUDENT_PROGRAM_LIST,
    payload: {
      loading: false,
    },
  });

  let result = true;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      '/student/course',
      generateHeaderRequest(),
    );
    const dataProgram = get(data, 'data');
    dispatch({
      type: SET_STUDENT_PROGRAM_LIST,
      payload: {
        data: dataProgram,
        loading: false,
      },
    });
    result = Promise.resolve(dataProgram);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const updateStudentProgramActivity = (payload) => async (dispatch) => {
  let result = true;
  try {
    dispatch({
      type: SET_STUDENT_PROGRAM_ENTITY,
      payload,
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getStudentProgramDetails = (payload) => async (dispatch) => {
  let result = true;
  try {
    const programParrent = { ...payload };
    const programActivity = await apiService.post(
      `/program/get_activity_list?paramProgramId=${payload?.id}`,
      {},
      generateHeaderRequest(),
    );

    const originalData = programActivity?.data?.data;
    const activityData = []; // generating activity list with entity list
    for (let idxActivity = 0; idxActivity < originalData?.length; ) {
      const itemPerActivity = {
        ...originalData[idxActivity],
        entity_list: [],
      };
      // getStudentProgramActivityEntity(itemPerActivity?.id).then(
      //   (respEntity) => {
      //     itemPerActivity.entity_list = respEntity;
      //   },
      // );
      activityData.push(itemPerActivity);
      idxActivity += 1;
    }

    const payloadReducer = {
      data: programParrent,
      activity: activityData,
      activityEntity: [],
      loading: false,
    };
    dispatch({
      type: SET_STUDENT_PROGRAM,
      payload: payloadReducer,
    });
    result = Promise.resolve(activityData);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getStudentProgramActivityEntity =
  (payload) => async (dispatch) => {
    let result = false;
    // if (isUserToken)
    try {
      const { status, data } = await apiService.get(
        `/program/get_act_entity_list?activity_id=${payload}`,
        generateHeaderRequest(),
      );
      dispatch({
        type: SET_STUDENT_PROGRAM_ENTITY,
        payload: {
          activityID: payload,
          list: data?.data,
        },
      });
      result = Promise.resolve(data?.data);
    } catch (error) {
      retrieveErrorHandling(error);
      generateNotification({
        type: 'error',
        response: error?.response?.status,
        messages: error?.response?.data?.detail,
      });
      result = Promise.reject();
    }
    return result;
  };

export const getStudentProgramActivityPerEntity =
  (payload) => async (dispatch) => {
    let result = false;
    // if (isUserToken)
    try {
      const { status, data } = await apiService.get(
        `program/get_act_entity_one?entity_id=${payload}`,
        generateHeaderRequest(),
      );
      result = Promise.resolve(data?.data);
    } catch (error) {
      retrieveErrorHandling(error);
      generateNotification({
        type: 'error',
        response: error?.response?.status,
        messages: error?.response?.data?.detail,
      });
      result = Promise.reject();
    }
    return result;
  };

export const getStudentQuizDetail = (payload) => async (dispatch) => {
  // dispatch({
  //   type: SET_STUDENT_PROGRAM,
  //   payload: {
  //     loading: false,
  //   },
  // });
  let result = true;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/quiz/get_quiz_detail?pQuizId=${payload}`,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_STUDENT_QUIZ_DETAIL,
      payload: {
        data: data?.data[0],
        loading: false,
      },
    });
    result = Promise.resolve(data?.data[0]);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getActivitySession = (payload) => async (dispatch) => {
  let result = true;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/student/get_activity_session?paramEntity_id=${payload.entityID}`,
      generateHeaderRequest(),
    );
    let dataSession = {
      id: null,
      quiz_score: null,
      vod_duration: null,
      vod_current_time: null,
      assignment_submit: null,
      status: null,
      history: [],
    };

    if (data?.data !== null) {
      dataSession = {
        ...dataSession,
        ...data.data[0],
      };
    }
    dispatch({
      type: get(payload, 'isForDependency')
        ? SET_STUDENT_PROGRAM_SESSION_ENTITY_PREV
        : SET_STUDENT_PROGRAM_SESSION_ENTITY,
      payload: {
        entity_id: payload.entityID,
        ...dataSession,
      },
    });
    result = Promise.resolve(dataSession);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const createActivitySession = (payload) => async (dispatch) => {
  let result = true;
  try {
    const { status, data } = await apiService.post(
      `/student/activity_session_start`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_STUDENT_PROGRAM_SESSION_ENTITY,
      payload,
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const updateActivitySession = (payload) => async (dispatch) => {
  let result = true;
  try {
    const { status, data } = await apiService.post(
      `/student/activity_session_update`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const updateQuizSubmission = (payload) => async (dispatch) => {
  let result = true;
  try {
    const { status, data } = await apiService.post(
      `/student/quiz_submission_insert`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const updateAssignmentSubmission = (payload) => async (dispatch) => {
  let result = true;
  try {
    const { status, data } = await apiService.post(
      `/student/assignment_submission_insert`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const updateGroupAssignmentSubmission =
  (payload) => async (dispatch) => {
    let result = true;
    try {
      const { status, data } = await apiService.put(
        `/student/update_activity_submit_group_assignment`,
        {
          ...payload,
        },
        generateHeaderRequest(),
      );
      result = Promise.resolve();
    } catch (error) {
      retrieveErrorHandling(error);
      generateNotification({
        type: 'error',
        response: error?.response?.status,
        messages: error?.response?.data?.detail,
      });
      result = Promise.reject();
    }
    return result;
  };

export const getQuizSubmission = (payload) => async (dispatch) => {
  let result = true;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/student/get_answer_submission?sessionID=${payload}`,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_STUDENT_QUIZ_SUBMISSION,
      payload: {
        data: data.data,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getCertifConfiguration = (payload) => async (dispatch) => {
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/certificate/get_configuration?ProgramTypeID=${payload}`,
      generateHeaderRequest(),
    );

    result = Promise.resolve({ ...data.data });
  } catch (error) {
    retrieveErrorHandling(error);

    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const claimCertificate = (payload) => async (dispatch) => {
  let result;
  try {
    const { status } = await apiService.post(
      '/certificate/claim_certificate_participant',
      { ...payload },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Certificate has been claimed.',
    });

    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);

    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getCourseDetail = (payload) => async (dispatch) => {
  dispatch({
    type: SET_STUDENT_COURSE_DETAIL,
    payload: {
      isLoading: true,
    },
  });
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/program/get_course_detail?programID=${payload}`,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_STUDENT_COURSE_DETAIL,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_STUDENT_COURSE_DETAIL,
      payload: {
        isLoading: true,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getCertifList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_STUDENT_CERTIFICATE_LIST,
    payload: {
      isLoading: true,
    },
  });
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      '/certificate/get_certificate_participant',
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_STUDENT_CERTIFICATE_LIST,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_STUDENT_CERTIFICATE_LIST,
      payload: {
        isLoading: true,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const validateClaimCertif = (payload) => async (dispatch) => {
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/certificate/get_certificate_participant?programID=${payload}`,
      generateHeaderRequest(),
    );

    result = Promise.resolve(data.data);
  } catch (error) {
    retrieveErrorHandling(error);

    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getStudentLearningProgress = (payload) => async (dispatch) => {
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/student/course/progress?programID=${payload}`,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_STUDENT_LEARNING_PROGRESS,
      payload: {
        data: get(data, 'data'),
      },
    });
    result = Promise.resolve(data.data);
  } catch (error) {
    retrieveErrorHandling(error);

    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};
