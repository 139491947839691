import moment from 'moment';
import { isNaN } from 'lodash';

const idLocale = require('moment/locale/id');
moment().locale('id', idLocale);

export const formatDate = (date) => {
  if (date !== null) return moment(new Date(date)).format('dddd, DD MMMM YYYY');
  return '-';
};

export const formatDateSimple = (date) => {
  if (date !== null) return moment(new Date(date)).format('DD MMMM YYYY');
  return '-';
};

export const formatDateTime = (date) => {
  if (date !== null)
    return moment(new Date(date)).format('dddd, DD MMMM YYYY, hh:mm A');
  return '-';
};

export const formatTime = (date) => {
  if (date !== null) return moment(new Date(date)).format('hh:mm A');
  return '-';
};

export const formatNumber = (number, fixed) => {
  const numberParsing = parseFloat(number);
  if (isNaN(numberParsing)) return '-';
  return numberParsing
    .toFixed(fixed)
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const getFormatedValue = (date, valueType) => {
  let value;
  if (valueType === 'year') {
    value = moment(date).year();
  } else {
    value = moment(date).month() + 1;
  }
  return value;
};

export const removeWhitespace = (text) => {
  const cleanedText = text.replace(/\s/g, '');
  return cleanedText;
};

export const getRandomId = () => {
  const result = [];
  for (let i = 0; i < 3; i += 1) {
    const randomNum = Math.floor(Math.random() * 36);
    if (randomNum < 10) {
      result.push(randomNum); // if the random number is less than 10, add it to the result as a number
    } else {
      result.push(String.fromCharCode(randomNum + 55)); // if the random number is greater than or equal to 10, convert it to a letter and add it to the result
    }
  }

  const randomId = result.join('');
  return randomId;
};

export const getInitialClassName = (selectedClassName, initialToTake) => {
  const classNameReady = getClassname(selectedClassName);
  const filteredName = classNameReady.split(' ');
  const NPCode = [];
  // eslint-disable-next-line
  for (let i = 0; i < initialToTake; i++) {
    NPCode.push(filteredName[i].charAt(0).toUpperCase());
  }
  const programNameInitial = NPCode.join('');
  return programNameInitial;
};

export const getClassname = (rawClassname) => {
  let className;
  const arrClassname = rawClassname.split(' - ');
  if (arrClassname.length === 1) {
    // eslint-disable-next-line
    className = arrClassname[0];
  } else if (arrClassname.length === 2) {
    // eslint-disable-next-line
    className = arrClassname[1];
  } else {
    // eslint-disable-next-line
    className = arrClassname[2];
  }

  return className;
};

export const getPrefix = (text) => {
  let prefix;
  const arrClassname = text.split(' - ');
  if (arrClassname.length === 1) {
    // eslint-disable-next-line
    prefix = '';
  } else {
    // eslint-disable-next-line
    prefix = arrClassname[0];
  }

  return prefix;
};
