import PropTypes from 'prop-types';

const displayName = 'CardTopPrograms';
const propTypes = {};
const defaultProps = {
  data: [
    {
      id: 6,
      program_name: 'Hai Boleh Belajar',
      description: 'Hai Boleh Belajar',
      created_at: '2022-07-12T09:18:50',
      modified_at: '2022-07-12T09:18:50',
    },
    {
      id: 7,
      program_name: 'Facebook Ads Marketing',
      description: 'Facebook Ads Marketing',
      created_at: '2022-07-12T09:19:16',
      modified_at: '2022-07-12T09:19:16',
    },
    {
      id: 8,
      program_name: 'Full stack Digital Marketing Course',
      description:
        'Description test mock data. di sini bisa diisi deskripsi dari Program Level Description. Jika mengisi Bootcamp, maka deskripsi Bootcamp. Jika mengisi Video Learning Maka deskripsi video learning.',
      created_at: '2022-08-26T06:33:46',
      modified_at: '2022-08-30T03:24:39',
    },
    {
      id: 9,
      program_name: 'contoh pertama',
      description: 'ini dummy',
      created_at: '2022-08-26T07:27:41',
      modified_at: '2022-08-26T07:27:41',
    },
    {
      id: 10,
      program_name: 'ada',
      description: 'ada',
      created_at: '2022-08-30T06:58:52',
      modified_at: '2022-08-30T06:58:52',
    },
  ],
};

export default { displayName, propTypes, defaultProps };
