import React, { useCallback, useState } from 'react';
import {
  Modal,
  Typography,
  Form,
  Input,
  Steps,
  Button,
  Select,
  Row,
  Col,
  message,
  List,
  Popover,
  notification,
} from 'antd';
import {
  CloseCircleFilled,
  PlusSquareOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import debounce from 'lodash/debounce';
import config from './SelectionLearner.component.config';
import './SelectionLearner.component.styles.css';

const { Title, Text } = Typography;
const { Option } = Select;
const { Step } = Steps;

const SelectionLearner = ({
  showModal,
  closeModal,
  actionCloseModal,
  listLearnerData,
  participantsList,
  actionUpdateParticipant,
  ...props
}) => {
  const refForm = React.useRef();
  const [current, setCurrent] = React.useState(0);
  const [currentSelection, setCurrentSelection] = React.useState([]);
  // const [learnerData, setLearnerData] = React.useState([]);
  const [learnerOptions, setLearnerOptions] = React.useState([]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSearch = debounce((value) => {
    if (value) {
      const dataLearner = listLearnerData.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
      const foundData = dataLearner.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setLearnerOptions(foundData);
    } else {
      setLearnerOptions([]);
    }
  }, 300);

  const handleSelect = (value) => {
    const listSelect = listLearnerData.find(
      (item) => item?.id === Number(value),
    );
    const idExist = currentSelection.find((item) => item === Number(value));
    if (!idExist) setCurrentSelection([...currentSelection, listSelect.id]);
    // const learnerExist = learnerData.find((item) => item?.id === Number(value));
    // if (!learnerExist) setLearnerData([...learnerData, listSelect]);
  };

  const removeLearnerFromSelection = (value) => {
    const updatedLearnerIDList = currentSelection.filter(
      (user) => user !== value,
    );
    setCurrentSelection([...updatedLearnerIDList]);
    // const updatedLearnerList = learnerData.filter((user) => user.id !== value);
    // setLearnerData([...updatedLearnerList]);
  };

  const renderPopoverUtils = (
    <div className="learner-to-group">
      <div className="util-create-group">
        <Input placeholder="create new group..." />
        <Button>Create new group</Button>
      </div>
      <div className="list-exist-group">
        <List
          key="existGroup"
          size="large"
          itemLayout="horizontal"
          dataSource={config.defaultProps.dummExistGroup()}
          renderItem={(item) => (
            <List.Item>
              <div>{item.group}</div>
              <div>{item.instructor}</div>
              <div>
                <Button
                  icon={<PlusSquareOutlined />}
                  onClick={() =>
                    notification.info({
                      message: 'Hai, Sorry!',
                      description: `This feature doesn't work for now, under development`,
                    })
                  }
                >
                  Add to Group
                </Button>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );

  return (
    <Modal
      className="modal-learner-custom"
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => {
        setCurrentSelection([]);
        closeModal({ action: actionCloseModal });
      }}
      footer={null}
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="vertical"
        name="createLearner"
        onFinish={() => {}}
      >
        <div>
          <Title level={4}>1. Select Learner</Title>
          {/* <LearnerPill
            selectedLearnerName={learnerData}
            onRemove={removeLearnerFromSelection}
          /> */}
          <p>Untuk mencari Learner, Silahkan ketik namanya</p>
          <Form.Item name="learner">
            <Select
              showSearch
              autoClearSearchValue
              placeholder="Search Learner"
              mode="multiple"
              onSearch={handleSearch}
              onSelect={handleSelect}
              onDeselect={removeLearnerFromSelection}
              filterOption={false}
              notFoundContent={null}
            >
              {learnerOptions.map((item) => (
                <Option
                  key={item.value}
                  value={item.value}
                  hidden={participantsList.find(
                    (datum) => datum.id === item.value,
                  )}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* <List
            key="DataList"
            size="large"
            itemLayout="horizontal"
            dataSource={currentSelection}
            renderItem={(item) => (
              <List.Item>
                <div
                  className="learner-selected"
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text>
                    <b>{item?.name}</b>
                  </Text>
                  <Popover
                    content={renderPopoverUtils}
                    trigger="click"
                    placement="bottomRight"
                  >
                    <Button icon={<PlusSquareOutlined />}>Add to Group</Button>
                  </Popover>
                </div>
              </List.Item>
            )}
            pagination={null}
          /> */}
        </div>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => {
                setCurrentSelection([]);
                closeModal({ action: actionCloseModal });
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                actionUpdateParticipant(currentSelection);
                setCurrentSelection([]);
              }}
            >
              Add Learner
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

SelectionLearner.displayName = config.displayName;
SelectionLearner.defaultProps = config.defaultProps;
SelectionLearner.propTypes = config.propTypes;

export default SelectionLearner;
