import { get } from 'lodash';
import {
  SET_PROGRAMS_LIST,
  SET_PROGRAMS_PARRENT,
  SET_PROGRAMS_PARTICIPANTS,
  SET_PROGRAMS_FIELD_SUGGESTIONS,
} from '../reduxType';

import api from '../../services/api.service';
import {
  generateNotification,
  generateHeaderRequest,
  retrieveErrorHandling,
} from '../../utils/general.utils';
import { getToken } from '../../services/token.service';

const { apiService, setAuthToken } = api.getInstance();
const isUserToken = getToken();

export const programGetTypeSuggestions = (payload) => async (dispatch) => {
  dispatch({
    type: SET_PROGRAMS_FIELD_SUGGESTIONS,
    payload: {
      isLoading: true,
    },
  });

  let outcome;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `program/program_type`,
      generateHeaderRequest(),
    );
    // console.log(data);
    dispatch({
      type: SET_PROGRAMS_FIELD_SUGGESTIONS,
      payload: {
        program_types: [...data.data],
        isLoading: false,
      },
    });
    outcome = Promise.resolve();
  } catch (err) {
    dispatch({
      type: SET_PROGRAMS_FIELD_SUGGESTIONS,
      payload: {
        program_types: [],
        isLoading: false,
      },
    });
    outcome = Promise.reject();
  }

  return outcome;
};

export const programCreate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/create_program',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program has been created',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programDuplicate = (programId) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/duplicate_program',
      programId,
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program has been duplicated',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programUpdate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/update_program',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_PROGRAMS_LIST,
    payload: {
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    let urlRequest = `/program/get_program_list`;
    if (payload.pagination === false) {
      urlRequest += `?pagination=${payload.pagination}`;
    } else {
      if (payload.page) urlRequest += `?page=${payload.page}`;
      if (payload.paramProgramName)
        urlRequest += `&paramProgramName=${payload.paramProgramName}`;
    }

    const { status, data } = await apiService.get(
      urlRequest,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_PROGRAMS_LIST,
      payload: {
        ...data,
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_PROGRAMS_LIST,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const programDetail = (payload) => async (dispatch) => {
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/program/get_program_detail?programID=${payload}`,
      {},
      generateHeaderRequest(),
    );
    result = Promise.resolve(data);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }

  return result;
};

export const setParrentProgram = (payload) => async (dispatch) => {
  let result = true;
  try {
    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        parrent: payload,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const programCreateActivity = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/create_activity',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program Activity has been created.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programActivityList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_PROGRAMS_PARRENT,
    payload: {
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.post(
      `/program/get_activity_list?paramProgramId=${payload}`,
      {},
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        activity: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const setActivityProgram = (payload) => async (dispatch) => {
  let result = true;
  try {
    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        selectedActivity: payload,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const programUpdateActivity = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/update_activity',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program Activity has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programUpdateActivityPosition = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'program/update_activity_position',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program Activity has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programDeleteActivity = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.delete(
      `program/delete_activity?activityID=${payload}`,
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Activity has been deleted.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    const descriptionMsg = `
      ${error?.response?.data?.detail}
      ${error?.response?.data?.detail?.[0]?.loc.join('/')}
      ${error?.response?.data?.detail?.[0]?.msg}
      ${error?.response?.data?.detail?.[0]?.type}
    `;
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: descriptionMsg,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programActivityEntityList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_PROGRAMS_PARRENT,
    payload: {
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/program/get_act_entity_list?activity_id=${payload}`,
      generateHeaderRequest(),
    );

    const arrayListActivityEntity = get(data, 'data')?.sort(
      (a, b) => a.position - b.position,
    );
    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        activityEntity: arrayListActivityEntity,
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const programEntityUpdatePosition = (payload) => async (dispatch) => {
  let result;
  try {
    const { status, data } = await apiService.post(
      `/program/update_act_entity_position`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const programCreateActivityEntity = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/create_activity_entity',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'suucess',
      response: status,
      messages: 'Program Activity Entity has been created.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programUpdateActivityEntity = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/update_activity_entity',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program Activity Entity has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programDeleteActivityEntity = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.delete(
      `program/delete_activity_entity?entityID=${payload}`,
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Activity Entity has been deleted.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    const descriptionMsg = `
      ${error?.response?.data?.detail}
      ${error?.response?.data?.detail?.[0]?.loc.join('/')}
      ${error?.response?.data?.detail?.[0]?.msg}
      ${error?.response?.data?.detail?.[0]?.type}
    `;
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: descriptionMsg,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programUpdateActivityEntityStatus = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'program/update_act_entity_active_status',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Program Activity Entity status has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programUpdateParticipant = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'program/add_participant',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Learner has been add to this programs.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    // generateNotification({
    //   type: 'error',
    //   response: error?.response?.status,
    //   messages: error?.response?.data?.detail,
    // });
    generateNotification({
      type: 'info',
      response: 200,
      messages:
        'Sorry this user maybe has been added, you cannot add multiple times.',
    });
    result = Promise.reject(error);
  }
  return result;
};

export const programRemoveParticipant = (arg) => async (dispatch) => {
  let outcome;
  try {
    const { status, data } = await apiService.delete(
      `program/remove_participant`,
      {
        ...generateHeaderRequest(),
        data: { ...arg },
      },
    );
    outcome = Promise.resolve();
  } catch (err) {
    outcome = Promise.reject(err);
  }
  return outcome;
};

export const programGetParticipants = (arg) => async (dispatch) => {
  dispatch({
    type: SET_PROGRAMS_PARTICIPANTS,
    payload: {
      isLoading: true,
    },
  });

  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.get(
      `lesson/get_participant_list?paramProgramId=${arg}`,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_PROGRAMS_PARTICIPANTS,
      payload: {
        data: data?.data,
        isLoading: false,
      },
    });
    outcome = Promise.resolve();
  } catch (err) {
    dispatch({
      type: SET_PROGRAMS_PARTICIPANTS,
      payload: {
        data: [],
        isLoading: false,
      },
    });
    outcome = Promise.reject();
  }
  return outcome;
};

export const programGetSessionPreview = (arg) => async (dispatch) => {
  dispatch({
    type: SET_PROGRAMS_PARRENT,
    payload: {
      isLoading: true,
    },
  });

  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.get(
      `lesson/get_entity_session?paramEntityId=${arg}`,
      generateHeaderRequest(),
    );

    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        sessionPreview: data?.data,
        isLoading: false,
      },
    });
    outcome = Promise.resolve();
  } catch (err) {
    dispatch({
      type: SET_PROGRAMS_PARRENT,
      payload: {
        isLoading: false,
      },
    });
    outcome = Promise.reject(err);
  }
  return outcome;
};

export const programUpdateEntityDependency = (arg) => async (dispatch) => {
  let outcome;
  try {
    const { status, data } = await apiService.put(
      `program/update_act_entity_dependency`,
      arg,
      generateHeaderRequest(),
    );
    // console.log(status)
    if (status) {
      outcome = Promise.resolve();
    }
  } catch (err) {
    outcome = Promise.reject(err);
  }
  return outcome;
};

export const participantsGetStudentData = (arg) => async (dispatch) => {
  dispatch({
    type: 'GET_STUDENT_DATA',
    payload: {
      isLoading: true,
    },
  });

  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.get(
      `report/get?program_id=${arg.programId}&user_id=${arg.userId}`,
      generateHeaderRequest(),
    );
    dispatch({
      type: 'GET_STUDENT_DATA',
      payload: {
        data: data?.data,
        isLoading: false,
      },
    });
    outcome = Promise.resolve(data?.data);
  } catch (err) {
    dispatch({
      type: 'GET_STUDENT_DATA',
      payload: {
        isLoading: false,
      },
    });
    outcome = Promise.reject();
  }
  return outcome;
};

export const participantsGetSummaryStudentData = (arg) => async (dispatch) => {
  dispatch({
    type: 'GET_STUDENT_DATA',
    payload: {
      isLoading: true,
    },
  });

  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.get(
      `report/summary/get?program_id=${arg.programId}&user_id=${arg.userId}`,
      generateHeaderRequest(),
    );
    outcome = Promise.resolve(data);
  } catch (err) {
    dispatch({
      type: 'GET_STUDENT_DATA',
      payload: {
        isLoading: false,
      },
    });
    outcome = Promise.reject();
  }
  return outcome;
};

export const participantsGetStudentQuizData =
  (sessionID) => async (dispatch) => {
    let outcome;
    // if (isUserToken)
    try {
      const { data } = await apiService.get(
        `report/get_submission?sessionID=${sessionID}`,
        generateHeaderRequest(),
      );
      outcome = Promise.resolve(data.data);
    } catch (err) {
      outcome = Promise.reject();
    }
    return outcome;
  };

export const participantsGenerateStudentQuiz =
  (payload) => async (dispatch) => {
    let outcome;
    // if (isUserToken)
    try {
      const { data } = await apiService.post(
        `report/generate_score`,
        payload,
        generateHeaderRequest(),
      );
      outcome = Promise.resolve(data.data);
    } catch (err) {
      outcome = Promise.reject();
    }
    return outcome;
  };

export const participantSubmitStudentQuiz = (payload) => async (dispatch) => {
  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.post(
      `report/submit_quiz`,
      payload,
      generateHeaderRequest(),
    );
    outcome = Promise.resolve(data.data);
    generateNotification({
      type: 'info',
      response: 200,
      messages: 'Quiz score has been submitted!',
    });
  } catch (err) {
    outcome = Promise.reject();
  }
  return outcome;
};

export const participantSubmitWeekAssignment =
  (payload) => async (dispatch) => {
    let outcome;
    // if (isUserToken)
    try {
      const { data } = await apiService.post(
        `report/submit_score`,
        payload,
        generateHeaderRequest(),
      );
      outcome = Promise.resolve(data.data);
      generateNotification({
        type: 'info',
        response: 200,
        messages: 'Weekly Assignment score has been submitted!',
      });
    } catch (err) {
      outcome = Promise.reject();
    }
    return outcome;
  };

export const participantWeeklyAssignment = (payload) => async (dispatch) => {
  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.get(
      `report/get_assignment_weekly?program_id=${payload.programID}&entity_id=${payload.entityID}`,
      generateHeaderRequest(),
    );
    outcome = Promise.resolve(data.data);
  } catch (err) {
    outcome = Promise.reject();
  }
  return outcome;
};

export const finalGroupGet = (payload) => async (dispatch) => {
  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.get(
      `program/get_group_assignment?program_id=${payload?.program_id}&activity_id=${payload?.activity_id}&entity_id=${payload?.entity_id}`,
      generateHeaderRequest(),
    );
    outcome = Promise.resolve(data.data);
  } catch (err) {
    outcome = Promise.reject();
  }
  return outcome;
};

export const finalGroupGetDetail = (payload) => async (dispatch) => {
  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.get(
      `program/get_detail_group_assignment?program_id=${payload?.program_id}&activity_id=${payload?.activity_id}&entity_id=${payload?.entity_id}`,
      generateHeaderRequest(),
    );
    outcome = Promise.resolve(data);
  } catch (err) {
    outcome = Promise.reject();
  }
  return outcome;
};

export const finalGroupScorePost = (payload) => async (dispatch) => {
  let outcome;
  // if (isUserToken)
  try {
    const { data } = await apiService.post(
      `program/submit_score_group_assignment`,
      { ...payload },
      generateHeaderRequest(),
    );
    outcome = Promise.resolve(data);
  } catch (err) {
    outcome = Promise.reject();
  }
  return outcome;
};

export const finalGroupCreate = (payload) => async (dispatch) => {
  let outcome;
  // if (isUserToken)
  try {
    const requestCreate = await apiService.post(
      `program/create_group_assignment`,
      payload,
      generateHeaderRequest(),
    );
    outcome = Promise.resolve(requestCreate.data);
    generateNotification({
      type: 'info',
      response: 200,
      messages: 'new group has been submitted!',
    });
  } catch (err) {
    outcome = Promise.reject();
  }
  return outcome;
};

export const finalGroupPut = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'program/update_group_assignment',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Group has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const finalGroupDelete = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.delete(
      `program/delete_group_assignment?group_id=${payload}`,
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Group has been deleted.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const postCertificateProgramAdmin = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      `certificate/claim_certificate_participant_adm`,
      { ...payload },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: `Certificate ${payload?.cert_id} has been uploaded.`,
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};
