import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import {
  Button,
  Card,
  Radio,
  Checkbox,
  Col,
  notification,
  Row,
  Typography,
  Image,
} from 'antd';
import {
  EditOutlined,
  ShareAltOutlined,
  LinkOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons';
import parse from 'html-react-parser';
import {
  ReactPlayerCustom,
  ModalCreateEntity,
  MotionPage,
  VideoPlayerCustom,
} from '../../../../components';

import { getObjectAwsS3Bucket } from '../../../../services/aws.service';

import config from './DetailEntity.component.config';
import './DetailEntity.component.styles.css';

const { Title, Text, Link } = Typography;

const DetailEntity = ({
  data,
  dataDetailQuiz,
  actionUpdateActivity,
  listQuiz,
  ...props
}) => {
  const [state, setState] = useState({
    editEntity: false,
    showModalEdit: false,
  });
  const navigationLocal = ({ ...setup }) => {
    setState((prevState) => {
      const currentState = { ...prevState };

      switch (setup.action) {
        case 'editEntity':
          currentState.editEntity = !currentState.editEntity;
          currentState.showModalEdit = !currentState.showModalEdit;
          break;
        default:
          currentState.editEntity = false;
          currentState.showModalEdit = false;
          break;
      }
      return { ...currentState };
    });
  };
  const generateVideoConfrence = () => (
    <>
      <Link href={get(data, 'material_link')} target="_blank">
        {get(data, 'material_link')}
      </Link>
      <Button type="link">
        Copy link <LinkOutlined />
      </Button>
    </>
  );
  const generateVideoLearning = () => (
    <Link disabled href={get(data, 'material_link')} target="_blank">
      {get(data, 'material_link')}
    </Link>
  );

  const generateContentVideo = () => {
    const materialSource = get(data, 'material_source');
    const materialLink = get(data, 'material_link');

    let content = null;
    if (
      materialSource === null ||
      materialSource === 0 ||
      materialSource === 1
    ) {
      content = <ReactPlayerCustom material={materialLink} />;
    } else if (materialSource === 2) {
      content = <VideoPlayerCustom material={materialLink} />;
    }
    return content;
  };
  const generateDocument = () => (
    <Link href={get(data, 'material_link')} target="_blank">
      {get(data, 'material_link')}
    </Link>
  );

  const generateItemsQuestion = (question) => {
    if (question) {
      return question.map((itemQuestion, indexQuestion) => {
        let imageQuestion = null;
        if (
          itemQuestion.images !== '' &&
          itemQuestion.images !== null &&
          itemQuestion.images !== undefined
        ) {
          imageQuestion = (
            <Row gutter={24} justify="start" className="row-image-question">
              {itemQuestion.images.split(', ').map((img) => (
                <Col>
                  <Image
                    className="question-image"
                    preview={{
                      src: img,
                    }}
                    style={{
                      backgroundImage: `url(${img})`,
                    }}
                  />
                </Col>
              ))}
            </Row>
          );
        }

        return (
          <div className="items-question">
            <div className="is-question">
              <Row gutter={24}>
                <Col md={20}>
                  {imageQuestion}
                  <Text>
                    {`${indexQuestion + 1}. `}
                    {itemQuestion?.question}
                  </Text>
                </Col>
                {/* <Col md={4}>
                  <Text>
                    <b>Score Weight: </b>
                    {itemQuestion?.score_weight}
                  </Text>
                </Col> */}
              </Row>
            </div>
            <div className="is-answer">
              {itemQuestion?.list_answer.map((itemAnswer, indexAnswer) => (
                <div>
                  {itemQuestion?.quiz_type === 1 && ( // single answer
                    <Radio
                      value={indexAnswer}
                      checked={get(itemAnswer, 'is_correct')}
                      onClick={() => console.log(indexAnswer, indexQuestion)}
                    >
                      <Text>
                        {`${config.defaultProps.alphabet[indexAnswer]}. ${itemAnswer?.answer} `}
                        {itemAnswer?.is_correct === 1 && <b>(correct)</b>}
                      </Text>
                    </Radio>
                  )}
                  {itemQuestion?.quiz_type === 2 && ( // multiple answer
                    <Checkbox
                      value={indexAnswer}
                      checked={get(itemAnswer, 'is_correct')}
                      onClick={() => console.log(indexAnswer, indexQuestion)}
                    >
                      <Text>
                        {`${config.defaultProps.alphabet[indexAnswer]}. ${itemAnswer?.answer} `}
                        {itemAnswer?.is_correct === 1 && <b>(correct)</b>}
                      </Text>
                    </Checkbox>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      });
    }
    return null;
  };
  const generateQuizDetail = () => {
    const parrentQuiz = dataDetailQuiz?.parrent;
    const itemsQuiz = dataDetailQuiz?.parrent?.items;
    return (
      <div className="quiz-content">
        <div className="quiz-info">
          <Title level={3}>{parrentQuiz?.quiz_name}</Title>
          <Row gutter={24}>
            <Col md={4}>
              <div>
                <Text>
                  <b>Min. Score :</b> {parrentQuiz?.min_score}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Duration :</b> {parrentQuiz?.duration} Minutes
                </Text>
              </div>
              <div>
                <Text>
                  <b>Take Limit :</b> {parrentQuiz?.retake_limit}
                </Text>
              </div>
            </Col>
            <Col md={20}>
              <Text>
                <b>Description: </b>
                <br />
                {parrentQuiz?.description}
              </Text>
            </Col>
          </Row>
        </div>
        <div className="quiz-detail">
          <Card>{generateItemsQuestion(itemsQuiz)}</Card>
        </div>
      </div>
    );
  };

  return (
    <MotionPage motionType="fadeIn">
      <div id="DetailEntity">
        <div className="section-header">
          <div className="action-header">
            <Button
              size="large"
              icon={<LeftCircleOutlined />}
              onClick={() => props.actionBack({ action: 'back' })}
              style={{ paddingLeft: 0 }}
            >
              Back
            </Button>
            <div>
              <Button
                size="large"
                icon={<EditOutlined />}
                onClick={() => navigationLocal({ action: 'editEntity' })}
              >
                Edit
              </Button>
              <Button
                size="large"
                icon={<ShareAltOutlined />}
                onClick={() =>
                  notification.info({
                    message: 'Hai, Sorry!',
                    description: `This feature doesn't work for now, under development`,
                  })
                }
              >
                Share
              </Button>
            </div>
          </div>
          <div className="information-header">
            <div className="content-header">
              <Title level={2}>{get(data, 'entity_name')}</Title>
              <Text>{data?.description}</Text>
            </div>

            {config.defaultProps.entityDontHaveLink.indexOf(
              get(data, 'activity_type_id'),
            ) < 0 && (
              <Row className="material">
                <Col md={18}>
                  <Title level={5}>Material</Title>
                  <div className="material-value">
                    <Text
                      ellipsis={{
                        rows: 1,
                        expandable: true,
                      }}
                    >
                      {get(data, 'activity_type_id') === 1 &&
                        generateVideoConfrence()}

                      {get(data, 'activity_type_id') === 2 &&
                        generateVideoLearning()}
                    </Text>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
        <div className="content-detail">
          {/* content video learning */}
          {get(data, 'activity_type_id') === 2 && generateContentVideo()}
          {/* content rich text */}
          {config.defaultProps.entityRichText.indexOf(
            get(data, 'activity_type_id'),
          ) >= 0 && <div>{parse(get(data, 'material_link'))}</div>}
          {/* content quiz */}
          {config.defaultProps.entityForQuizID.indexOf(
            get(data, 'activity_type_id'),
          ) >= 0 && generateQuizDetail()}
        </div>
        <ModalCreateEntity
          title="Edit Lesson Entity"
          showModal={state.showModalEdit}
          closeModal={navigationLocal}
          actionCloseModal="editEntity"
          isEditData={state.editEntity}
          actionUpdateActivity={actionUpdateActivity}
          entitySelection={data}
          listQuiz={listQuiz}
        />
      </div>
    </MotionPage>
  );
};

DetailEntity.displayName = config.displayName;
DetailEntity.defaultProps = config.defaultProps;
DetailEntity.propTypes = config.propTypes;

export default DetailEntity;
