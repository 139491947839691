import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import config from './RichTextEditor.component.config';
import './RichTextEditor.component.styles.css';

const RichTextEditor = ({ defaultValue, actionGetValue, ...props }) => {
  const [convertedText, setConvertedText] = React.useState(defaultValue);
  return (
    <div>
      <ReactQuill
        theme="snow"
        value={convertedText}
        onChange={(e) => {
          setConvertedText(e);
          actionGetValue(e);
        }}
        style={{ minHeight: 100 }}
      />
    </div>
  );
};

RichTextEditor.displayName = config.displayName;
RichTextEditor.defaultProps = config.defaultProps;
RichTextEditor.propTypes = config.propTypes;

export default RichTextEditor;
