/* eslint-disable prettier/prettier */

import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const displayName = 'AdminPrograms';
const propTypes = {};
const defaultProps = {
  dummData: () => {
    const data = [];
    for (let index = 0; index < 10;) {
      data.push({
        id: index,
        'program name': `Porgram ${index}`,
        'create date': '2022-06-01',
        lesson: '20 Lesson',
        status: 'on porgress',
      });
      index += 1;
    }
    return data;
  },
  headColumn: [
    {
      title: '#ID',
      key: 'id',
      width: 60,
      fixed: true,
      sortNumber: true,
    },
    {
      title: '#SKU',
      key: 'program_sku',
      width: 120,
      fixed: true,
      sortNumber: true,
    },
    {
      title: 'Program Name',
      key: 'program_name',
      sort: true,
      width: 300,
    },
    {
      title: 'Program Type',
      key: 'program_type_name',
      sort: true,
      width: 150,
    },
    {
      title: 'Description',
      key: 'description',
      formatParagraph: true,
      width: 350,
    },
    {
      title: 'Created Date',
      key: 'created_at',
      formatDate: true,
      sortFormatDate: true,
      width: 250,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      width: 120,
    },
  ],
};

export default { displayName, propTypes, defaultProps };
