import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Typography, Upload } from 'antd';
import React from 'react';

import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../../services/aws.service';
import config from './ModalCreate.component.config';
import './ModalCreate.component.styles.css';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ModalCreate = ({ actionCreate, showModal, closeModal }) => {
  const refForm = React.useRef();
  const [state, setState] = React.useState({
    fileList: [],
    onUploadFile: false,
  });
  const actionUpload = async (upload) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.onUploadFile = !currentState.onUploadFile;
      return { ...currentState };
    });
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/activity',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };

  return (
    <Modal
      title={
        <div className="header-custom">
          <Title level={3}>Create Lesson</Title>
          <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
      }
      centered
      visible={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => closeModal({ action: 'closeModal' })}
      footer={null}
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="vertical"
        id="createLesson"
        onFinish={() => {
          const requestBody = {
            ...refForm.current.getFieldsValue(),
            banner_image:
              state?.fileList[0]?.uid !== undefined
                ? state?.fileList[0]?.uid
                : '',
          };
          actionCreate(requestBody);
          setState({ fileList: [] });
          refForm.current.setFieldsValue({
            lesson_name: '',
            description: '',
            banner_image: '',
            status: '',
          });
        }}
      >
        <Form.Item
          label="Lesson Name"
          name="lesson_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Activity Tags"
          name="lesson_tags"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <Form.Item label="Upload Banner">
          <Form.Item name="banner_image" noStyle hidden={state.onUploadFile}>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={actionUpload}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <div hidden={!state.onUploadFile} style={{ textAlign: 'center' }}>
            <p>Banner uploading, please wait...</p>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => closeModal({ action: 'closeModal' })}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalCreate.displayName = config.displayName;
ModalCreate.defaultProps = config.defaultProps;
ModalCreate.propTypes = config.propTypes;

export default ModalCreate;
