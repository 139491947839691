import PropTypes from 'prop-types';

const displayName = 'ActivityPrograms';
const propTypes = {};
const defaultProps = {
  dummData: () => {
    const data = [];
    for (let index = 0; index < 10; ) {
      data.push({
        id: index,
        'activity name': `activity ${index}`,
        instructor: ['instructure 1', 'instructure 2', 'instructure 3'],
        schedule: '2022-05-01 / 2022-05-31',
        moderator: 'Moch. Dicky',
      });
      index += 1;
    }
    return data;
  },
};

export default { displayName, propTypes, defaultProps };
