import { get } from 'lodash';
import {
  SET_QUIZ_LIST,
  SET_QUIZ_PARRENT,
  SET_QUIZ_PARRENT_DETAIL,
  SET_QUIZ_SUGGESTION,
  SET_QUIZ_VALIDATION,
} from '../reduxType';

import api from '../../services/api.service';
import {
  generateNotification,
  generateHeaderRequest,
  retrieveErrorHandling,
} from '../../utils/general.utils';
import { getToken } from '../../services/token.service';

const { apiService, setAuthToken } = api.getInstance();
const isUserToken = getToken();

export const listSuggestQuestion = (payload) => async (dispatch) => {
  let result;
  dispatch({
    type: SET_QUIZ_SUGGESTION,
    payload: {
      question: [],
    },
  });
  // if (isUserToken && payload !== '')
  try {
    const paramSearch = payload ? `?paramQuestion=${payload}` : null;
    const { status, data } = await apiService.get(
      `quiz/get_question_list${paramSearch}`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    const existQuestion = [];
    if (data?.data?.length > 0) {
      for (let index = 0; index < data?.data.length; ) {
        existQuestion.push({
          id: data?.data[index].id,
          value: data?.data[index].question,
        });
        index += 1;
      }
    }
    dispatch({
      type: SET_QUIZ_SUGGESTION,
      payload: {
        question: existQuestion,
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const listSuggestAnswer = (payload) => async (dispatch) => {
  let result;
  dispatch({
    type: SET_QUIZ_SUGGESTION,
    payload: {
      answer: [],
    },
  });
  // if (isUserToken && payload !== '')
  try {
    const paramSearch = payload ? `?paramAnswer=${payload}` : null;
    const { status, data } = await apiService.get(
      `quiz/get_answer_list${paramSearch}`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    const existAnswer = [];
    if (data?.data?.length > 0) {
      for (let index = 0; index < data?.data.length; ) {
        const findData = existAnswer.find(
          (items) => items.value === data?.data[index].answer,
        );
        if (findData === undefined) {
          existAnswer.push({
            id: data?.data[index].id,
            value: data?.data[index].answer,
          });
        }
        index += 1;
      }
    }
    dispatch({
      type: SET_QUIZ_SUGGESTION,
      payload: {
        answer: existAnswer,
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const createQuiz = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'quiz/create_quiz',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Assignment has been created.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const updateQuiz = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'quiz/update_quiz',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Assignment has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const deleteQuiz = (id) => async () => {
  let result;
  try {
    const { status } = await apiService.delete(
      `quiz/delete_quiz?quizID=${id}`,
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Quiz has been deleted.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const validateQuiz = (id) => async (dispatch) => {
  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/quiz/get_validation_info?quizID=${id}`,
      generateHeaderRequest(),
    );
    result = Promise.resolve({ status, ...data.data });
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const getListQuiz = (payload) => async (dispatch) => {
  dispatch({
    type: SET_QUIZ_LIST,
    payload: {
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      '/quiz/get_quiz_list',
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_QUIZ_LIST,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_QUIZ_LIST,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const setParrentQuiz = (payload) => async (dispatch) => {
  let result = true;
  try {
    dispatch({
      type: SET_QUIZ_PARRENT,
      payload: {
        parrent: payload,
        questionList: [],
      },
    });
    result = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const createQuizDetail = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'quiz/create_quiz_detail',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Assignment Detail has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const getListQuizDetail = (payload) => async (dispatch) => {
  dispatch({
    type: SET_QUIZ_PARRENT_DETAIL,
    payload: {
      parrent: {},
      questionList: [],
      isLoading: true,
    },
  });

  let result;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `/quiz/admin/get_quiz_detail?pQuizId=${payload}`,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_QUIZ_PARRENT_DETAIL,
      payload: {
        parrent: data?.data[0],
        questionList: data?.data[0]?.items,
        isLoading: false,
      },
    });
    result = Promise.resolve(data?.data[0]?.items);
  } catch (error) {
    retrieveErrorHandling(error);
    dispatch({
      type: SET_QUIZ_PARRENT_DETAIL,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const updatePositionQuiz = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'quiz/update_quiz_position',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Assignment has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};
