import { get } from 'lodash';
import {
  SET_PROGRAM_BUNDLING_LIST,
  SET_PROGRAM_BUNDLING_SET,
} from '../reduxType';

import api from '../../services/api.service';
import {
  generateNotification,
  generateHeaderRequest,
  retrieveErrorHandling,
} from '../../utils/general.utils';
import { getToken } from '../../services/token.service';

const { apiService, setAuthToken } = api.getInstance();
const isUserToken = getToken();

export const getProgramBundling = (payload) => async (dispatch) => {
  dispatch({
    type: SET_PROGRAM_BUNDLING_LIST,
    payload: {
      isLoading: true,
    },
  });

  let outcome;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.get(
      `program_bundling`,
      generateHeaderRequest(),
    );
    dispatch({
      type: SET_PROGRAM_BUNDLING_LIST,
      payload: {
        data: [...data.data],
        isLoading: false,
      },
    });
    outcome = Promise.resolve();
  } catch (err) {
    dispatch({
      type: SET_PROGRAM_BUNDLING_LIST,
      payload: {
        data: [],
        isLoading: false,
      },
    });
    outcome = Promise.reject();
  }
  return outcome;
};

export const setProgramBundlingSelected = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PROGRAM_BUNDLING_SET,
      payload,
    });
  } catch (error) {
    console.log(error);
  }
  return true;
};

export const createProgramBundling = (payload) => async (dispatch) => {
  let outcome;
  try {
    const { status, data } = await apiService.post(
      `program_bundling/create`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    outcome = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    outcome = Promise.reject();
  }
  return outcome;
};

export const updateProgramBundling = (payload) => async (dispatch) => {
  let outcome;
  try {
    const { status, data } = await apiService.put(
      `program_bundling/update`,
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    outcome = Promise.resolve();
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    outcome = Promise.reject();
  }
  return outcome;
};
