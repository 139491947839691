const BASE_PATH = {
  auth: '/auth',
  admin: '/admin/*',
  instructure: '/instructure/*',
  moderator: '/moderator/*',
  student: '/student/*',
  frwrd: '/frwrd',
  cert: '/cert/*',
  customapp: '/custom-app/*',
};

const GENERAL_PATH = {
  dashboard: 'dashboard',
  class: 'class',
  programs: 'programs',
  lessons: 'lessons',
  detail: 'detail',
  course: 'course',
  newcourse: 'newcourse',
  account: 'account',
  help: 'help',
  calendar: 'calendar',
  myCertificate: 'my-certificate',
};

const ADMIN_PATH = {
  dashboard: 'dashboard',
  class: 'class',
  programs: 'programs',
  'program-bundling': 'program-bundling',
  lessons: 'lessons',
  detail: 'detail',
  assignment: 'assignment',
  certificate: 'certificate',
};

export default {
  ...BASE_PATH,
  GENERAL_PATH,
  ADMIN_PATH,
};
