import { SET_DEFAULT_REDUX_MENU } from '../reduxType';
import api from '../../services/api.service';
import {
  generateNotification,
  generateHeaderRequest,
  retrieveErrorHandling,
} from '../../utils/general.utils';
import { getToken } from '../../services/token.service';

const { apiService, setAuthToken } = api.getInstance();
const isUserToken = getToken();

export const updateCollapsedMenu = (payload) => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_REDUX_MENU,
    payload: { collapsedMenu: payload },
  });
};

export const getUserList = (payload) => async (dispatch) => {
  let result = true;
  // if (isUserToken)
  try {
    const { status, data } = await apiService.post(
      `/program/get_user_list?paramUserRole=${payload}`,
      {},
      generateHeaderRequest(),
    );
    result = Promise.resolve(data?.data);
  } catch (error) {
    retrieveErrorHandling(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};
