import React, { useEffect } from 'react';
import introJs from 'intro.js';
import { Button } from 'antd';
import { getToken } from '../../services/token.service';

const IntroTour = () => {
  useEffect(() => {
    const isVisited = JSON.parse(localStorage.getItem('visited'));
    const intro = introJs();
    const steps = [
      {
        title: 'Selamat datang di LMS Boleh Belajar, Learners!',
        intro: `Katanya, tak kenal maka tak... tahu dong! Hehe. Yuk, kenalan dulu sama Minbel yang bakal menemani Learners menjelajahi fitur-fitur LMS Boleh Belajar. Biar proses belajar Learners makin sat-set sat-set, deh!<br/>Dalam hitungan ke-3... 2... 1... Minbel tebak, Learners bakal klik Next.`,
      },
      {
        element: '#home-mobile',
        title: 'Home',
        intro: `Learners bisa mengakses kelas yang sedang aktif diikuti di menu Kelas Saya. Cek semua aktivitas seru yang berkaitan dengan proses pembelajaran di sini, ya!`,
        position: 'center',
      },
      {
        element: '#calendar-mobile',
        title: 'Kalender',
        intro: `Biar proses belajar Learners makin lancar tanpa hambatan, ada menu Kalender yang dapat membantu Learners melihat jadwal berlangsungnya live session, kuis, dan aktivitas lain terkait course program.`,
        position: 'center',
      },
      {
        element: '#certificate-mobile',
        title: 'Sertifikat Saya',
        intro: `Setelah menyelesaikan course, Learners bisa mendapatkan sertifikat sebagai bukti pencapaian di menu Sertifikat Saya. Learners juga bisa menyinkronkan sertifikat dengan akun LinkedIn pribadi, loh.`,
        position: 'center',
      },
      {
        element: '#profile-mobile',
        title: 'Profil',
        intro: `<i>Say cheese!</i> Di menu Profil, Learners bisa mengatur tampilan profil dan mengubah password.`,
        position: 'center',
      },
      {
        title: 'Kita sampai sini dulu ya…',
        intro: `Pergi berlibur dengan kereta, sukses selalu Learners tercinta. Selamat belajar dan berprogress bersama Boleh Belajar, ya! Kalau Learners memiliki feedback untuk Boleh Belajar, jangan sungkan untuk memberi tahu kami~`,
        position: 'center',
      },
      // ... add more steps
    ];
    if (isVisited) {
      intro.exit();
    } else if (getToken() !== null) {
      intro.setOptions({
        // Configure options for the tour
        // For example, you can set the steps, text, position, etc.
        tooltipClass: 'customTour',
        skipLabel: 'Skip',
        exitOnOverlayClick: false,
        nextLabel: 'Lanjutkan',
        prevLabel: 'Kembali',
        doneLabel: 'Selesai',
        exitOnEsc: true,
        helperElementPadding: -0.5,
        steps,
      });

      intro.start();
      intro.oncomplete(() => {
        localStorage.setItem('visited', JSON.stringify(true));
      });
      intro.onbeforechange(() => {
        if (intro._currentStep === 7) {
          window.scrollTo(0, 0);
        }
      });
      intro.onexit(() => {
        localStorage.setItem('visited', JSON.stringify(true));
      });
    }

    return () => {
      // Clean up the tour when the component unmounts
      intro.exit();
    };
  }, []);

  return <div />;
};

export default IntroTour;
