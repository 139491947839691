import React, { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { get } from 'lodash';
import { Layout } from 'antd';

import { HeaderLayout, SideMenuLayout } from '../../components/BaseLayout';
import { destroyToken, getToken } from '../../services/token.service';
import { NotificationExpiredToken } from '../../utils/general.utils';
import { ADMIN_ROUTE } from '../../navigations/admin.route';
import config from './Admin.component.config';

const Admin = ({ ...props }) => {
  const updateCollapsedMenu = (collapsed) => {
    props.updateCollapsedMenu(collapsed);
  };
  const location = useLocation();
  const { fConsumeToken } = props;
  const isUserToken = getToken();
  const navigate = useNavigate();
  const navigationMenu = (e) => navigate(e);

  useEffect(() => {
    if (isUserToken !== null) {
      fConsumeToken();
    } else {
      destroyToken();
      NotificationExpiredToken();
    }
    if (get(isUserToken, 'uslo') !== undefined && isUserToken.uslo !== 1) {
      NotificationExpiredToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pathsWithoutSearchBar = [`/admin/dashboard`];

  return (
    <div>
      <Layout>
        <HeaderLayout
          users={props.users}
          collapsed={props.common.collapsedMenu}
          hideSearchBar={
            pathsWithoutSearchBar.includes(location.pathname) ||
            (location.pathname.includes('programs/') &&
              !location.search.includes('&'))
          }
        />
        <SideMenuLayout
          routeBase={ADMIN_ROUTE}
          location={location}
          setStateSideMenu={updateCollapsedMenu}
        />
      </Layout>
      <Routes>
        {ADMIN_ROUTE.map((items) => (
          <Route key={items.key} {...items} />
        ))}
        <Route path="/" element={<Navigate to="/admin/dashboard" />} />
        <Route path="*" element={<Navigate to="/admin/dashboard" />} />
      </Routes>
    </div>
  );
};

Admin.displayName = config.displayName;
Admin.defaultProps = config.defaultProps;
Admin.propTypes = config.propTypes;

export default Admin;
