import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Form, Modal, Select, Typography, Upload } from 'antd';
import React, { useEffect } from 'react';

import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../../services/aws.service';
import config from './ModalCreateCertificate.component.config';
import './ModalCreateCertificate.component.styles.css';

const { Title } = Typography;
const { Option } = Select;

const ModalCreateCertificate = ({
  showModal,
  closeModal,
  updateCertificate,
  createCertificate,
  fieldSuggestions,
  editData,
  isEdit,
  ...props
}) => {
  const refForm = React.useRef();
  const [state, setState] = React.useState({
    fileList: [],
  });
  const uploadCertificate = async (template) => {
    const tempData = {
      uid: template?.file?.uid,
      name: template?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'certificate/config',
      template?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };

  useEffect(() => {
    if (isEdit) {
      const tempData = {
        uid: editData?.template,
        name: editData?.template,
        url: editData?.template,
        status: 'done',
      };

      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.fileList = [];
        currentState.fileList.push(tempData);
        return { ...currentState };
      });
      setTimeout(() => {
        const fields = {
          program_type: editData?.program_type,
        };
        refForm?.current?.setFieldsValue(fields);
      }, 1000);
    } else {
      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.fileList = [];
        return { ...currentState };
      });
    }
  }, [isEdit, editData]);
  return (
    <Modal
      title={
        <div className="header-custom">
          <Title level={3}>
            {isEdit ? 'Edit Certificate' : 'Create New Certificate'}
          </Title>
          <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
      }
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={(e) => {
        e.stopPropagation();
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.fileList = [];
          return { ...currentState };
        });
        closeModal();
      }}
      footer={null}
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="vertical"
        id="CreateCertificate"
        onFinish={() => {
          const requestBody = {
            ...refForm.current.getFieldsValue(),
            template:
              state?.fileList[0]?.uid !== undefined
                ? state?.fileList[0]?.uid
                : '',
          };
          isEdit
            ? updateCertificate(requestBody)
            : createCertificate(requestBody);
          setState({ fileList: [] });
          refForm.current.setFieldsValue({
            program_type: '',
          });
        }}
      >
        {/* <Form.Item
          label="Program Type"
          name="program_type"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Select defaultValue={null}>
            {fieldSuggestions?.map((item) => (
              <Option key={item?.id} value={item?.id}>
                {item?.value}
              </Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item label="Upload Template">
          <Form.Item name="template_image" noStyle>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={uploadCertificate}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => closeModal({ action: 'closeModal' })}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalCreateCertificate.displayName = config.displayName;
ModalCreateCertificate.defaultProps = config.defaultProps;
ModalCreateCertificate.propTypes = config.propTypes;

export default ModalCreateCertificate;
