/* eslint-disable no-param-reassign */
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileOutlined,
  LaptopOutlined,
  PaperClipOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { Collapse, Menu, Modal, Progress, Tree, Typography } from 'antd';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import {
  createActivitySession,
  getActivitySession,
  getStudentLearningProgress,
  updateActivitySession,
} from '../../../redux/actions/student.actions';
import config from './MainControl.component.config';
import './MainControl.component.styles.css';

const { Title } = Typography;
const { confirm } = Modal;
const { Panel } = Collapse;

const MainControl = ({
  sourceData,
  courseName,
  studentLearningProgress,
  courseDescription,
  navigateCourse,
  defaultCheckTree,
  setDefaultCheckTree,
  onUpdateStateSession,
  onCreateActivitySession,
  onUpdateActivitySession,
  showLoading,
  handleRefreshContent,
  collapsed,
  activitySessionUser,
  setCollapsed,
  courseDetail,
  ...props
}) => {
  const {
    contentListData,
    selectionContent,
    contentQuizRunning,
    contentVideoPlaying,
  } = sourceData;
  const [stateTree, setStateTree] = React.useState({
    selected: defaultCheckTree,
    loadingContent: false,
  });
  const dispatch = useDispatch();
  const VideoOrQuizId = [2, 3];
  const onOKPopModal = (option) => {
    setStateTree({ ...stateTree, selected: [option?.selectedKey] });
    onUpdateStateSession({
      video_playing: false,
      quiz_running: false,
    });
    setDefaultCheckTree([option?.selectedKey]);
    navigateCourse(option?.params);
  };
  const onCancelPopModal = (option) => {
    setStateTree((prev) => {
      const current = { ...prev };
      current.selected = defaultCheckTree;
      return { ...current };
    });
    setDefaultCheckTree(defaultCheckTree);
  };

  const showConfirm = ({ ...option }) => {
    confirm({
      title: 'PERHATIAN!',
      icon: <ExclamationCircleOutlined />,
      content: option?.description,
      okText: 'Tetap di sini',
      onOk: () => onCancelPopModal(option),
      cancelText: 'Tinggalkan dulu',
      onCancel: () => onOKPopModal(option),
    });
  };

  const isEntityFinished = (entityID) => {
    const listOfFinishedEntity = studentLearningProgress.list_entity_done;
    const entityData = listOfFinishedEntity.find(
      (item) => item.entity_id === Number(entityID),
    );
    return entityData?.status;
  };

  const getIcon = (activityType, params) => {
    let icon = null;
    const entityID = params.split('-')[2];
    switch (activityType) {
      case 2:
        icon = (
          <>
            {isEntityFinished(entityID) === 1 ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )}
            <YoutubeOutlined />
          </>
        );
        break;
      case 3:
        icon = (
          <>
            {isEntityFinished(entityID) === 1 ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )}
            <FileDoneOutlined />
          </>
        );

        break;
      case 6:
        icon = (
          <>
            {isEntityFinished(entityID) === 1 ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )}
            <PaperClipOutlined />
          </>
        );

        break;

      default:
        icon = (
          <>
            {isEntityFinished(entityID) === 1 ? (
              <CheckCircleFilled style={{ color: '#C9264B' }} />
            ) : (
              <CheckCircleOutlined />
            )}
            <FileOutlined />
          </>
        );

        break;
    }

    return icon;
  };

  const mappedData = courseDetail?.activity_list?.map((item) => ({
    label: item.activity_name,
    key: item.id,
    disabled: item.is_active === 0,
    children: item?.entity_list?.map((data) => ({
      label: data.entity_name,
      disabled: item.is_active === 0,
      icon: getIcon(data.activity_type_id, data.id),
      key: data.id,
    })),
  }));

  const rootKeys = courseDetail?.activity_list?.map((items) => items.id);
  const [openKeys, setOpenKeys] = useState([defaultCheckTree[0]]);
  const [currentKey, setCurrentKey] = useState([defaultCheckTree[1]]);

  const onOpenChange = (keys) => {
    // console.log(keys);
    setDefaultCheckTree([
      keys[1] === undefined ? defaultCheckTree[0] : keys[1],
      defaultCheckTree[1],
    ]);
    // const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    // if (rootKeys.indexOf(latestOpenKey) === -1) {
    //   setOpenKeys(keys);
    // } else {
    //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    // }
  };
  // console.log(defaultCheckTree);
  const onSelectTree = (keyTree) => {
    if (keyTree) {
      const keySplit = keyTree.split('-');
      const parrentID = keySplit[0];
      const activityID = keySplit[1];
      const entityID = keySplit[2];
      const paramNav = {
        parrent: null,
        activity: null,
        entity: null,
      };
      setDefaultCheckTree([`${parrentID}-${activityID}`, keyTree]);
      switch (keySplit.length) {
        case 3:
          paramNav.parrent = parrentID;
          paramNav.activity = activityID;
          paramNav.entity = entityID;
          break;
        case 2:
          paramNav.parrent = parrentID;
          paramNav.activity = activityID;
          break;
        default:
          paramNav.parrent = parrentID;
          break;
      }

      // logic for hold navigation, when learner is availbale in current progress video / quiz
      if (selectionContent?.activity_type_id === 2 && contentVideoPlaying) {
        showConfirm({
          description:
            'Saat ini video sedang berjalan, bila kamu menutup sesi ini, video akan dihentikan!',
          selectedKey: keyTree,
          params: paramNav,
          isContent: 'video', // video / quiz
        });
      } else if (
        config.defaultProps.typeOfQuiz.indexOf(
          selectionContent?.activity_type_id,
        ) >= 0 &&
        contentQuizRunning
      ) {
        showConfirm({
          description:
            'Saat ini quiz sedang berjalan, bila kamu menutup sesi ini, jawaban yang sudah anda jawab akan hilang, dan jika quiz memiliki waktu pengerjaan, waktu akan dilanjutkan meski anda menutup sesi ini!',
          selectedKey: keyTree,
          params: paramNav,
          isContent: 'quiz', // video / quiz
        });
      } else {
        setStateTree({ ...stateTree, selected: [keyTree] });
        navigateCourse(paramNav);
      }
    }
  };

  const isVideoOrQuiz = (key) => {
    const EntityID = key.key.split('-')[2];
    const obj = {
      entityID: EntityID,
    };

    dispatch(getActivitySession(obj)).then((res) => {
      const ID = key.key.split('-')[2];
      const activity = courseDetail.activity_list.find(
        (item) => item.id === key.keyPath[1],
      );
      const entity = activity.entity_list.find(
        (item) => item.id === key.keyPath[0],
      );
      if (res?.id === null) {
        if (VideoOrQuizId.indexOf(entity.activity_type_id) === -1) {
          dispatch(
            createActivitySession({
              entity_id: ID,
              vod_duration: 0,
            }),
          ).then(() => {
            dispatch(getActivitySession(obj)).then((sessionData) =>
              dispatch(
                updateActivitySession({
                  id: sessionData?.id,
                  vod_current_time: 0,
                  status: 1,
                }),
              ).then(() =>
                dispatch(getStudentLearningProgress(courseDetail.id)),
              ),
            );
          });
        }
      }
      return true;
    });
  };

  const handleMenuClick = (e) => {
    // const activity = courseDetail.activity_list.find(
    //   (item) => item.id === e.keyPath[1],
    // );
    // const entity = activity.entity_list.find(
    //   (item) => item.id === e.keyPath[0],
    // );
    // console.log(activity);
    isVideoOrQuiz(e);
    onSelectTree(e.key);
    setCurrentKey(e.key);
  };
  // console.log(defaultCheckTree);
  return (
    <div id="MainControl" className={`${isMobile && 'mobile'}`}>
      <div className="list-course">
        <Title
          level={4}
          style={{ margin: 'unset', display: isMobile && 'none' }}
        >
          {isMobile ? courseName : ''}
        </Title>
        {courseDetail && (
          <div className="list-content">
            {!isMobile && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: collapsed ? 'center' : 'space-between',
                  height: 60,
                  paddingLeft: 24,
                }}
              >
                <p style={{ fontSize: 16 }} className="text-fade">
                  Topik Materi
                </p>
              </div>
            )}
            <div
              style={{
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: isMobile && 12,
                paddingBottom: isMobile && 12,
                backgroundColor: 'white',
                marginBottom: 12,
                marginTop: 12,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LaptopOutlined />
                <span style={{ marginLeft: 6 }}>Course Completion</span>
              </div>
              <Progress
                percent={studentLearningProgress.progress_learner.toFixed(1)}
              />
            </div>
            <div
              style={{
                backgroundColor: 'white',
                fontSize: 18,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: isMobile && 12,
                paddingBottom: isMobile && 12,
                marginBottom: isMobile && 1,
                fontWeight: 600,
                display: !isMobile && 'none',
              }}
            >
              <span>Topik Materi</span>
            </div>
            <Menu
              id="menu-sidebar"
              theme="light"
              mode="inline"
              onClick={handleMenuClick}
              onOpenChange={onOpenChange}
              openKeys={[defaultCheckTree[0]]}
              selectedKeys={[defaultCheckTree[1]]}
              items={mappedData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

MainControl.displayName = config.displayName;
MainControl.defaultProps = config.defaultProps;
MainControl.propTypes = config.propTypes;

export default MainControl;
