import {
  SET_PROGRAM_BUNDLING_LIST,
  SET_PROGRAM_BUNDLING_SET,
} from '../reduxType';

const initialState = {
  list: {
    data: [],
    isLoading: false,
  },
  selected: {
    id: false,
    program_bundling: false,
    program_sku: false,
    description: false,
    program_banner: false,
    assign_product: false,
    created_at: false,
    updated_at: false,
    program_list: [],
  },
};

export default (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_PROGRAM_BUNDLING_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      };
    case SET_PROGRAM_BUNDLING_SET:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};
