import PropTypes from 'prop-types';

const displayName = 'SideMenuLayout';
const propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};
const defaultProps = {};

export default {
  displayName,
  propTypes,
  defaultProps,
};
