import {
  BellOutlined,
  CloseCircleFilled,
  LogoutOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { Badge, Button, Layout, Modal, Typography } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { ICON_BOLBEL_FULL, ICON_CALENDAR } from '../../../assets/svg';

import { destroyToken } from '../../../services/token.service';
import config from './HeaderLayout.component.config';
import './HeaderLayout.component.styles.css';

const { Header } = Layout;
const { Title, Text } = Typography;
const HeaderLayout = ({ routeBase, users, ...props }) => {
  const [state, setState] = React.useState({
    visibleModalNotification: false,
  });
  const navigate = useNavigate();
  const menuDropdown = {
    items: [
      // {
      //   icon: <DatabaseOutlined />,
      //   label: 'Program saya',
      //   key: '/student/programs',
      // },
      ...routeBase.map((items) => (items.foruser ? items : null)),
      {
        icon: <LogoutOutlined style={{ fontSize: '18px', color: '#C9264B' }} />,
        label: 'Keluar',
        key: 'logout',
      },
    ],
    onClick: (e) => {
      if (e.key === 'logout') {
        destroyToken();
        navigate('/auth');
      } else if (e.key === '/student/help') {
        window.open('https://wa.me/6281944365758', '_blank');
      } else {
        navigate(e.key);
      }
    },
  };

  const modalNotificationTrigger = () => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.visibleModalNotification =
        !currentState.visibleModalNotification;
      return { ...currentState };
    });
  };

  return (
    <Header
      id="HeaderStudent"
      style={{
        padding: isMobile ? '0 20px' : '0 40px 0 40px',
        height: isMobile ? 60 : 80,
      }}
    >
      <div
        className="icon-header"
        onClick={() => navigate('/student/dashboard')}
        style={{ cursor: 'pointer' }}
      >
        <ICON_BOLBEL_FULL />
        {/* <Image
          src="https://bolehbelajar.com/wp-content/uploads/2021/08/boleh-belajar-red.png"
          className="logo"
          preview={false}
          width={isMobile ? 100 : 150}
          onClick={() => navigate('/student/dashboard')}
          style={{ cursor: 'pointer' }}
        /> */}
      </div>
      {/* <Input
        size="large"
        placeholder="Search anything..."
        allowClear
        style={{ width: 300 }}
        prefix={<SearchOutlined />}
      /> */}
      {/* <div>
        <Menu
          className="menu-student"
          mode="horizontal"
          items={[
            {
              // icon: <DatabaseOutlined />,
              label: 'Program saya',
              key: '/student/programs',
            },
          ]}
          onClick={(e) => navigate(e.key)}
          selectedKeys={[]}
        />
      </div> */}
      {!isMobile && (
        <div className="action-right">
          {/* <Badge count={1}>
            <Button
              type="default"
              shape="circle"
              size="large"
              icon={<BellOutlined />}
              onClick={() => modalNotificationTrigger()}
            />
          </Badge> */}
          <Button
            className="calendar-tour"
            type="default"
            shape="circle"
            size="large"
            icon={<CalendarOutlined />}
            onClick={() => navigate('/student/calendar')}
          />
          {/* <Dropdown
            className="drowpdown-button-custom"
            menu={menuDropdown}
            placement="bottomRight"
            arrow
          >
            <div className="user-drawer">
              <Button
                type="default"
                shape="circle"
                size="large"
                icon={<ICON_PROFILE />}
              />
              {!isMobile && (
                <div>
                  <Title level={5}>{users?.person}</Title>
                  <Text>{users?.email}</Text>
                </div>
              )}
            </div>
          </Dropdown> */}
        </div>
      )}
      <Modal
        title={
          <div className="header-custom">
            <Title level={3}>Notifikasi</Title>
          </div>
        }
        open={state.visibleModalNotification}
        closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
        onCancel={() => modalNotificationTrigger()}
        footer={null}
        destroyOnClose
      >
        {/* <p>This feature on TBD</p> */}
        <span>Notifikasi belum tersedia.</span>
      </Modal>
    </Header>
  );
};

HeaderLayout.displayName = config.displayName;
HeaderLayout.defaultProps = config.defaultProps;
HeaderLayout.propTypes = config.propTypes;

export default HeaderLayout;
