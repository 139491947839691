import {
  SET_QUIZ_LIST,
  SET_QUIZ_PARRENT,
  SET_QUIZ_PARRENT_DETAIL,
  SET_QUIZ_SUGGESTION,
  SET_QUIZ_VALIDATION,
} from '../reduxType';

const initialState = {
  list: {
    data: [],
    isLoading: false,
  },
  validation: {},
  detail: {
    parrent: {},
    questionList: [],
    isLoading: false,
  },
  suggestion: {
    question: [],
    answer: [],
  },
};

export default (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_QUIZ_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action?.payload,
        },
      };
    case SET_QUIZ_PARRENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action?.payload,
        },
      };
    case SET_QUIZ_PARRENT_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action?.payload,
        },
      };
    case SET_QUIZ_SUGGESTION:
      return {
        ...state,
        suggestion: {
          ...state.suggestion,
          ...action?.payload,
        },
      };
    case SET_QUIZ_VALIDATION:
      return {
        ...state,
        validation: {
          ...action?.payload,
        },
      };
    default:
      return state;
  }
};
