import { jsPDF as Pdf } from 'jspdf';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { quicksandBold, quicksandReguler } from '../assets/fonts';
import {
  claimCertificate,
  getCertifConfiguration,
} from '../redux/actions/student.actions';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../services/aws.service';
import {
  getClassname,
  getInitialClassName,
  getPrefix,
  getRandomId,
  removeWhitespace,
} from '../utils/format';
import { generateNotification } from '../utils/general.utils';

export default function useGeneratePDF(certifName = undefined) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingClaim, setLoadingClaim] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const todayDate = moment();
  const formatedTodaysDate = todayDate.format('D MMMM YYYY');
  const font = {
    regulerFont: quicksandReguler,
    boldFont: quicksandBold,
  };

  const generatePDF = (templateImage, certID, name, programName, date) => {
    let pdfOutput = null;
    const certificate = new Pdf({
      orientation: 'landscape',
      compress: true,
    });
    certificate.addFileToVFS('Quicksand-Regular.ttf', font.regulerFont);
    certificate.addFileToVFS('Quicksand-SemiBold.ttf', font.boldFont);
    certificate.addFont('Quicksand-Regular.ttf', 'Quicksand', 'normal');
    certificate.addFont('Quicksand-SemiBold.ttf', 'Quicksand', 'bold');
    const centerWidth = 150;
    const x = -1.3;
    const y = 0;
    certificate.addImage(templateImage, 'JPEG', x, y, 300, 212);
    certificate.setTextColor(255, 255, 255);
    certificate.setFontSize(12);
    certificate.setFont('Quicksand', 'normal');
    certificate.text(`ID Sertifikat : ${certID}`, centerWidth, 58, {
      align: 'center',
    });
    certificate.setFontSize(24);
    certificate.setFont('Quicksand', 'normal');
    certificate.text(name, centerWidth, 87, {
      align: 'center',
    });
    certificate.setFont('Quicksand', 'bold');
    certificate.setLineHeightFactor(1.2);
    // console.log('Jumlah: ', programName.length);
    if (programName.length <= 55) {
      certificate.setFontSize(24);
      certificate.text(programName, centerWidth, 135, {
        align: 'center',
      });
    } else {
      certificate.setFontSize(23);
      const className = certificate.splitTextToSize(programName, 240);
      certificate.text(className, centerWidth, 130, {
        align: 'center',
      });
    }
    certificate.setFontSize(12);
    certificate.setFont('Quicksand', 'normal');
    certificate.text(date, centerWidth, 159, { align: 'center' });
    pdfOutput = certificate.output('blob');

    return pdfOutput;
  };

  const generateCertificateID = (
    selectedProgram,
    yearCertificateClaimed,
    monthCertificateClaimed,
  ) => {
    let IDCertificate;
    let TP;
    const IDProgram = [selectedProgram.id];
    const randomNumbers = getRandomId();
    const prefix = getPrefix(selectedProgram.program_name);

    try {
      switch (selectedProgram.program_type_id) {
        case 1:
          TP = prefix;
          break;
        case 2:
          TP = 'MC';
          break;
        case 3:
          TP = 'VC';
          break;
        default:
          break;
      }
      const NP = getInitialClassName(selectedProgram.program_name, 3);
      IDCertificate = `${TP.concat(IDProgram.toString().padStart(3, '0'))
        .concat(NP)
        .concat(yearCertificateClaimed)
        .concat(monthCertificateClaimed)
        .concat(selectedProgram.student_serial_number)
        .concat(randomNumbers)}`;
    } catch (error) {
      console.log('Error Generating Certificate: ', error);
      generateNotification({
        type: 'error',
        response: 'Failed Generating Certificate',
        messages: `Incompatible Data Format! (Such as Class Name, Student Name, etc)`,
      });
      setLoadingClaim(false);
      setIsModalOpen(false);
    }

    return IDCertificate;
  };

  const generateLinkCertificate = async (
    selectedProgramId,
    certifNameInput,
    learnerName,
    certID,
    file,
  ) => {
    await putObjectAwsS3Bucket(
      'certificate/learner',
      file,
      `${certID}-${removeWhitespace(learnerName)}.pdf`,
      (originKey) => {
        const keyCertificate = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyCertificate.replaceAll('+', ' '),
          (objectURL) => {
            const payload = {
              program_id: selectedProgramId,
              learner_name: learnerName,
              cert_id: certID,
              cert_link_file: objectURL,
            };
            dispatch(claimCertificate(payload))
              .then(() => {
                setTimeout(() => {
                  setLoadingClaim(false);
                  setIsModalOpen(false);
                }, 1000);
                navigate('/student/my-certificate');
              })
              .catch((err) => console.log('programCreate', err));
          },
          'uploadCertificate',
        );
      },
      'uploadCertificate',
    );
    return true;
  };

  const generateCertificate = (selectedProgram, studentName) => {
    dispatch(getCertifConfiguration(selectedProgram.program_type_id)).then(
      (configData) => {
        if (configData[0] === undefined) {
          generateNotification({
            type: 'error',
            response: 'Failed Generating Certificate',
            messages: `No ${selectedProgram.program_type_name} Certificate Config found.`,
          });
        } else {
          setLoadingClaim(true);
          const NPCode = generateCertificateID(
            selectedProgram, // Change to CourseDetail Data
            todayDate.format('YY'),
            todayDate.format('MM'),
          );
          const classNamePrefixRemoved = getClassname(
            selectedProgram.program_name,
          );

          const file = generatePDF(
            configData[0].template,
            NPCode,
            studentName,
            classNamePrefixRemoved,
            formatedTodaysDate,
          );
          generateLinkCertificate(
            selectedProgram.id,
            certifName,
            studentName,
            NPCode,
            file,
          );
        }
      },
    );
  };

  return {
    generateCertificate,
    setIsModalOpen,
    setLoadingClaim,
    isModalOpen,
    loadingClaim,
  };
}
