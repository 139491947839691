import { connect } from 'react-redux';
import Moderator from './Moderator.component';

const mapStateToProps = (state) => ({
  name: state.header,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Moderator);
