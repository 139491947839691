import {
  ContainerFilled,
  DatabaseFilled,
  FileDoneOutlined,
  FilePdfFilled,
  HomeFilled,
} from '@ant-design/icons';
import React from 'react';
import { ICON_BANTUAN, ICON_PROFILE } from '../assets/svg';

import AdminDashboard from '../containers/Admin/AdminDashboard/AdminDashboard.container';
import path from './path';
// import AdminClass from '../containers/Admin/AdminClass/AdminClass.container';
import AdminLessons from '../containers/Admin/AdminLessons/AdminLessons.container';
import AdminPrograms from '../containers/Admin/AdminPrograms/AdminPrograms.container';
import AdminProgramBundling from '../containers/Admin/AdminProgramBundling/AdminProgramBundling.container';
import AdminQuiz from '../containers/Admin/AdminQuiz/AdminQuiz.container';
import DetailLessons from '../containers/Admin/DetailLessons/DetailLessons.container';
import DetailPrograms from '../containers/Admin/DetailPrograms/DetailPrograms.container';
import DetailQuizContainer from '../containers/Admin/DetailQuiz/DetailQuiz.container';

import UserAccountContainer from '../containers/UserAccount/UserAccount.container';
import UserHelpContainer from '../containers/UserHelp/UserHelp.container';
import AdminCertificate from '../containers/Admin/AdminCertificate/AdminCertificate.container';

export const ADMIN_ROUTE = [
  {
    key: `/admin/${path.ADMIN_PATH.dashboard}`,
    label: 'Dashboard',
    icon: <HomeFilled />,
    path: path.ADMIN_PATH.dashboard,
    element: <AdminDashboard />,
    ismenu: 'yes',
  },
  // {
  //   key: `/admin/${path.ADMIN_PATH.class}`,
  //   label: 'Class',
  //   icon: <BankFilled />,
  //   path: path.ADMIN_PATH.class,
  //   element: <AdminClass />,
  //   ismenu: 'yes',
  // },
  {
    key: `/admin/${path.ADMIN_PATH.programs}`,
    label: 'Programs',
    icon: <ContainerFilled />,
    path: path.ADMIN_PATH.programs,
    element: <AdminPrograms />,
    ismenu: 'yes',
  },
  {
    key: `/admin/${path.ADMIN_PATH['program-bundling']}`,
    label: 'Program Bundling',
    icon: <ContainerFilled />,
    path: path.ADMIN_PATH['program-bundling'],
    element: <AdminProgramBundling />,
    ismenu: 'yes',
  },
  {
    key: `/${path.ADMIN_PATH.programs}/${path.ADMIN_PATH.detail}`,
    label: 'Programs Detail',
    path: `/${path.ADMIN_PATH.programs}/${path.ADMIN_PATH.detail}`,
    element: <DetailPrograms />,
    ismenu: 'no',
  },
  {
    key: `/admin/${path.ADMIN_PATH.lessons}`,
    label: 'Master Lessons',
    icon: <DatabaseFilled />,
    path: path.ADMIN_PATH.lessons,
    element: <AdminLessons />,
    // ismenu: 'yes',
    ismenu: 'no',
  },
  {
    key: `/${path.ADMIN_PATH.lessons}/${path.ADMIN_PATH.detail}`,
    label: 'Lessons Detail',
    path: `/${path.ADMIN_PATH.lessons}/${path.ADMIN_PATH.detail}`,
    element: <DetailLessons />,
    ismenu: 'no',
  },
  {
    key: `/admin/${path.ADMIN_PATH.assignment}`,
    label: 'Assignment',
    icon: <FileDoneOutlined />,
    path: path.ADMIN_PATH.assignment,
    element: <AdminQuiz />,
    ismenu: 'yes',
  },
  {
    key: `/admin/${path.ADMIN_PATH.certificate}`,
    label: 'Certificate',
    icon: <FilePdfFilled />,
    path: path.ADMIN_PATH.certificate,
    element: <AdminCertificate />,
    ismenu: 'yes',
  },
  {
    key: `/${path.ADMIN_PATH.assignment}/${path.ADMIN_PATH.detail}`,
    label: 'Assignment Detail',
    path: `/${path.ADMIN_PATH.assignment}/${path.ADMIN_PATH.detail}`,
    element: <DetailQuizContainer />,
    ismenu: 'no',
  },
  {
    key: `/admin/${path.GENERAL_PATH.account}`,
    label: 'Account',
    icon: <ICON_PROFILE />,
    path: path.GENERAL_PATH.account,
    element: <UserAccountContainer />,
    ismenu: 'no',
    foruser: 'yes',
  },
  {
    key: `/admin/${path.GENERAL_PATH.help}`,
    label: 'Help',
    icon: <ICON_BANTUAN />,
    path: path.GENERAL_PATH.help,
    element: <UserHelpContainer />,
    ismenu: 'no',
    foruser: 'yes',
  },
];
