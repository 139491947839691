export const SET_DEFAULT_REDUX = 'SET_DEFAULT_REDUX';
export const SET_DEFAULT_REDUX_HEADER = 'SET_DEFAULT_REDUX_HEADER';
export const SET_DEFAULT_REDUX_MENU = 'SET_DEFAULT_REDUX_MENU';

export const SET_AUTH_USER = 'SET_AUTH_USER';

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';

export const SET_LESSON_LIST = 'SET_LESSON_LIST';
export const SET_LESSON_ENTITY_LIST = 'SET_LESSON_ENTITY_LIST';
export const SET_LESSON_FIELD_SUGGESTIONS = 'SET_LESSON_FIELD_SUGGESTIONS';

export const SET_PROGRAMS_LIST = 'SET_PROGRAMS_LIST';
export const SET_PROGRAMS_PARRENT = 'SET_PROGRAMS_PARRENT';
export const SET_PROGRAMS_PARTICIPANTS = 'SET_PROGRAMS_PARTICIPANTS';
export const SET_PROGRAMS_FIELD_SUGGESTIONS = 'SET_PROGRAMS_FIELD_SUGGESTIONS';

export const SET_PROGRAM_BUNDLING_LIST = 'SET_PROGRAM_BUNDLING_LIST';
export const SET_PROGRAM_BUNDLING_SET = 'SET_PROGRAM_BUNDLING_SET';

export const SET_QUIZ_LIST = 'SET_QUIZ_LIST';
export const SET_QUIZ_PARRENT = 'SET_QUIZ_PARRENT';
export const SET_QUIZ_PARRENT_DETAIL = 'SET_QUIZ_PARRENT_DETAIL';
export const SET_QUIZ_SUGGESTION = 'SET_QUIZ_SUGGESTION';
export const SET_QUIZ_VALIDATION = 'SET_QUIZ_VALIDATION';

export const SET_STUDENT_UPEVENTS = 'SET_STUDENT_UPEVENTS';
export const SET_STUDENT_PROGRAM = 'SET_STUDENT_PROGRAM';
export const SET_STUDENT_PROGRAM_ENTITY = 'SET_STUDENT_PROGRAM_ENTITY';
export const SET_STUDENT_PROGRAM_SESSION_ENTITY =
  'SET_STUDENT_PROGRAM_SESSION_ENTITY';
export const SET_STUDENT_PROGRAM_SESSION_ENTITY_PREV =
  'SET_STUDENT_PROGRAM_SESSION_ENTITY_PREV';
export const SET_STUDENT_PROGRAM_LIST = 'SET_STUDENT_PROGRAM_LIST';
export const SET_STUDENT_QUIZ_DETAIL = 'SET_STUDENT_QUIZ_DETAIL';
export const SET_STUDENT_QUIZ_SUBMISSION = 'SET_STUDENT_QUIZ_SUBMISSION';

export const SET_CERTIFICATE = 'SET_CERTIFICATE';
export const SET_CERTIFICATE_LIST = 'SET_CERTIFICATE_LIST';
export const SET_STUDENT_CERTIFICATE_LIST = 'SET_STUDENT_CERTIFICATE_LIST';
export const SET_STUDENT_COURSE_DETAIL = 'SET_STUDENT_COURSE_DETAIL';
export const SET_STUDENT_LEARNING_PROGRESS = 'SET_STUDENT_LEARNING_PROGRESS';
