import { connect } from 'react-redux';
import AdminPrograms from './AdminPrograms.component';
import {
  programCreate,
  programList,
  programDuplicate,
  programDetail,
  setParrentProgram,
  programGetTypeSuggestions,
} from '../../../redux/actions/programs.actions';
import {
  clearSearchText,
  setSearchText,
} from '../../../redux/actions/search.actions';

const mapStateToProps = (state) => ({
  common: state.common.settings,
  searchText: state.search,
  programListData: state.program.list,
  fieldSuggestions: state.program.fieldSuggestions,
});

const mapDispatchToProps = {
  clearSearchText,
  programCreate,
  programList,
  setSearchText,
  programDuplicate,
  programDetail,
  setParrentProgram,
  programGetTypeSuggestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPrograms);
