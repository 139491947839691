import React from 'react';
import {
  ContainerFilled,
  FileProtectOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  ICON_BANTUAN,
  ICON_PROFILE,
  ICON_CALENDAR,
  ICON_HOME,
  ICON_PROFILE_2,
} from '../assets/svg';

import path from './path';
import StudentDashboardContainer from '../containers/Student/StudentDashboard/StudentDashboard.container';
import StudentProgramsContainer from '../containers/Student/StudentPrograms/StudentPrograms.container';
import MaincourseContainer from '../containers/Maincourse/Maincourse.container';
import StudentCalendarEvents from '../containers/Student/StudentCalendarEvents/StudentCalendarEvents.container';
import StudentCertificate from '../containers/Student/StudentCertificate/StudentCertificate.container';
import UserAccountContainer from '../containers/UserAccount/UserAccount.container';
import UserHelpContainer from '../containers/UserHelp/UserHelp.container';
import NewMaincourseContainer from '../containers/NewMaincourse/NewMaincourse.container';

export const STUDENT_ROUTE = [
  {
    key: `/student/${path.GENERAL_PATH.dashboard}`,
    label: 'Home',
    icon: <ICON_HOME />,
    index: true,
    path: path.GENERAL_PATH.dashboard,
    element: <StudentDashboardContainer />,
    ismenu: 'yes',
    id: 'home-mobile',
    forMobile: true,
  },
  {
    key: `/student/${path.GENERAL_PATH.programs}`,
    label: 'Programs',
    icon: <ContainerFilled />,
    path: path.GENERAL_PATH.programs,
    element: <StudentProgramsContainer />,
    ismenu: 'yes',
  },
  {
    key: `/${path.GENERAL_PATH.programs}/${path.GENERAL_PATH.course}`,
    label: 'Course',
    icon: <ContainerFilled />,
    path: `/${path.GENERAL_PATH.programs}/${path.GENERAL_PATH.course}`,
    element: <MaincourseContainer />,
    ismenu: false,
  },
  {
    key: `/student/${path.GENERAL_PATH.calendar}`,
    label: 'Kalender',
    icon: <ICON_CALENDAR />,
    index: true,
    path: path.GENERAL_PATH.calendar,
    element: <StudentCalendarEvents />,
    ismenu: 'yes',
    id: 'calendar-mobile',
    forMobile: true,
  },
  // utils
  {
    key: `/student/${path.GENERAL_PATH.account}`,
    label: 'Profil',
    icon: <UserOutlined style={{ fontSize: '18px', color: '#C9264B' }} />,
    path: path.GENERAL_PATH.account,
    element: <UserAccountContainer />,
    ismenu: false,
    foruser: 'yes',
  },
  {
    key: `/student/${path.GENERAL_PATH.myCertificate}`,
    label: 'My Certificate',
    icon: (
      <FileProtectOutlined style={{ fontSize: '18px', color: '#C9264B' }} />
    ),
    path: path.GENERAL_PATH.myCertificate,
    element: <StudentCertificate />,
    ismenu: false,
    foruser: 'yes',
  },
  {
    key: `/student/${path.GENERAL_PATH.myCertificate}`,
    label: 'My Certificate',
    icon: <FileProtectOutlined style={{ fontSize: '18px' }} />,
    path: path.GENERAL_PATH.myCertificate,
    element: <StudentCertificate />,
    ismenu: false,
    id: 'certificate-mobile',
    forMobile: true,
  },
  {
    key: `/student/${path.GENERAL_PATH.account}`,
    label: 'Profil',
    icon: <ICON_PROFILE_2 />,
    path: path.GENERAL_PATH.account,
    element: <UserAccountContainer />,
    ismenu: false,
    id: 'profile-mobile',
    forMobile: true,
  },
  {
    key: `/student/${path.GENERAL_PATH.help}`,
    label: 'Bantuan',
    icon: (
      <QuestionCircleOutlined style={{ fontSize: '18px', color: '#C9264B' }} />
    ),
    path: path.GENERAL_PATH.help,
    element: <UserHelpContainer />,
    ismenu: false,
    foruser: 'yes',
  },
  {
    key: `/${path.GENERAL_PATH.programs}/${path.GENERAL_PATH.newcourse}`,
    label: 'New Course',
    icon: <ContainerFilled />,
    path: `/${path.GENERAL_PATH.programs}/${path.GENERAL_PATH.newcourse}`,
    element: <NewMaincourseContainer />,
    ismenu: false,
  },
];
