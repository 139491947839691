import React from 'react';
import { Modal } from 'antd';

import config from './LoadingEvent.component.config';
import './LoadingEvent.component.styles.css';
import { ICON_BOLBEL_FULL } from '../../assets/svg';

const LoadingEvent = ({ showLoading, textLoading, onType, ...others }) =>
  onType === 'admin' ? (
    <div
      id="modalLoading"
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '#efefef',
        zIndex: 1,
      }}
    >
      <div
        style={{
          textAlign: 'center',
          height: 'fit-content',
          marginTop: '30%',
        }}
      >
        <div>
          <ICON_BOLBEL_FULL />
        </div>
        <p>
          {textLoading !== undefined
            ? textLoading
            : 'Mohon tunggu, sedang memuat konten belajar kamu.'}
        </p>
      </div>
    </div>
  ) : (
    <Modal
      id="modalLoading"
      open={showLoading}
      cancelButtonProps={false}
      footer={false}
      centered
      closeIcon={false}
      closable={false}
      width={250}
      {...others}
    >
      <div style={{ textAlign: 'center' }}>
        <div>
          <ICON_BOLBEL_FULL />
        </div>
        <p>
          {textLoading !== undefined
            ? textLoading
            : 'Mohon tunggu, sedang memuat konten belajar kamu.'}
        </p>
      </div>
    </Modal>
  );

LoadingEvent.displayName = config.displayName;
LoadingEvent.defaultProps = config.defaultProps;
LoadingEvent.propTypes = config.propTypes;

export default LoadingEvent;
