import { connect } from 'react-redux';
import UserHelp from './UserHelp.component';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  users: state.users,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserHelp);
