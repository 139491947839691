import React from 'react';

export default function useHandleAddLinkedin() {
  const handleAddToLinkedinProfile = (
    programName,
    certificateUrl,
    certificateID,
    year,
    month,
  ) => {
    const link = `https://www.linkedin.com/profile/add?startTask=${programName}&name=${programName}&organizationId=80781181&issueYear=${year}&issueMonth=${month}&certUrl=${certificateUrl}&certId=${certificateID}`;
    window.open(link, '_blank');
  };
  return { handleAddToLinkedinProfile };
}
