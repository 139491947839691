import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Layout,
  Typography,
  notification,
} from 'antd';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { ICON_BOLBEL_FULL } from '../../assets/svg';

import { MotionPage } from '../../components';

import { getToken } from '../../services/token.service';

import config from './Auth.component.config';
import './Auth.component.styles.css';

const { Content, Footer, Header } = Layout;
const { Title, Text } = Typography;

const Auth = ({ ...props }) => {
  const { user } = props;
  const [loading, setloading] = React.useState(false);
  const refForm = React.createRef();
  const isUserToken = getToken();
  const [state, setState] = React.useState({
    loadingForm: false,
    showQuickResetPass: false,
    passHasReset: false,
  });
  const [toggleForgotPassword, setToggleForgotPassword] = React.useState(false);

  const navigate = useNavigate();
  const navigationMenu = (e) => navigate(e);

  const onFinishLoginAction = () => {
    const fields = refForm.current.getFieldsValue();
    const { fAuthUser } = props;
    fAuthUser(fields)
      .then(() => {
        setloading(false);
        window.location.reload();
      })
      .catch(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    const findRoles = config.defaultProps.userRoles.find(
      (items) => items.role === user?.role,
    );
    if (isUserToken !== null) {
      if (isUserToken.uslo === 1) {
        navigationMenu('/admin/*');
      } else {
        navigationMenu('/student/*');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderFormAuth = () => (
    <Form
      className="form-auth"
      layout="vertical"
      ref={refForm}
      onFinish={onFinishLoginAction}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Email is required',
          },
          {
            type: 'email',
            message: 'Email is not valid',
          },
        ]}
      >
        <Input
          placeholder="you@example.com"
          autoComplete="off"
          className="input-text"
        />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Password is required',
          },
        ]}
        extra={[
          <Checkbox key="remember">Remember me</Checkbox>,
          <Button
            key="forgotPassword"
            type="link"
            className="btn-forgot"
            // onClick={() => setToggleForgotPassword(true)}
            onClick={() => {
              const ahref = document.createElement('a');
              ahref.href = 'https://bolehbelajar.com/auth/forgot';
              ahref.setAttribute('target', '_blank');
              ahref.click();
            }}
          >
            Forgot Password?
          </Button>,
        ]}
      >
        <Input.Password
          type="password"
          autoComplete="off"
          placeholder="Enter your password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          htmlType="submit"
          type="default"
          size="large"
          className="button-signin"
          onClick={() => setloading(true)}
          loading={loading}
        >
          LOGIN
        </Button>
      </Form.Item>
    </Form>
  );

  const onFinishForgotPasswordAction = () => {
    const fields = refForm.current.getFieldsValue();
    const { forgotPassword } = props;
    forgotPassword(fields)
      .then(() =>
        notification.success({
          message: `Success`,
          description: `Check your email to get/reset your password.`,
        }),
      )
      .then(() => setToggleForgotPassword(false))
      .catch(() =>
        notification.error({
          message: `System Error`,
          description: `Either the service is down or this email is not registered. Please try again later.`,
        }),
      );
  };

  const renderFormForgotPassword = () => (
    <Form
      className="form-auth"
      layout="vertical"
      ref={refForm}
      onFinish={onFinishForgotPasswordAction}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Email is required',
          },
          {
            type: 'email',
            message: 'Email is not valid',
          },
        ]}
        extra={[
          <Button
            key="backToLoginForm"
            type="link"
            className="btn-forgot"
            onClick={() => setToggleForgotPassword(false)}
          >
            Back to Login
          </Button>,
        ]}
      >
        <Input
          placeholder="you@example.com"
          autoComplete="off"
          className="input-text"
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          htmlType="submit"
          type="default"
          size="large"
          className="button-signin"
          onClick={() => setloading(true)}
          loading={loading}
        >
          RESET PASSWORD
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layout id="AuthLayout">
      <MotionPage motionType="fadeIn">
        <Content id="authForm" className={`${isMobile && 'mobile-version'}`}>
          <div className="section-content">
            <Card>
              <div className="section-form">
                <div className="image-content">
                  <ICON_BOLBEL_FULL />
                </div>
                <div style={{ textAlign: 'left' }}>
                  <Title level={4} style={{ margin: 'unset' }}>
                    {toggleForgotPassword === false
                      ? 'Login'
                      : 'Forgot Password'}
                  </Title>
                  <Text>
                    {toggleForgotPassword === false
                      ? 'Login in to your LMS Account'
                      : 'Reset your password'}
                  </Text>
                </div>
                {toggleForgotPassword === false && renderFormAuth()}
                {toggleForgotPassword === true && renderFormForgotPassword()}
              </div>
              {isMobile && (
                <Text className="footer">
                  © {format(new Date(), 'yyyy ')}
                  LMS Boleh Belajar All right reserved
                </Text>
              )}
            </Card>
          </div>
          {!isMobile && (
            <Text className="footer">
              © {format(new Date(), 'yyyy ')}
              LMS Boleh Belajar All right reserved
            </Text>
          )}
        </Content>
      </MotionPage>
    </Layout>
  );
};

Auth.displayName = config.displayName;
Auth.defaultProps = config.defaultProps;
Auth.propTypes = config.propTypes;

export default Auth;
