import {
  SET_STUDENT_UPEVENTS,
  SET_STUDENT_PROGRAM,
  SET_STUDENT_PROGRAM_ENTITY,
  SET_STUDENT_PROGRAM_LIST,
  SET_STUDENT_QUIZ_DETAIL,
  SET_STUDENT_QUIZ_SUBMISSION,
  SET_STUDENT_PROGRAM_SESSION_ENTITY,
  SET_STUDENT_PROGRAM_SESSION_ENTITY_PREV,
  SET_STUDENT_CERTIFICATE_LIST,
  SET_STUDENT_COURSE_DETAIL,
  SET_STUDENT_LEARNING_PROGRESS,
} from '../reduxType';

const initialState = {
  upcomingEvents: {
    data: [],
    loading: false,
  },
  certifList: {
    data: [],
    isLoading: false,
  },
  studentLearningProgress: {
    data: {
      program_id: 0,
      program_name: '',
      progress_learner: 0,
      total_entity: 0,
      total_entity_done: 0,
    },
  },
  courseDetail: {
    data: {
      id: false,
      program_name: false,
      program_type_id: false,
      program_type_name: false,
      student_serial_number: false,
      description: false,
      banner_image: false,
      activity_list: [],
    },
    isLoading: false,
  },
  programList: {
    data: [],
    loading: false,
  },
  programSelection: {
    data: {
      banner_image: '',
      created_at: '',
      description: '',
      id: null,
      modified_at: '',
      program_name: '',
    },
    activity: [],
    activityEntity: [],
    loading: true,
  },
  quizSelection: {},
  quizSubmission: {
    data: {
      activity_session_id: 0,
      list_submission: [],
    },
  },
  activitySession: {
    entity_id: null,
    id: null,
    quiz_score: null,
    vod_duration: null,
    vod_current_time: null,
    status: null,
    history: [],
  },
  activitySessionPrevious: {
    entity_id: null,
    id: null,
    quiz_score: null,
    vod_duration: null,
    vod_current_time: null,
    status: null,
    history: [],
  },
};

export default (state = initialState, action = {}) => {
  let newDataActivityEntity = state.programSelection.activityEntity;
  if (action.type === SET_STUDENT_PROGRAM_ENTITY) {
    const findExistData = newDataActivityEntity.find(
      (items) => items.activityID === action.payload.activityID,
    );
    if (findExistData !== undefined) {
      const replacingData = newDataActivityEntity.filter(
        (items) => items.activityID !== action.payload.activityID,
      );
      newDataActivityEntity = replacingData;
      newDataActivityEntity.push(action.payload);
    } else {
      newDataActivityEntity.push(action.payload);
    }
  }

  switch (action?.type) {
    case SET_STUDENT_UPEVENTS:
      return {
        ...state,
        upcomingEvents: {
          ...action?.payload,
        },
      };
    case SET_STUDENT_PROGRAM:
      return {
        ...state,
        programSelection: {
          ...action?.payload,
        },
      };
    case SET_STUDENT_PROGRAM_ENTITY:
      return {
        ...state,
        programSelection: {
          ...state.programSelection,
          activityEntity: newDataActivityEntity,
        },
      };
    case SET_STUDENT_PROGRAM_SESSION_ENTITY:
      return {
        ...state,
        activitySession: {
          ...state.activitySession,
          ...action.payload,
        },
      };
    case SET_STUDENT_PROGRAM_SESSION_ENTITY_PREV:
      return {
        ...state,
        activitySessionPrevious: {
          ...state.activitySessionPrevious,
          ...action.payload,
        },
      };
    case SET_STUDENT_PROGRAM_LIST:
      return {
        ...state,
        programList: {
          ...state.programList,
          ...action.payload,
        },
      };
    case SET_STUDENT_QUIZ_DETAIL:
      return {
        ...state,
        quizSelection: {
          ...state.quizSelection,
          ...action.payload,
        },
      };
    case SET_STUDENT_QUIZ_SUBMISSION:
      return {
        ...state,
        quizSubmission: {
          ...state.quizSubmission,
          ...action.payload,
        },
      };
    case SET_STUDENT_CERTIFICATE_LIST:
      return {
        ...state,
        certifList: {
          ...state.certifList,
          ...action.payload,
        },
      };
    case SET_STUDENT_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          ...action.payload,
        },
      };
    case SET_STUDENT_LEARNING_PROGRESS:
      return {
        ...state,
        studentLearningProgress: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
