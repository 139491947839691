import { connect } from 'react-redux';
import DetailLessons from './DetailLessons.component';
import {
  lessonsUpdate,
  lessonsDelete,
  lessonsEntityList,
  lessonsEntityCreate,
  lessonsEntityDelete,
  lessonsEntityUpdate,
  lessonsEntityUpdatePosition,
  lessonGetTypeSuggestions,
} from '../../../redux/actions/lessons.actions';
import {
  getListQuiz,
  getListQuizDetail,
} from '../../../redux/actions/quiz.actions';
import { clearSearchText } from '../../../redux/actions/search.actions';

const mapStateToProps = (state) => ({
  common: state.common.settings,
  searchText: state.search,
  lessonEntityObject: state.lesson.detail,
  listQuiz: state.quiz.list,
  selectionQuiz: state.quiz.detail,
  fieldSuggestions: state.lesson.fieldSuggestions,
});

const mapDispatchToProps = {
  clearSearchText,
  lessonsUpdate,
  lessonsDelete,
  lessonsEntityList,
  lessonsEntityCreate,
  lessonsEntityDelete,
  lessonsEntityUpdate,
  lessonsEntityUpdatePosition,
  lessonGetTypeSuggestions,
  getListQuiz,
  getListQuizDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailLessons);
