import { connect } from 'react-redux';
import { programGetTypeSuggestions } from '../../../redux/actions/programs.actions';
import AdminCertificate from './AdminCertificate.component';
import {
  certificateCreate,
  certificateList,
  certificateUpdate,
} from '../../../redux/actions/certificate.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  fieldSuggestions: state.program.fieldSuggestions,
  listCertificate: state.certificate.list,
});

const mapDispatchToProps = {
  programGetTypeSuggestions,
  certificateCreate,
  certificateUpdate,
  certificateList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCertificate);
