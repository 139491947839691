import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  certificateCreate,
  certificateUpdate,
} from '../redux/actions/certificate.actions';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../services/aws.service';

export default function useCertificateConfig() {
  const [currentSelectedId, setCurrentSelectedId] = useState({
    certificateId: null,
    programId: null,
    program: '',
    create: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    fileList: [],
  });
  const uploadCertificate = async (template) => {
    const tempData = {
      uid: template?.file?.uid,
      name: template?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'certificate/config',
      template?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };

  const editCertificate = (programId, certificateId, program) => {
    setIsEdit(true);
    setCurrentSelectedId({
      ...currentSelectedId,
      certificateId,
      programId,
      program,
    });
    setOpenModal(true);
  };

  const getIdForCreateCertificate = (itemId) => {
    setIsEdit(false);
    setCurrentSelectedId({ ...currentSelectedId, create: itemId });
    setOpenModal(true);
  };

  const createCertificate = (payload) => {
    const createCertifPayload = {
      program_type: currentSelectedId.create,
      ...payload,
    };
    dispatch(certificateCreate(createCertifPayload))
      .then(() => {
        setRefreshData(false);
        setOpenModal(false);
      })
      .catch((err) => console.log('createCertificate', err));
  };

  const updateCertificate = (payload) => {
    const updatedPayload = {
      id: currentSelectedId.certificateId,
      program_type: currentSelectedId.programId,
      ...payload,
    };
    dispatch(certificateUpdate(updatedPayload))
      .then(() => {
        setRefreshData(false);
        setOpenModal(false);
      })
      .catch((err) => console.log('updateCertificate', err));
  };
  return {
    updateCertificate,
    createCertificate,
    getIdForCreateCertificate,
    editCertificate,
    // uploadCertificate,
    // setState,
    // state,
    currentSelectedId,
    isEdit,
    openModal,
    setOpenModal,
    setIsEdit,
    setRefreshData,
    refreshData,
  };
}
