import PropTypes from 'prop-types';

const displayName = 'Auth';
const propTypes = {};
const defaultProps = {
  userRoles: [
    {
      role: 1,
      site: 'admin',
    },
    {
      role: 2,
      site: 'student',
    },
    {
      role: 3,
      site: 'moderator',
    },
    {
      role: 4,
      site: 'instructor',
    },
  ],
};

export default { displayName, propTypes, defaultProps };
