import {
  CloseCircleFilled,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Typography,
  Upload,
} from 'antd';
import React from 'react';

import moment from 'moment';
import {
  getObjectAwsS3Bucket,
  putObjectAwsS3Bucket,
} from '../../../../services/aws.service';

import config from './control.component.config';
import './control.component.styles.css';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const FormCreate = ({
  showModal,
  closeModal,
  createProgram,
  updateProgram,
  optionForm,
  programList,
  programSelected,
  ...props
}) => {
  const refForm = React.useRef();
  // const [productSelected, setProductSelected] = useState([]);
  // const [tempIDs, setTempIDs] = useState([]);
  const [state, setState] = React.useState({
    fileList: [],
  });
  // const [searchText, setSearchText] = React.useState('');
  const [programBundlingList, setProgramBundlingList] = React.useState([0]);
  const [programBundlingProgress, setProgramBundlingProgress] =
    React.useState(false);
  const optionCheckbox = () => {
    const datacheckbox = [];
    programList.data.forEach((data) => {
      if (data.program_type_id === 3) {
        datacheckbox.push({
          label: data.program_name,
          value: data.id,
          disabled: programBundlingList.includes(data.id),
        });
      }
    });
    return datacheckbox.sort((a, b) => a.value - b.value);
  };
  const updateProgramBundlingList = (index, value) => {
    programBundlingList[index] = value;
    setProgramBundlingList([...programBundlingList]);
  };
  const addProgramBundlingList = () => {
    setProgramBundlingList([...programBundlingList, 0]);
  };
  const removeProgramBundlingList = (index, value) => {
    setProgramBundlingProgress(true);
    const newData = programBundlingList.filter((item) => item !== value);
    setProgramBundlingList(newData);
    setTimeout(() => {
      setProgramBundlingProgress(false);
    }, 500);
  };

  // const findProductName = (idsToFind) =>
  //   idsToFind.map((id) => {
  //     const productFound = optionCheckbox().find((item) => item.value === id);
  //     return productFound ?? productFound;
  //   });

  // const handleProgramChange = (checkedValues) => {
  //   if (optionForm === 'update') {
  //     setTempIDs(checkedValues);
  //   }

  //   const productsName = findProductName(checkedValues);
  //   setProductSelected(productsName);
  // };
  // console.log(programSelected);
  React.useEffect(() => {
    if (optionForm === 'update') {
      let ids =
        programSelected.program_list?.length > 0 &&
        programSelected.program_list.map((list) => list.program_id);
      ids = ids.filter((item, index) => ids.indexOf(item) === index);
      // setTempIDs(ids);
      // const productsName = ids.map((item, index) => {
      //   const temp = programSelected.program_list.find(
      //     (program) => program.program_id === item,
      //   );
      //   return {
      //     label: temp.program_name,
      //     value: temp.id,
      //   };
      // });
      // setProductSelected(productsName);
      setProgramBundlingList([]);
      setTimeout(() => {
        refForm.current.setFieldsValue({
          program_batch: programSelected.program_bundling,
          assign_product: programSelected.assign_product,
          description: programSelected.description,
          // program_list: ids,
        });
        setProgramBundlingList(ids);
      }, 500);
    }
  }, [
    optionForm,
    programSelected.program_list,
    programSelected.assign_product,
    programSelected.description,
    programSelected.program_bundling,
  ]);

  // const renderSelectedProducts = (products) => {
  //   let elements;
  //   const dataSelected = productSelected.sort((a, b) => a.value - b.value);
  //   if (dataSelected.length > 0) {
  //     elements = (
  //       <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
  //         {dataSelected.map((item) => (
  //           <li key={item.value} id="product-selected-list">
  //             {item.label}
  //           </li>
  //         ))}
  //       </ul>
  //     );
  //   }

  //   return elements;
  // };

  const actionUpload = async (upload) => {
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
    };
    await putObjectAwsS3Bucket(
      'assets/programs',
      upload?.file,
      'no-option',
      (originKey) => {
        const keyBanner = originKey.split('amazonaws.com/')[1];
        getObjectAwsS3Bucket(
          keyBanner.replaceAll('+', ' '),
          (objectURL) => {
            // console.log(objectURL);
            setState((prevState) => {
              const currentState = { ...prevState };
              currentState.fileList = [];
              currentState.fileList.push({
                ...tempData,
                uid: originKey,
                url: objectURL,
                status: 'done',
              });
              currentState.onUploadFile = !currentState.onUploadFile;
              return { ...currentState };
            });
          },
          'bucket-private',
        );
      },
      'bucket-private',
    );
  };

  return (
    <Modal
      title={
        <div className="header-custom">
          <Title level={3}>
            {optionForm === 'update' ? 'Update' : 'Create'} Program Bundling
          </Title>
          <span>
            Isi field-field di bawah ini untuk membuat Program Bundling Baru.
          </span>
        </div>
      }
      centered
      open={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => closeModal({ action: 'closeModal' })}
      footer={null}
      style={{ marginTop: 36, marginBottom: 36 }}
      destroyOnClose
      maskClosable={false}
    >
      <Form
        ref={refForm}
        layout="vertical"
        id="createProgramBundling"
        onFinish={() => {
          const fields = refForm.current.getFieldsValue();
          const bodyRequest = {
            ...fields,
            program_type: 4,
            assign_product:
              fields.assign_product === undefined ? 0 : fields.assign_product,
            program_banner:
              state?.fileList[0]?.url !== undefined
                ? state?.fileList[0]?.url
                : '',
            program_list: programBundlingList,
          };
          if (optionForm === 'update') {
            const updateBody = {
              ...bodyRequest,
              id: programSelected.id,
            };
            updateProgram(updateBody);
          } else {
            createProgram(bodyRequest);
          }
          setProgramBundlingList([0]);
          refForm.current.setFieldsValue({
            program_batch: '',
            assign_product: '',
            description: '',
            program_list: [],
          });
        }}
      >
        <Form.Item
          label="Program Bundle"
          name="program_batch"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input type="text" placeholder="input here ..." maxLength={110} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea
            type="text"
            placeholder="Isikan deskripsi program di sini (opsional)"
            rows={5}
          />
        </Form.Item>
        <Form.Item label="Upload Banner">
          <Form.Item name="program_banner" noStyle>
            <Upload.Dragger
              name="files"
              listType="picture"
              customRequest={actionUpload}
              fileList={state.fileList}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        {/* <div style={{ marginBottom: 10 }}>
          <div
            style={{
              marginBottom: 10,
            }}
          >
            Program
          </div>
          <Input
            type="text"
            placeholder="search input here ..."
            onKeyUp={(e) => setSearchText(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </div> */}
        {/* <div style={{ marginTop: 10, marginBottom: 10 }}>
          <span>Program Selected:</span>
          {renderSelectedProducts()}
        </div> */}
        <Form.Item label="Program List" name="program_list">
          {!programBundlingProgress ? (
            programBundlingList.map((item, index) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 10,
                }}
              >
                <Select
                  style={{ width: '85%' }}
                  showSearch
                  placeholder="Select Program ..."
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionCheckbox()}
                  defaultValue={item === 0 ? 'Select Program ...' : item}
                  onChange={(value) => updateProgramBundlingList(index, value)}
                />
                <div style={{ display: 'flex', marginLeft: 10 }}>
                  <Button
                    hidden={programBundlingList.length - 1 !== index}
                    style={{ borderRadius: 100 }}
                    icon={<PlusOutlined />}
                    type="ghost"
                    size="small"
                    onClick={() => addProgramBundlingList()}
                  />
                  <Button
                    hidden={programBundlingList.length === 1}
                    style={{ marginLeft: 5, borderRadius: 100 }}
                    icon={<MinusOutlined />}
                    type="primary"
                    size="small"
                    onClick={() => removeProgramBundlingList(index, item)}
                  />
                </div>
              </div>
            ))
          ) : (
            <Card loading />
          )}
          {/* <Checkbox.Group
            onChange={handleProgramChange}
            style={{ border: 'solid 1px #dedede', padding: 4, marginTop: 8 }}
            options={optionCheckbox().filter((item) =>
              item?.label?.toLowerCase()?.includes(searchText?.toLowerCase()),
            )}
          /> */}
        </Form.Item>
        <Form.Item label="Assign to Product">
          <Form.Item name="assign_product" noStyle>
            <Radio.Group
              options={[
                {
                  label: 'No',
                  value: 0,
                },
                {
                  label: 'Yes',
                  value: 1,
                },
              ]}
              defaultValue={0}
              optionType="button"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <div className="form-action-button">
            <Button
              type="default"
              onClick={() => {
                setProgramBundlingList([0]);
                closeModal({ action: 'closeModal' });
              }}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              {optionForm === 'update' ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

FormCreate.displayName = config.displayName;
FormCreate.defaultProps = config.defaultProps;
FormCreate.propTypes = config.propTypes;

export default FormCreate;
