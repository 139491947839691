import {
  BellOutlined,
  ChromeOutlined,
  CloseCircleFilled,
  DatabaseOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  LogoutOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Menu,
  Modal,
  Row,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Sider from 'antd/lib/layout/Sider';
import { Content, Header } from 'antd/lib/layout/layout';
import { isMobile } from 'react-device-detect';
import CardTopPrograms from './CardTopPrograms/CardTopPrograms.component';

import { ICON_BOLBEL_FULL } from '../../../assets/svg';
import { LoadingEvent, MotionPage } from '../../../components';
import { destroyToken } from '../../../services/token.service';
import config from './StudentDashboard.component.config';
import './StudentDashboard.component.styles.css';

const { Title, Text, Paragraph } = Typography;

const StudentDashboard = ({
  programList,
  getStudentCourseList,
  getStudentProgramList,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  updateStudentProgramActivity,
  getCourseDetail,
  getStudentLearningProgress,
  users,
  common,
  ...props
}) => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    needMounting: true,
    showLoading: true,
    list_data: [],
    temp_data: [],
    temp_selected: {},
    temp_selected_detail: false,
  });
  // const programData = programList.data;

  const mountingFirstData = () => {
    // old program list ---
    // getStudentProgramList().then((dataResponse) => {
    //   // console.log('Data Response: ', dataResponse);
    //   setState((prevState) => {
    //     const currentState = { ...prevState };
    //     dataResponse.forEach((items) => {
    //       currentState.temp_data = [...currentState.temp_data, items];
    //     });
    //     currentState.list_data = currentState.temp_data;
    //     return { ...currentState };
    //   });
    // });
    getStudentCourseList().then((dataResponse) => {
      console.log('dataResponse', dataResponse);
      setState((prevState) => {
        const currentState = { ...prevState };
        dataResponse.forEach((items) => {
          currentState.temp_data = [...currentState.temp_data, items];
        });
        currentState.list_data = currentState.temp_data;
        currentState.showLoading = !currentState.showLoading;
        return { ...currentState };
      });
    });
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };

  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  });

  const navigateToDetailPrograms = (programObject) => {
    console.log(programObject);
    const programId = programObject.id;
    if (programObject.program_type_id === 4) {
      setState((prevState) => {
        // set loading indicator true
        const currentState = { ...prevState };
        currentState.temp_selected = programObject;
        currentState.temp_selected_detail = !currentState.temp_selected_detail;
        return { ...currentState };
      });
    } else {
      setState((prevState) => {
        // set loading indicator true
        const currentState = { ...prevState };
        currentState.showLoading = !currentState.showLoading;
        return { ...currentState };
      });

      getCourseDetail(programId).then(() => {
        getStudentLearningProgress(programId);
        setTimeout(() => {
          setState((prevState) => {
            const currentState = { ...prevState };
            currentState.showLoading = !currentState.showLoading;
            return { ...currentState };
          });
          navigate(`/student/programs/course?course=${programId}`);
        }, 1000);
      });
    }
  };

  return (
    <Layout>
      <MotionPage motionType="fadeIn">
        <Content>
          <div
            id="StudentDashboard"
            style={{
              padding: isMobile ? '6rem 1.5rem' : '7rem 2.5rem 12rem 19rem',
              minHeight: '100vh',
            }}
          >
            <div style={{ marginBottom: 24 }}>
              <Title style={{ margin: 'unset' }}>Hi, {users?.person}!</Title>
              <Text style={{ fontSize: 12 }}>
                Selamat Datang Kembali, selamat belajar lagi!
              </Text>
            </div>
            <Row gutter={24}>
              <Col md={24}>
                {/* modal program bundling */}
                {state.temp_selected_detail && (
                  <MotionPage motionType="fadeIn">
                    <Card>
                      <div className="bundling">
                        <div
                          className="bundling-header"
                          style={{
                            display: 'flex',
                            alignContent: 'flex-start',
                          }}
                        >
                          <div style={{ marginRight: 20 }}>
                            <Button
                              icon={<LeftOutlined />}
                              onClick={() => {
                                setState((prevState) => {
                                  // set loading indicator true
                                  const currentState = { ...prevState };
                                  currentState.temp_selected_detail =
                                    !currentState.temp_selected_detail;
                                  return { ...currentState };
                                });
                              }}
                            />
                          </div>
                          <div className="title">
                            <Title level={3}>
                              {state.temp_selected?.program_name}
                            </Title>
                            <Paragraph
                              ellipsis={{
                                rows: 2,
                                expandable: false,
                              }}
                              style={{
                                color: '#7B7777',
                              }}
                            >
                              {state.temp_selected?.description}
                            </Paragraph>
                          </div>
                        </div>
                        <div className="bundling-body">
                          <CardTopPrograms
                            sourceData={state.temp_selected?.detail}
                            navigateToDetailPrograms={navigateToDetailPrograms}
                          />
                        </div>
                      </div>
                    </Card>
                  </MotionPage>
                )}
                {!state.temp_selected_detail && (
                  <MotionPage motionType="fadeIn">
                    <CardTopPrograms
                      sourceData={state.list_data}
                      navigateToDetailPrograms={navigateToDetailPrograms}
                    />
                  </MotionPage>
                )}
              </Col>
            </Row>
          </div>
          <LoadingEvent showLoading={state.showLoading} />
        </Content>
      </MotionPage>
    </Layout>
  );
};

StudentDashboard.displayName = config.displayName;
StudentDashboard.defaultProps = config.defaultProps;
StudentDashboard.propTypes = config.propTypes;

export default StudentDashboard;
