import { Modal, notification } from 'antd';
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx';
import { destroyToken, getToken } from '../services/token.service';
// import { deleteQuiz } from '../redux/actions/quiz.actions';

const studentName = '';

export const generateHeaderRequest = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()?.access_token}`,
    },
  };
  return { ...config };
};

export const generateNotification = ({ ...props }) => {
  const notif = notification;
  const msgResponse =
    props?.messages?.message || props?.messages[0].type || props?.messages;

  switch (props.type) {
    case 'error':
      notif.error({
        message: props?.response
          ? `System Err [${props?.response}]`
          : 'Action Denied',
        description: msgResponse,
      });
      break;
    case 'success':
      notif.success({
        message: `Success [${props?.response}]`,
        description: props?.messages,
      });
      break;
    case 'info':
      notif.info({
        message: `Information: ${props?.messages}`,
      });
      break;
    default:
      notif.info({
        message: `Information: ${props?.messages}`,
      });
      break;
  }
  return notif.config({
    duration: 10,
  });
};

export const cuntDownTime = (timer, func) => {
  let minutes = Math.floor(timer);
  let seconds = 0;

  setInterval(() => {
    if (seconds <= 0) {
      if (minutes !== 0) {
        seconds = 60;
        minutes -= 1;
      }
    } else {
      seconds -= 1;
    }
    func({ minutes, seconds });
    // func(`${minutes}m ${seconds}s`);
  }, 1000);
};

export const exportArraytoExcel = ({ data, wbName, wsName }) => {
  const wb = xlsxUtils.book_new();
  const ws = xlsxUtils.json_to_sheet(data);

  xlsxUtils.book_append_sheet(wb, ws, wsName || 'MySheet1');
  xlsxWriteFile(wb, `${wbName || 'MyExcel'}.xlsx`);
};

export const NotificationExpiredToken = () => {
  let secondsToGo = 5;
  const timer = setInterval(() => {
    secondsToGo -= 1;
    modal.update({
      content: `Sesi login kamu sudah habis, kamu akan diarahkan ke halaman login dalam ${secondsToGo} detik.`,
    });
  }, 1000);
  const modal = Modal.info({
    title: 'Informasi',
    content: `Sesi login kamu sudah habis, kamu akan diarahkan ke halaman login dalam ${secondsToGo} detik.`,
    centered: true,
    mask: true,
    maskClosable: false,
    maskStyle: {
      backgroundColor: 'rgba(0, 0, 0, 95%)',
    },
    onOk: () => {
      clearInterval(timer);
      modal.destroy();
      destroyToken();
      window.location.href = `${window.location.origin}/auth`;
    },
  });
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
    destroyToken();
    window.location.href = `${window.location.origin}/auth`;
  }, secondsToGo * 1000);
};

export const retrieveErrorHandling = (error) => {
  const statusCodeData = error?.response?.data?.detail?.code;
  const statusCodeRequest = error?.response?.status;
  generateNotification({
    type: 'error',
    response: error?.response?.data?.detail?.code,
    messages: error?.response?.data?.detail,
  });
  if (statusCodeData === 40101 || statusCodeRequest === 401) {
    // NotificationExpiredToken();
  }
};

export const greetingWords = [
  `Congratulations, ${studentName}! You did it!`,
  `Well done, ${studentName}! You've completed your course online!`,
  `Great job, ${studentName}! You should be proud of yourself!`,
  `Congratulations on completing your course online, ${studentName}! You've earned your certificate!`,
  `You've done it, ${studentName}! Congratulations on finishing your course online!`,
  `Way to go, ${studentName}! You've completed your course and earned your certificate online!`,
  `Amazing work, ${studentName}! You've finished your course online and claimed your certificate!`,
  `Congratulations on your achievement, ${studentName}! You've completed your course online!`,
  `We are so proud of you, ${studentName}! Congratulations on finishing your course online and earning your certificate!`,
  `Bravo, ${studentName}! You've successfully completed your course online and claimed your certificate!`,
];
