import { get } from 'lodash';
import { SET_AUTH_USER } from '../reduxType';
import {
  generateNotification,
  generateHeaderRequest,
} from '../../utils/general.utils';
import { destroyToken, getToken } from '../../services/token.service';
import api from '../../services/api.service';

const { apiService, setAuthToken } = api.getInstance();

export const fConsumeToken = () => async () => {
  const existingToken = getToken();
  try {
    const { data } = await apiService.post('auth/refresh_token', {
      refresh_token: existingToken?.refresh_token,
    });
    const newToken = {
      ...existingToken,
      ...data?.data,
    };
    setAuthToken(newToken);
  } catch (error) {
    const { status } = error.response;
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    if (status === 400) {
      destroyToken();
      window.location.replace('/auth');
    } else {
      setAuthToken(existingToken);
    }
  }
};

export const fAuthUser = (payload) => async (dispatch) => {
  let result;
  dispatch({
    type: SET_AUTH_USER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const { data, status } = await apiService.post('auth/login', {
      ...payload,
    });
    const returnData = {
      ...data.data,
      email: payload?.email,
      isLoading: false,
    };
    setAuthToken(returnData);
    dispatch({
      type: SET_AUTH_USER,
      payload: returnData,
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    dispatch({
      type: SET_AUTH_USER,
      payload: {
        isLoading: false,
      },
    });
    result = Promise.reject(error);
  }
  return result;
};

export const userUpdatePassword = (payload) => async (dispatch) => {
  let result;
  try {
    const { data, status } = await apiService.post(
      '/auth/edit_password',
      {
        ...payload,
      },
      generateHeaderRequest(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Successfully updated your account.',
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    dispatch({
      type: SET_AUTH_USER,
      payload: {
        isLoading: false,
      },
    });
    result = Promise.reject(error);
  }
  return result;
};

export const forgotPassword = (payload) => async (dispatch) => {
  let outcome;
  dispatch({
    type: SET_AUTH_USER,
    payload: {
      isLoading: true,
    },
  });

  try {
    const { data } = await apiService.post(
      `auth/forgot`,
      payload,
      generateHeaderRequest(),
    );

    // console.log(data);
    dispatch({
      type: SET_AUTH_USER,
      payload: {
        // email: data?.email,
        ...data,
        isLoading: false,
      },
    });
    outcome = Promise.resolve();
  } catch (err) {
    dispatch({
      type: SET_AUTH_USER,
      payload: {
        isLoading: false,
      },
    });
    outcome = Promise.reject();
  }

  return outcome;
};

export const fAuthForward = (payload) => async (dispatch) => {
  let result;
  dispatch({
    type: SET_AUTH_USER,
    payload: {
      isLoading: true,
    },
  });
  try {
    // #old method validation token forward
    // const returnData = {
    //   ...payload,
    //   isLoading: false,
    // };

    // #new method validation token forward
    const { status, data } = await apiService.get('auth/me', {
      headers: {
        Authorization: `Bearer ${payload}`,
      },
    });
    const returnData = {
      ...data.data,
      isLoading: false,
    };
    // -----------------------
    setAuthToken(returnData);
    dispatch({
      type: SET_AUTH_USER,
      payload: returnData,
    });
    result = Promise.resolve(returnData);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    dispatch({
      type: SET_AUTH_USER,
      payload: {
        isLoading: false,
      },
    });
    result = Promise.reject(error);
  }
  return result;
};
